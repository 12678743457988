import { WP_API_URL } from '@/constants/API'
import { get } from '../lib/fetch-data'

const url = `${WP_API_URL}/wp-json/api/blog`
type FetchParams = {
  page: number
  order: 'ASC' | 'DESC'
  search?: string
}

export const fetchByAll = (params: FetchParams) => get(url, params).then((response) => {
  if (response) {
    if (response.http_code === 200) {
      return response.data
    }
  }
})

export const fetchById = (slug: string) => get(`${url}/${slug}`).then((response) => {
  if (response) {
    if (response.http_code === 200) {
      return response.data
    }
  }
})
