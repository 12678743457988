import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { Button, Input } from '@/ui/atoms'
import { open } from '@/redux/actions/popup-action'
import { Checkbox, PopupSignIn } from '@/ui/molecules'
import { Store } from '@/redux/store/store.interface'
import { UserDTO } from '@/api/origin/api'
import { FieldArray, Formik } from 'formik'
import { isEmailHasProblem, isPhoneHasProblem } from '@/lib/form'
import { InquireFormType } from '@/redux/types/inquiries-form-type'
import { postInquireFormAction } from '@/redux/actions/inquiry-form-action'
import { PopupInquiryFormByClassified } from '@/ui/molecules/popup-inquiry-form-by-classified'
import styles from './form-put-a-request-classified.module.scss'

const getSelectorUser = () => ({ auth }: Store): UserDTO | null => auth.user || {}

interface IForm {
  name: string
  phone: string
  isEmail: boolean
  email: string
  request: {
    details: string
    url: string
  }[]
}

export const FormPutARequestClassified = () => {
  const dispatch = useDispatch()
  const user = useSelector<Store, any>(getSelectorUser())
  const [popupViewed, setPopupViewed] = useState(false)

  const initialFieldsValues: IForm = {
    name: '',
    phone: '',
    email: '',
    isEmail: false,
    request: [
      {
        details: '',
        url: '',
      },
    ],
  }

  return (
    <LazyLoadComponent>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>
          Send your
          <span className={styles.titleDark}> request</span>
        </h1>
        <div className={styles.text}>
          Use one of the websites above to find a vehicle in Europe , then copy the link(s) and paste it to the text box
          below and sends request
        </div>
        <div>
          <Formik initialValues={initialFieldsValues} validate={handleValidate} onSubmit={handleRequest}>
            {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue, touched }) => (
              <form>
                <div>
                  <FieldArray
                    name="request"
                    render={(arrayHelpers) => (
                      <div>
                        {values.request.map((request, idx) => (
                          <div key={idx} className={styles.fieldWrapper}>
                            <div className={styles.fieldRow}>
                              <Input
                                name={`request.${idx}.url`}
                                wrapperClassName={styles.field}
                                value={request.url}
                                onChange={handleChange}
                                placeholder="htpps://"
                                label="URL"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                              <button
                                className={styles.button}
                                type="button"
                                onClick={() => arrayHelpers.push({ details: '', url: '' })}
                                // disabled={values.request[idx].url.length >= 5}
                              >
                                + Add
                              </button>
                            </div>
                            <Input
                              label="Comments"
                              placeholder="Ask any additional information about vehicles"
                              name={`request.${idx}.details`}
                              value={request.details}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              multiline
                              rows={4}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  />

                  <Input
                    wrapperClassName="mb-32"
                    name="name"
                    id="name"
                    label="Name"
                    value={
                      values.name && values.name.length
                        ? values.name
                        : user.firstName && user.lastName
                          ? `${user.firstName} ${user.lastName}`
                          : ''
                    }
                    isError={Boolean(errors.name && touched.name)}
                    errorText={errors.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Input
                    wrapperClassName="mb-16"
                    name="email"
                    id="email"
                    label="Email"
                    value={values.email && values.email.length ? values.email : user.email ? user.email : ''}
                    isError={Boolean(errors.email && touched.email)}
                    errorText={errors.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Checkbox
                    className="mb-32"
                    name="shipping-insurance"
                    classNameContent={styles.shippingInsuranceContent}
                    checked={values.isEmail}
                    onChange={handleFieldsChange(setFieldValue, 'isEmail')}
                  >
                    Email is my preferred means of communication
                  </Checkbox>
                  <Input
                    wrapperClassName="mb-32"
                    name="phone"
                    id="phone"
                    label="Phone"
                    value={values.phone && values.phone.length ? values.phone : user.phone ? user.phone : ''}
                    isError={Boolean(errors.phone && touched.phone)}
                    errorText={errors.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <Button type="primary" fullWidth onClick={handleSubmit} formSubmitButton aria-label="Send request">
                  Submit request
                </Button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </LazyLoadComponent>
  )

  function handleFieldsChange(setFieldValue: Function, fieldName: string) {
    return (value: number | boolean) => {
      setFieldValue(fieldName, value, true)
    }
  }

  function handleValidate(values: IForm | any) {
    if (!user.id && !popupViewed) {
      dispatch(
        open({
          body: <PopupSignIn />,
        }),
      )
      setPopupViewed(true)
    }
    const errors: IForm | any = {}
    if (!values.name) errors.name = 'Please fill this field'
    if (!values.email) errors.email = 'Please fill this field'
    if (values.email && isEmailHasProblem(values.email)) errors.email = 'Invalid email'
    if (!values.isEmail) {
      if (!values.phone) errors.phone = 'Please fill this field'
      if (values.phone && isPhoneHasProblem(values.phone)) errors.phone = 'Invalid phone'
    }
    return errors
  }

  function handleRequest(values: IForm, { setSubmitting }: { setSubmitting: Function }) {
    setSubmitting(false)
    const params: InquireFormType = {
      name: values.name,
      titleRequest: 'Inquire Form',
    }
    params.phone = values.phone
    params.isEmail = values.isEmail
    params.email = values.email
    params.request = values.request

    dispatch(postInquireFormAction(params))
    dispatch(
      open({
        body: <PopupInquiryFormByClassified />,
        height: 'auto',
        size: 'small',
      }),
    )
  }
}
