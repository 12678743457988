import { ActionsObservable, combineEpics } from 'redux-observable'
import { Action } from 'typescript-fsa'

import { mergeMap, catchError } from 'rxjs/operators'
import { of, from } from 'rxjs'
import { paymentTypeApi } from '@/api'
import { WalletTransactionTypeDto } from '@/api/origin/api'
import { ofAction } from '@/lib/rxjs-operators'

import { WalletTransactionTypesControllerApiIndexWalletTransactionTypesUsingGETRequestAction } from '@/api/origin/action'

import { normalizePaymentTypes } from '@/redux/normalize'

const getWalletTransactionTypes = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(WalletTransactionTypesControllerApiIndexWalletTransactionTypesUsingGETRequestAction.started),
  mergeMap(() => from(paymentTypeApi.indexWalletTransactionTypesUsingGET()).pipe(
    mergeMap(({ data }: { data: WalletTransactionTypeDto[] }) => of(
      WalletTransactionTypesControllerApiIndexWalletTransactionTypesUsingGETRequestAction.done({
        // TODO: fix ts-ignore
        // @ts-ignore
        result: normalizePaymentTypes(data),
      }),
    ),
    ),
    catchError((error) => of(
      WalletTransactionTypesControllerApiIndexWalletTransactionTypesUsingGETRequestAction.failed(error.response),
    ),
    ),
  ),
  ),
)

export const paymentTypesEpic = combineEpics(getWalletTransactionTypes)
