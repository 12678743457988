import { ActionsObservable, combineEpics } from 'redux-observable'
import { Action } from 'typescript-fsa'
import { map, delay, tap } from 'rxjs/operators'

import { create, remove } from '@/redux/actions/snack-action'
import { ofAction } from '@/lib/rxjs-operators'

const openEpic = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(create),
  delay(5000),
  tap((a) => console.log(a)),
  map(({ payload: { id } }) => remove({ id })),
)

export const snackEpic = combineEpics(openEpic)
