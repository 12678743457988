import React from 'react'
import cn from 'classnames'

import styles from './buger-icon.module.scss'

type Props = {
  classNameWrapper?: string
  classNameCircle?: string
  onClick?: () => void
}

export const BurgerIcon: React.FC<Props> = ({ classNameWrapper, classNameCircle, onClick }) => (
  <div className={cn(styles.wrapperComponent, classNameWrapper)} onClick={onClick && onClick}>
    <div className={cn(styles.circle, classNameCircle)} />
  </div>
)
