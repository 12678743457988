import React, { useState } from 'react'
import cn from 'classnames'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import { Button, CustomizedTooltips, Icon } from '@/ui/atoms'
import { LotDto } from '@/api/origin/api'
import { HorizontalCardProperties, VehicleModelsOfAction } from '@/pages/index/page-vehicles/ui/atoms'
import { getCarTitle, formatAuctionDate, formatPrice } from '@/lib'
import { AuctionDate } from '../../atoms/auction-date'
import { Img } from '../vehicle-vertical-card/img'
import styles from './vehicle-horizontal-card.module.scss'

type Props = {
  car: LotDto
} & RouteComponentProps

export const VehicleHorizontalCard = withRouter(
  React.memo(({ car }: Props) => {
    const [showActions, setActionsState] = useState(false)
    const callback = (value: boolean) => setActionsState(value)

    return (
      <LazyLoadComponent>
        <div className={styles.card}>
          <div className={styles.image}>
            <Link to={`/vehicle/${car.externalId}`} target="_blank">
              <Img img={car.images as string[] | undefined} context="vehicleHorizontalCard" />
            </Link>
            <VehicleModelsOfAction
              callback={callback}
              className={cn(styles.modelsOfActionPosition, { [styles.modelsOfActionActive]: showActions })}
              auction={car}
            />
          </div>

          <div className={styles.info}>
            <Link to={`/vehicle/${car.externalId}`} target="_blank" className={styles.link}>
              <CustomizedTooltips title={getCarTitle(car)}>
                <h2 className={styles.title}>{getCarTitle(car)}</h2>
              </CustomizedTooltips>
            </Link>
            <div>
              <HorizontalCardProperties car={car} />
            </div>
          </div>
          <div className={styles.shoppingManagement}>
            <div className={styles.buttonsPosition}>
              <Link to={`/procurement/${car.externalId}`}>
                <Button type="primary">place bid</Button>
              </Link>
              <div className={styles.price} title="Start Price">
                {formatPrice(car)}
              </div>
              <div className={styles.positioningStyleAuctionDate}>
                <CustomizedTooltips title="Auction Date">
                  <div style={{ display: 'flex' }}>
                    <Icon name="icon-card-data-small" width="20px" height="20px" className={styles.CalIcon} />
                    <AuctionDate value={formatAuctionDate(car.auctionDate)} className={styles.auctionDateValue} />
                  </div>
                </CustomizedTooltips>
              </div>
            </div>
          </div>
        </div>
      </LazyLoadComponent>
    )
  }),
)
