import React from 'react'
import cn from 'classnames'

import styles from './step.module.scss'

type Props = {
  num: string
  title: string
  description: string
  icon: any
  className?: string
}

export const Step: React.FC<Props> = ({ num, title, description, icon, className }) => (
  <div className={cn(className, styles.component)}>
    <div className={styles.iconHolder} style={{ backgroundImage: `url(${icon})` }} />
    <div className={styles.contentHolder}>
      <div className={styles.num}>{num}</div>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.description}>{description}</div>
    </div>
  </div>
)
