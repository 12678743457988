import React from 'react'
import { SEOGeneralType } from '@/redux/types/seo-type'
import { Icon, Phone } from '..'
import styles from './address.module.scss'

export const Address: React.FC<{
  info: SEOGeneralType
  className?: string
}> = ({ info }) => (
  <address>
    <div className={styles.address}>
      <span>
        <Icon name="icon-address-geo" width="14px" height="20px" />
      </span>
      <a rel="noopener noreferrer nofollow" target="_blank" href={info.address_map_link} aria-label={info.address}>
        <span className={styles.text}>{info.address}</span>
      </a>
    </div>
    <div className={styles.address}>
      <span>
        <Icon name="icon-address-phone" width="20px" height="20px" />
      </span>
      <Phone className={styles.text} phone={info.phone_number} />
    </div>
    <div className={styles.address}>
      <span>
        <Icon name="icon-address-email" width="20px" height="20px" />
      </span>
      <a rel="noopener noreferrer nofollow" href={`mailto:${info.email}`} aria-label={info.email}>
        <span className={styles.text}>{info.email}</span>
      </a>
    </div>
  </address>
)
