import React, { useState, useRef } from 'react'
import cn from 'classnames'
import { useClickAway } from 'react-use'
import { useSelector } from 'react-redux'
import { Icon } from '@/ui/atoms'
import * as BlogRequest from '@/api/blog'
import { Store } from '@/redux/store/store.interface'
import { SEOAboutUs, SEOFAQ } from '@/redux/types/seo-type'
import { Options } from './options'
import styles from './search.module.scss'

export interface SearchItem {
  href: string
  title: string
}

export class SearchResult {
  blogs: SearchItem[] = []

  otherPage: SearchItem[] = []
}

const getSelectorStore = ({ seo }: Store) => ({
  about_us: seo.about_us,
  faq: seo.faq,
  terms: seo.general.footer.terms_conditions,
  privacy_policy: seo.general.footer.privacy_policy,
})

export const Search: React.FC<{
  className?: string
}> = ({ className }) => {
  const [active, setActive] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [searchResult, setSearchResult] = useState(new SearchResult())
  const ref = useRef(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const { faq, about_us, terms, privacy_policy } = useSelector<
    Store,
    { faq: SEOFAQ; about_us: SEOAboutUs; terms: string; privacy_policy: string }
  >(getSelectorStore)

  const handleClick = (e: any) => {
    e.preventDefault()
    // eslint-disable-next-line no-unused-expressions
    inputRef.current && inputRef.current.focus && inputRef.current.focus()
    setActive(true)
  }

  const handleClickOutside = () => {
    setActive(false)
    setSearchValue('')
    setSearchResult(new SearchResult())
  }

  useClickAway(ref, handleClickOutside)

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
    if (event.target.value.length > 2) {
      findElements(event.target.value)
    }
  }

  const findElements = async (search: string) => {
    const result: SearchResult = new SearchResult()

    const data = await BlogRequest.fetchByAll({
      search,
      page: 0,
      order: 'DESC',
    })
    data.posts.map((post: any) => result.blogs.push({ href: `/blog/${post.slug}`, title: post.title }))

    if (isSearchPage(search, faq.rich_text)) {
      const page: SearchItem = {
        href: '/faq',
        title: 'FAQ',
      }
      result.otherPage.push(page)
    }
    if (isSearchPage(search, about_us.rich_text)) {
      const page: SearchItem = {
        href: '/about-us',
        title: 'About Us',
      }
      result.otherPage.push(page)
    }
    if (isSearchPage(search, terms)) {
      const page: SearchItem = {
        href: '/terms-of-service',
        title: 'Terms of Service',
      }
      result.otherPage.push(page)
    }
    if (isSearchPage(search, privacy_policy)) {
      const page: SearchItem = {
        href: '/privacy-policy',
        title: 'Privacy Policy',
      }
      result.otherPage.push(page)
    }

    setSearchResult(result)
  }

  const isOpenPopup = searchResult.blogs.length > 0 || searchResult.otherPage.length > 0

  return (
    <div ref={ref} className={cn(className, styles.wrapperComponent, { [styles.active]: active })}>
      <label htmlFor="search">
        <input
          id="search"
          ref={inputRef}
          type="text"
          className={styles.input}
          value={searchValue}
          onChange={handleSearch}
          aria-label="Search"
        />
      </label>
      <button
        type="submit"
        className={styles.submit}
        onClick={handleClick}
        name="Search button"
        aria-label="Search button"
      >
        <Icon name="icon-header-search" className={styles.icon} width="21px" height="21px" />
      </button>
      {isOpenPopup && <Options blocks={searchResult} onClick={handleClickOutside} />}
    </div>
  )

  function isSearchPage(search: string, text: string) {
    if (!text) {
      return false
    }
    return Boolean(text.replace(/<[^>]+>/g, '').match(new RegExp(search)))
  }
}
