import { UserDTO, LotDto, OrderDto } from '@/api/origin/api'
import { ProcurementItem } from '@/redux/types/procurement-type'
import { OrderState } from '@/redux/types/order-type'
import { WalletState } from '@/redux/types/wallet-type'
import { Store } from '@/redux/store/store.interface'
import { DataType } from '@/pages/procurement/ui/molecules/side-menu/data'
import { orderInitState } from '@/redux/reducers/order-reducer'
import { walletInitState } from '@/redux/reducers/wallet-reducer'

export const getSelectorPort = () => ({ port }: Store) => port.ports || []
export const getSelectorUser = () => ({ auth }: Store): UserDTO | {} => auth.user || {}
export const getSelectorAuctionById = (id: string): any => ({ auction }: Store): LotDto => auction.byId[id]
export const getSelectorLotById = (id: string): any => ({ lot }: Store): LotDto => lot.byId[id]
export const getSelectorLotsById = (id: string, page: string): any => ({ auction }: Store): LotDto[] =>
  // tslint:disable-next-line
  // auction.passedById?.[id]?.[page] || []
  // eslint-disable-next-line implicit-arrow-linebreak
  (auction && auction.passedById && auction.passedById[id] && auction.passedById[id][page]) || []
export const getSelectorLotsPassedPages = () => ({ auction }: Store): number => auction.passedPages
export const getSelectorBidById = (bidId: number, userId: number = 0): any => ({ bid }: Store): LotDto => {
  if (bidId && bid.byId[bidId] && bid.byId[bidId].userId === userId) {
    return bid.byId[bidId]
  }
  return {}
}

export const getLotStatesById = () => ({ lot }: Store) => lot?.lotStates?.byId
export const getLotStatesAllIds = () => ({ lot }: Store) => lot?.lotStates?.allIds

export const getSelectorBidByLotId = (lotId: number): any => ({ bid }: Store): any => {
  const bidId: string | undefined = bid.allIds.find((bidId: string) => bid.byId[bidId].lotId === lotId)
  return bidId ? bid.byId[bidId] : {}
}

export const getSelectorOrderById = (id: number) => ({ order }: Store): OrderDto => order.byId[id]
export const getSelectorOrderByLotId = (id: number) => ({ order }: Store): OrderDto => {
  const orderId: string | undefined = order.allIds.find((orderId: string) => order.byId[orderId].lotId === id)
  return orderId ? order.byId[orderId] : {}
}

export const getSelectorProcurementByLot = (id: string | undefined): any => ({
  procurement,
}: Store): ProcurementItem | null => (id ? procurement.byId[id] : null)

export const getSelectorMenu = (listMenu: DataType[], id: string) => listMenu.map((item: DataType) => {
  const newItem = { ...item }
  newItem.href = newItem.href.replace(/:id/, id)
  return newItem
})

//
export const getSelectorLotByExternalId = (id: string): any => ({ lot }: Store): LotDto => {
  const lotId: string | undefined = lot.allIds.find((lotId: string) => lot.byId[lotId].externalId === id)
  return lotId ? lot.byId[lotId] : {}
}

export const getSelectorOrderByUserId = (id: number | undefined) => ({ order }: Store): OrderDto => {
  if (!id) {
    return {}
  }
  const orderId: string | undefined = order.allIds.find((orderId: string) => order.byId[orderId].userId === id)
  return orderId ? order.byId[orderId] : {}
}

export const getSelectorOrdersByUserId = (userId: number | undefined) => ({ order }: Store): OrderState => {
  if (!userId) {
    return orderInitState
  }
  const allIds: string[] = order.allIds.filter((id: string) => order.byId[id].userId === userId)
  const byId: any = {}
  allIds.forEach((id: string) => {
    byId[id] = { ...order.byId[id] }
  })
  return { ...order, allIds, byId }
}

export const getSelectorWalletByUserId = (userId: number | undefined) => ({ wallet }: Store): WalletState => {
  if (!userId) {
    return walletInitState
  }
  const allIds: string[] = wallet.allIds.filter((id: string) => wallet.byId[id].userId === userId)
  const byId: any = {}
  allIds.forEach((id: string) => {
    byId[id] = { ...wallet.byId[id] }
  })
  return { ...wallet, allIds, byId }
}
