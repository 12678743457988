import React from 'react'
import { useDispatch } from 'react-redux'
import { open } from '@/redux/actions/popup-action'
import { API_URL } from '@/constants/API'
import { authorize } from '@/redux/actions/auth-action'
import { isEmailHasProblem, isPasswordHasProblem } from '../../../lib/form'
import { Button, Input, Icon, Checkbox } from '../../atoms'
import { PopupSignUp, PopupResetPassword } from '..'
import styles from './popup-sign-in.module.scss'

export const PopupSignIn = () => {
  const {
    state: { email, password, isEmailValid, isPasswordValid, isValid, rememberMe },
    setEmail,
    setPassword,
    setRememberMe,
    signIn,
  } = useSignIn()

  const dispatch = useDispatch()

  const openSignUp = () => dispatch(
    open({
      body: <PopupSignUp />,
    }),
  )

  const openChangePassword = () => dispatch(
    open({
      body: <PopupResetPassword />,
    }),
  )

  const signInUser = (e: any) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      signIn()
    }
  }

  return (
    <>
      <div className={styles.title}>Sign In</div>
      <div className={styles.wrapperSocial}>
        <a href={`${API_URL}/auth/google?scope=email%20profile`}>
          <Icon name="icon-login-google" width="40px" height="40px" className={styles.socialIcon} />
        </a>
      </div>
      <div className={styles.useMailText}>or use your email account:</div>
      <div className={styles.inputWrapper}>
        <Input
          wrapperClassName="mb-20"
          name="mail"
          id="mail"
          label="Email"
          value={email}
          type="email"
          isError={Boolean(email) && !isEmailValid}
          errorText={Boolean(email) && !isEmailValid ? 'Invalid Email Address' : ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.currentTarget.value)}
          onKeyDown={signInUser}
          autoComplete="email"
        />
        <Input
          wrapperClassName="mb-20"
          name="password"
          id="password"
          label="Password"
          type="password"
          value={password}
          isError={Boolean(password) && !isPasswordValid}
          errorText={Boolean(password) && !isPasswordValid ? 'Invalid Password' : ''}
          helpText="Must be at least 5 characters long"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.currentTarget.value)}
          onKeyDown={signInUser}
        />
        <div className={styles.optionWrapper}>
          <Checkbox
            name="subNews"
            checked={rememberMe || false}
            onChange={() => setRememberMe(!rememberMe)}
            className={styles.rememberMeText}
          >
            Remember me
          </Checkbox>
          <div className={styles.forgotPassText} onClick={openChangePassword}>
            Forgot your password?
          </div>
        </div>
        <Button type="primary" disabled={!isValid} fullWidth onClick={signIn} aria-label="Login">
          Login
        </Button>
      </div>
      <div className={styles.changeLoginOption}>
        Don't have an account?
        <span className={styles.changeLoginOptionLink} onClick={openSignUp}>
          Sign Up
        </span>
      </div>
    </>
  )
}

type Action =
  | { type: 'setPassword'; payload: string }
  | { type: 'setEmail'; payload: string }
  | { type: 'setRememberMe'; payload: boolean }

type State = {
  password: string
  email: string
  rememberMe: boolean
}

const initialState: State = {
  password: '',
  email: '',
  rememberMe: true,
}

const reducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case 'setEmail':
      return { ...state, email: action.payload }
    case 'setPassword':
      return { ...state, password: action.payload }
    case 'setRememberMe':
      return { ...state, rememberMe: action.payload }
    default:
      return state
  }
}

export const useSignIn = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const dispatchRedux = useDispatch()
  const setPassword = (payload: string) => dispatch({ type: 'setPassword', payload })
  const setEmail = (payload: string) => dispatch({ type: 'setEmail', payload })
  const setRememberMe = (payload: boolean) => dispatch({ type: 'setRememberMe', payload })

  const signIn = () => {
    dispatchRedux(
      authorize({
        managedUserVM: {
          username: state.email,
          password: state.password,
          'remember-me': state.rememberMe,
        },
      }),
    )
  }

  const isEmailValid = !isEmailHasProblem(state.email)
  const isPasswordValid = !isPasswordHasProblem(state.password)
  const isValid = isEmailValid && isPasswordValid

  return {
    state: {
      ...state,
      isEmailValid,
      isPasswordValid,
      isValid,
    },
    setPassword,
    setEmail,
    setRememberMe,
    signIn,
  }
}
