import { ActionsObservable, combineEpics, StateObservable, ofType } from 'redux-observable'
import { Action } from 'typescript-fsa'
import { initialYear } from '@/redux/reducers/filter-reducer'
import { mergeMap, catchError, withLatestFrom, pluck } from 'rxjs/operators'
import { of, from } from 'rxjs'

import {
  AvtoJpLotsFacadeControllerApiIndexAuctionLotsUsingGETRequestAction,
  AvtoJpLotsFacadeControllerApiFindAuctionLotByIdUsingGETRequestAction,
  LotResourceApiGetLotUsingGETRequestAction,
  AvtoJpLotsFacadeControllerApiIndexPassedAuctionLotsUsingGETRequestAction,
} from '@/api/origin/action'
import { auctionApi, lotApi } from '@/api'
import { ofAction, ofActions } from '@/lib/rxjs-operators'
import { NOTHING } from '@/redux/actions/auth-action'
import * as VehiclesActions from '@/redux/actions/auction-action'
import * as FilterActions from '@/redux/actions/filter-action'
import { Store } from '@/redux/store/store.interface'
import { routerActions, LOCATION_CHANGE } from 'connected-react-router'
import { parse } from 'query-string'
import { rangeFilterToString, makeFilterQueryParams } from '@/lib'
import { GridType } from '@/redux/types/auction-type'
import { normalizeAuction } from '@/redux/normalize'
import { LotDto } from '@/api/origin/api'

import { IHeaders } from '@/lib/types'

const findByIdEpic = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(AvtoJpLotsFacadeControllerApiFindAuctionLotByIdUsingGETRequestAction.started),
  mergeMap(({ payload: { id } }) => from(auctionApi.findAuctionLotByIdUsingGET(id)).pipe(
    mergeMap(({ data }: { data: LotDto }) => of(
      AvtoJpLotsFacadeControllerApiFindAuctionLotByIdUsingGETRequestAction.done({ result: data, params: { id } }),
    ),
    ),
    catchError(() => of(NOTHING())),
  ),
  ),
)

const findByAllPassedEpic = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(AvtoJpLotsFacadeControllerApiIndexPassedAuctionLotsUsingGETRequestAction.started),
  // @ts-ignore
  mergeMap(({ payload: { kuzov, markaName, modelName, options, page, size, sort, year } }) => from(
    auctionApi.indexPassedAuctionLotsUsingGET(markaName, modelName, year, kuzov, page, size, sort, options),
  ).pipe(
    mergeMap(({ data, headers }: { data: LotDto[]; headers: IHeaders }) => of(
      AvtoJpLotsFacadeControllerApiIndexPassedAuctionLotsUsingGETRequestAction.done({
        result: data, // todo wrap to normalize
        params: {
          kuzov,
          markaName,
          modelName,
          page,
          size,
          sort,
          year,
          options: {
            ...options,
            pageCount: Math.ceil(Number(headers['x-total-count']) / size),
          },
        },
      }),
    ),
    ),
    catchError(() => of(NOTHING())),
  ),
  ),
)

const findByAllEpic = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(AvtoJpLotsFacadeControllerApiIndexAuctionLotsUsingGETRequestAction.started),
  mergeMap(
    ({
      payload: {
        auction,
        color,
        engineDisplacementCc,
        make,
        mileageMi,
        model,
        page,
        price,
        priceUSD,
        size,
        sort,
        transmissionType,
        year,
        options,
      },
    }) => from(
      auctionApi.indexAuctionLotsUsingGET(
        auction,
        color,
        engineDisplacementCc,
        make,
        mileageMi,
        model,
        page ? page - 1 : undefined,
        price,
        priceUSD,
        size,
        ['auction_type,desc'],
        transmissionType,
        year,
        options,
      ),
    ).pipe(
      mergeMap((result) => of(
        AvtoJpLotsFacadeControllerApiIndexAuctionLotsUsingGETRequestAction.done({
          // TODO: fix ts-ignore
          // @ts-ignore
          result: normalizeAuction(result.data),
          params: {
            auction,
            color,
            engineDisplacementCc,
            make,
            mileageMi,
            model,
            page,
            price,
            priceUSD,
            size,
            sort,
            transmissionType,
            year,
            options,
          },
        }),
      ),
      ),
      catchError((error) => {
        console.error(error.response)
        return of(NOTHING())
      }),
    ),
  ),
)

const changeFilterEpic = (action$: ActionsObservable<Action<any>>, store$: StateObservable<Store>) => action$.pipe(
  ofActions([
    VehiclesActions.changeCurrentPage,
    // VehiclesActions.changeTypeGrid,
    FilterActions.changePlainFilter,
    FilterActions.changeRangeFilter,
  ]),
  withLatestFrom(store$.pipe(pluck('auction')), store$.pipe(pluck('filter'))),
  mergeMap(
    ([
      {
        payload: { name },
      },
      auctionStore,
      filterStore,
    ]) => {
      const page = !name ? auctionStore.number : 1
      const grid = auctionStore.grid
      const { lang } = store$.value.lang
      let prefilter = ''

      if (filterStore.prefilter !== '' && name !== 'MAKE' && name !== 'MODEL') {
        prefilter = `/${filterStore.prefilter}`
      }
      let path = `/vehicles${prefilter}?page=${page}&grid=${grid}&${makeFilterQueryParams(
        filterStore,
        prefilter === '',
        name === 'MAKE',
      )}`
      if (lang !== '') {
        path = `/${lang}${path}`
      }
      return of(routerActions.push(path))
    },
  ),
)

const initVehiclesPageEpic = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(VehiclesActions.initVehiclesPage),
  mergeMap(
    ({
      payload: {
        auction,
        color,
        engineDisplacementCc,
        make,
        mileageMi,
        model,
        page,
        price,
        priceUSD,
        size,
        // sort,
        transmissionType,
        year,
        options,
      },
    }) => of(
      AvtoJpLotsFacadeControllerApiIndexAuctionLotsUsingGETRequestAction.started({
        auction,
        color,
        engineDisplacementCc,
        make: make ? [make.join(',')] : undefined,
        mileageMi,
        model: model ? [model.join(',')] : undefined,
        page,
        price,
        priceUSD,
        size,
        sort: ['auction_type,desc'],
        transmissionType,
        year,
        options,
      }),
    ),
  ),
)

const locationChangeEpic = (action$: ActionsObservable<Action<any>>, store$: StateObservable<Store>) => action$.pipe(
  ofType(LOCATION_CHANGE),
  withLatestFrom(store$.pipe(pluck('router')), store$.pipe(pluck('seo'))),
  mergeMap(([, routerStore, seoStore]) => {
    if (routerStore.location.pathname.includes('/vehicles')) {
      const result = parse(routerStore.location.search)

      const grid = result.grid as GridType | undefined
      const page = result.page as number | undefined
      const auction = result.auction as string | undefined
      const color = result.color as string | undefined
      const make = result.make as string | undefined
      const model = result.model as string | undefined
      const priceUSD = result.priceUSD as string | undefined
      const year = (result.year || rangeFilterToString(initialYear)) as string | undefined
      const engineDisplacementCc = result.engineDisplacementCc as string | undefined
      const mileageMi = result.mileageMi as string | undefined
      const transmissionType = result.transmissionType as string | undefined

      const resultQuery = {
        make: make ? make.split(',') : [],
        model: model ? model.split(',') : [],
      }

      const path = routerStore.location.pathname.split('/')
      let prefixURL = ''

      if (path.length > 2) {
        prefixURL = path[2].split('?')[0]
        const pageVariant = seoStore.vehicles.page_variants.find((page: any) => page.slug === prefixURL)
        if (pageVariant) {
          const result = parse(pageVariant.url_with_applied_filters)
          if (result.make) {
            resultQuery.make = [...new Set([...resultQuery.make, ...(result.make as string).split(',')])]
          }
          if (result.model) {
            resultQuery.model = [...new Set([...resultQuery.model, ...(result.model as string).split(',')])]
          }
        }
      }

      return [
        VehiclesActions.initVehiclesPage({
          auction,
          color,
          engineDisplacementCc,
          make: resultQuery.make.length ? resultQuery.make : undefined,
          model: resultQuery.model.length ? resultQuery.model : undefined,
          mileageMi,
          page,
          price: undefined,
          priceUSD,
          size: 12,
          sort: ['auction_type,desc'],
          transmissionType,
          year,
          grid: grid || 'grid',
          options: {},
          prefilter: prefixURL,
        }),
      ]
    }
    return of(NOTHING())
  }),
)

const getLotByIdEpic = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(LotResourceApiGetLotUsingGETRequestAction.started),
  mergeMap(({ payload: { id } }) => from(lotApi.getLotUsingGET(id)).pipe(
    mergeMap(({ data }: { data: any }) => of(
      LotResourceApiGetLotUsingGETRequestAction.done({
        result: data,
        params: {
          id,
        },
      }),
    ),
    ),
    catchError((error) => of(LotResourceApiGetLotUsingGETRequestAction.failed(error.response))),
  ),
  ),
)

export const auctionEpic = combineEpics(
  findByIdEpic,
  findByAllEpic,
  changeFilterEpic,
  locationChangeEpic,
  initVehiclesPageEpic,
  getLotByIdEpic,
  findByAllPassedEpic,
)
