import React from 'react'

import { DefaultPopup } from '@/ui/molecules'

export const PopupExceededComparison: React.FC<{ d: () => void; cancel: () => void }> = ({ d, cancel }) => (
  <DefaultPopup
    title="Oops"
    body="You can have only 3 cars in Comparison List. Please remove one to add another one."
    cancel={cancel}
    d={d}
    buttonText="Remove"
  />
)
