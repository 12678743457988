import React, { useState, useCallback, useMemo, useEffect } from 'react'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { Pagination, TableMaterialUI } from '@/ui/molecules'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Popover from '@material-ui/core/Popover'
import { getUSAFormatPrice, formatAuctionDate, useResize, getCarTitle, getLotStateName, HTMLDecoder } from '@/lib'
import { getCarMil } from '@/lib/getCarMil'
import { Store } from '@/redux/store/store.interface'
import { LotDto, LotStateDto } from '@/api/origin/api'
import { AvtoJpLotsFacadeControllerApiIndexPassedAuctionLotsUsingGETRequestAction } from '@/api/origin/action'
import SalesStatisticsCard from '@/pages/index/page-lot/ui/molecules/sales-statistics-card'
import { defaultTableRowsPerPage } from '@/index'
import {
  getLotStatesById,
  getSelectorLotsById,
  getSelectorLotsPassedPages,
} from '@/redux/selectors/procurement-selector'
import { Icon, PageLoadingPlaceholder } from '@/ui/atoms'
import { DocButton } from '@/pages/index/page-lot/ui/atoms'
import PopoverAuctionSheet from '@/pages/index/page-lot/ui/molecules/popover-auction-sheet'
import { ILot } from './types'
import styles from './popup-sales-statistics.module.scss'

interface IProps {
  auction: LotDto
  id: string
  cancel: () => void
}

const PopupSalesStatistics: React.FC<IProps> = ({ auction, id, cancel }) => {
  const dispatch = useDispatch()
  const { make, chassis, year, model } = auction
  const windowSize = useResize()
  const [page, setPage] = useState(1)

  const [currentLot, setCurrentLot] = useState<LotDto | undefined>(undefined)

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const handleActionSheetOpen = (event: React.MouseEvent<HTMLButtonElement>, auction: LotDto | undefined) => {
    setCurrentLot(auction)
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setCurrentLot(undefined)
    setAnchorEl(null)
  }

  const handleCloseAuctionSheetPop = (currentLot: any, anchorEl: any) => {
    setCurrentLot(currentLot)
    setAnchorEl(anchorEl)
  }

  const open = Boolean(anchorEl)
  const popoverId = open ? 'auction-sheet' : undefined

  const passedLots = useSelector<Store, LotDto[]>(getSelectorLotsById(id, `page-${page}`))
  const loading = useSelector<Store, boolean>((state: Store) => state.auction.isLoading)
  const countPage = useSelector<Store, number>(getSelectorLotsPassedPages())
  const lotStates = useSelector<Store, { [key: number]: LotStateDto }>(getLotStatesById())
  const columns = [
    { name: 'image', title: ' ' },
    { name: 'doc', title: ' ' },
    { name: 'vehicle', title: 'Vehicle' },
    { name: 'auctionDate', title: 'Auction Date' },
    { name: 'chassisID', title: 'ChassisID' },
    { name: 'mileage', title: 'Mileage' },
    { name: 'condition', title: 'Condition' },
    { name: 'soldFor', title: 'Sold For' },
    { name: 'startingBid', title: 'Starting Bid' },
    { name: 'purchaseStatus', title: 'Purchase Status' },
  ]

  useEffect(() => {
    if (!passedLots.length && loading) {
      dispatch(
        AvtoJpLotsFacadeControllerApiIndexPassedAuctionLotsUsingGETRequestAction.started({
          kuzov: chassis,
          markaName: make || '',
          modelName: model || '',
          page: page ? page - 1 : 0,
          size: defaultTableRowsPerPage,
          sort: ['auction_type,asc'],
          year: year || 0,
          options: {
            id,
            page,
          },
        }),
      )
    }
  })

  const memoGetRows = useMemo(
    () => passedLots.map((el: LotDto) => ({
      id: `${el.id}-${el.number}`,
      image: (el && el.images && el.images[1]) || '',
      doc: '',
      vehicle: getCarTitle(el),
      auctionDate: formatAuctionDate(el.auctionDate),
      chassisID: HTMLDecoder(el.chassis),
      mileage: getCarMil(el.mileage),
      condition: el.rate,
      soldFor: getUSAFormatPrice(el.finalPrice || 0),
      startingBid: getUSAFormatPrice(el && el.price ? el.price : 0),
      purchaseStatus: getLotStateName(lotStates, el?.stateId || -1),
      auction: el,
    })),
    [passedLots, lotStates],
  )

  const memoFormatRows = useCallback(
    (arr: ILot[]) => arr.map((el: ILot) => {
      const getImage = (src: string) => (
        <div className={styles.row_carPreview}>
          <img src={src} alt="car preview" className={styles.carPreview_image} />
        </div>
      )
      return {
        id: el.id,
        image: getImage((el && el.image) || ''),
        doc: <DocButton auction={el.auction} onClick={handleActionSheetOpen} />,
        vehicle: (
          <Typography variant="body2" style={{ fontWeight: 'bold' }}>
            {el.vehicle}
          </Typography>
        ),
        auctionDate: <Typography variant="body2">{el.auctionDate}</Typography>,
        chassisID: <Typography variant="body2">{el.chassisID}</Typography>,
        mileage: <Typography variant="body2">{el.mileage}</Typography>,
        condition: <Typography variant="body2">{el.condition}</Typography>,
        soldFor: <Typography variant="body2">{el.soldFor}</Typography>,
        startingBid: <Typography variant="body2">{el.startingBid}</Typography>,
        purchaseStatus: <Typography variant="body2">{el.purchaseStatus}</Typography>,
      }
    }),
    [],
  )

  const columnExtensions = [
    { columnName: 'image', width: 120 },
    { columnName: 'doc', width: 45 },
    { columnName: 'vehicle', width: 250 },
    { columnName: 'purchaseStatus', width: 150 },
  ]

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography className={cn(styles.title)} variant="h4">
          Sales Statistics
        </Typography>
        <button className={styles.popupCloseIcon} onClick={cancel}>
          <Icon className={styles.iconPopup} name="icon-popup-close" width="16px" height="16px" />
        </button>
      </div>
      <Popover
        classes={{
          paper: styles.popover_paper,
        }}
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <PopoverAuctionSheet auction={currentLot} onClose={handleCloseAuctionSheetPop} isClose />
      </Popover>
      <>
        {memoGetRows.length ? (
          <>
            <div className={styles.containerTable}>
              {windowSize < 900 ? (
                <div className={styles.card_container}>
                  <Grid container spacing={2} justify="center">
                    {memoGetRows.map((el) => (
                      <SalesStatisticsCard key={el.id} onClick={handleActionSheetOpen} {...el} />
                    ))}
                  </Grid>
                </div>
              ) : (
                <TableMaterialUI
                  columns={columns}
                  rows={memoFormatRows(memoGetRows)}
                  columnExtensions={columnExtensions}
                />
              )}
            </div>
            <Grid container justify="center">
              <Pagination currentPage={page} totalPage={countPage} changePageNumber={setPage} />
            </Grid>
          </>
        ) : loading ? (
          <Grid container justify="center">
            <Typography variant="subtitle1">Sorry, no matches were found</Typography>
          </Grid>
        ) : (
          <PageLoadingPlaceholder className={styles.loader} />
        )}
      </>
    </div>
  )
}

export default PopupSalesStatistics
