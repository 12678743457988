import React, { useRef, useEffect, useState } from 'react'
import { FormHelperText, Checkbox, ListItemText } from '@material-ui/core'
import { StylesProvider } from '@material-ui/styles'
import {
  STChipWrap,
  STFormControl,
  STSelect,
  STMenuItem,
  STChip,
  STInputLabel,
  STOutlinedInput,
  STListSubheader,
} from './styles'

interface IProps<T, ItemType> {
  value: T
  keyAsTitle: keyof ItemType
  keyAsValue: keyof ItemType
  items: ItemType[]
  groupItems?: { items: ItemType[]; title: string }[]
  label: string
  onChange: (value: ItemType[]) => void
  className?: string
  isTouched?: boolean
  isError?: boolean
  errorText?: React.ReactNode
  helpText?: React.ReactNode
  disabled?: boolean
  notChip?: boolean
  isMake?: boolean
}
export const FormMultiselect = <T, ItemType>({
  value,
  keyAsTitle,
  keyAsValue,
  items,
  groupItems,
  label,
  onChange,
  className,
  isTouched,
  isError,
  errorText,
  helpText,
  disabled,
  notChip,
  isMake,
}: IProps<T, ItemType>) => {
  const inputLabel = useRef<HTMLLabelElement>(null)
  const [labelWidth, setLabelWidth] = useState(0)
  useEffect(() => {
    handleFocus()
    const values = (value as any) as []
    if (values.length === 0) {
      setLabelWidth(0)
    }
  }, [value, inputLabel])
  const getItemCheckedState = (item: ItemType) => {
    const values = (value as any) as []
    const foundedItem = values.findIndex((str: string | number) => String(str) === String(item[keyAsValue]))
    return foundedItem
  }
  const handleBlur = () => {
    const values = (value as any) as []
    if (values.length === 0) {
      setLabelWidth(0)
    }
  }
  const handleFocus = () => {
    if (inputLabel && inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth + 8 || 0)
    }
  }
  const handleChange = (event: any) => {
    const values = (event.target.value as any) as string[]
    const newValue = items.filter((item: ItemType) => values.includes(String(item[keyAsValue])))
    onChange(newValue)
  }
  const handleDelete = (itemRemove: ItemType) => () => {
    const values = (value as any) as string[]
    const valueFilter = values.filter((str: string | number) => String(str) !== String(itemRemove[keyAsValue]))
    const newValue = items.filter((item: ItemType) => valueFilter.includes(String(item[keyAsValue])))
    onChange(newValue)
  }
  return (
    <div className={className}>
      <StylesProvider injectFirst>
        <STFormControl variant="outlined" error={isTouched && isError} disabled={disabled}>
          <STInputLabel htmlFor="select-multiple-chip" ref={inputLabel}>
            {label}
          </STInputLabel>
          <STSelect
            variant="outlined"
            data-onindex={notChip}
            multiple
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              transformOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              getContentAnchorEl: null,
            }}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            input={<STOutlinedInput labelWidth={labelWidth} notched={Boolean(label)} name={label} />}
            renderValue={(selected: any) => (notChip ? (
              (selected as string[]).join(', ')
            ) : (
              <STChipWrap>
                {items
                  .filter((item: ItemType) => selected.includes(item[keyAsValue]))
                  .map((value: ItemType) => (
                    <STChip key={value[keyAsValue] as any} label={value[keyAsTitle]} onDelete={handleDelete(value)} />
                  ))}
              </STChipWrap>
            ))
            }
          >
            {isMake && <STListSubheader>Popular</STListSubheader>}
            {isMake && createList(items, true)}
            {isMake && <STListSubheader>All</STListSubheader>}
            {groupItems
              ? groupItems.map((group: any) => {
                const items = createList(group.items)
                items.unshift(
                  <STMenuItem disabled value="">
                    {group.title}
                  </STMenuItem>,
                )
                return items
              })
              : createList(items)}
          </STSelect>
          {isTouched && isError && <FormHelperText>{errorText}</FormHelperText>}
        </STFormControl>
      </StylesProvider>
    </div>
  )
  function createList(items: ItemType[], popular: boolean = false) {
    const POPULAR_BRANDS = ['bmw', 'audi', 'toyota']
    const data = popular ? items.filter((i: any) => POPULAR_BRANDS.indexOf(i.name.toLowerCase()) !== -1) : items
    return data.map((item: ItemType, i: number) => (
      <STMenuItem key={i} value={item[keyAsValue] as any}>
        <Checkbox checked={getItemCheckedState(item) > -1} color="default" />
        <ListItemText primary={item[keyAsTitle] as any} />
      </STMenuItem>
    ))
  }
}
