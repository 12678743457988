import { ActionsObservable, combineEpics } from 'redux-observable'
import { Action } from 'typescript-fsa'
import { mergeMap, catchError } from 'rxjs/operators'
import { of, from } from 'rxjs'
import { controllerApi } from '@/api'
import { ofAction } from '@/lib/rxjs-operators'
import {
  OrdersControllerApiIndexOrdersByManagerUsingGETRequestAction,
  OrdersControllerApiUpdateOrderByManagerUsingPUTRequestAction,
} from '@/api/origin/action'
import { normalizeManagerOrders } from '@/redux/normalize'
import { OrderDto } from '@/api/origin/api'
import { create } from '@/redux/actions/snack-action'
import nanoid from 'nanoid'

const getManagerOrders = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(OrdersControllerApiIndexOrdersByManagerUsingGETRequestAction.started),
  mergeMap(({ payload: { page, size, sort, state } }) => from(controllerApi.indexOrdersByManagerUsingGET(page, size, sort, state)).pipe(
    mergeMap(({ data, headers }: { data: OrderDto[]; headers: { [key: string]: string } }) => of(
      OrdersControllerApiIndexOrdersByManagerUsingGETRequestAction.done({
        // TODO: fix ts-ignore
        // @ts-ignore
        result: normalizeManagerOrders(data),
        options: {
          state,
          page,
          size,
          sort,
          pageCount: Math.ceil(Number(headers['x-total-count']) / size),
        },
      }),
    ),
    ),
    catchError((error) => of(OrdersControllerApiIndexOrdersByManagerUsingGETRequestAction.failed(error.response))),
  ),
  ),
)

const updateOrder = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(OrdersControllerApiUpdateOrderByManagerUsingPUTRequestAction.started),
  mergeMap(({ payload: { id, requestBody } }) => from(controllerApi.updateOrderByManagerUsingPUT(id, requestBody)).pipe(
    mergeMap(() => [
      OrdersControllerApiUpdateOrderByManagerUsingPUTRequestAction.done({
        // TODO: fix ts-ignore
        // @ts-ignore
        options: {
          id,
          ...requestBody,
        },
      }),
      create({ body: 'Order update', id: nanoid() }),
    ]),
    catchError((error) => [
      OrdersControllerApiUpdateOrderByManagerUsingPUTRequestAction.failed(error.response),
      create({ body: 'Order update failed', id: nanoid() }),
    ]),
  ),
  ),
)

export const managerOrdersEpic = combineEpics(getManagerOrders, updateOrder)
