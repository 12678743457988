import React from 'react'

import styles from './social.module.scss'
import { Icon } from '..'

type DataType = {
  id: number
  icon: string
  href: string
  target: string
  name: string
}

export const Social = ({ socialList }: any) => (
  <div className={styles.social}>
    {socialList.map((item: DataType) => (
      <a
        rel="noopener noreferrer nofollow"
        key={item.id}
        target={item.target}
        href={item.href}
        className={styles.link}
        aria-label={item.name}
      >
        <Icon name={item.icon} />
      </a>
    ))}
  </div>
)
