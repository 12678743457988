import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import { useClickAway } from 'react-use'
import { useSelector, useDispatch } from 'react-redux'
import { Icon } from '@/ui/atoms'
import { Store } from '@/redux/store/store.interface'
import { UserDTO, LotDto, BidUpdateManagerRequestStateEnum } from '@/api/origin/api'
import { UserResourceApiIndexBidsUsingGETRequestAction } from '@/api/origin/action'
import { Options } from './options'
import styles from './styles.module.scss'

type Props = {
  classNames?: string
}

const getSelectorBids = () => ({ bid }: Store) => bid
const getSelectorUser = () => ({ auth }: Store): UserDTO | null => auth.user || {}
const getSelectorLots = () => ({ lot }: { lot: any }): LotDto[] => lot

export const CardBids: React.FC<Props> = ({ classNames }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const refForClickAway = React.useRef(null)
  const user = useSelector<Store, any>(getSelectorUser())
  const bids = useSelector<Store, any>(getSelectorBids())
  const lots = useSelector<Store, any>(getSelectorLots())
  const dispatch = useDispatch()

  const isLoad = bids.isLoading && lots.isLoading

  useEffect(() => {
    if (user.id && !bids.isLoading && isMenuOpen) {
      dispatch(UserResourceApiIndexBidsUsingGETRequestAction.started({ userId: user.id, page: 0, size: 100000 }))
    }
  }, [bids.isLoading, dispatch, isMenuOpen, user.id])

  const filterBidsId = bids.allIds
    .filter((id: string) => bids.byId[id].state === BidUpdateManagerRequestStateEnum.ACTIVE)
    .sort((a: string, b: string) => Number(b) - Number(a))
    .slice(0, 4)

  const openMenu = () => setIsMenuOpen(!isMenuOpen)
  const closeMenu = () => setIsMenuOpen(false)

  useClickAway(refForClickAway, closeMenu)

  return (
    <div ref={refForClickAway} onClick={openMenu} className={cn(classNames, styles.wrapperSection)}>
      <div className={styles.pathIcon}>
        <Icon name="icon-header-path" width="20px" height="20px" title="Active bids" />
      </div>
      {user.id && isLoad && <Options isOpen={isMenuOpen} bids={bids} lots={lots} filterBidsId={filterBidsId} />}
    </div>
  )
}
