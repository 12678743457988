import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { ComparisonListState, ComparisonListActionType } from '@/redux/types/comparison-list-type'
import { addComparison, removeComparison } from '@/redux/actions/comparison-list-action'

export const comparisonListInitState: ComparisonListState = []
export const comparisonListReducer = reducerWithInitialState<ComparisonListState>(comparisonListInitState)
  .case(addComparison, (state, { externalId }: ComparisonListActionType) => [...new Set([...state, externalId])])
  .case(removeComparison, (state, { externalId }: ComparisonListActionType) => [
    ...state.filter((id: string) => id !== externalId),
  ])
  .build()
