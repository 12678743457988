import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { getValueRangeFilter } from '@/lib'
import { FilterState } from '@/redux/types/filter-type'
import { changePlainFilter, changeRangeFilter } from '@/redux/actions/filter-action'
import { FilterResourceApiFindFilterByPlatformAndTypeUsingGETRequestAction } from '@/api/origin/action'
import * as VehiclesActions from '@/redux/actions/auction-action'

// const year = new Date().getFullYear()
const year = 2010
export const initialYear: readonly [number, number] = [year - 99, year]

export const filterInitialState: FilterState = {
  MAKE: { list: [], value: [] },
  MODEL: { list: [], value: [] },
  TRANSMISSION_TYPE: { list: [], value: undefined },
  COLOR: { list: [], value: undefined },
  AUCTION: { list: [], value: undefined },
  PRICE: { value: undefined },
  YEAR: { value: initialYear },
  ENGINE_DISPLACEMENT: { value: undefined },
  MILEAGE: { value: undefined },
  prefilter: '',
}

export const filterReducer = reducerWithInitialState<FilterState>(filterInitialState)
  .case(FilterResourceApiFindFilterByPlatformAndTypeUsingGETRequestAction.done, (state, { params, result }) => ({
    ...state,
    [params.filterType]: { ...state[params.filterType], list: result },
  }))
  // @ts-ignore
  .case(changePlainFilter, (state, { name, value }) => ({ ...state, [name]: { ...state[name], value } }))
  .case(changeRangeFilter, (state, { name, value }) => ({ ...state, [name]: { ...state[name], value } }))
  // .case(resetFilter, (_) => filterInitialState)
  .case(
    VehiclesActions.initVehiclesPage,
    (
      state,
      { auction, color, engineDisplacementCc, make, mileageMi, model, priceUSD, transmissionType, year, prefilter },
    ) => ({
      ...state,
      AUCTION: { ...state.AUCTION, value: auction },
      COLOR: { ...state.COLOR, value: color },
      ENGINE_DISPLACEMENT: {
        ...state.ENGINE_DISPLACEMENT,
        value: engineDisplacementCc ? (getValueRangeFilter(engineDisplacementCc) as any) : undefined,
      },
      MAKE: { ...state.MAKE, value: make || [] },
      MODEL: { ...state.MODEL, value: model || [] },
      MILEAGE: {
        ...state.MILEAGE,
        value: mileageMi ? (getValueRangeFilter(mileageMi) as any) : undefined,
      },
      PRICE: { value: priceUSD ? (getValueRangeFilter(priceUSD) as any) : undefined },
      TRANSMISSION_TYPE: {
        ...state.TRANSMISSION_TYPE,
        value: transmissionType,
      },
      YEAR: { ...state.YEAR, value: year ? (getValueRangeFilter(year) as any) : initialYear },
      prefilter,
    }),
  )
  .build()
