export const getUSAFormatPrice = (value: number, withName = false) => {
  const val = value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  return withName ? `${val} USD` : val
}
