import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { ManagerBidsState } from '@/redux/types/manager-bids-type'
import {
  BidsReportRowsControllerApiIndexBidsReportRowsUsingGETRequestAction,
  BidsControllerApiUpdateBidByManagerUsingPUTRequestAction,
  OrdersControllerApiCreateOrderUsingPOSTRequestAction,
} from '@/api/origin/action'
import { logout } from '@/redux/actions/auth-action'
import { defaultTableRowsPerPage } from '@/index'
import { BidDtoStateEnum } from '@/api/origin/api'

export const managerBidsInitState: ManagerBidsState = {
  byId: {},
  allIds: [],
  byIdBids: {},
  allIdsBids: [],
  bidState: BidDtoStateEnum.ACTIVE,
  currentPage: 0,
  size: defaultTableRowsPerPage,
  pageCount: 0,
  isLoading: false,
}

export const managerBidsReducer = reducerWithInitialState<ManagerBidsState>(managerBidsInitState)
  .case(logout, () => managerBidsInitState)
  .case(BidsReportRowsControllerApiIndexBidsReportRowsUsingGETRequestAction.started, (state) => ({
    ...state,
    isLoading: false,
  }))
  .case(BidsReportRowsControllerApiIndexBidsReportRowsUsingGETRequestAction.done, (_, { result, options }: any) => ({
    byId: {
      ...result.report.byId,
    },
    allIds: [...result.report.allIds],
    byIdBids: {
      ...result.bids.byId,
    },
    allIdsBids: [...result.bids.allIds],
    bidState: options.bidState,
    currentPage: options.page,
    size: options.size,
    pageCount: options.pageCount,
    isLoading: true,
  }))
  .case(BidsControllerApiUpdateBidByManagerUsingPUTRequestAction.done, (state, { options }: any) => ({
    ...state,
    byIdBids: {
      ...state.byIdBids,
      [options.id]: {
        ...state.byIdBids[options.id],
        ...options,
      },
    },
  }))
  .case(OrdersControllerApiCreateOrderUsingPOSTRequestAction.started, (state) => ({
    ...state,
    isLoading: false,
  }))
  // eslint-disable-next-line
  .case(OrdersControllerApiCreateOrderUsingPOSTRequestAction.done, (state, { result, params }: any) => {
    return {
      ...state,
      isLoading: true,
    }
  })
  .build()
