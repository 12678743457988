import React from 'react'
import cn from 'classnames'
import { FooterNavigation, FooterLatestNews } from '@/ui/molecules'
import { SEOGeneralType } from '@/redux/types/seo-type'
import { Address, Logo, Social } from '../../atoms'
import styles from './footer-section.module.scss'

type Props = {
  className?: string
  info: SEOGeneralType
}

const getSelectorSocialList = (socialObj: any) => Object.keys(socialObj).map((key, index) => ({
  id: index + 1,
  icon: `icon-${key}`,
  href: socialObj[key].url,
  target: socialObj[key].target,
  name: key,
}))

export const FooterSection: React.FC<Props> = ({ className, info }) => (
  <div className={cn(className, styles.wrapperSection)}>
    <div className={cn('container')}>
      <div className={styles.linksSection}>
        <div className={styles.address}>
          <Logo className={styles.addressLogo} />
          <Address info={info} />
        </div>
        <FooterNavigation />
        <FooterLatestNews className={styles.footerLatestNews} />
      </div>
      <div className={styles.socialSection}>
        <Social socialList={getSelectorSocialList(info.social_networks)} />
      </div>
    </div>
  </div>
)
