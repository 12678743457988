import { post } from '@/lib/fetch-data'
import { RequestACallType } from '@/redux/types/request-call-type'
import { WP_API_URL } from '@/constants/API'

const url = `${WP_API_URL}/wp-json/api/email/contact-us`
export const postRequest = (params: RequestACallType) => post(url, params).then((response) => {
  if (response) {
    if (response.http_code === 200) {
      return response.data
    }
  }
})
