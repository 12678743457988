import { WP_API_URL } from '@/constants/API'
import { get } from '../lib/fetch-data'

const url = `${WP_API_URL}/wp-json/api/general`
export const fetch = () => get(url).then((response) => {
  if (response) {
    if (response.http_code === 200) {
      return response.data
    }
  }
})
