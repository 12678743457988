import React from 'react'
import { Icon } from '@/ui/atoms'

import styles from './snackbar-remove-comparison.module.scss'

export const SnackbarRemoveComparison: React.FC<{ name: string }> = ({ name }) => (
  <div className={styles.wrapperContent}>
    <Icon name="icon-snackbar-success" width="40px" height="40px" />
    <div>
      <div className={styles.messageText}>
        {name}
        {' '}
        remove to the comparison list.
      </div>
    </div>
  </div>
)
