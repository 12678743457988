import React from 'react'
import { ActionsObservable, combineEpics } from 'redux-observable'
import { Action } from 'typescript-fsa'
import { mergeMap, catchError } from 'rxjs/operators'
import * as Request from '@/api/requestACall'
import { from, of } from 'rxjs'
import { ofAction } from '@/lib/rxjs-operators'
import { postRequestACallAction } from '@/redux/actions/request-call-action'
import { Snackbar } from '@/pages/index/page-comparison-list/ui/atoms'
import { create } from '@/redux/actions/snack-action'
import nanoid from 'nanoid'

const postRequest = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(postRequestACallAction),
  mergeMap(({ payload }) => from(Request.postRequest(payload)).pipe(
    mergeMap((_result) => {
      const id = nanoid()
      return of(create({ body: React.createElement(Snackbar, { text: 'Request has been sent' }, null), id }))
    }),
    catchError((_error) => {
      const id = nanoid()
      return of(create({ body: React.createElement(Snackbar, { text: 'Error sending' }, null), id }))
    }),
  ),
  ),
)

export const requestEpic = combineEpics(postRequest)
