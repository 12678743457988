import React from 'react'
import cn from 'classnames'
import style from './cancel.module.scss'

type Props = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  disabled?: boolean
  className?: string
}

export const Cancel: React.FC<Props> = ({ disabled, className, onClick }) => (
  <button
    className={cn(style.cancel, className, {
      [style.disabled]: disabled,
    })}
    disabled={disabled}
    onClick={onClick}
    name="Cancel"
    aria-label="Cancel"
  >
    Cancel
  </button>
)
