import { ActionsObservable, combineEpics } from 'redux-observable'
import { Action } from 'typescript-fsa'
import { mergeMap, catchError } from 'rxjs/operators'
import { of, from } from 'rxjs'
import { bidsReportApi, bidsApi, controllerApi } from '@/api'
import { ofAction } from '@/lib/rxjs-operators'
import {
  BidsReportRowsControllerApiIndexBidsReportRowsUsingGETRequestAction,
  BidsControllerApiUpdateBidByManagerUsingPUTRequestAction,
  OrdersControllerApiCreateOrderUsingPOSTRequestAction,
} from '@/api/origin/action'
import { normalizeManagerBids } from '@/redux/normalize'
import { BidsReportRowDto } from '@/api/origin/api'
import { create } from '@/redux/actions/snack-action'
import nanoid from 'nanoid'

const getManagerBids = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(BidsReportRowsControllerApiIndexBidsReportRowsUsingGETRequestAction.started),
  mergeMap(({ payload: { bidState, page, size } }) => from(bidsReportApi.indexBidsReportRowsUsingGET(bidState, page, size)).pipe(
    mergeMap(({ data, headers }: { data: BidsReportRowDto[]; headers: { [key: string]: string } }) => of(
      BidsReportRowsControllerApiIndexBidsReportRowsUsingGETRequestAction.done({
        // TODO: fix ts-ignore
        // @ts-ignore
        result: normalizeManagerBids(data),
        options: {
          bidState,
          page,
          size,
          pageCount: Math.ceil(Number(headers['x-total-count']) / size),
        },
      }),
    ),
    ),
    catchError((error) => of(BidsReportRowsControllerApiIndexBidsReportRowsUsingGETRequestAction.failed(error.response)),
    ),
  ),
  ),
)

const updateBid = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(BidsControllerApiUpdateBidByManagerUsingPUTRequestAction.started),
  mergeMap(({ payload: { id, requestBody } }) => from(bidsApi.updateBidByManagerUsingPUT(id, requestBody)).pipe(
    mergeMap(() => [
      BidsControllerApiUpdateBidByManagerUsingPUTRequestAction.done({
        // TODO: fix ts-ignore
        // @ts-ignore
        options: {
          id,
          ...requestBody,
        },
      }),
      create({ body: 'Bid update', id: nanoid() }),
    ]),
    catchError((error) => [
      BidsControllerApiUpdateBidByManagerUsingPUTRequestAction.failed(error.response),
      create({ body: 'Bid update failed', id: nanoid() }),
    ]),
  ),
  ),
)

const createOrder = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(OrdersControllerApiCreateOrderUsingPOSTRequestAction.started),
  mergeMap(({ payload: { requestBody } }) => from(controllerApi.createOrderUsingPOST(requestBody)).pipe(
    // todo normalize responce
    mergeMap(({ data, headers }: any) => [
      OrdersControllerApiCreateOrderUsingPOSTRequestAction.done({
        result: data,
        params: {
          requestBody,
        },
      }),
      create({ body: 'Order was created', id: nanoid() }),
    ]),
    catchError((error) => [
      OrdersControllerApiCreateOrderUsingPOSTRequestAction.failed(error.response),
      create({ body: 'Order create failed', id: nanoid() }),
    ]),
  ),
  ),
)

export const managerBidsEpic = combineEpics(getManagerBids, updateBid, createOrder)
