import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import Chip from '@material-ui/core/Chip'

const useStyles = makeStyles((theme: Theme) => createStyles({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}),
)

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

/* function getStyles(value: string, items: string[], theme: Theme) {
  return {
    fontWeight: items.indexOf(value) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
  }
} */

type Props = {
  margin?: string
  id?: string
  name?: string
  disabled?: boolean
  type?: string
  label?: string
  isError?: boolean
  autoComplete?: string
  errorText?: React.ReactNode
  helpText?: React.ReactNode
  onBlur?: (value: any) => void
  onChange: (value: any) => void
  className?: string
  currency?: boolean
  defaultValue?: any
  isNoneBlock?: boolean
  value: {
    id: string
    value: string
  }[]
}

export const MultipleSelect: React.FC<Props> = ({
  label,
  value = [],
  defaultValue,
  name,
  disabled,
  errorText,
  helpText,
  isError = false,
  className,
  currency,
  onBlur,
  onChange,
  isNoneBlock = false,
  ...inputProps
}) => {
  const classes = useStyles()
  const [selectedValues, setSelectedValues] = React.useState<string[]>([])

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedValues(event.target.value as string[])
  }

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="mutiple-chip-label">{label}</InputLabel>
        <Select
          id="mutiple-chip"
          multiple
          value={value}
          onChange={handleChange}
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {(selected as { id: string; value: string }[]).map((value) => (
                <Chip key={value.id} label={value.value} className={classes.chip} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {value.map((i) => (
            <MenuItem key={i.value} value={i.value}>
              <ListItemText primary={i.value} />
              <Checkbox color="default" checked={selectedValues.indexOf(i.value) > -1} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
