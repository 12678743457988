import React, { useEffect, useState } from 'react'
import { NavArrow } from '@/ui/molecules'
import { GalleryItem } from '../gallery-item'
import styles from '../gallery.module.scss'

type Props = {
  slidesURLs: any
  activeSlide: number
  slideSelectionHandler: (slide: number) => void
  imgObjLength: number
}

export const GalleryCarousel: React.FC<Props> = ({ slidesURLs, activeSlide, slideSelectionHandler, imgObjLength }) => {
  const [carouselSlidesPosition, setCarouselSlidesPosition] = useState({ firstSlide: 0, lastSlide: 3 })
  const handleForwardCarouselArrowClick = () => {
    let { firstSlide, lastSlide } = carouselSlidesPosition
    if (lastSlide < imgObjLength - 1) {
      // eslint-disable-next-line
      setCarouselSlidesPosition({ firstSlide: ++firstSlide, lastSlide: ++lastSlide })
    }
  }

  const handleBackwardCarouselArrowClick = () => {
    let { firstSlide, lastSlide } = carouselSlidesPosition
    if (firstSlide > 0) {
      // eslint-disable-next-line
      setCarouselSlidesPosition({ firstSlide: --firstSlide, lastSlide: --lastSlide })
    }
  }

  useEffect(() => {
    if (isActiveSlideOutOfVisibleCarouselArea()) moveCarouselToActiveSlide()
    // eslint-disable-next-line
  }, [activeSlide])

  return (
    <div className={styles.carousel}>
      <NavArrow type="backward" onClick={handleBackwardCarouselArrowClick} />
      <div className={styles.carouselItemsWrapper}>{renderSlides()}</div>
      <NavArrow type="forward" onClick={handleForwardCarouselArrowClick} />
    </div>
  )

  function renderSlides(): React.ReactNode[] {
    // TODO
    const { firstSlide, lastSlide } = carouselSlidesPosition
    const slides: React.ReactNode[] = []
    // eslint-disable-next-line
    for (let i: number = firstSlide; i <= lastSlide; ++i) {
      slides.push(
        <GalleryItem
          key={i}
          imageURL={getSlideURL(i)}
          active={i === activeSlide}
          selectionHandler={() => slideSelectionHandler(i)}
        />,
      )
    }
    return slides
  }

  function getSlideURL(slideNum: number): string {
    return Array.isArray(slidesURLs)
      ? slidesURLs[slideNum]
      : Boolean(slidesURLs[390])
        ? slidesURLs[390][slideNum]
        : Boolean(slidesURLs[320])
          ? slidesURLs[320][slideNum]
          : Boolean(slidesURLs[50][50])
            ? slidesURLs[50][slideNum]
            : ''
  }

  function isActiveSlideOutOfVisibleCarouselArea(): boolean {
    return (
      (activeSlide > carouselSlidesPosition.firstSlide && activeSlide > carouselSlidesPosition.lastSlide) ||
      (activeSlide < carouselSlidesPosition.firstSlide && activeSlide < carouselSlidesPosition.lastSlide)
    )
  }

  function determineCrouselMovingDirection(): 'forward' | 'backward' | undefined {
    if (activeSlide > carouselSlidesPosition.firstSlide && activeSlide > carouselSlidesPosition.lastSlide) {
      return 'forward'
    } else if (activeSlide < carouselSlidesPosition.firstSlide && activeSlide < carouselSlidesPosition.lastSlide) {
      return 'backward'
    } else {
      return undefined
    }
  }

  function moveCarouselToActiveSlide() {
    if (determineCrouselMovingDirection() === 'forward') {
      const difference = activeSlide - carouselSlidesPosition.lastSlide
      setCarouselSlidesPosition({
        firstSlide: carouselSlidesPosition.firstSlide + difference,
        lastSlide: carouselSlidesPosition.lastSlide + difference,
      })
    } else if (determineCrouselMovingDirection() === 'backward') {
      const difference = carouselSlidesPosition.firstSlide - activeSlide
      setCarouselSlidesPosition({
        firstSlide: carouselSlidesPosition.firstSlide - difference,
        lastSlide: carouselSlidesPosition.lastSlide - difference,
      })
    }
  }
}
