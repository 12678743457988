import { combineEpics } from 'redux-observable'

import { authEpic } from '@/redux/epics/auth-epic'
import { watchListEpic } from '@/redux/epics/watch-list-epic'
import { snackEpic } from '@/redux/epics/snack-epic'
import { seoEpic } from '@/redux/epics/seo-epic'
import { filterEpic } from '@/redux/epics/filter-epic'
import { auctionEpic } from '@/redux/epics/auction-epic'
import { blogEpic } from '@/redux/epics/blog-epic'
import { bidEpic } from '@/redux/epics/bid-epic'
import { procurementEpic } from '@/redux/epics/procurement-epic'
import { orderEpic } from '@/redux/epics/order-epic'
import { portEpic } from '@/redux/epics/port-epic'
import { clientsEpic } from '@/redux/epics/clients-epic'
import { walletEpic } from '@/redux/epics/wallet-epic'
import { paymentTypesEpic } from '@/redux/epics/paymentTypes-epic'
import { comparisonListEpic } from '@/redux/epics/comparison-list-epic'
import { requestEpic } from '@/redux/epics/request-call-epic'
import { managerBidsEpic } from '@/redux/epics/manager-bids-epic'
import { driverLicense } from '@/redux/epics/driver-license'
import { lotEpic } from '@/redux/epics/lot-epic'
import { inquireEpic } from '@/redux/epics/inquiry-form-epic'
import { managerOrdersEpic } from '@/redux/epics/manager-orders-epic'

export const rootEpic = combineEpics(
  authEpic,
  watchListEpic,
  snackEpic,
  seoEpic,
  filterEpic,
  auctionEpic,
  blogEpic,
  bidEpic,
  procurementEpic,
  orderEpic,
  portEpic,
  clientsEpic,
  walletEpic,
  paymentTypesEpic,
  comparisonListEpic,
  requestEpic,
  managerBidsEpic,
  driverLicense,
  lotEpic,
  inquireEpic,
  managerOrdersEpic,
)
