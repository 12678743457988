import React, { ReactNode, useEffect, useRef } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { Scrollbars } from 'react-custom-scrollbars'

type TProps = {
  height?: string
  autoHeight?: boolean
  children: ReactNode
} & RouteComponentProps

export const CustomScrollBar = withRouter(({ height = '100vh', children, location }: TProps) => {
  const scrollbar = useRef(null)

  const renderThumb = ({ style, ...props }: any) => {
    const thumbStyle = {
      borderRadius: 8,
      backgroundColor: 'rgba(236, 165, 44, 0.6)',
      zIndex: 21,
    }
    return <div style={{ ...style, ...thumbStyle }} {...props} />
  }

  useEffect(() => {
    // @ts-ignore
    scrollbar.current.scrollTop(0)
  }, [location.pathname, location.search])

  return (
    <Scrollbars
      autoHide
      style={{ height }}
      autoHeight={height === 'auto'}
      autoHeightMax={500}
      renderThumbHorizontal={renderThumb}
      renderThumbVertical={renderThumb}
      hideTracksWhenNotNeeded
      ref={scrollbar}
    >
      {children}
    </Scrollbars>
  )
})
