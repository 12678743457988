import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { BidState } from '@/redux/types/bid-type'
import {
  UserResourceApiCreateBidUsingPOSTRequestAction,
  UserResourceApiIndexBidsUsingGETRequestAction,
  UserResourceApiUpdateBidStateUsingPUTRequestAction,
  UserResourceApiUpdateBidUsingPUTRequestAction,
} from '@/api/origin/action'
import { logout } from '@/redux/actions/auth-action'

export const bidInitState: BidState = {
  byId: {},
  allIds: [],
  isLoading: false,
}

export const bidReducer = reducerWithInitialState<BidState>(bidInitState)
  .case(logout, () => bidInitState)
  .case(UserResourceApiCreateBidUsingPOSTRequestAction.started, (state) => ({
    ...state,
    isLoading: false,
  }))
  .case(UserResourceApiIndexBidsUsingGETRequestAction.started, (state) => ({
    ...state,
    isLoading: false,
  }))
  .case(UserResourceApiCreateBidUsingPOSTRequestAction.done, (state, data: any) => ({
    ...state,
    byId: {
      ...state.byId,
      [data.id]: data,
    },
    allIds: [...new Set([...state.allIds, data.id])],
    isLoading: true,
  }))
  .case(UserResourceApiIndexBidsUsingGETRequestAction.done, (state, { result }: any) => ({
    ...state,
    byId: {
      ...state.byId,
      ...result.bid.byId,
    },
    allIds: [...new Set([...state.allIds, ...result.bid.allIds])],
    isLoading: true,
  }))
  .case(UserResourceApiUpdateBidUsingPUTRequestAction.done, (state, bid: any) => ({
    ...state,
    byId: {
      ...state.byId,
      [bid.id]: {
        ...state.byId[bid.id],
        ...bid,
      },
    },
  }))
  .case(UserResourceApiUpdateBidStateUsingPUTRequestAction.done, (state, data: any) => ({
    ...state,
    byId: {
      ...state.byId,
      [data.bidId]: {
        ...state.byId[data.bidId],
        state: data.value,
      },
    },
  }))
  .build()
