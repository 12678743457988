import React from 'react'
import cn from 'classnames'
import { CustomizedTooltips, Icon } from '@/ui/atoms'
import styles from './average-price-prompt.module.scss'

export const AveragePricePrompt: React.FC<{
  className?: string
  iconClassName?: string
}> = ({ className, iconClassName }) => (
  <div className={cn(className, styles.wrapperComponent)}>
    <CustomizedTooltips title="Average price at which that model has sold.">
      <Icon name="icon-card-info" width="16px" height="16px" className={cn(styles.shippingIcon, iconClassName)} />
    </CustomizedTooltips>
  </div>
)
