import React from 'react'
import cn from 'classnames'
import { ControllerStateAndHelpers } from 'downshift'

import style from './dropdown-list.module.scss'

type Props = {
  items: {
    id: string | number
    value: string
    name: string
  }[]
  clearButtonHandler?: () => void
  active: boolean
  downshift: ControllerStateAndHelpers<any>
}

export function DropdownList({ items, clearButtonHandler, active, downshift }: Props) {
  function listContent(downshift: ControllerStateAndHelpers<any>) {
    if (items.length) {
      return items.map((item, index) => (
        <li
          {...downshift.getItemProps({
            className: style.listItem,
            key: item.id,
            index,
            item,
            value: item.id,
          })}
          key={item.id}
        >
          {item.name}
        </li>
      ))
    } else {
      return <li className={style.noMatch}>no match</li>
    }
  }
  return (
    <ul {...downshift.getMenuProps({ className: cn(style.list, { [style.activated]: active }) })}>
      {clearButtonHandler && (
        <li className={cn(style.listItem, style.select)} key="-1" onMouseDown={clearButtonHandler}>
          Select
        </li>
      )}
      {listContent(downshift)}
    </ul>
  )
}
