import React from 'react'
import cn from 'classnames'
import { Icon } from '@/ui/atoms'
import styles from './nav-arrow.module.scss'

export const NavArrow: React.FC<{
  type: 'backward' | 'forward'
  onClick: (event: any) => void
  wrapperClassName?: string
  arrowClassName?: string
  disabled?: boolean
}> = ({ type, onClick, arrowClassName, wrapperClassName, disabled }) => (
  <button
    className={cn(styles.wrapper, wrapperClassName, styles[type], { [styles.disabled]: disabled })}
    onClick={onClick}
    disabled={disabled}
    name="Arrow button"
    aria-label="Arrow button"
  >
    <Icon name="icon-slider-arrow" width="12px" height="19px" className={cn(styles.icon, arrowClassName)} />
  </button>
)
