import React from 'react'
import cn from 'classnames'
import { Icon } from '@/ui/atoms'
import style from './dropdown-arrow.module.scss'

type Props = {
  toggle: () => void
  active: boolean
}

export const DropdownArrow: React.FC<Props> = ({ toggle, active }) => (
  <Icon
    name="icon-dropdown-arrow-gray"
    width="11px"
    height="7px"
    className={cn(style.style, { [style.activated]: active })}
    onClick={toggle}
  />
)
