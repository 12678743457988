import React from 'react'

import { SearchForm } from '@/pages/index/page-vehicles/ui/atoms'
import { FilterState, PlainName, RangeName, PlaneFilterValue } from '@/redux/types/filter-type'
import styles from './filter.module.scss'

export const Filter: React.FC<{
  filter: FilterState
  changePlainFilter: (name: PlainName) => (value?: string | string[]) => void
  changeRangeFilter: (name: RangeName) => (value?: PlaneFilterValue) => void
  resetFilter: () => void
}> = React.memo(({ filter, changePlainFilter, changeRangeFilter, resetFilter }) => (
  <div>
    <div className={styles.title}>Auction Filter</div>
    <SearchForm
      filter={filter}
      changePlainFilter={changePlainFilter}
      changeRangeFilter={changeRangeFilter}
      resetFilter={resetFilter}
    />
  </div>
))
