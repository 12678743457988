import React, { useEffect, useRef } from 'react'
import cn from 'classnames'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Store } from '@/redux/store/store.interface'

import { ComparisonListState } from '@/redux/types/comparison-list-type'
import { PopupEmptyComparison } from '@/pages/index/page-comparison-list/ui/atoms'
import { open, close } from '@/redux/actions/popup-action'

import { Icon } from '@/ui/atoms'
import styles from './comparison-list.module.scss'

const getSelectorCompair = () => ({ comparisonList }: Store) => comparisonList || []

export const ComparisonList = () => {
  const dispatch = useDispatch()
  const refForArrayLength = useRef<HTMLElement>()
  const comparisonList = useSelector<Store, ComparisonListState>(getSelectorCompair())

  const handleClick = () => {
    if (comparisonList.length > 0) {
      dispatch(push('/comparison-list'))
    } else {
      dispatch(
        open({
          body: <PopupEmptyComparison cancel={() => dispatch(close())} d={() => dispatch(push('/vehicles'))} />,
        }),
      )
    }
  }

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    refForArrayLength.current && refForArrayLength.current.classList.add(styles.updateLength)

    setTimeout(() => {
      // eslint-disable-next-line no-unused-expressions
      refForArrayLength.current && refForArrayLength.current.classList.remove(styles.updateLength)
    }, 2000)
  }, [comparisonList.length])

  return (
    <button
      className={cn(styles.wrapperSection, { [styles.hasItems]: comparisonList.length > 0 })}
      onClick={handleClick}
      name="Comparison list button"
      aria-label="Comparison list button"
    >
      <Icon name="icon-card-compare" ref={refForArrayLength} className={styles.img} title="Comparison list" />
    </button>
  )
}
