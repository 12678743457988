import { API_URL } from '@/constants/API'

const cookieName = 'EAI-XSRF-TOKEN'

const cookie = document.cookie.match(
  new RegExp('(?:^|; )' + cookieName.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'),
)

const cookieTOKEN = cookie ? cookie[1] : ''

export const SignOut = () => {
  fetch(`${API_URL}/signout`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'X-XSRF-TOKEN': cookieTOKEN,
    },
  }).then((i) => {
    if (i.status === 403) {
      const name = 'EAI-XSRF-TOKEN'
      const token = document.cookie.match(
        new RegExp('(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'),
      )
      fetch(`${API_URL}/signout`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'X-XSRF-TOKEN': token ? token[1] : '',
        },
      })
        .then(() => {
          document.cookie = 'EAI-XSRF-TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 GMT'
        })
        .catch((e) => console.error(e))
    } else {
      document.cookie = 'EAI-XSRF-TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 GMT'
    }
  })
}
