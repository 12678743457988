import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { WalletState } from '@/redux/types/wallet-type'
import {
  UserResourceApiIndexWalletTransactionsUsingGETRequestAction,
  UserResourceApiCreateWalletTransactionUsingPOSTRequestAction,
  UserResourceApiUpdateWalletTransactionUsingPUTRequestAction,
} from '@/api/origin/action'
import { logout } from '@/redux/actions/auth-action'
import { defaultTableRowsPerPage } from '@/index'

export const walletInitState: WalletState = {
  byId: {},
  allIds: [],
  currentPage: 0,
  size: defaultTableRowsPerPage,
  sort: [],
  pageCount: 0,
  isLoading: false,
}

export const walletReducer = reducerWithInitialState<WalletState>(walletInitState)
  .case(logout, () => walletInitState)
  .case(UserResourceApiIndexWalletTransactionsUsingGETRequestAction.started, (state) => ({
    ...state,
    isLoading: false,
  }))
  .case(
    UserResourceApiIndexWalletTransactionsUsingGETRequestAction.done,
    (state, { result, params, options }: any) => ({
      ...state,
      byId: {
        ...result.byId,
      },
      allIds: [...new Set([...result.allIds])],
      currentPage: params.page,
      size: params.size,
      sort: params.sort,
      pageCount: options.pageCount,
      isLoading: true,
    }),
  )
  .case(UserResourceApiCreateWalletTransactionUsingPOSTRequestAction.done, (state, { result }: any) => ({
    ...state,
    byId: {
      ...state.byId,
      [result.id]: result,
    },
    allIds: [...new Set([result.id, ...state.allIds])],
    isLoading: true,
  }))
  .case(UserResourceApiUpdateWalletTransactionUsingPUTRequestAction.done, (state, { params }: any) => ({
    ...state,
    byId: {
      ...state.byId,
      [params.id]: {
        ...state.byId[params.id],
        ...params,
      },
    },
  }))
  .build()
