import React from 'react'
import cn from 'classnames'

import { Icon } from '@/ui/atoms'
import styles from './messages-bell.module.scss'

export const MessagesBell: React.FC<{
  className?: string
  isMessage?: boolean
}> = ({ className, isMessage = false }) => (
  <button
    className={cn(className, styles.wrapperSection, { [styles.hasMessages]: isMessage })}
    name="Notifications button"
    aria-label="Notifications button"
  >
    <Icon name="icon-header-bell" className={styles.bell} width="16px" height="16px" title="Notifications" />
  </button>
)
