import React from 'react'

import { useDispatch } from 'react-redux'
import { registration } from '@/redux/actions/auth-action'
import { open, close } from '@/redux/actions/popup-action'
import { API_URL } from '@/constants/API'
import {
  isEmailHasProblem,
  isPasswordHasProblem,
  isConfirmPasswordHasProblem,
  isPhoneHasProblem,
} from '../../../lib/form'
import { Input, Button, Icon } from '../../atoms'
import { PopupSignIn } from '..'
import styles from './popup-sign-up.module.scss'

export const PopupSignUp: React.FC = () => {
  const {
    state,
    setFirstName,
    setPassword,
    setPhone,
    setEmail,
    setSecondName,
    setConfirmPassword,
    signUp,
  } = useSignUp()

  const dispatch = useDispatch()

  const openSignIn = () => dispatch(
    open({
      body: <PopupSignIn />,
    }),
  )

  const signUpUser = (e: any) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      signUp()
    }
  }

  return (
    <>
      <div className={styles.wrapperSignUpFromSection}>
        <div className={styles.title}>Sign Up</div>
        <div className={styles.wrapperSocial}>
          <a href={`${API_URL}/auth/google?scope=email%20profile`}>
            <Icon name="icon-login-google" width="40px" height="40px" className={styles.socialIcon} />
          </a>
        </div>
        <div className={styles.useMailText}>or use your email account:</div>
        <div className="mb-25">
          <div className={styles.gridSignUp}>
            <Input
              name="firstName"
              id="firstName"
              label="First Name"
              maxLength={50}
              value={state.firstName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFirstName(event.currentTarget.value)}
              autoComplete="on"
            />
            <Input
              name="lastName"
              id="lastName"
              label="Last Name"
              maxLength={50}
              value={state.lastName}
              autoComplete="on"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSecondName(event.currentTarget.value)}
            />
            <Input
              name="tel"
              id="phone"
              label="Phone"
              type="tel"
              value={state.phone}
              maxLength={16}
              errorText={state.phone && !state.isValidPhone ? 'Invalid Phone Number' : undefined}
              isError={state.phone ? !state.isValidPhone : false}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPhone(event.currentTarget.value)}
              autoComplete="on"
            />
            <Input
              name="email"
              id="mail"
              label="Email"
              type="email"
              value={state.email}
              isError={state.email ? !state.isEmailValid : false}
              errorText={state.email && !state.isEmailValid ? 'Invalid Email Address' : undefined}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.currentTarget.value)}
              autoComplete="email"
            />
            <Input
              name="password"
              id="password"
              label="Password"
              type="password"
              value={state.password}
              minLength={4}
              isError={state.password ? !state.isPasswordValid : false}
              errorText={state.password && !state.isPasswordValid ? 'Invalid Password' : undefined}
              helpText="Must be at least 5 characters long"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.currentTarget.value)}
              autoComplete="on"
            />
            <Input
              name="confirmPassword"
              id="confirmPassword"
              label="Confirm Password"
              type="password"
              value={state.confirmPassword}
              isError={state.confirmPassword ? !state.isValidConfirmPassword : false}
              errorText={
                state.confirmPassword && !state.isValidConfirmPassword ? 'Password does not match.' : undefined
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(event.currentTarget.value)}
              onKeyDown={signUpUser}
            />
          </div>
          <Button type="primary" fullWidth disabled={!state.isValid} onClick={signUp} aria-label="Sign Up">
            Sign Up
          </Button>
        </div>
        <div className={styles.optionWrapper}>
          <div className={styles.changeLoginOption}>
            Already have an account?
            <span className={styles.changeLoginOptionLink} onClick={openSignIn}>
              Sign In
            </span>
          </div>
        </div>
      </div>
      <div className={styles.terms}>
        <div className={styles.termsTitle}>By clicking the “Sign Up” button you:</div>
        <div className={styles.wrapperTermsList}>
          <div className={styles.term}>
            • Certify that you have read and agree to EasyAutoImport’s Terms of Use and the E-SIGN consent that enables
            all transactions and disclosure delivery to occur electronically.
          </div>
          <div className={styles.term}>
            • Agree EasyAutoImport, or EasyAutoImport’s authorized representatives*, may call and/or send text messages
            (including by using equipment to automatically dial telephone numbers) about your interest in a purchase,
            for marketing/sales purposes, or for any other servicing or informational purpose related to your acciunt.
            You do not have to consent to receiveing calls or texts to purchase from EasyAutoImport.
          </div>
        </div>
        <div className={styles.note}>
          *Including, but not limited to, Bridecrest Credit Company, GO Financial and SulverRock Automotive.
        </div>
      </div>
    </>
  )
}

type Action =
  | { type: 'setPassword'; payload: string }
  | { type: 'setConfirmPassword'; payload: string }
  | { type: 'setEmail'; payload: string }
  | { type: 'setFirstName'; payload: string }
  | { type: 'setLastName'; payload: string }
  | { type: 'setPhone'; payload: string }
  | { type: 'setRememberMe'; payload: boolean }

type State = {
  firstName: string
  lastName: string
  phone: string
  password: string
  confirmPassword: string
  email: string
  status: 'idle'
  rememberMe: boolean
}

const initialState: State = {
  firstName: '',
  lastName: '',
  password: '',
  confirmPassword: '',
  phone: '',
  email: '',
  status: 'idle',
  rememberMe: true,
}

const reducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case 'setFirstName':
      return { ...state, firstName: action.payload }
    case 'setLastName':
      return { ...state, lastName: action.payload }
    case 'setEmail':
      return { ...state, email: action.payload }
    case 'setPhone':
      return { ...state, phone: action.payload }
    case 'setPassword':
      return { ...state, password: action.payload }
    case 'setConfirmPassword':
      return { ...state, confirmPassword: action.payload }
    default:
      return state
  }
}

export const useSignUp = () => {
  const [state, dispatchLoc] = React.useReducer(reducer, initialState)
  const setPassword = (payload: string) => dispatchLoc({ type: 'setPassword', payload })
  const setConfirmPassword = (payload: string) => dispatchLoc({ type: 'setConfirmPassword', payload })

  const setEmail = (payload: string) => dispatchLoc({ type: 'setEmail', payload })
  const setFirstName = (payload: string) => dispatchLoc({ type: 'setFirstName', payload })
  const setSecondName = (payload: string) => dispatchLoc({ type: 'setLastName', payload })

  const setPhone = (payload: string) => dispatchLoc({ type: 'setPhone', payload })

  const dispatch = useDispatch()

  const signUp = () => {
    dispatch(
      registration({
        managedUserVM: { ...state },
      }),
    )
    dispatch(close())
  }

  const isEmailValid = !isEmailHasProblem(state.email)
  const isPasswordValid = !isPasswordHasProblem(state.password)
  const isValidConfirmPassword = !isConfirmPasswordHasProblem(state.password, state.confirmPassword)
  const isValidPhone = !isPhoneHasProblem(state.phone)

  const isValid = [
    Boolean(state.firstName),
    Boolean(state.lastName),
    Boolean(state.email),
    Boolean(state.password),
    Boolean(state.phone),
    Boolean(state.confirmPassword),
    isEmailValid,
    isPasswordValid,
    isValidPhone,
    isValidConfirmPassword,
  ].every(Boolean)

  return {
    state: {
      ...state,
      isPasswordValid,
      isEmailValid,
      isValidPhone,
      isValidConfirmPassword,
      isValid,
    },
    setPassword,
    setConfirmPassword,
    setEmail,
    signUp,
    setFirstName,
    setSecondName,
    setPhone,
  }
}
