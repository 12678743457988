import React from 'react'
import { NavigationItem } from '@/ui/atoms'
import { withRouter, RouteComponentProps } from 'react-router'
import cn from 'classnames'
import styles from '@/ui/molecules/navigation-phone/navigation-phone.module.scss'

type Props = {
  className?: string
  active?: boolean
  closeMenu: () => void
}
export const NavigationPhone = withRouter(
  ({ location: { pathname }, active = false, closeMenu }: Props & RouteComponentProps) => (
    <nav className={cn(styles.wrapperComponent, { [styles.active]: active })}>
      <div className={styles.listSection} onClick={closeMenu}>
        <NavigationItem className={styles.listItem} href="/" value="Home" active={pathname === '/'} />
        <NavigationItem
          className={styles.listItem}
          href="/vehicles"
          value="Vehicles"
          active={pathname === '/vehicles'}
        />
        <NavigationItem
          className={styles.listItem}
          href="/european-car-imports"
          value="Cars from Europe"
          active={pathname === '/european-car-imports'}
        />

        <NavigationItem
          className={styles.listItem}
          href="/about-us"
          value="About Us"
          active={pathname === '/about-us'}
        />
        <NavigationItem className={styles.listItem} href="/blog" value="Blog" active={pathname === '/blog'} />
        {/* <NavigationItem className={styles.listItem} href="/faq" value="faq" active={'/faq' === pathname} />
          <NavigationItem className={styles.listItem} href="/#HowItWorks" value="How It Works" />
          <NavigationItem
            className={styles.listItem}
            href="/contact-us"
            value="Contact Us"
            active={'/contact-us' === pathname}
          /> */}
      </div>
    </nav>
  ),
)
