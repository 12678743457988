import React from 'react'
import cn from 'classnames'
import { CustomizedTooltips, Icon } from '@/ui/atoms'
import { getUSAFormatPrice } from '@/lib'
import styles from './est-shipping-prompt.module.scss'

export const EstShippingPrompt: React.FC<{
  className?: string
  iconClassName?: string
  value: number
}> = ({ className, iconClassName, value }) => (
  <div className={cn(className, styles.wrapperComponent)}>
    <CustomizedTooltips
      title={`There is a ${getUSAFormatPrice(
        value,
      )} shipping cost on this vehicle due to its significant transport distance to
      your location. Try searching for the same car with no shipping cost.`}
    >
      <Icon name="icon-card-info" width="16px" height="16px" className={cn(styles.shippingIcon, iconClassName)} />
    </CustomizedTooltips>
  </div>
)
