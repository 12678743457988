import React, { useRef, useState, useEffect } from 'react'
import cn from 'classnames'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { useLocation } from 'react-router'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { Button, SectionHeaderDynamic } from '@/ui/atoms'
import { useResize } from '@/lib'
import { Step } from './step'
import styles from './how-it-works.module.scss'

interface IHowItWorks {
  className?: string
  steps: {
    title: string
    video_code: string
    steps: {
      name: string
      title: string
      description: string
      icon: string
    }[]
    button: {
      url: string
      text: string
      target: string
    }
  }
  isActiveButton?: Boolean
}

export const HowItWorks: React.FC<IHowItWorks> = ({ className, steps, isActiveButton = true }) => {
  const [hash, setHash] = useState('')
  const dispatch = useDispatch()
  const ref = useRef<HTMLElement>(null)
  const location = useLocation()
  const windowSize = useResize()
  const tabletSize = windowSize < 1400

  useEffect(() => {
    if (hash && ref && ref.current) {
      window.scrollTo(0, ref.current.offsetTop)
      setHash('')
    }
  }, [hash, ref])

  useEffect(() => {
    if (location.hash && !hash) {
      setHash(location.hash)
    }
  }, [hash, location.hash])

  return (
    <section id="HowItWorks" className={cn(className, styles.section)} ref={ref}>
      <LazyLoadComponent>
        <SectionHeaderDynamic type="dark" className={tabletSize ? 'mb-20' : 'mb-35'} text={steps.title} />
        <div className={cn(styles.grid, 'container')}>
          <div className={styles.video} dangerouslySetInnerHTML={{ __html: steps.video_code }} />
          <div className={styles.steps}>
            {steps.steps.map((i, index) => (
              <Step key={index} num={i.name} title={i.title} description={i.description} icon={i.icon} />
            ))}
          </div>
        </div>
        {isActiveButton && (
          <LazyLoadComponent>
            <div className={styles.button}>
              <Button type="primary" onClick={() => dispatch(push('/faq'))} aria-label="Learn more">
                LEARN MORE
              </Button>
            </div>
          </LazyLoadComponent>
        )}
      </LazyLoadComponent>
    </section>
  )
}
