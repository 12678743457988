import styled from 'styled-components'
import { Typography } from '@material-ui/core'

export const STTableTypography = styled(Typography)`
  && {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #2a323c;
  }
`
