import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable'
import { Action } from 'typescript-fsa'

import { switchMap, mergeMap, catchError, withLatestFrom, pluck } from 'rxjs/operators'
import { of, from } from 'rxjs'

import {
  DashboardResourceApiIndexFavoritesUsingGETRequestAction,
  DashboardResourceApiCreateFavoriteUsingPOSTRequestAction,
  DashboardResourceApiDeleteFavoriteUsingDELETERequestAction,
} from '@/api/origin/action'
import { create } from '@/redux/actions/snack-action'
import { addWatch, addWatchInProcurement } from '@/redux/actions/watch-list-action'
import React from 'react'
import { Store } from '@/redux/store/store.interface'
import { open } from '@/redux/actions/popup-action'

import nanoid from 'nanoid'
import { dashboardApi } from '@/api'
import { ofAction } from '@/lib/rxjs-operators'
import { NOTHING } from '@/redux/actions/auth-action'
import { SnackbarAddWatch } from '@/pages/dashboard/page-watch-list/ui/atoms'
import { PopupSignIn } from '@/ui/molecules'
import { getCarTitle } from '@/lib'
import { normalizeWatch } from '@/redux/normalize'

const findByAllEpic = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(DashboardResourceApiIndexFavoritesUsingGETRequestAction.started),
  mergeMap(() => from(dashboardApi.indexFavoritesUsingGET()).pipe(
    mergeMap((result) => {
      // @ts-ignore
      const data = normalizeWatch(result.data)
      return of(
        DashboardResourceApiIndexFavoritesUsingGETRequestAction.done({ result: data, params: { options: {} } }),
      )
    }),
    catchError((error) => of(DashboardResourceApiIndexFavoritesUsingGETRequestAction.failed({ params: {}, error: error.response })),
    ),
  ),
  ),
)

const addWatchEpic = (action$: ActionsObservable<Action<any>>, store$: StateObservable<Store>) => action$.pipe(
  ofAction(addWatch),
  withLatestFrom(store$.pipe(pluck('auth'))),
  mergeMap(([{ payload: car }, authStore]) => {
    const user = authStore.user
    if (user !== null) {
      const id = nanoid()
      return from(dashboardApi.createFavoriteUsingPOST(car.externalId || '')).pipe(
        mergeMap((result) => [
          DashboardResourceApiCreateFavoriteUsingPOSTRequestAction.done({
            result: result.data,
            params: { externalId: '', options: {} },
          }),
          DashboardResourceApiIndexFavoritesUsingGETRequestAction.started({ options: {} }),
          create({ body: React.createElement(SnackbarAddWatch, { name: getCarTitle(car) }, null), id }),
        ]),
        catchError((error) => of(NOTHING())),
      )
    }
    return of(open({ body: React.createElement(PopupSignIn) }))
  }),
)

const addWatchInProcurementEpic = (action$: ActionsObservable<Action<any>>, store$: StateObservable<Store>) => action$.pipe(
  ofAction(addWatchInProcurement),
  withLatestFrom(store$.pipe(pluck('auth'))),
  mergeMap(([{ payload: car }, authStore]) => {
    const user = authStore.user
    if (user !== null) {
      const id = nanoid()
      return from(dashboardApi.createFavoriteUsingPOST(car.externalId || '')).pipe(
        mergeMap((result) => [
          create({ body: React.createElement(SnackbarAddWatch, { name: getCarTitle(car) }, null), id }),
        ]),
        catchError((error) => of(NOTHING())),
      )
    }
    return of(open({ body: React.createElement(PopupSignIn) }))
  }),
)

const deleteWatchEpic = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(DashboardResourceApiDeleteFavoriteUsingDELETERequestAction.started),
  switchMap(({ payload: { lotId } }) => dashboardApi.deleteFavoriteUsingDELETE(lotId)),
  mergeMap((result) => [
    DashboardResourceApiDeleteFavoriteUsingDELETERequestAction.done({
      result: result.data,
      params: { lotId: 0, options: {} },
    }),
    DashboardResourceApiIndexFavoritesUsingGETRequestAction.started({ options: {} }),
  ]),
  catchError(() => of()),
)

export const watchListEpic = combineEpics(findByAllEpic, addWatchEpic, deleteWatchEpic, addWatchInProcurementEpic)
