import React from 'react'
import cn from 'classnames'

import styles from './popup-title.module.scss'

interface Props {
  size: 'small' | 'big'
  className?: string
  alignCenter?: boolean
}

export const PopupTitle: React.FC<Props> = ({ size, className, children, alignCenter }) => (
  <h2
    className={cn(
      styles.root,
      { [styles.sizeSmall]: size === 'small' },
      { [styles.sizeBig]: size === 'big' },
      { [styles.alignCenter]: alignCenter },
      className,
    )}
  >
    {children}
  </h2>
)
