import React from 'react'
import cn from 'classnames'
import { LotDto } from '@/api/origin/api'
import { HTMLDecoder } from '@/lib'
import styles from './modification-prompt.module.scss'

export const ModificationPrompt: React.FC<{
  car: LotDto
  className?: string
}> = ({ className, car }) => (
  <div className={cn(className, styles.wrapperComponent)}>{HTMLDecoder(car.modification)}</div>
)
