import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable'
import { Action } from 'typescript-fsa'
import { mergeMap, catchError, withLatestFrom, pluck, first } from 'rxjs/operators'
import { from, of } from 'rxjs'

import { FilterResourceApiFindFilterByPlatformAndTypeUsingGETRequestAction } from '@/api/origin/action'
import { filterApi } from '@/api/index'
import { ofAction } from '@/lib/rxjs-operators'
import { changePlainFilter, resetFilter } from '@/redux/actions/filter-action'
import { NOTHING } from '@/redux/actions/auth-action'
import * as VehiclesActions from '@/redux/actions/auction-action'
import { Store } from '@/redux/store/store.interface'
import { routerActions } from 'connected-react-router'

const getFiltersEpic = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  first(),
  mergeMap(() => [
    FilterResourceApiFindFilterByPlatformAndTypeUsingGETRequestAction.started({
      filterType: 'MAKE',
      platform: 'AVTO_JP',
      parent: undefined,
      options: {},
    }),
    FilterResourceApiFindFilterByPlatformAndTypeUsingGETRequestAction.started({
      filterType: 'COLOR',
      platform: 'AVTO_JP',
      parent: undefined,
      options: {},
    }),

    FilterResourceApiFindFilterByPlatformAndTypeUsingGETRequestAction.started({
      filterType: 'AUCTION',
      platform: 'AVTO_JP',
      parent: undefined,
      options: {},
    }),
    FilterResourceApiFindFilterByPlatformAndTypeUsingGETRequestAction.started({
      filterType: 'TRANSMISSION_TYPE',
      platform: 'AVTO_JP',
      parent: undefined,
      options: {},
    }),
  ]),
  catchError((error: any) => {
    console.error(error.response)
    return of(NOTHING())
  }),
)

const fetchFilterEpic = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(FilterResourceApiFindFilterByPlatformAndTypeUsingGETRequestAction.started),
  mergeMap(({ payload: { filterType, platform, parent } }) => from(filterApi.findFilterByPlatformAndTypeUsingGET(filterType, platform, parent)).pipe(
    mergeMap((result) => of(
      FilterResourceApiFindFilterByPlatformAndTypeUsingGETRequestAction.done({
        result: result.data,
        params: {
          filterType,
          platform,
          parent,
          options: {},
        },
      }),
    ),
    ),
    catchError((error) => of(
      FilterResourceApiFindFilterByPlatformAndTypeUsingGETRequestAction.failed({
        error: error.response,
        params: {
          filterType,
          platform,
          parent,
          options: {},
        },
      }),
    ),
    ),
  ),
  ),
)

const changeFilterEpic = (action$: ActionsObservable<Action<any>>, store$: StateObservable<Store>) => action$.pipe(
  ofAction(changePlainFilter),
  withLatestFrom(store$.pipe(pluck('filter'))),
  mergeMap(
    ([
      {
        payload: { name, value },
      },
      filterState,
    ]) => {
      if (name === 'MAKE' && value) {
        const externalIdMakes = filterState.MAKE.list.filter((make) => value.includes(make.name || ''))
        of(
          FilterResourceApiFindFilterByPlatformAndTypeUsingGETRequestAction.started({
            filterType: 'MODEL',
            platform: 'AVTO_JP',
            parent: externalIdMakes.map((item) => item.externalId || ''),
            options: {},
          }),
        )
      }
      return of(NOTHING())
    },
  ),
)

const initVehiclesPageFilterEpic = (action$: ActionsObservable<Action<any>>, store$: StateObservable<Store>) => action$.pipe(
  ofAction(VehiclesActions.initVehiclesPage),
  withLatestFrom(store$.pipe(pluck('filter'))),
  mergeMap(([_, filterState]) => {
    if (filterState.MAKE.value && filterState.MAKE.value[0]) {
      const externalIdMakes = filterState.MAKE.list.filter((make) => filterState.MAKE.value.includes(make.name || ''))
      return of(
        FilterResourceApiFindFilterByPlatformAndTypeUsingGETRequestAction.started({
          filterType: 'MODEL',
          platform: 'AVTO_JP',
          parent: externalIdMakes.map((item) => item.externalId || ''),
          options: {},
        }),
      )
    }

    return of(NOTHING())
  }),
)

const resetFilterEpic = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(resetFilter),
  mergeMap(() => of(routerActions.push('/vehicles'))),
)

export const filterEpic = combineEpics(
  fetchFilterEpic,
  changeFilterEpic,
  initVehiclesPageFilterEpic,
  resetFilterEpic,
  getFiltersEpic,
)
