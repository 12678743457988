import React, { useRef, useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { Store } from '@/redux/store/store.interface'
import { WatchListState } from '@/redux/types/watch-list-type'
import { Icon } from '@/ui/atoms'
import { DashboardResourceApiIndexFavoritesUsingGETRequestAction } from '@/api/origin/action'
import styles from './/watch-list.module.scss'

const getSelectorWatchList = () => ({ watchList }: Store) => watchList || []

export const WatchList = withRouter(({ history }: RouteComponentProps) => {
  const watchList = useSelector<Store, WatchListState>(getSelectorWatchList())
  const dispatch = useDispatch()
  const refForArrayLength = useRef<HTMLElement>()

  useEffect(() => {
    dispatch(DashboardResourceApiIndexFavoritesUsingGETRequestAction.started({ options: {} }))
  }, [dispatch])

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    refForArrayLength.current && refForArrayLength.current.classList.add(styles.updateLength)

    setTimeout(() => {
      // eslint-disable-next-line no-unused-expressions
      refForArrayLength.current && refForArrayLength.current.classList.remove(styles.updateLength)
    }, 2000)
  }, [watchList.length])

  return (
    <button
      className={cn(styles.wrapperSection, { [styles.hasItems]: watchList.length > 0 })}
      onClick={handleClickWatchlist}
      aria-label="Watch list button"
    >
      <Icon name="icon-card-wishlist" className={cn(styles.img)} title="Watch list" ref={refForArrayLength} />
    </button>
  )

  function handleClickWatchlist() {
    history.push('/dashboard/watchlist')
  }
})
