import React, { useState } from 'react'
import cn from 'classnames'
import {
  RowDetailState,
  EditingState,
  TableRowDetail as TableRowDetailBase,
  ChangeSet,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  TableFixedColumns,
  TableEditRow,
  TableEditColumn,
} from '@devexpress/dx-react-grid-material-ui'
import Paper from '@material-ui/core/Paper'

import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'
import { SelectSubMenu, Checkbox } from '@/ui/atoms'
import { withStyles, Theme } from '@material-ui/core/styles'
import style from './table-material-ui.module.scss'

export const TableMaterialUI: React.FC<{
  rows: any
  columns: any
  columnExtensions?: any
  RowDetail?: React.ComponentType<TableRowDetailBase.ContentProps>
  editableColumns?: { [key: string]: string | any[] }
  onCommitChanges?: (val: ChangeSet) => void
  className?: string
  fixedColumns?: { left?: (string | symbol)[]; right?: (string | symbol)[] }
}> = ({ rows, columns, columnExtensions, RowDetail, editableColumns, onCommitChanges, fixedColumns }) => {
  const [editingRowIds, setEditingRowIds] = useState([])
  // const [rowChanges, setRowChanges] = useState({})
  const commitChanges = (changeSet: ChangeSet) => {
    if (onCommitChanges) {
      onCommitChanges(changeSet)
    }
  }

  const TableCell = ({ column, ...restProps }: any) => (
    <Table.Cell
      {...restProps}
      style={{
        ...restProps.style,
        paddingTop: 0,
        paddingBottom: 0,
        height: '50px',
        overflow: column && column.name === 'subMenu' ? 'visible' : 'hidden',
        paddingLeft: '8px',
      }}
    />
  )

  const HeaderCell = ({ column, ...restProps }: any) => (
    <TableHeaderRow.Cell
      className={cn({ [style.priority]: column.name === 'priority' }, style.tableCell)}
      {...restProps}
    />
  )

  const HeaderRow = ({ ...restProps }: any) => <TableHeaderRow.Row className={style.tableHeader} {...restProps} />

  const editing_cell_styles = (theme: Theme) => ({
    cell: {
      padding: 0,
      paddingLeft: '5px',
    },
  })

  const EditingCellComponentBase = (props: any) => <TableEditColumn.Cell {...props} />

  const EditingCellComponent = withStyles(editing_cell_styles, { name: 'EditingCell' })(EditingCellComponentBase)

  const EditButton = ({ onExecute }: any) => (
    <IconButton onClick={onExecute} title="Edit row">
      <EditIcon />
    </IconButton>
  )

  const CommitButton = ({ onExecute }: any) => (
    <IconButton onClick={onExecute} title="Save changes">
      <SaveIcon />
    </IconButton>
  )

  const CancelButton = ({ onExecute }: any) => (
    <IconButton color="secondary" onClick={onExecute} title="Cancel changes">
      <CancelIcon />
    </IconButton>
  )

  const commandComponents: { [key: string]: React.ReactNode } = {
    edit: EditButton,
    commit: CommitButton,
    cancel: CancelButton,
  }

  const Command = ({ id, onExecute }: { id: string; onExecute: any }) => {
    const CommandButton: React.ReactNode = id ? commandComponents[id] || false : false
    // @ts-ignore
    return CommandButton ? <CommandButton onExecute={onExecute} /> : <i />
  }

  const LookupEditCell = ({ availableColumnValues, value, onValueChange }: any) => (
    <TableCell>
      <SelectSubMenu items={availableColumnValues} value={value} onChange={onValueChange} />
    </TableCell>
  )

  const CheckboxCell = ({ value, onValueChange, column }: any) => (
    <TableCell>
      <Checkbox name={`checkbox-${column?.name}`} checked={value} onChange={onValueChange} />
    </TableCell>
  )

  const EditCell = (props: any) => {
    const { column } = props
    // @ts-ignore

    const availableColumnValues = editableColumns[column.name]
    const disabled = !!props?.row?.disabled?.[column?.name]
    if (availableColumnValues && !disabled) {
      switch (availableColumnValues) {
        case 'text': {
          return <TableEditRow.Cell {...props} />
        }
        case 'currency': {
          const mergedProps = {
            ...props,
            // eslint-disable-next-line
            value: props.value?.replace(/[$\,\s]/gm, '') || '',
          }
          return <TableEditRow.Cell {...mergedProps} />
        }
        case 'checkbox': {
          return <CheckboxCell {...props} />
        }
        case 'insuranceCost': {
          const {
            row: {
              insuranceCost: {
                props: { shippingInsuranceCost },
              },
            },
            value,
          } = props

          const newValue: string | false = typeof value === 'string' ? value : false

          const mergedProps = {
            ...props,
            value: newValue || shippingInsuranceCost.toString().replace('.', ',') || '',
          }
          return <TableEditRow.Cell {...mergedProps} />
        }
        default: {
          return <LookupEditCell {...props} availableColumnValues={availableColumnValues} />
        }
      }
    } else {
      return <TableCell {...props} />
    }
  }

  const getRowId = (row: any) => row.id

  return (
    <Paper>
      {Boolean(rows && rows.length) && (
        <div className={style.gridWrapper}>
          <Grid rows={rows} columns={columns} getRowId={getRowId}>
            <EditingState
              editingRowIds={editingRowIds}
              // @ts-ignore
              onEditingRowIdsChange={setEditingRowIds}
              // rowChanges={rowChanges}
              // onRowChangesChange={setRowChanges}
              onCommitChanges={commitChanges}
            />
            <RowDetailState defaultExpandedRowIds={[]} />
            <Table columnExtensions={columnExtensions || []} cellComponent={TableCell} />
            <TableHeaderRow cellComponent={HeaderCell} rowComponent={HeaderRow} />
            {RowDetail && <TableRowDetail contentComponent={RowDetail} />}
            <TableEditRow cellComponent={EditCell} />
            {editableColumns && (
              <TableEditColumn
                width={55}
                // @ts-ignore
                cellComponent={EditingCellComponent}
                showAddCommand={false}
                showEditCommand
                showDeleteCommand
                commandComponent={Command}
              />
            )}
            <TableFixedColumns leftColumns={fixedColumns?.left || []} rightColumns={fixedColumns?.right || []} />
          </Grid>
        </div>
      )}
    </Paper>
  )
}
