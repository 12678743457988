import React, { useEffect } from 'react'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { Store } from '@/redux/store/store.interface'
import { BlogState } from '@/redux/types/blog-type'
import * as BlogActions from '@/redux/actions/blog-action'
import { LanguageLink } from '@/ui/atoms'
import { SectionHeaderHighlight, SectionHeader, BlogItem } from '../../atoms'
import styles from './home-latest-news.module.scss'

const getSelectorBlogById = () => ({ blog }: Store): BlogState => blog

export const HomeLatestNews: React.FC<{ className?: string }> = ({ className }) => {
  const blogState = useSelector<Store, BlogState>((store) => store.blog)
  const dispatch = useDispatch()
  const { posts } = useSelector<Store, any>(getSelectorBlogById())

  useEffect(() => {
    if (!posts.allPostsSlugs.length) {
      dispatch(BlogActions.fetchByAll.started({}))
    }
  }, [posts, dispatch])

  return (
    <section className={cn(className, styles.wrapperComponent)}>
      <LazyLoadComponent>
        <div className="container">
          <SectionHeader type="dark" className={styles.sectionHeader}>
            <SectionHeaderHighlight>Latest</SectionHeaderHighlight>
            News
          </SectionHeader>
          <div className={styles.newsList}>
            {blogState.posts.allPostsSlugs.slice(0, 4).map((postSlug: string) => (
              <BlogItem key={postSlug} post={blogState.posts.bySlug[postSlug]} to={`/blog/${postSlug}`} />
            ))}
          </div>
          <LanguageLink to="/blog" className={styles.goToBlogLink} aria-label="Go to Blog">
            <span>Go to Blog</span>
          </LanguageLink>
        </div>
      </LazyLoadComponent>
    </section>
  )
}
