import React, { useEffect, useRef, useState } from 'react'

import cn from 'classnames'
import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputBase from '@material-ui/core/InputBase'
import styles from './select-customized.module.scss'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    // minWidth: 200,
    width: '100%',
    '& .MuiSelect-select.Mui-disabled': {
      cursor: 'not-allowed',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: '#ECA52C',
      padding: '0 4px',
      opacity: 1,
      transform: 'translate(7px, -6px) scale(0.75)',
    },
  },
  label: {
    backgroundColor: '#fff',
    padding: '0',
    transform: 'translate(10px, 11px) scale(1)',
    color: '#555b63',
    opacity: 0.6,
  },
  labelFocus: {
    color: '#ECA52C !important',
  },
  selected: {
    backgroundColor: '#eca52c !important',
    color: 'white',
    fontWeight: 600,
  },
  // selectEmpty: {
  //   marginTop: 'theme.spacing(2)'
  // }
}))

const BootstrapInput = withStyles((theme: Theme) => createStyles({
  input: {
    height: '16px',
    borderRadius: 5,
    position: 'relative',
    backgroundColor: '#fff',
    border: '1px solid #ededed',
    fontSize: 16,
    padding: '10px 26px 10px 8px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#eca52c',
      backgroundColor: '#fff',
    },
  },
}),
)(InputBase)

type Props = {
  margin?: string
  id: string
  name?: string
  disabled?: boolean
  type?: string
  label?: string
  value: any
  isError?: boolean
  autoComplete?: string
  errorText?: React.ReactNode
  helpText?: React.ReactNode
  onBlur?: (value: any) => void
  onChange: (value: any) => void
  className?: string
  currency?: boolean
  defaultValue?: any
  isNoneBlock?: boolean
  items: {
    name?: string
    value: string | number
  }[]
}

export const SelectCustomized: React.FC<Props> = ({
  id,
  label,
  value = '',
  defaultValue,
  name,
  disabled,
  errorText,
  helpText,
  isError = false,
  className,
  currency,
  onBlur,
  items = [],
  onChange,
  isNoneBlock = false,
  ...inputProps
}) => {
  const classes = useStyles()
  const [value2, setValue] = useState(value)

  const handleChange = (event: any) => {
    setValue(event.target.value)
    onChange(event.target.value)
  }

  const inputLabel = useRef<HTMLLabelElement>(null)
  const [labelWidth, setLabelWidth] = React.useState(0)

  useEffect(() => {
    if (inputLabel && inputLabel.current) {
      setLabelWidth((inputLabel as any).current.offsetWidth)
    }
  }, [])

  useEffect(() => {
    setValue(value)
  }, [value])

  return (
    <div className={cn(className, styles.selectCustomized)}>
      <FormControl variant="outlined" className={classes.formControl} disabled={disabled} error={isError}>
        <InputLabel
          ref={inputLabel}
          id={`simple-select-outlined-label-${id}`}
          classes={{
            root: classes.label,
            focused: classes.labelFocus,
          }}
        >
          {label}
        </InputLabel>
        <Select
          id={`simple-select-outlined-${id}`}
          value={value2 || value}
          onChange={handleChange}
          labelWidth={labelWidth}
          input={<BootstrapInput />}
          className={styles['MuiSelect-selectMenu']}
          MenuProps={{
            getContentAnchorEl: null,
            anchorPosition: {
              top: 0,
              left: 0,
            },
          }}
        >
          {isNoneBlock && (
            <MenuItem value="" classes={{ selected: classes.selected }}>
              None
            </MenuItem>
          )}
          {items.map((i) => (
            <MenuItem value={i.value} key={i.value} classes={{ selected: classes.selected }}>
              {i.name || i.value}
            </MenuItem>
          ))}
        </Select>
        {isError && <FormHelperText>{errorText}</FormHelperText>}
      </FormControl>
    </div>
  )
}
