import React from 'react'
import { ILink } from '@/redux/types/seo-type'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import styles from './item.module.scss'

type Props = {
  src: string
  link: ILink
}

export const Item: React.FC<Props> = ({ src, link }) => (
  <a
    href={link.url}
    title={link.text}
    target={link.target}
    className={styles.wrapperComponent}
    rel="noreferrer"
    aria-label="Item"
  >
    <LazyLoadImage src={src} alt={link.text} className={styles.image} />
  </a>
)
