import React from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import { ILot } from '@/pages/index/page-lot/ui/molecules/popup-sales-statistics/types'
import Typography from '@material-ui/core/Typography'
import { DocButton } from '@/pages/index/page-lot/ui/atoms'
import { LotDto } from '@/api/origin/api'
import styles from './sales-statistics-card.module.scss'

interface IProps extends ILot {
  onClick: (event: React.MouseEvent<HTMLButtonElement>, auction: LotDto | undefined) => void
}

const SalesStatisticsCard: React.FC<IProps> = ({
  id,
  auctionDate,
  chassisID,
  condition,
  doc,
  image,
  mileage,
  purchaseStatus,
  soldFor,
  startingBid,
  vehicle,
  auction,
  onClick,
}) => (
  <Grid item xs={12} sm={6}>
    <Paper elevation={1} className={styles.container}>
      <Grid container direction="column">
        <Grid container direction="row" justify="space-between">
          <Typography variant="body2" style={{ fontWeight: 'bold' }}>
            {vehicle}
          </Typography>
          <DocButton auction={auction} onClick={onClick} />
        </Grid>
        <Divider className={styles.divider} />
        <Grid container direction="row" justify="space-between">
          <Typography variant="body2">Chassis ID</Typography>
          <Typography variant="body2">{chassisID}</Typography>
        </Grid>
        <Grid container direction="row" justify="space-between">
          <Typography variant="body2">Mileage</Typography>
          <Typography variant="body2">{mileage}</Typography>
        </Grid>
        <Grid container direction="row" justify="space-between">
          <Typography variant="body2">Condition</Typography>
          <Typography variant="body2">{condition}</Typography>
        </Grid>
        <Grid container direction="row" justify="space-between">
          <Typography variant="body2">Sold For</Typography>
          <Typography variant="body2">{soldFor}</Typography>
        </Grid>
        <Grid container direction="row" justify="space-between">
          <Typography variant="body2">Starting Bid</Typography>
          <Typography variant="body2">{startingBid}</Typography>
        </Grid>
        <Grid container direction="row" justify="space-between">
          <Typography variant="body2">Purchase Status</Typography>
          <Typography variant="body2">{purchaseStatus}</Typography>
        </Grid>
        <Divider className={styles.divider} />
        <Grid container direction="row" justify="space-between">
          <Typography variant="body2">Auction Date</Typography>
          <Typography variant="body2">{auctionDate}</Typography>
        </Grid>
      </Grid>
    </Paper>
  </Grid>
)

export default SalesStatisticsCard
