import React from 'react'
import cn from 'classnames'
import { UserSection } from '@/ui/molecules'

import { useSelector } from 'react-redux'
import { Store } from '@/redux/store/store.interface'

import styles from './header.module.scss'
import { NavigationSection } from '..'

type SelectorType = {
  phone: string
  hours: string
}

const getSelectorStore = ({ seo }: Store) => ({
  phone: seo.general.phone_number,
  hours: seo.general.opening_hours,
})

export const Header = ({ isFloat, isNotBorder }: { isNotBorder?: boolean; isFloat?: boolean }) => {
  const { hours, phone } = useSelector<Store, SelectorType>(getSelectorStore)

  return (
    <header className={cn(styles.style, { [styles.float]: isFloat }, { [styles.isNotBorder]: isNotBorder })}>
      <UserSection isFloat={isFloat} hours={hours} phone={phone} />
      <NavigationSection isFloat={isFloat} />
    </header>
  )
}
