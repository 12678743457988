import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { open, close } from '@/redux/actions/popup-action'
import { PopupState } from '@/redux/types/popup-type'

export const popupInitialState: PopupState = {
  state: false,
  size: 'large',
  body: null,
  noScroll: false,
  isCloseButton: true,
}

export const popupReducer = reducerWithInitialState(popupInitialState)
  .case(open, (_, payload) => ({
    state: true,
    ...payload,
  }))
  .case(close, () => ({
    ...popupInitialState,
  }))
  .build()
