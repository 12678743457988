import React from 'react'

import styles from './section-header-highlight.module.scss'

export const SectionHeaderHighlight: React.FC = ({ children }) => (
  <span className={styles.highlight}>
    &nbsp;
    {children}
    &nbsp;
  </span>
)
