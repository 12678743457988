import React from 'react'
import cn from 'classnames'

import { Icon } from '@/ui/atoms'
import styles from './checkbox.module.scss'

export const Checkbox: React.FC<{
  className?: string
  classNameContent?: string
  name: string
  checked?: boolean
  onChange?: (isChecked: boolean) => void
  disabled?: boolean
}> = ({ className, classNameContent, name, children, checked = false, onChange, disabled }) => (
  <label className={cn(styles.wrapperComponent, className)}>
    <input
      disabled={disabled}
      type="checkbox"
      name={name}
      checked={checked}
      onChange={(e) => {
        // eslint-disable-next-line no-unused-expressions
        onChange && onChange(e.target.checked)
      }}
      hidden
      className={styles.input}
    />
    <div>
      <div className={styles.wrapperIcon}>
        <Icon
          name="icon-checkbox"
          width="18px"
          height="18px"
          className={cn(styles.icon, { [styles.active]: checked })}
        />
        <div className={cn(styles.iconOverflow, { [styles.active]: checked })} />
      </div>
    </div>
    <div className={cn(styles.content, classNameContent)}>{children}</div>
  </label>
)
