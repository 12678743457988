import React, { useState } from 'react'
import cn from 'classnames'
import { useClickAway } from 'react-use'
import { Icon } from '@/ui/atoms'
import { Options } from './options'
import styles from './user-card.module.scss'

type Props = {
  classNames?: string
  name?: string
  logout: () => void
}

export const UserCard: React.FC<Props> = ({ classNames, name = 'John Smith', logout }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const refForClickAway = React.useRef(null)

  const openMenu = () => setIsMenuOpen(true)
  const closeMenu = () => setIsMenuOpen(false)
  useClickAway(refForClickAway, closeMenu)

  return (
    <div
      ref={refForClickAway}
      onClick={!isMenuOpen ? openMenu : closeMenu}
      className={cn(classNames, styles.wrapperSection)}
    >
      <div className={styles.name}>{name}</div>
      <Icon
        name="icon-header-dropdown"
        width="24px"
        height="24px"
        className={cn(styles.icon, { [styles.active]: isMenuOpen })}
      />
      <div className={styles.optionsWrapper}>
        <Options active={isMenuOpen} logout={logout} />
      </div>
    </div>
  )
}
