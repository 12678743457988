import React from 'react'
import { SearchResult } from '@/ui/atoms/search/search'
import { BlockItem } from './block-item'
import styles from './styles.module.scss'

interface IProps {
  blocks: SearchResult
  onClick: () => void
}

export const Options: React.FC<IProps> = ({ blocks, onClick }) => (
  <div className={styles.wrapperComponent}>
    {blocks.blogs.length > 0 && <BlockItem items={blocks.blogs} title="Blog" onClick={onClick} />}
    {blocks.otherPage.length > 0 && <BlockItem items={blocks.otherPage} title="Other Page" onClick={onClick} />}
  </div>
)
