import React, { ReactNode } from 'react'

import styles from './default-popup.module.scss'
import { Button, Cancel } from '../../atoms'

export const DefaultPopup: React.FC<{
  title: ReactNode
  body: ReactNode
  buttonText: ReactNode
  d: () => void
  cancel: () => void
}> = ({ title, body, d, cancel, buttonText }) => (
  <div className={styles.wrapperSignUpFromSection}>
    <div className={styles.title}>{title}</div>
    <div className={styles.body}>{body}</div>
    <div className={styles.buttons}>
      <Cancel onClick={cancel} />
      <Button
        className="ml-40"
        type="primary"
        onClick={() => {
          d()
          cancel()
        }}
        aria-label="Button"
      >
        {buttonText}
      </Button>
    </div>
  </div>
)
