import React, { useCallback, useEffect } from 'react'
import cn from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Store } from '@/redux/store/store.interface'
import { open } from '@/redux/actions/popup-action'
import { UserDTO } from '@/api/origin/api'
import { AuthState } from '@/redux/types/auth-type'
import { PopupState } from '@/redux/types/popup-type'
import { MessagesBell, LoginButtons, ComparisonList, WatchList } from '../../atoms'
import { UserCard, PopupSignUp, PopupSignIn, CardBids, PopupLogout } from '..'
import styles from './user-board.module.scss'

const getUserFullName = (user: UserDTO) => `${user.firstName || ''} ${user.lastName || ''}`

type TProps = {
  className?: string
}

export const UserBoard = withRouter(({ className, match, history, location }: TProps & RouteComponentProps) => {
  const { user } = useSelector<Store, AuthState>((store) => store.auth)
  const popup = useSelector<Store, PopupState>((store) => store.popup)
  const dispatch = useDispatch()
  const openPopupSignIn = useCallback(() => {
    dispatch(open({ body: <PopupSignIn /> }))
  }, [dispatch])
  const openPopupSignUp = useCallback(() => {
    dispatch(open({ body: <PopupSignUp />, size: 'large' }))
  }, [dispatch])

  const openPopupLogout = useCallback(() => {
    dispatch(open({ body: <PopupLogout />, size: 'small' }))
  }, [dispatch])

  useEffect(() => {
    if (user) return

    const hash = location.hash.slice(1)

    if (hash === 'reg') {
      openPopupSignUp()
    } else if (hash === 'login') {
      openPopupSignIn()
    }
  }, [location.hash, user, openPopupSignUp, openPopupSignIn])

  useEffect(() => {
    const hash = location.hash.slice(1)
    if (hash && !popup.state) {
      history.push(match.url)
    }
  }, [popup.state, location.hash, history, match.url])

  return (
    <>
      {user && Object.keys(user).length !== 0 && (
        <div className={cn(className, styles.wrapperSection)}>
          <ComparisonList />
          <WatchList />
          <CardBids />
          <MessagesBell className={styles.messageIcon} />
          <UserCard classNames={styles.userCard} name={getUserFullName(user)} logout={openPopupLogout} />
        </div>
      )}
      {Boolean((user && Object.keys(user).length === 0) || !user) && (
        <div className={cn(className, styles.wrapperSection)}>
          <ComparisonList />
          <LoginButtons signIn={openPopupSignIn} signUp={openPopupSignUp} />
        </div>
      )}
    </>
  )
})
