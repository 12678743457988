// core components imports
import React from 'react'

// styles imports
// template components imports
import { Icon } from '../'

type Props = {
  className?: string
  value: 'germany' | 'italy' | 'japan'
}

export const Flag: React.FC<Props> = ({ className, value }) => {
  // eslint-disable-next-line default-case
  switch (value) {
    case 'germany':
      return <Icon name="icon-flag-germany" width="26px" height="17px" className={className} />
    case 'italy':
      return <Icon name="icon-flag-italy" width="26px" height="16px" className={className} />
    case 'japan':
      return <Icon name="icon-flag-japan" width="26px" height="16px" className={className} />
  }
}

// inner components
