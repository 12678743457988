import React from 'react'
import cn from 'classnames'

import styles from './tabs.module.scss'

export const Tabs: React.FC<{
  className?: string
  activeTab: number
  onTabChange: (tabNumber: number) => void
  isBorderSet?: boolean
  limitedHeight?: '5 lines' | '11 lines'
  equalTabWidth?: boolean
  children: React.ReactNode
}> = ({ activeTab = 0, children, className, isBorderSet, limitedHeight, onTabChange, equalTabWidth = false }) => {
  const renderChildrenWithTabsApiAsProps = () => React.Children.map(children, (child, index) => React.cloneElement(child as React.ReactElement<any>, {
    onClick: onTabChange,
    tabIndex: index,
    isActive: index === activeTab,
    equalTabWidth,
  }),
  )

  const renderActiveTabContent = () => {
    const childrenArray: any = React.Children.toArray(children)
    return childrenArray[activeTab]!.props.children
  }

  return (
    <div className={className}>
      <div className={styles.tabTitles}>{renderChildrenWithTabsApiAsProps()}</div>
      <div
        className={cn(
          styles.activeTabContent,
          { [styles.border]: isBorderSet },
          { [styles.limitedHeight5]: limitedHeight === '5 lines' },
          { [styles.limitedHeight11]: limitedHeight === '11 lines' },
        )}
      >
        {renderActiveTabContent()}
      </div>
    </div>
  )
}
