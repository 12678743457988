import React from 'react'
import Downshift, { ControllerStateAndHelpers } from 'downshift'

import cn from 'classnames'
import { DropdownArrow, DropdownList } from '../../atoms'

import style from './dropdown.module.scss'

type Props<T> = {
  className?: string
  label?: string
  name?: string
  id?: string
  value?: any
  disabled?: boolean
  writable?: boolean
  classes?: string
  onChange: (value?: T) => void
  isClear?: boolean
  items: T[] | any[]
  autoCapitalize?: string
  autoComplete?: string
}

export function Dropdown<T extends { id?: string | number; name?: string }>({
  className,
  label,
  value,
  items,
  classes,
  disabled,
  onChange,
  writable,
  isClear = true,
}: Props<T>) {
  const [inputValue, setInputValue] = React.useState(value)

  const onChangeInput = (v: string) => {
    if (disabled) return undefined
    if (!writable) return undefined
    return setInputValue(v)
  }

  React.useEffect(() => {
    setInputValue(value)
  }, [value])

  const createClassName = (isOpen: boolean) => {
    const isActive = inputValue || isOpen

    return cn(
      className,
      `${style['eai-input']}`,
      `${style['eai-input-dropdown']}`,
      classes,
      { [`${style['eai-input__active']}`]: isActive },
      { [`${style['eai-input__activated']}`]: isOpen },
      { [`${style['eai-input__disabled']}`]: disabled },
      { [style.noneWritable]: disabled || !writable },
    )
  }

  const clear = (downshift: ControllerStateAndHelpers<any>) => {
    onChange(undefined)
    downshift.clearSelection()
    downshift.closeMenu()
  }

  const filterList = (input: string | null) => {
    if (input && writable) {
      const regex = RegExp('.*' + input.trim().toLowerCase() + '.*')
      return items.filter((item: any) => regex.test(item.name.toLowerCase()))
    }

    return items
  }

  const onFocusInput = (downshift: ControllerStateAndHelpers<any>) => {
    if (!disabled) {
      downshift.openMenu()
    }
  }

  const toggleMenu = (downshift: ControllerStateAndHelpers<any>) => {
    if (!disabled) {
      downshift.toggleMenu()
    }
  }

  return (
    <Downshift
      inputValue={inputValue}
      onInputValueChange={onChangeInput}
      onChange={(selection) => onChange(selection)}
      itemToString={(item) => (item ? item.name : '')}
    >
      {(downshift) => (
        <div className={createClassName(downshift.isOpen)}>
          <input
            {...downshift.getInputProps({
              className: cn(style['eai-input__input'], { [style.noneWritable]: disabled || !writable }),
              onFocus: () => onFocusInput(downshift),
              autoComplete: 'off',
              autoCapitalize: 'words',
              disabled,
            })}
          />
          <label
            {...downshift.getLabelProps({
              className: cn(style['eai-input__label']),
            })}
          >
            {label}
          </label>
          <DropdownArrow toggle={() => toggleMenu(downshift)} active={downshift.isOpen} />
          <DropdownList
            downshift={downshift}
            items={filterList(downshift.inputValue)}
            clearButtonHandler={isClear ? () => clear(downshift) : undefined}
            active={downshift.isOpen}
          />
        </div>
      )}
    </Downshift>
  )
}
