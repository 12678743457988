import React from 'react'

import styles from './condition-prompt-no-icon.module.scss'

// TODO

export const ConditionPromptNoIcon: React.FC<{
  className?: string
  estimate: string
}> = ({ estimate }) => {
  let message: string

  if (estimate === '0') {
    message = 'The car had been in an accident and was fixed.'
  } else if (estimate === '1') {
    message = `A car which had been in flooding,caught
            in hail or was highly modernized by the previous
            owner (e.g. transmission type was changed or the
            engine was replaced with one from another car model)`
  } else if (estimate === '2') {
    message = 'The car is in poor condition'
  } else if (estimate === '3') {
    message = `The car has obvious dents and scratches,
            needs body repair. Maybe dirt and stains in the cabin,
            burn marks from cigarettes. Even in the absence of these
            defects, a car gets the same grade if
            it has more than 150,000 km on the odometer.`
  } else if (estimate === '3.5') {
    message = `Vehicle may have more than two small
            dents and scratches, the cabin has a few
            spots, possibly cigarette burns, scuff marks,
            and the floor may need additional cleaning.`
  } else if (estimate === '4') {
    message = `Good condition, less than two minor
            dents or scratches, clean interior,
            may need a simple cleaning.`
  } else if (estimate === '4.5') {
    message = 'Excellent condition, no obvious dents, and scratches, clean interior.'
  } else if (estimate === '5') {
    message = 'Almost new car.'
  } else if (estimate === '6') {
    message = 'Brand new car.'
  } else {
    message = "The vehicle wasn't estimated"
  }

  return <div className={styles.messageWrapper}>{message}</div>
}
