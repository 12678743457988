import React from 'react'

import { Icon } from '@/ui/atoms'
import { getShortDescription } from '@/lib'

import styles from './what-our-clients-saying-card.module.scss'

type Props = {
  className?: string
  rate: number
  logo: string
  customer: string
  description: string
  link: {
    url: string
    text: string
    target: string
  }
}

export const WhatOurClientsSayingCard: React.FC<Props> = ({ description, logo, customer, link, rate }) => {
  const Tag = link.url ? 'a' : 'span'
  const attribute = link.url
    ? {
      href: link.url,
      target: '_blank',
      rel: 'noopener noreferrer',
    }
    : {}

  return (
    <article className={styles.cardWrapper}>
      <Tag className={styles.card} {...attribute}>
        <div className={styles.starWrapper}>
          {[...Array(rate)].map((e, i) => (
            <Icon key={i} name="icon-rate" width="16px" height="16px" className={styles.star} />
          ))}
        </div>
        <div className={styles.opinion}>{getShortDescription(description, 215)}</div>
        <div className={styles.opinionForTablet}>{getShortDescription(description, 115)}</div>
        <div className={styles.info}>
          <div className="mt-5">{customer}</div>
          <div className={styles.logoCompany} style={{ backgroundImage: `url(${logo})` }} />
        </div>
      </Tag>
    </article>
  )
}
