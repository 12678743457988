import React from 'react'
import cn from 'classnames'
import { getCarMil, formatAuctionDate } from '@/lib'
import { ConditionPromptNoIcon } from '@/pages/index/page-vehicles/ui/molecules'
import { CustomizedTooltips, Icon } from '@/ui/atoms'

import styles from './vertical-card-properties.module.scss'

interface IProps {
  rate?: string
  auctionDate?: Date | string
  mileage?: number
}

export const VerticalCardProperties: React.FC<IProps> = ({ rate, auctionDate, mileage }) => (
  <div className={styles.wrapper}>
    {Boolean(mileage) && (
      <div className={cn(styles.mileage, styles.item)}>
        <Icon name="icon-card-mileage" width="20px" height="20px" className={styles.icon} />
        <div className={styles.value}>{getCarMil(mileage)}</div>
      </div>
    )}
    {rate && (
      <div className={cn(styles.rate, styles.item)}>
        <div className={styles.itemWrapper}>
          <Icon
            name="icon-card-condition"
            width="18px"
            height="22px"
            className={cn(styles.icon, styles.conditionIcon)}
          />
          <div className={cn(styles.value, styles.conditionValue)}>{rate}</div>
        </div>
        <div className={styles.itemPrompt}>
          <ConditionPromptNoIcon estimate={rate} />
        </div>
      </div>
    )}
    {auctionDate && (
      <div className={cn(styles.date, styles.item)}>
        <CustomizedTooltips title="Auction Date">
          <div className={styles.auctionDateWrapper}>
            <Icon name="icon-card-calendar" width="20px" height="20px" className={cn(styles.icon, styles.dateIcon)} />
            <div className={cn(styles.value, styles.dateValue)}>{formatAuctionDate(auctionDate)}</div>
          </div>
        </CustomizedTooltips>
      </div>
    )}
  </div>
)
