import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { ManagerOrdersState } from '@/redux/types/manager-orders-type'
import {
  OrdersControllerApiIndexOrdersByManagerUsingGETRequestAction,
  OrdersControllerApiUpdateOrderByManagerUsingPUTRequestAction,
} from '@/api/origin/action'
import { logout } from '@/redux/actions/auth-action'
import { defaultTableRowsPerPage } from '@/index'
import { OrderDtoStateEnum } from '@/api/origin/api'

export const managerOrdersInitState: ManagerOrdersState = {
  byId: {},
  allIds: [],
  byIdOrders: {},
  allIdsOrders: [],
  state: OrderDtoStateEnum.NEW,
  currentPage: 0,
  size: defaultTableRowsPerPage,
  pageCount: 0,
  isLoading: false,
}

export const managerOrdersReducer = reducerWithInitialState<ManagerOrdersState>(managerOrdersInitState)
  .case(logout, () => managerOrdersInitState)
  .case(OrdersControllerApiIndexOrdersByManagerUsingGETRequestAction.started, (state) => ({
    ...state,
    isLoading: false,
  }))
  .case(OrdersControllerApiIndexOrdersByManagerUsingGETRequestAction.done, (_, { result, options }: any) => ({
    byId: {
      ...result.report.byId,
    },
    allIds: [...result.report.allIds],
    byIdOrders: {
      ...result.orders.byId,
    },
    allIdsOrders: [...result.orders.allIds],
    state: options.state,
    currentPage: options.page,
    size: options.size,
    pageCount: options.pageCount,
    isLoading: true,
  }))
  .case(OrdersControllerApiUpdateOrderByManagerUsingPUTRequestAction.done, (state, { options }: any) => ({
    ...state,
    byIdOrders: {
      ...state.byIdOrders,
      [options.id]: {
        ...state.byIdOrders[options.id],
        ...options,
      },
    },
  }))
  .build()
