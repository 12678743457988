import React from 'react'
import { useSelector } from 'react-redux'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { Store } from '@/redux/store/store.interface'
import { SEOGeneralType } from '@/redux/types/seo-type'
import { FooterCopyright } from '@/ui/molecules'
import { FooterSection } from '../footer-section'

type SelectorType = {
  info: SEOGeneralType
}

const getSelectorStore = ({ seo }: Store) => ({
  info: seo.general,
})

export const Footer = () => {
  const { info } = useSelector<Store, SelectorType>(getSelectorStore)

  return (
    <footer>
      <LazyLoadComponent>
        <FooterSection info={info} />
        <FooterCopyright info={info.footer} />
      </LazyLoadComponent>
    </footer>
  )
}
