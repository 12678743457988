import React, { useState } from 'react'
import cn from 'classnames'
import { useDispatch } from 'react-redux'
import { useClickAway } from 'react-use'
import MenuIcon from '@material-ui/icons/Menu'
import { Button, Logo, Search } from '@/ui/atoms'
import { NavigationPhone, PopupRequestACall } from '@/ui/molecules'
import { open } from '@/redux/actions/popup-action'
import { useResize } from '@/lib'
import { Navigation } from '..'
import styles from './navigation-section.module.scss'

export const NavigationSection: React.FC<{
  className?: string
  isFloat?: boolean
}> = ({ className, isFloat }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const refForClickAway = React.useRef(null)
  const dispatch = useDispatch()
  const openMenu = () => (!isMenuOpen ? setIsMenuOpen(true) : undefined)
  const closeMenu = () => setIsMenuOpen(false)
  const windowSize = useResize()
  useClickAway(refForClickAway, closeMenu)

  return (
    <div className={cn(className, styles.wrapperComponent)} ref={refForClickAway}>
      <div className={cn(isFloat ? 'container-fluid' : 'container', styles.wrapperContainer)}>
        <button
          className={styles.buttonForTabletOnly}
          onClick={!isMenuOpen ? openMenu : closeMenu}
          name="Navigation button"
          aria-label="Navigation button"
        >
          <MenuIcon className={styles.icon} />
        </button>
        <div className={styles.navigationPhoneWrapper}>
          <NavigationPhone closeMenu={closeMenu} active={isMenuOpen} />
        </div>
        <Logo className={cn(styles.homePageLogo, { [styles.homePageLogoLarge]: windowSize > 900 })} />
        <Navigation className={styles.menuForDesktop} />
        <div className={styles.wrapperOptions}>
          <Search />
          <Button
            className={cn(styles.desktopOnly, 'ml-25')}
            type="primary"
            onClick={handleRequest}
            aria-label="Request a call"
          >
            Request a call
          </Button>
        </div>
      </div>
    </div>
  )

  function handleRequest() {
    dispatch(open({ body: <PopupRequestACall />, size: 'small' }))
  }
}
