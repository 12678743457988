import React, { useState } from 'react'
import MomentUtils from '@date-io/moment'
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { ParsableDate } from '@material-ui/pickers/constants/prop-types'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  dense: {
    marginTop: theme.spacing(0),
    width: '100%',
  },
  root: {
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: '#ECA52C',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#ECA52C',
    },
  },
}))

type Props = {
  onChange: (date: Date, value?: string) => void
  onError?: boolean
  minDate?: ParsableDate
  maxDate?: ParsableDate
  label?: string
  helperText?: string
  format: string
} & React.HTMLProps<HTMLInputElement>

const outerTheme = createMuiTheme({
  palette: {
    primary: {
      light: 'rgba(248, 231, 28, 1)',
      main: 'rgba(245, 166, 35, 1)',
      dark: 'rgba(248, 28, 28, 1)',
      contrastText: '#fff',
    },
    secondary: {
      light: 'rgba(248, 231, 28, 1)',
      main: 'rgba(245, 166, 35, 1)',
      dark: 'rgba(139, 87, 42, 1)',
      contrastText: '#fff',
    },
  },
})

export const DataPicker: React.FC<Props> = ({ value = '', label, onChange, minDate, helperText, format }) => {
  const [selectedDate, setSelectedDate] = useState(new Date())
  const handleDateChange = (date: any) => {
    setSelectedDate(date)
    onChange(date && date.format(format))
  }

  const classes = useStyles()

  return (
    <ThemeProvider theme={outerTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          className={clsx(classes.root, classes.dense)}
          disableToolbar
          margin="dense"
          variant="inline"
          inputVariant="outlined"
          format={format}
          id="date-picker-inline"
          label={label}
          value={value || selectedDate}
          onChange={handleDateChange}
          helperText={helperText}
          minDate={minDate}
          autoOk
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}
