export default [
  { id: 1, href: '/', title: 'Home' },
  { id: 2, href: '/vehicles', title: 'Vehicles' },
  { id: 3, href: '/about-us', title: 'About Us' },
  { id: 4, href: '/blog', title: 'Blog' },
  { id: 5, href: '/faq', title: 'FAQ' },
  { id: 6, href: '/contact-us', title: 'Contact Us' },
]

export type DataListType = {
  id: number
  href: string
  title: string
}
