import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { PaymentTypesState } from '@/redux/types/paymentTypes-type'
import { WalletTransactionTypesControllerApiIndexWalletTransactionTypesUsingGETRequestAction } from '@/api/origin/action'

export const paymentTypesInitState: PaymentTypesState = {
  byId: {},
  allIds: [],
  isLoading: false,
}

export const paymentTypesReducer = reducerWithInitialState<PaymentTypesState>(paymentTypesInitState)
  .case(WalletTransactionTypesControllerApiIndexWalletTransactionTypesUsingGETRequestAction.started, (state) => ({
    ...state,
    isLoading: false,
  }))
  .case(
    WalletTransactionTypesControllerApiIndexWalletTransactionTypesUsingGETRequestAction.done,
    (state, { result }: any) => ({
      ...state,
      byId: {
        ...result.byId,
      },
      allIds: [...result.allIds],
      isLoading: true,
    }),
  )
  .build()
