import React from 'react'
import cn from 'classnames'
import { Phone } from '@/ui/atoms'
import { UserBoard } from '../user-board'
import styles from './user-section.module.scss'

type Props = {
  hours: string
  className?: string
  isFloat?: boolean
  phone: string
}

export const UserSection: React.FC<Props> = ({ hours, isFloat, phone }) => (
  <div className={styles.wrapperSection}>
    <div className={cn(isFloat ? 'container-fluid' : 'container', styles.wrapperContainer)}>
      <div className={styles.openingHours}>
        <span className={styles.bigPhoneUp}>
          {hours}
          {' '}
          :&nbsp;
        </span>
        <Phone responsive className={styles.desktopOnly} phone={phone} />
      </div>
      <UserBoard />
    </div>
  </div>
)
