import { ActionsObservable, combineEpics } from 'redux-observable'
import { Action } from 'typescript-fsa'
import { mergeMap, catchError } from 'rxjs/operators'
import { of, from } from 'rxjs'
import { ofAction } from '@/lib/rxjs-operators'

import { LotStatesControllerApiIndexLotStatesUsingGETRequestAction } from '@/api/origin/action'
import { lotStates } from '@/api'
import { normalizeLotsStates } from '@/redux/normalize'

const getIndexLotStates = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(LotStatesControllerApiIndexLotStatesUsingGETRequestAction.started),
  mergeMap(() => from(lotStates.indexLotStatesUsingGET()).pipe(
    // LotStateDto
    mergeMap(({ data }: { data: any }) => of(
      LotStatesControllerApiIndexLotStatesUsingGETRequestAction.done({
        // @ts-ignore
        result: normalizeLotsStates(data),
        params: {},
      }),
    ),
    ),
    catchError((error) => of(LotStatesControllerApiIndexLotStatesUsingGETRequestAction.failed(error.response))),
  ),
  ),
)

export const lotEpic = combineEpics(getIndexLotStates)
