import React from 'react'
import { filterApi } from '@/api'
import { stringify } from 'query-string'
import { rangeFilterToString } from '@/lib'
import { initialYear } from '@/redux/reducers/filter-reducer'
import { Store } from '@/redux/store/store.interface'
import { useSelector } from 'react-redux'
import { FilterDto } from '@/api/origin/api'

const platform = 'AVTO_JP'

type RangeFilterType = readonly number[]

interface IFilter {
  make: string[]
  modelList: any
  model: string[]
  auction?: string
  year?: RangeFilterType
  price?: RangeFilterType
}

export enum FilterFieldType {
  MAKE = 'make',
  MODEL = 'model',
  AUCTION = 'auction',
  YEAR = 'year',
  PRICE = 'price',
}

const initStateFilter: IFilter = {
  make: [],
  modelList: [],
  model: [],
  auction: '',
  year: initialYear,
}

const getSelectorFilter = () => ({ filter }: Store) => filter || {}

export const useFilter = () => {
  const filters = useSelector<Store, any>(getSelectorFilter())
  const [filterValues, setFilterValue] = React.useState(initStateFilter)

  const selectFilter = (name: FilterFieldType) => async (value: any) => {
    if (name === FilterFieldType.MAKE) {
      const externalIdMakes = filters.MAKE.list
        .filter((make: FilterDto) => value.includes(make.name || ''))
        .map((make: FilterDto) => make.externalId || '')
      const modelList = (await filterApi.findFilterByPlatformAndTypeUsingGET('MODEL', platform, externalIdMakes)).data

      const newModels = modelList.filter((model: FilterDto) => filterValues.model.includes(model.name || ''))
      const model = newModels
        .filter((model: FilterDto) => externalIdMakes.includes(model.parentExternalId || ''))
        .map((model: FilterDto) => model.name || '')

      setFilterValue({
        ...filterValues,
        [name]: value,
        model,
        modelList,
      })
    } else {
      setFilterValue({ ...filterValues, [name]: value })
    }
  }

  const queryParams = `/vehicles?page=1&grid=grid&${stringify({
    make: filterValues.make.length > 0 ? filterValues.make.join(',') : undefined,
    model: filterValues.model.length > 0 ? filterValues.model.join(',') : undefined,
    year: filterValues.year ? rangeFilterToString(filterValues.year as any) : undefined,
    priceUSD: filterValues.price ? rangeFilterToString(filterValues.price as any) : undefined,
    auction: filterValues.auction ? filterValues.auction : undefined,
  })}`

  return {
    filters,
    filterValues,
    selectFilter,
    queryParams,
  }
}
