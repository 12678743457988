import React, { useRef } from 'react'
import ReactToPrint from 'react-to-print'
import { HTMLDecoder } from '@/lib'
import { Button, Icon } from '@/ui/atoms'
import styles from './popup-auction-sheet.module.scss'

type Props = {
  d: () => void
  cancel: () => void
  img: any
  bodyWrapper: any
}

export const PopupAuctionSheet: React.FC<Props> = ({ d, cancel, img, bodyWrapper }) => {
  const componentRef = useRef()
  return (
    <div className={styles.popupWrapper}>
      <div className={styles.popupHeader}>
        <h2 className={styles.titlePopup}>Auction Sheet</h2>
        <button className={styles.popupCloseIcon} onClick={cancel}>
          <Icon className={styles.iconPopup} name="icon-popup-close" width="16px" height="16px" />
        </button>
      </div>
      {bodyWrapper && (
        <p className={styles.chassis}>
          This Auction Sheet is verified against the Chassis number:
          {' '}
          {HTMLDecoder(`${bodyWrapper}`)}
        </p>
      )}
      <img
        // @ts-ignore
        ref={componentRef}
        className={styles.auctionSheet}
        src={img}
        alt="Auction Sheet"
      />

      <div className={styles.buttonWrapper}>
        <Button
          className={styles.auctionSheetLink}
          type="secondary"
          onClick={() => {
            d()
            cancel()
          }}
        >
          How to read
        </Button>
        <ReactToPrint
          trigger={() => (
            <Button className={styles.button} type="primary">
              Save
            </Button>
          )}
          // @ts-ignore
          content={() => componentRef.current}
        />
      </div>
    </div>
  )
}
