import React, { useMemo } from 'react'
import { RangeDropdown } from '@/ui/molecules'
import { FormMultiselect, SelectCustomized } from '@/ui/atoms'
import { FilterState, PlainName, RangeName, PlaneFilterValue } from '@/redux/types/filter-type'
import { FilterDto } from '@/api/origin/api'
// import { getYear } from '@/lib'
import styles from './search-form.module.scss'

export const SearchForm: React.FC<{
  filter: FilterState
  changePlainFilter: (name: PlainName) => (value?: string | string[]) => void
  changeRangeFilter: (name: RangeName) => (value?: PlaneFilterValue) => void
  resetFilter: () => void
}> = ({ filter, changePlainFilter, changeRangeFilter, resetFilter }) => {
  const handleChange = (name: PlainName) => (value: FilterDto[]) => {
    changePlainFilter(name)(value.map((item) => item.name || ''))
  }
  // const maxYear = getYear()
  const listModelGroup = useMemo(
    () => filter.MAKE.list
      .filter((item: FilterDto) => filter.MAKE.value.includes(item['name'] || ''))
      .map((item: FilterDto) => ({
        title: item.name || '',
        items: filter.MODEL.list.filter((modelItem: any) => modelItem.parentExternalId === item.externalId),
      })),
    [filter.MAKE.list, filter.MAKE.value, filter.MODEL.list],
  )

  return (
    <form className={styles.style}>
      <FormMultiselect<string[], FilterDto>
        className="mb-25"
        label="Make"
        value={filter.MAKE.value}
        items={filter.MAKE.list}
        keyAsValue="name"
        keyAsTitle="name"
        onChange={handleChange('MAKE')}
        isMake
      />
      <FormMultiselect<string[], FilterDto>
        className="mb-25"
        label="Model"
        value={filter.MODEL.value}
        items={filter.MODEL.list}
        groupItems={listModelGroup}
        keyAsValue="name"
        keyAsTitle="name"
        onChange={handleChange('MODEL')}
        disabled={filter.MAKE.value.length === 0}
      />
      <RangeDropdown
        id="year"
        label="Year"
        min={1921}
        max={2010}
        classes="mb-25"
        step={1}
        value={filter.YEAR.value as any}
        onChange={(value: any) => changeRangeFilter('YEAR')(value)}
        reset={() => changeRangeFilter('YEAR')(undefined)}
      />
      <RangeDropdown
        id="price"
        label="Price"
        min={0}
        max={99999}
        classes="mb-25"
        step={1}
        value={filter.PRICE.value as any}
        onChange={(value: any) => changeRangeFilter('PRICE')(value)}
        reset={() => changeRangeFilter('PRICE')(undefined)}
        money
      />
      <RangeDropdown
        id="engine"
        label="Engine Displacement(Cс):"
        step={500}
        min={0}
        max={10000}
        classes="mb-25"
        value={filter.ENGINE_DISPLACEMENT.value as any}
        onChange={(value: any) => changeRangeFilter('ENGINE_DISPLACEMENT')(value)}
        reset={() => changeRangeFilter('ENGINE_DISPLACEMENT')(undefined)}
      />

      <RangeDropdown
        id="mileage"
        label="Mileage"
        step={10000}
        min={0}
        max={100000}
        classes="mb-25"
        value={filter.MILEAGE.value as any}
        onChange={(value: any) => changeRangeFilter('MILEAGE')(value)}
        reset={() => changeRangeFilter('MILEAGE')(undefined)}
      />

      <SelectCustomized
        className="mb-25"
        name="transmission"
        id="transmission"
        label="Transmission"
        isNoneBlock
        value={filter.TRANSMISSION_TYPE.value ? filter.TRANSMISSION_TYPE.value : ''}
        items={filter.TRANSMISSION_TYPE.list.map((item: any) => ({ id: item.id, value: item.name }))}
        onChange={changePlainFilter('TRANSMISSION_TYPE')}
      />
      <SelectCustomized
        className="mb-25"
        name="color"
        id="color"
        label="Color"
        isNoneBlock
        value={filter.COLOR.value ? filter.COLOR.value : ''}
        items={filter.COLOR.list.map((item: any) => ({ id: item.id, value: item.name }))}
        onChange={changePlainFilter('COLOR')}
      />
      <SelectCustomized
        className="mb-25"
        name="auction"
        id="auction"
        label="Auction"
        isNoneBlock
        value={filter.AUCTION.value ? filter.AUCTION.value : ''}
        items={filter.AUCTION.list.map((item: any) => ({ id: item.id, value: item.name }))}
        onChange={changePlainFilter('AUCTION')}
      />
      <SelectCustomized
        className="mb-25"
        name="country"
        id="country"
        label="Country"
        isNoneBlock
        value="Japan"
        items={[{ value: 'Japan' }]}
        onChange={() => {}}
        disabled
      />
      <div className={styles.resetButton} onClick={resetFilter}>
        Reset Filters
      </div>
    </form>
  )
}
