import React from 'react'
import cn from 'classnames'

import styles from './reason.module.scss'

type ReasonProps = {
  className?: string
}

export const Reason: React.FC<ReasonProps> = ({ className, children }) => (
  <article className={cn(className, styles.reason)}>{children}</article>
)

export const ReasonTitle: React.FC = ({ children }) => <h3 className={styles.title}>{children}</h3>

export const ReasonDescription: React.FC = ({ children }) => <div className={styles.description}>{children}</div>
