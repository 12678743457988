import styled from 'styled-components'
import { Typography } from '@material-ui/core'

export const STTableHeadTypography = styled(Typography)`
  && {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    color: rgba(42, 50, 60, 0.6);
    text-transform: uppercase;
  }
`
