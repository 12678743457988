import { reducerWithInitialState } from 'typescript-fsa-reducers'

import {
  UserResourceApiUpdateOrderDriverLicenseUsingPOSTRequestAction,
  UserResourceApiReadOrderDriverLicenseUsingGETRequestAction,
} from '@/api/origin/action'

export const driverLicenseInitState: any = {
  state: 'load',
}

export const driverLicenseReducer = reducerWithInitialState<any>(driverLicenseInitState)
  .case(UserResourceApiUpdateOrderDriverLicenseUsingPOSTRequestAction.done, (state) => ({
    ...state,
    driverLicense: {
      state: 'update',
    },
  }))
  .case(UserResourceApiReadOrderDriverLicenseUsingGETRequestAction.done, (state) => ({
    ...state,
  }))
  .build()
