import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Icon } from '@/ui/atoms'
import styles from './styles.module.scss'

interface IProps {
  imageSrc: string
  title: string
  onClick: () => void
}

export const ListItem: React.FC<IProps> = ({ imageSrc, title, onClick }) => (
  <div className={styles.row} onClick={onClick}>
    {imageSrc ? (
      <LazyLoadImage className={styles.image} src={imageSrc} alt="car" />
    ) : (
      <Icon name="image-placeholder" className={styles.image} />
    )}
    <div className={styles.title}>{title}</div>
  </div>
)
