import { ActionsObservable, combineEpics } from 'redux-observable'
import { Action } from 'typescript-fsa'

import { mergeMap, catchError } from 'rxjs/operators'
import { of, from } from 'rxjs'
import { userApi } from '@/api'
import { ofAction } from '@/lib/rxjs-operators'

import {
  UserResourceApiUpdateOrderDriverLicenseUsingPOSTRequestAction,
  UserResourceApiIndexOrdersUsingGETRequestAction,
} from '@/api/origin/action'

const updateDriverLicense = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(UserResourceApiUpdateOrderDriverLicenseUsingPOSTRequestAction.started),
  mergeMap(({ payload: { orderId, userId, file, options } }) => from(userApi.updateOrderDriverLicenseUsingPOST(orderId, userId, file, options)).pipe(
    mergeMap(({ data }: { data: any }) => [
      UserResourceApiUpdateOrderDriverLicenseUsingPOSTRequestAction.done(data),
      UserResourceApiIndexOrdersUsingGETRequestAction.started({ userId }),
    ]),
    catchError((error) => of(UserResourceApiUpdateOrderDriverLicenseUsingPOSTRequestAction.failed(error.response))),
  ),
  ),
)

export const driverLicense = combineEpics(updateDriverLicense)
