import React from 'react'
import cn from 'classnames'
import styles from './title.module.scss'

export const Title: React.FC<{
  classNames?: string
  title: string
}> = ({ title, classNames }) => (
  <div className={cn(styles.style, classNames)}>
    <h2 className={styles.title}>{title}</h2>
  </div>
)
