import React from 'react'
import cn from 'classnames'

import styles from './listing-titled-block.module.scss'

export const ListingTitledBlock: React.FC<{
  title: string
  className?: string
}> = ({ title, children, className }) => (
  <div className={cn(className, styles.titledBlock)}>
    <h2 className={styles.title}>{title}</h2>
    <div className={styles.content}>{children}</div>
  </div>
)
