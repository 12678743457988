import React from 'react'
import { Icon } from '@/ui/atoms'
import { Link } from 'react-router-dom'

import styles from './snackbar-add-comparison.module.scss'

export const SnackbarAddComparison: React.FC<{ name: string }> = ({ name }) => (
  <div className={styles.wrapperContent}>
    <Icon name="icon-snackbar-success" width="40px" height="40px" />
    <div>
      <div className={styles.messageText}>
        {name}
        {' '}
        Added to the comparison list.
      </div>
      <Link className={styles.link} to="/comparison-list">
        View
      </Link>
    </div>
  </div>
)
