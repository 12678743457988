import React from 'react'
import cn from 'classnames'
import { LanguageLink } from '@/ui/atoms'

import styles from './navigation-item.module.scss'

export const NavigationItem: React.FC<{
  className?: string
  value: string
  href: string
  active?: boolean
}> = ({ className, value, href, active }) => (
  <LanguageLink to={href} className={cn(className, styles.wrapperComponent, { [styles.active]: active })}>
    {value}
  </LanguageLink>
)
