import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import cn from 'classnames'
import { useResizeHeight, HTMLDecoder, getCarTitle } from '@/lib'
import { Icon } from '@/ui/atoms'
import { ComparisonListState } from '@/redux/types/comparison-list-type'
import { Store } from '@/redux/store/store.interface'
import { addComparison, removeComparison } from '@/redux/actions/comparison-list-action'
import { LotDto } from '@/api/origin/api'
import { DashboardResourceApiDeleteFavoriteUsingDELETERequestAction } from '@/api/origin/action'
import { addWatch } from '@/redux/actions/watch-list-action'
import { getSelectorLotByExternalId } from '@/redux/selectors/procurement-selector'
import { open, close } from '@/redux/actions/popup-action'
import { PopupDeleteWatch } from '@/pages/dashboard/page-watch-list/ui/molecules'
import { PopupAuctionSheet } from '@/pages/index/page-vehicles/ui/atoms'
import { PopupRemoveComparison, PopupExceededComparison } from '@/pages/index/page-comparison-list/ui/atoms'
import { auctionApi } from '@/api'
import styles from './vehicle-models-of-action.module.scss'

const getSelectorStore = ({ watchList, comparisonList }: Store) => ({
  watchList,
  comparisonList,
})

type TProps = {
  className?: string
  auction: LotDto
  isActive?: boolean
  callback?: (value: boolean) => void
}

export const VehicleModelsOfAction = ({ className, auction, callback }: TProps) => {
  const dispatch = useDispatch()
  const { watchList, comparisonList } = useSelector<
    Store,
    {
      watchList: number[]
      comparisonList: ComparisonListState
    }
  >(getSelectorStore)
  const externalId = auction.externalId || ''
  const lot = useSelector<Store, any>(getSelectorLotByExternalId(externalId || ''))
  const isComparison = comparisonList.includes(externalId)
  const isWatch = watchList.includes(lot.id || 0)
  const windowHeightSize = useResizeHeight()

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    callback && callback(isWatch || isComparison)
  }, [callback, isWatch, isComparison])

  const handleComparisonList = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault()
    if (isComparison) {
      openPopupRemoveComparison(externalId)
    } else {
      if (comparisonList.length === 3) {
        return openPopupExceededComparison()
      }
      dispatch(addComparison({ externalId, name: getCarTitle(auction) }))
    }
  }

  const handleWatchList = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault()
    if (isWatch) {
      openPopupRemoveWatch(lot.id || 0)
    } else {
      dispatch(addWatch(auction))
    }
  }

  const handleCardSheet = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault()

    if (externalId) {
      auctionApi.findAuctionLotByIdUsingGET(externalId).then((e) => {
        openPopupCardSheet(e.data)
      })
    }
  }

  return (
    <>
      <div className={cn(styles.wrapper, className)}>
        {auction.auctionSheetUrl && (
          <Icon name="icon-card-sheet" className={cn(styles.item)} onClick={handleCardSheet} />
        )}
        <Icon
          name="icon-card-compare"
          className={cn(styles.item, { [styles.active]: isComparison })}
          title={titleComparison(isComparison)}
          onClick={handleComparisonList}
        />
        <Icon
          name="icon-card-wishlist"
          className={cn(styles.item, { [styles.active]: isWatch })}
          title={titleWatch(isWatch)}
          onClick={handleWatchList}
        />
      </div>
    </>
  )

  function titleComparison(isActive: boolean) {
    return isActive ? 'Remove from Comparison List' : 'Add To Comparison List'
  }

  function titleWatch(isActive: boolean) {
    return isActive ? 'Remove from Watchlist' : 'Add To Watchlist'
  }

  function openPopupRemoveComparison(externalId: string) {
    dispatch(
      open({
        body: (
          <PopupRemoveComparison
            cancel={() => dispatch(close())}
            d={() => dispatch(removeComparison({ externalId, name: getCarTitle(auction) }))}
          />
        ),
      }),
    )
  }

  function openPopupExceededComparison() {
    dispatch(
      open({
        body: <PopupExceededComparison cancel={() => dispatch(close())} d={() => dispatch(push('/comparison-list'))} />,
      }),
    )
  }

  function openPopupRemoveWatch(lotId: number) {
    dispatch(
      open({
        body: (
          <PopupDeleteWatch
            d={() => dispatch(DashboardResourceApiDeleteFavoriteUsingDELETERequestAction.started({ lotId, options: {} }))
            }
            cancel={() => dispatch(close())}
          />
        ),
      }),
    )
  }

  function openPopupCardSheet(lot: LotDto) {
    dispatch(
      open({
        body: lot.images && (
          <PopupAuctionSheet
            d={() => dispatch(push('/how-to-read-auction-sheet'))}
            cancel={() => dispatch(close())}
            img={lot.images[0]}
            bodyWrapper={HTMLDecoder(auction.chassis)}
          />
        ),
        height: 'auto',
        noScroll: windowHeightSize > 700,
        isCloseButton: false,
      }),
    )
  }
}
