import React, { useState } from 'react'

import { isEmailHasProblem } from '@/lib/form'
import { resetPassword } from '@/redux/actions/auth-action'
import { close } from '@/redux/actions/popup-action'
import { useDispatch } from 'react-redux'
import { Button, Input } from '../../atoms'
import styles from './popup-reset-password.module.scss'

export const PopupResetPassword = () => {
  const [email, setEmail] = useState<string>('')
  const dispatch = useDispatch()
  const changePassword = () => {
    dispatch(resetPassword({ email }))
    dispatch(close())
  }

  return (
    <>
      <div className={styles.titleSmall}>Forgot password</div>
      <Input
        wrapperClassName="mb-20"
        name="mail"
        id="mail"
        label="Input your email here"
        value={email}
        type="email"
        isError={Boolean(email) && isEmailHasProblem(email)}
        errorText={Boolean(email) && isEmailHasProblem(email) ? 'Invalid Email Address' : ''}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.currentTarget.value)}
      />

      <Button type="primary" disabled={isEmailHasProblem(email)} fullWidth onClick={changePassword} aria-label="Login">
        Reset password
      </Button>
    </>
  )
}
