import React from 'react'
import { PaginationItemsHolder } from '@/ui/atoms'
import { NavArrow } from '..'
import styles from './pagination.module.scss'

export const Pagination: React.FC<{
  currentPage: number
  totalPage: number
  changePageNumber: (num: number) => void
}> = ({ totalPage, currentPage, changePageNumber }) => (
  <div className={styles.pagination}>
    <NavArrow
      type="backward"
      onClick={() => changePageNumber(currentPage - 1)}
      arrowClassName={styles.arrowColor}
      wrapperClassName="mr-10"
      disabled={currentPage === 1}
    />
    <PaginationItemsHolder totalPage={totalPage} currentPage={currentPage} changePageNumber={changePageNumber} />
    <NavArrow
      type="forward"
      onClick={() => changePageNumber(currentPage + 1)}
      arrowClassName={styles.arrowColor}
      wrapperClassName="ml-10"
      disabled={currentPage === totalPage}
    />
  </div>
)
