import { API_URL } from '@/constants/API'
import {
  AccountResourceApi,
  AvtoJpLotsFacadeControllerApi,
  DashboardResourceApi,
  FilterResourceApi,
  UserJwtControllerApi,
  UserResourceApi,
  LotResourceApi,
  LotStatesControllerApi,
  PortsControllerApi,
  BidsControllerApi,
  WalletTransactionTypesControllerApi,
  BidsReportRowsControllerApi,
  OrdersControllerApi,
  PaymentResourceApi,
} from './origin/api'

const basePath = API_URL
const configuration = { basePath }

export const accountApi = new AccountResourceApi(configuration)
export const auctionApi = new AvtoJpLotsFacadeControllerApi(configuration)
export const dashboardApi = new DashboardResourceApi(configuration)
export const filterApi = new FilterResourceApi(configuration)
export const userJwtControllerApi = new UserJwtControllerApi(configuration)
export const userApi = new UserResourceApi(configuration)
export const lotApi = new LotResourceApi(configuration)
export const lotStates = new LotStatesControllerApi(configuration)
export const portsApi = new PortsControllerApi(configuration)
export const bidsApi = new BidsControllerApi(configuration)
export const bidsReportApi = new BidsReportRowsControllerApi(configuration)
export const paymentTypeApi = new WalletTransactionTypesControllerApi(configuration)
export const controllerApi = new OrdersControllerApi(configuration)
export const paymentApi = new PaymentResourceApi(configuration)
