import React, { useEffect, useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import cn from 'classnames'
import { isNaN } from 'formik'
import style from './range-input.module.scss'

type Props = {
  money?: boolean
  label?: string
  name?: string
  id?: string
  value?: string
  disabled?: boolean
  hasError?: boolean
  onChange?: (value: React.ReactText) => void
  min?: number
  max?: number
}

export const RangeInput: React.FC<Props> = ({
  label,
  name,
  value = '',
  id,
  disabled,
  min,
  max,
  hasError,
  onChange,
  money,
}) => {
  const [inputFocus, setInputFocus] = useState(false)
  const [inputValue, setInputvalue] = useState(value)
  const [isInputHasError, setIsInputHasError] = useState(hasError)
  const [isUserUsingInputField, setIsUserUsingInputField] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!isUserUsingInputField) {
      setInputvalue(value)
    }
    setIsInputHasError(false)
  }, [value, isUserUsingInputField])

  useEffect(() => {
    setIsInputHasError(hasError)
  }, [hasError])

  const className = cn(
    `${style['eai-input']}`,
    { [`${style['eai-input__active']}`]: value || inputFocus },
    { [`${style['eai-input__activated']}`]: inputFocus },
  )

  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = ''

    if (money && event.target.value.split('$').length === 2) {
      inputValue = event.target.value.split('$')[1].trim()
    } else {
      inputValue = event.target.value
    }
    if (onChange && /^\d*$/.test(inputValue)) {
      if (
        !isNaN(inputValue) &&
        min !== undefined &&
        Number(inputValue) >= min &&
        max !== undefined &&
        Number(inputValue) <= max
      ) {
        onChange(inputValue)
        setInputvalue(inputValue)
        if (!hasError) setIsInputHasError(false)
      } else {
        setInputvalue(inputValue)
        setIsInputHasError(true)
      }
    }
  }

  function handleFocus() {
    setInputFocus(true)
    setIsUserUsingInputField(true)
  }

  function handleBlur() {
    setInputFocus(false)
    setIsUserUsingInputField(false)
  }

  useClickAway(
    inputRef,
    () => {
      handleBlur()
      inputRef.current!.blur()
    },
    ['mousedown'],
  )

  const inputValuetoDiplay = money ? `$ ${inputValue}` : inputValue
  return (
    <div className={className}>
      <input
        ref={inputRef}
        className={cn(style['eai-input__input'], { [style.inputOnError]: isInputHasError })}
        id={id}
        type="text"
        name={name}
        value={inputValuetoDiplay}
        onChange={onHandleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={disabled}
      />
      <label htmlFor={id} className={`${style['eai-input__label']}`}>
        {label}
      </label>
    </div>
  )
}
