import React, { useState } from 'react'

import { Button, Input, Popup, PopupTitle } from '@/ui/atoms'
import { loadStripe } from '@stripe/stripe-js'
import StripeConfig from '@/constants/stripe'
import { paymentApi } from '@/api'
import { useDispatch } from 'react-redux'
import { close } from '@/redux/actions/popup-action'
import styles from './popup-top-up-balance.module.scss'

type Props = {
  title: string
  limit?: boolean
  defaultAmount?: number
}

const stripePromise = loadStripe(StripeConfig.API_TOKEN)

export const PopupTopUpBalance: React.FC<Props> = ({ limit, title, defaultAmount }) => {
  const [amount, setAmount] = useState<number>(defaultAmount || 0)

  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(close())
  }

  const saveChange = async () => {
    if (!amount) return

    try {
      const stripe = await stripePromise
      const response = await paymentApi.createCheckoutSessionUsingPOST({ amount })

      if (response.data && stripe) {
        const result = await stripe.redirectToCheckout({
          sessionId: response.data,
        })

        if (result.error) {
          console.error(result.error.message)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }
  // Replenish the balance amounting to
  const isError = limit && amount > 5000
  return (
    <Popup handler={handleClose} size="small" color="grey">
      <PopupTitle size="small">{title}</PopupTitle>
      <div className={styles.inputWrapper}>
        <Input
          wrapperClassName={styles.input}
          label="Amount"
          value={amount}
          currency
          isError={isError}
          errorText="$5,000 max"
          onChange={(event: any) => {
            setAmount(event.target.value)
          }}
          decimalSeparator={false}
          allowNegative={false}
        />
      </div>
      <div className={styles.buttonsWrapper}>
        <button onClick={handleClose} className="mr-25" name="Cancel" aria-label="Cancel">
          Cancel
        </button>
        <Button
          className={styles.button}
          type="primary"
          aria-label="Confirm"
          fullWidth
          onClick={saveChange}
          disabled={isError}
        >
          Confirm
        </Button>
      </div>
    </Popup>
  )
}
