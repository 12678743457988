import { reducerWithInitialState } from 'typescript-fsa-reducers'
import * as R from 'ramda'
import { create, remove } from '@/redux/actions/snack-action'
import { SnackState } from '@/redux/types/snack-type'

export const snackInitialState: SnackState = { index: [], items: {} }

export const snackReducer = reducerWithInitialState(snackInitialState)
  .case(create, (state, { id, body }) => ({
    index: [...state.index, id],
    items: { ...state.items, [id]: body },
  }))
  .case(remove, (state, { id }) => ({
    index: state.index.filter((i) => i !== id),
    items: R.omit([id], state.items),
  }))
  .build()
