import React from 'react'
import cn from 'classnames'

import styles from './section-header.module.scss'

export const SectionHeader: React.FC<{
  type: 'light' | 'dark'
  className?: string
}> = ({ className, children, type }) => (
  <h2 className={cn(className, styles.header, { [styles.light]: type === 'light' })}>{children}</h2>
)
