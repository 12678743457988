import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { LotState } from '@/redux/types/lot-type'
import {
  DashboardResourceApiIndexFavoritesUsingGETRequestAction,
  LotStatesControllerApiIndexLotStatesUsingGETRequestAction,
  UserResourceApiCreateBiddingFlowUsingPOSTRequestAction,
  UserResourceApiCreateBidUsingPOSTRequestAction,
  UserResourceApiIndexBidsUsingGETRequestAction,
  UserResourceApiIndexOrdersUsingGETRequestAction,
} from '@/api/origin/action'
import { logout } from '@/redux/actions/auth-action'
import { addProcurement } from '../actions/procurement-action'

export const lotInitState: LotState = {
  byId: {},
  allIds: [],
  isLoading: false,
  lotStates: {
    byId: {},
    allIds: [],
  },
}

export const lotReducer = reducerWithInitialState<LotState>(lotInitState)
  .case(logout, () => lotInitState)
  .case(UserResourceApiCreateBiddingFlowUsingPOSTRequestAction.started, (state) => ({
    ...state,
    isLoading: false,
  }))
  .case(UserResourceApiCreateBiddingFlowUsingPOSTRequestAction.done, (state, { result, params }: any) => ({
    ...state,
    byId: {
      ...state.byId,
      [result.lotId]: {
        ...params,
        id: result.lotId,
      },
    },
    allIds: [...new Set([...state.allIds, result.lotId])],
    isLoading: true,
  }))
  .case(UserResourceApiCreateBidUsingPOSTRequestAction.done, (state, { id, lotId }: any) => ({
    ...state,
    byId: {
      ...state.byId,
      [lotId]: {
        ...state.byId[lotId],
        bidId: id,
      },
    },
  }))
  .case(UserResourceApiIndexBidsUsingGETRequestAction.done, (state, { result }: any) => ({
    ...state,
    byId: {
      ...state.byId,
      ...result.lot.byId,
    },
    allIds: [...new Set([...state.allIds, ...result.lot.allIds])],
    isLoading: true,
  }))
  .case(UserResourceApiIndexOrdersUsingGETRequestAction.done, (state, { result }: any) => ({
    ...state,
    byId: {
      ...state.byId,
      ...result.lot.byId,
    },
    allIds: [...new Set([...state.allIds, ...result.lot.allIds])],
    isLoading: true,
  }))
  .case(addProcurement, (state, { lotId, id }) => ({
    ...state,
    byId: {
      ...state.byId,
      [lotId]: {
        ...state.byId[lotId],
        procurementId: id,
      },
    },
    isLoading: true,
  }))
  .case(DashboardResourceApiIndexFavoritesUsingGETRequestAction.done, (state, { result }: any) => ({
    ...state,
    byId: {
      ...state.byId,
      ...result.byId,
    },
    allIds: [...new Set([...state.allIds, ...result.allIds])],
    isLoading: true,
  }))
  .case(LotStatesControllerApiIndexLotStatesUsingGETRequestAction.done, (state, { result }: any) => ({
    ...state,
    lotStates: { ...result },
  }))
  .build()
