import React from 'react'
import cn from 'classnames'
import styles from './horizontal-card-property.module.scss'

export const HorizontalCardProperty: React.FC<{
  name: string
  value?: any
  postfix?: any
  hoverPostfix?: boolean
}> = ({ name, value = '-', postfix, hoverPostfix = false }) => {
  if (/^\s*$/.test(value)) value = '-'

  return (
    <div className="style.property">
      <div className={styles.name}>{name}</div>
      <div className={cn(styles.value, { [styles.havePopup]: Boolean(postfix) })}>
        {value}
        <div className={cn(styles.postfix, { [styles.hoverPostfix]: hoverPostfix })}>{postfix}</div>
      </div>
    </div>
  )
}
