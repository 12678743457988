// global imports
import React from 'react'
// local imoorts
import IconButton from '@material-ui/core/IconButton'
import DocIcon from '@/pages/index/page-lot/ui/molecules/popup-sales-statistics/docIcon'
import { LotDto } from '@/api/origin/api'

interface IProps {
  auction: LotDto | undefined
  onClick: (event: React.MouseEvent<HTMLButtonElement>, auction: LotDto | undefined) => void
}

const DocButton: React.FC<IProps> = ({ onClick, auction }) => (
  <IconButton
    style={{ backgroundColor: 'transparent' }}
    disableRipple
    disableFocusRipple
    disableTouchRipple
    aria-label="auction-sheet"
    onClick={(event) => onClick(event, auction)}
  >
    <DocIcon />
  </IconButton>
)

export default DocButton
