import React from 'react'
import {
  Slider as BaseSlider,
  Rail,
  Handles,
  Tracks,
  GetHandleProps,
  SliderItem,
  GetTrackProps,
} from 'react-compound-slider'

import { Icon } from '../../atoms'

const sliderStyle: React.CSSProperties = {
  position: 'relative',
  left: '9px',
  width: 'calc(100% - 18px)',
  height: 35,
}

const railStyle: React.CSSProperties = {
  position: 'absolute',
  width: '100%',
  height: 2,
  marginTop: 9,
  // opacity: '0.4',
  borderRadius: 2,
  backgroundColor: '#061021',
  cursor: 'pointer',
}

type Props = {
  value?: readonly [number, number]
  onChange: (value: readonly number[]) => void
  max?: number
  min?: number
  step?: number
}

export const Slider: React.FC<Props> = ({ value = [100, 250], onChange, max = 500, min = 100, step = 1 }) => (
  <BaseSlider
    rootStyle={sliderStyle}
    domain={[min, max]}
    step={step}
    mode={2}
    values={value}
    onUpdate={onChange}
    onSlideEnd={onChange}
    onSlideStart={onChange}
    warnOnChanges
  >
    <Rail>{({ getRailProps }) => <div style={railStyle} {...getRailProps()} />}</Rail>
    <Handles>
      {({ handles, getHandleProps }) => (
        <div className="slider-handles">
          {handles.map((handle) => (
            <Handle key={handle.id} handle={handle} getHandleProps={getHandleProps} />
          ))}
        </div>
      )}
    </Handles>
    <Tracks left={false} right={false}>
      {({ tracks, getTrackProps }) => (
        <div className="slider-tracks">
          {tracks.map(({ id, source, target }) => (
            <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
          ))}
        </div>
      )}
    </Tracks>
  </BaseSlider>
)

const Handle: React.FC<{ handle: SliderItem; getHandleProps: GetHandleProps }> = ({
  handle: { percent, id },
  getHandleProps,
}) => (
  <div
    style={{
      left: `${percent}%`,
      position: 'absolute',
      marginLeft: -9,
      zIndex: 2,
      textAlign: 'center',
      cursor: 'pointer',
    }}
    {...getHandleProps(id)}
  >
    <Icon name="icon-handle-slider" width="18px" height="18px" />
  </div>
)

const Track: React.FC<{ source: SliderItem; target: SliderItem; getTrackProps: GetTrackProps }> = ({
  source,
  target,
  getTrackProps,
}) => (
  <div
    style={{
      position: 'absolute',
      height: 2,
      zIndex: 1,
      marginTop: 9,
      backgroundColor: '#ECA52C',
      borderRadius: 5,
      cursor: 'pointer',
      left: `${source.percent}%`,
      width: `${target.percent - source.percent}%`,
    }}
    {...getTrackProps()}
  />
)
