import React, { useState } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import cn from 'classnames'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { AuctionDate, VerticalCardProperties, VehicleModelsOfAction } from '@/pages/index/page-vehicles/ui/atoms'
import { LotDto } from '@/api/origin/api'
import { getCarTitle, formatAuctionDate, formatPrice } from '@/lib'
import { AveragePrice, EstShipping, CustomizedTooltips, LanguageLink } from '@/ui/atoms'
import { SourceOfSupply } from '../../atoms/source-of-supply'
import { Img } from './img'
import styles from './vehicle-vertical-card.module.scss'

type Props = {
  car: LotDto
} & RouteComponentProps

export const VehicleVerticalCard = withRouter(
  React.memo(({ car }: Props) => {
    const [showActions, setActionsState] = useState(false)
    const callback = (value: boolean) => setActionsState(value)
    return (
      <LanguageLink to={`/vehicle/${car.externalId}`} target="_blank" className={styles.wrapperComponent}>
        <LazyLoadComponent>
          <Img img={car.images as string[] | undefined} context="vehicleVerticalCard" />
          <VehicleModelsOfAction
            callback={callback}
            className={cn(styles.modelsOfActionPosition, { [styles.modelsOfActionActive]: showActions })}
            auction={car}
          />
          <div className={styles.wrapperInfo}>
            <LazyLoadComponent>
              <div className={styles.titlePromptWrapper}>
                <CustomizedTooltips title={getCarTitle(car)}>
                  <h3 className={styles.title}>{getCarTitle(car)}</h3>
                </CustomizedTooltips>
              </div>
              <div className={styles.wrapperPriceDate}>
                <div className={styles.price}>{formatPrice(car)}</div>
                <AuctionDate value={formatAuctionDate(car.auctionDate)} />
              </div>
            </LazyLoadComponent>
            <LazyLoadComponent>
              <AveragePrice value={car.averagePrice} className="mb-15" />
              <EstShipping value={1200} className="mb-25" />
              <VerticalCardProperties mileage={Number(car.mileage)} auctionDate={car.auctionDate} rate={car.rate} />
            </LazyLoadComponent>
          </div>
          <LazyLoadComponent>
            <div className={styles.wrapperSourceOfSupply}>
              <SourceOfSupply value={car.auctionName} flag="japan" />
            </div>
          </LazyLoadComponent>
        </LazyLoadComponent>
      </LanguageLink>
    )
  }),
)
