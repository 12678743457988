import 'rxjs'
import { createStore, applyMiddleware, Store as ReduxStore } from 'redux'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import { createBrowserHistory } from 'history'
import { rootReducer } from '@/redux/reducers'
import { rootEpic } from '@/redux/epics'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createEpicMiddleware } from 'redux-observable'
import { routerMiddleware } from 'connected-react-router'
import * as storage from '@/lib/storage'
import { Store } from './store.interface'

export const history = createBrowserHistory()
const sessionData = storage.getItem('ease-auto-import')
const initialState = sessionData ? JSON.parse(sessionData) : {}
const seoData = storage.getItem('ease-auto-import-seo')
if (seoData) {
  initialState.seo = { ...JSON.parse(seoData) }
}
let store: ReduxStore<Store>
const reactRouterMiddleware = routerMiddleware(history)
const epicMiddleware = createEpicMiddleware()

export const configureStoreProd = () => {
  const middlewares = [reactRouterMiddleware, epicMiddleware]

  const enhancers = composeWithDevTools(applyMiddleware(...middlewares))
  store = createStore(rootReducer(history), initialState, enhancers)
  epicMiddleware.run(rootEpic as any)
  return store
}

export const configureStoreDev = () => {
  const middlewares = [reduxImmutableStateInvariant(), epicMiddleware, reactRouterMiddleware]

  const enhancers = composeWithDevTools(applyMiddleware(...middlewares))
  store = createStore(rootReducer(history), initialState, enhancers)
  epicMiddleware.run(rootEpic as any)
  return store
}

export const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev

/**
 * @deprecated
 * @returns {Store<IStore>}
 */
export const getStore = (): ReduxStore<Store> => store
