import React from 'react'

import { DefaultPopup } from '@/ui/molecules'

export const PopupRemoveComparison: React.FC<{ d: () => void; cancel: () => void }> = ({ d, cancel }) => (
  <DefaultPopup
    title="Remove car"
    body="Are you sure you want to remove this car from comparison list?"
    cancel={cancel}
    d={d}
    buttonText="Remove"
  />
)
