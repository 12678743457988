import React from 'react'
import { useDispatch } from 'react-redux'
import cn from 'classnames'
import { Icon } from '@/ui/atoms'
import { close, open } from '@/redux/actions/popup-action'
import { PopupSharePage } from '@/pages/index/page-vehicles/ui/atoms'
import styles from './listing-header.module.scss'

export const ListingHeader: React.FC<{
  className?: string
  value?: string
  getPageRef?: () => any
}> = ({ className, value = '', getPageRef }) => {
  const dispatch = useDispatch()
  const location = window.location

  const handleShareButton = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault()
    openPopupSharePage()
  }
  return (
    <div className={cn(className, styles.headerWrapper)}>
      <h1 className={styles.headerTitle}>{value}</h1>
      <button onClick={handleShareButton} className={styles.downloadLink} aria-label="Home">
        <Icon name="icon-listing-download" width="22px" height="24px" />
      </button>
    </div>
  )

  function openPopupSharePage() {
    dispatch(
      open({
        body: (
          <PopupSharePage
            cancel={() => dispatch(close())}
            text="Check out this website:"
            url={location.href}
            getPageRef={getPageRef}
          />
        ),
        size: 'small',
      }),
    )
  }
}
