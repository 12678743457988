import React, { lazy } from 'react'

const LayoutIndex = lazy(() => import('./pages/index/layout-index/layout-index'))
const PageNotFound = lazy(() => import('./pages/index/page-not-found/page-not-found'))
const PageIndex = lazy(() => import('./pages/index/page-index/page-index'))
const PageAboutUs = lazy(() => import('./pages/index/page-about-us/page-about-us'))
const PageFAQ = lazy(() => import('./pages/index/page-faq/page-faq'))
const PageTermsOfService = lazy(() => import('./pages/index/page-terms-of-service/page-terms-of-service'))
const PageBlog = lazy(() => import('./pages/index/page-blog/page-blog'))
const PageVehicles = lazy(() => import('./pages/index/page-vehicles/page-vehicles'))
const PageBlogPost = lazy(() => import('./pages/index/page-blog-post/page-blog-post'))
const PageHowToReadAuctionSheet = lazy(() => import('./pages/index/page-how-to-read-auction-sheet/page-how-to-read-auction-sheet'),
)
const PageContactUs = lazy(() => import('./pages/index/page-contact-us/page-contact-us'))
const PagePrivacyPolicy = lazy(() => import('./pages/index/page-privacy-policy/page-privacy-policy'))
const PageLot = lazy(() => import('./pages/index/page-lot/page-lot'))
const PageComparisonList = lazy(() => import('./pages/index/page-comparison-list/page-comparison-list'))
const PageEuropClassifieds = lazy(() => import('./pages/index/page-europ-classifieds/page-europ-classifieds'))
const LayoutManagement = lazy(() => import('./pages/management/layout-management/layout-management'))
const PageClients = lazy(() => import('./pages/management/page-clients/page-clients'))
const PageBids = lazy(() => import('./pages/management/page-bids/page-bids'))
const PageCurrentOrder = lazy(() => import('./pages/management/page-current-order/page-current-order'))
const PagePaymentInformation = lazy(() => import('./pages/management/page-payment-information/page-payment-information'),
)
const LayoutDashboard = lazy(() => import('./pages/dashboard/layout-dashboard/layout-dashboard'))
const PagePersonalInformation = lazy(() => import('./pages/dashboard/page-personal-information/page-personal-information'),
)
const PageMyOrders = lazy(() => import('./pages/dashboard/page-my-orders/page-my-orders'))
const PageMyBids = lazy(() => import('./pages/dashboard/page-my-bids/page-my-bids'))
const PageMyWallet = lazy(() => import('./pages/dashboard/page-my-wallet/page-my-wallet'))
const PagePaymentInformationUser = lazy(() => import('./pages/dashboard/page-payment-information-user/page-payment-information-user'),
)
const PageWatchList = lazy(() => import('./pages/dashboard/page-watch-list/page-watch-list'))
const LayoutProcurement = lazy(() => import('./pages/procurement/layout-procurement/layout-procurement'))
const PagePersonalDetails = lazy(() => import('./pages/procurement/page-personal-details/page-personal-details'))
const PageShippingDetails = lazy(() => import('./pages/procurement/page-shipping-details/page-shipping-details'))
const PageAdditionalRequests = lazy(() => import('./pages/procurement/page-additional-requests/page-additional-requests'),
)
const PageOrderReceived = lazy(() => import('./pages/procurement/page-order-received/page-order-received'))
const PageBidding = lazy(() => import('./pages/procurement/page-bidding/page-bidding'))
const PageFinalPayment = lazy(() => import('./pages/procurement/page-final-payment/page-final-payment'))
const PageDriverLicense = lazy(() => import('./pages/procurement/page-driver-license/page-driver-license'))
const LayoutAdminArea = lazy(() => import('./pages/admin-area/layout-admin-area/layout-admin-area'))
const PageConfirmEmail = lazy(() => import('./pages/index/page-confirm-email/page-confirm-email'))
const PagePasswordRecovery = lazy(() => import('./pages/index/page-password-recovery/page-password-recovery'))

export const routes: RouteType[] = [
  {
    path: '/swagger-ui',
    role: 'ROLE_MANAGER',
    component: LayoutAdminArea,
  },
  {
    path: '/management/:page',
    private: true,
    role: 'ROLE_MANAGER',
    component: LayoutManagement,
    routes: [
      {
        path: '/management/clients/:id?',
        exact: true,
        component: PageClients,
      },
      {
        path: '/management/bids',
        exact: true,
        component: PageBids,
      },
      {
        path: '/management/current-order',
        exact: true,
        component: PageCurrentOrder,
      },
      {
        path: '/management/payment-information',
        exact: true,
        component: PagePaymentInformation,
      },
      // {
      //   path: '/management/chat-room',
      //   exact: true,
      //   component: PageChatRoom
      // }
    ],
  },
  {
    path: '/dashboard/:page',
    private: true,
    component: LayoutDashboard,
    routes: [
      {
        path: '/dashboard/personal-information',
        exact: true,
        component: PagePersonalInformation,
      },
      {
        path: '/dashboard/payment-information-user',
        exact: true,
        component: PagePaymentInformationUser,
      },
      {
        path: '/dashboard/my-orders',
        exact: true,
        component: PageMyOrders,
      },
      {
        path: '/dashboard/my-bids',
        exact: true,
        component: PageMyBids,
      },
      {
        path: '/dashboard/my-wallet',
        exact: true,
        component: PageMyWallet,
      },
      {
        path: '/dashboard/watchlist',
        exact: true,
        component: PageWatchList,
      },
    ],
  },
  {
    path: '/procurement/:id/:step?',
    private: true,
    component: LayoutProcurement,
    routes: [
      {
        path: '/procurement/:id/personal-details',
        exact: true,
        component: PagePersonalDetails,
      },
      {
        path: '/procurement/:id/shipping-details',
        exact: true,
        component: PageShippingDetails,
      },
      {
        path: '/procurement/:id/additional-requests',
        exact: true,
        component: PageAdditionalRequests,
      },
      {
        path: '/procurement/:id/bidding',
        exact: true,
        component: PageBidding,
      },
      {
        path: '/procurement/:id/final-payment',
        exact: true,
        component: PageFinalPayment,
      },
      {
        path: '/procurement/:id/driver-license',
        exact: true,
        component: PageDriverLicense,
      },
      {
        path: '/procurement/:id/order-received',
        exact: true,
        component: PageOrderReceived,
      },
    ],
  },
  {
    path: '/:lang?/:page?',
    component: LayoutIndex,
    routes: [
      {
        path: '/404',
        exact: true,
        component: PageNotFound,
      },
      // confirm-email/?user_id=794752&token=b8566f35-2e96-475a-99a9-acac26ae5680
      {
        path: '/:lang?/confirm-email',
        exact: true,
        component: PageConfirmEmail,
      },
      {
        path: '/:lang?/password-recovery',
        exact: true,
        component: PagePasswordRecovery,
      },
      {
        path: '/:lang?/vehicles/:prefix?/',
        exact: true,
        component: PageVehicles,
      },
      {
        path: '/:lang?/vehicle/:id',
        exact: true,
        component: PageLot,
      },
      {
        path: '/:lang?/european-classifieds',
        exact: true,
        component: PageEuropClassifieds,
      },
      {
        path: '/:lang?/about-us',
        exact: true,
        component: PageAboutUs,
      },
      {
        path: '/:lang?/blog',
        exact: true,
        component: PageBlog,
      },
      {
        path: '/:lang?/blog/:id',
        exact: true,
        component: PageBlogPost,
      },
      {
        path: '/:lang?/FAQ',
        exact: true,
        component: PageFAQ,
      },
      {
        path: '/:lang?/terms-of-service',
        exact: true,
        component: PageTermsOfService,
      },
      {
        path: '/:lang?/privacy-policy',
        exact: true,
        component: PagePrivacyPolicy,
      },
      {
        path: '/:lang?/how-to-read-auction-sheet',
        exact: true,
        component: PageHowToReadAuctionSheet,
      },
      {
        path: '/:lang?/contact-us',
        exact: true,
        component: PageContactUs,
      },
      {
        path: '/:lang?/comparison-list',
        exact: true,
        component: PageComparisonList,
      },
      {
        path: '/:lang?/',
        exact: true,
        component: PageIndex,
      },
    ],
  },
]

export type RouteType = {
  exact?: boolean
  private?: boolean
  component: React.FC
  status?: 404
  path?: string
  role?: string
  routes?: any[]
}
