import { useEffect, useState } from 'react'
import _throttle from 'lodash/throttle'

export const useResizeHeight = () => {
  const [windowHeightSize, setWindowHeightSize] = useState(window.innerHeight)

  useEffect(() => {
    const handleResize = _throttle(() => {
      setWindowHeightSize(window.innerHeight)
    }, 600)

    window.addEventListener('resize', handleResize, { passive: true })

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return windowHeightSize
}
