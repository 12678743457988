import React from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { SectionHeaderDynamic } from '@/ui/atoms'

import RoadImage from '@/static/images/home-road.jpg'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import styles from './most-effective-way.module.scss'

export const MostEffectiveWay: React.FC<{
  className?: string
  get_started: {
    title: string
    description: string
    button: {
      url: string
      text: string
      target: string
    }
  }
}> = ({ className, get_started }) => (
  <LazyLoadComponent>
    <section className={cn(className, styles.wrapperComponent)} style={{ backgroundImage: `url("${RoadImage}")` }}>
      <SectionHeaderDynamic type="light" className={styles.header} text={get_started.title} />
      <p className={styles.subTitle}>{get_started.description}</p>
      <Link to={get_started.button.url} className={styles.homePageLink}>
        {get_started.button.text}
      </Link>
    </section>
  </LazyLoadComponent>
)
