import React from 'react'
import { PaginationItem } from '@/ui/atoms'
import { pagination } from '@/lib/pagination'
import styles from './pagination-items-holder.module.scss'

export const PaginationItemsHolder: React.FC<{
  currentPage: number
  totalPage: number
  changePageNumber: (num: number) => void
}> = ({ totalPage, currentPage, changePageNumber }) => {
  const page = pagination(currentPage, totalPage)

  return (
    <div className={styles.holder}>
      {page.map((item: any, i: any) => (
        <PaginationItem
          key={item === '...' ? i + item : item}
          label={item}
          active={Number(item) === currentPage}
          onClick={() => changePageNumber(Number(item))}
          disabled={item === '...'}
        />
      ))}
    </div>
  )
}
