import React from 'react'
import { useHistory } from 'react-router'
import { SearchItem } from '@/ui/atoms/search/search'
import styles from './styles.module.scss'

interface IProps {
  items: SearchItem[]
  title: string
  onClick: () => void
}

export const BlockItem: React.FC<IProps> = ({ items, title, onClick }) => {
  const history = useHistory()
  const handleClickRow = (href: string) => () => {
    onClick()
    history.push(href)
  }
  return (
    <>
      <div className={styles.optionBlockHeader}>{title}</div>
      {items.map((item: SearchItem, i: number) => (
        <div key={i} className={styles.optionBlockRow} onClick={handleClickRow(item.href)}>
          {item.title}
        </div>
      ))}
    </>
  )
}
