import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'

import { Store } from '@/redux/store/store.interface'
import { watchListReducer } from '@/redux/reducers/watch-list-reducer'
import { authReducer } from '@/redux/reducers/auth-reducer'
import { popupReducer } from '@/redux/reducers/popup-reducer'
import { snackReducer } from '@/redux/reducers/snack-reducer'
import { SEOReducer } from '@/redux/reducers/seo-reducer'
import { filterReducer } from '@/redux/reducers/filter-reducer'
import { auctionReducer } from '@/redux/reducers/auction-reducer'
import { procurementReducer } from '@/redux/reducers/procurement-reducer'
import { blogReducer } from '@/redux/reducers/blog-reducer'
import { bidReducer } from '@/redux/reducers/bid-reducer'
import { lotReducer } from '@/redux/reducers/lot-reducer'
import { orderReducer } from '@/redux/reducers/order-reducer'
import { portReducer } from '@/redux/reducers/port-reducer'
import { langReducer } from '@/redux/reducers/lang-reducer'
import { clientsReducer } from '@/redux/reducers/clients-reducer'
import { walletReducer } from '@/redux/reducers/wallet-reducer'
import { paymentTypesReducer } from '@/redux/reducers/paymentTypes-reducer'
import { comparisonListReducer } from '@/redux/reducers/comparison-list-reducer'
import { managerBidsReducer } from '@/redux/reducers/manager-bids-reducer'
import { driverLicenseReducer } from '@/redux/reducers/driver-license'
import { managerOrdersReducer } from '@/redux/reducers/manager-orders-reducer'

export const rootReducer = (history: History) => combineReducers<Store>({
  auth: authReducer,
  watchList: watchListReducer,
  popup: popupReducer,
  snack: snackReducer,
  seo: SEOReducer,
  filter: filterReducer,
  auction: auctionReducer,
  procurement: procurementReducer,
  blog: blogReducer,
  lot: lotReducer,
  bid: bidReducer,
  order: orderReducer,
  router: connectRouter(history),
  port: portReducer,
  lang: langReducer,
  clients: clientsReducer,
  wallet: walletReducer,
  paymentTypes: paymentTypesReducer,
  comparisonList: comparisonListReducer,
  managerBids: managerBidsReducer,
  driverLicense: driverLicenseReducer,
  managerOrders: managerOrdersReducer,
})
