import React, { useState } from 'react'
import cn from 'classnames'

import styles from './textarea.module.scss'

type Props = {
  className?: string
  value?: string
  label?: string
  isError?: boolean
  errorText?: React.ReactNode
  helpText?: React.ReactNode
} & React.HTMLProps<HTMLTextAreaElement>

export const Textarea: React.FC<Props> = ({
  className,
  value = '',
  label,
  isError,
  errorText,
  helpText,
  onBlur,
  disabled,
  ...props
}) => {
  const [isElementHasFocus, setIsElementHasFocus] = useState(false)

  const componentStyles = cn(
    styles.root,
    className,
    { [styles.active]: value || isElementHasFocus },
    { [styles.activated]: isElementHasFocus },
    { [styles.disabled]: disabled },
    { [styles.hasError]: isError },
  )

  return (
    <label className={componentStyles}>
      <textarea
        className={styles.textarea}
        value={value}
        onFocus={() => setIsElementHasFocus(true)}
        onBlur={(e) => {
          setIsElementHasFocus(false)
          // eslint-disable-next-line no-unused-expressions
          onBlur && onBlur(e)
        }}
        {...props}
      />
      {label && <span className={styles.label}>{label}</span>}
      {errorText && <div className={styles.errorText}>{errorText}</div>}
      {!Boolean(errorText) && helpText && <div className={styles.helpText}>{helpText}</div>}
    </label>
  )
}
