import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { logout, signInWithTokenXSRF } from '@/redux/actions/auth-action'
import { AuthState, AuthorizationState } from '@/redux/types/auth-type'
import {
  AccountResourceApiGetAccountUsingGETRequestAction,
  AccountResourceApiSaveAccountUsingPOSTRequestAction,
  UserResourceApiReadUserUsingGETRequestAction,
} from '@/api/origin/action'

export const authInitState: AuthState = {
  user: null,
  state: AuthorizationState.Initial,
  previousState: null,
}

export const authReducer = reducerWithInitialState<AuthState>(authInitState)
  .case(logout, (_) => authInitState)
  .case(signInWithTokenXSRF.done as any, (state: any, { result }: any) => ({
    ...state,
    user: result,
  }))
  .case(AccountResourceApiGetAccountUsingGETRequestAction.done, (state, { result }) => ({
    ...state,
    user: result,
  }))
  .case(UserResourceApiReadUserUsingGETRequestAction.done, (state, { result }) => ({
    ...state,
    user: result,
  }))
  .case(AccountResourceApiSaveAccountUsingPOSTRequestAction.started, (state, { userDTO }) => ({
    ...state,
    user: userDTO,
  }))
  .build()
