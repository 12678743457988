import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PopupAuctionSheet } from '@/pages/index/page-vehicles/ui/atoms'
import { push } from 'connected-react-router'
import { close } from '@/redux/actions/popup-action'
import { HTMLDecoder } from '@/lib'
import { LotDto } from '@/api/origin/api'
import { AvtoJpLotsFacadeControllerApiFindAuctionLotByIdUsingGETRequestAction } from '@/api/origin/action'
import { Store } from '@/redux/store/store.interface'
import { getSelectorAuctionById } from '@/redux/selectors/procurement-selector'

interface IProps {
  auction?: LotDto | null
  onClose?: any
  isClose?: boolean
}

const PopoverAuctionSheet: React.FC<IProps> = ({ auction, onClose, isClose }) => {
  const dispatch = useDispatch()

  const currentAuction = useSelector<Store, LotDto>(getSelectorAuctionById(auction?.externalId || '')) || null

  useEffect(() => {
    if (auction?.externalId) {
      dispatch(AvtoJpLotsFacadeControllerApiFindAuctionLotByIdUsingGETRequestAction.started({ id: auction.externalId }))
    }
  }, [auction, dispatch])

  const handleClose = () => {
    if (isClose) {
      onClose(undefined, null)
    } else {
      dispatch(close())
    }
  }
  return (
    <div
      style={{
        padding: '24px',
        borderRadius: '16px',
      }}
    >
      <PopupAuctionSheet
        d={() => dispatch(push('/how-to-read-auction-sheet'))}
        cancel={handleClose}
        img={currentAuction.auctionSheetUrl ? currentAuction.auctionSheetUrl : ''}
        bodyWrapper={HTMLDecoder((auction && auction.chassis) || '')}
      />
    </div>
  )
}

export default PopoverAuctionSheet
