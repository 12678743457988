import React, { useMemo } from 'react'
import cn from 'classnames'
import { withRouter, RouteComponentProps } from 'react-router'
import { FilterFieldType } from '@/lib/use-filter/use-filter'
import { RangeDropdown } from '@/ui/molecules'
import { Button, SelectCustomized, FormMultiselect } from '@/ui/atoms'
import {
  //  getYear,
  useFilter,
} from '@/lib'
import { FilterDto } from '@/api/origin/api'
import styles from './find-your-dream-car.module.scss'

type Props = {
  lang: string
  className?: string
} & RouteComponentProps

export const FindYourDreamCar = withRouter(({ lang, className, history }: Props) => {
  const { filters, filterValues, selectFilter, queryParams } = useFilter()

  const handleSearch = () => {
    const path = lang === '' ? queryParams : `/${lang}${queryParams}`
    history.push(path)
  }

  const listModelGroup = useMemo(
    () => filters.MAKE.list
      .filter((item: FilterDto) => filterValues.make.includes(item['name'] || ''))
      .map((item: FilterDto) => ({
        title: item.name || '',
        items: filterValues.modelList.filter(
          (modelItem: FilterDto) => modelItem.parentExternalId === item.externalId,
        ),
      })),
    [filterValues.make, filterValues.modelList, filters.MAKE.list],
  )

  const handleChange = (name: FilterFieldType) => (value: FilterDto[]) => {
    selectFilter(name)(value.map((item) => item.name || ''))
  }

  const handleReset = (name: FilterFieldType) => () => {
    selectFilter(name)(undefined)
  }

  return (
    <div className={cn(className, styles.component)}>
      <div className={styles.componentItem}>
        <h2 className={styles.header}>Find your dream car</h2>
        <div className={styles.grid}>
          <FormMultiselect<string[], FilterDto>
            label="Make"
            value={filterValues.make}
            items={filters.MAKE.list}
            keyAsValue="name"
            keyAsTitle="name"
            onChange={handleChange(FilterFieldType.MAKE)}
            notChip
          />
          <FormMultiselect<string[], FilterDto>
            label="Model"
            value={filterValues.model}
            items={filterValues.modelList}
            groupItems={listModelGroup}
            keyAsValue="name"
            keyAsTitle="name"
            onChange={handleChange(FilterFieldType.MODEL)}
            disabled={filterValues.make.length === 0}
            notChip
          />
          <RangeDropdown
            id="Year"
            label="Year"
            min={1921}
            max={2010}
            step={1}
            value={filterValues.year}
            onChange={selectFilter(FilterFieldType.YEAR)}
            reset={handleReset(FilterFieldType.YEAR)}
          />
          <RangeDropdown
            id="Price"
            money
            label="Price"
            step={1}
            min={0}
            max={99999}
            value={filterValues.price}
            onChange={selectFilter(FilterFieldType.PRICE)}
            reset={handleReset(FilterFieldType.PRICE)}
          />
          <SelectCustomized
            name="auction"
            id="auction"
            label="Auction"
            isNoneBlock
            value={filterValues.auction}
            items={filters.AUCTION.list.map((item: any) => ({ id: item.id, value: item.name }))}
            onChange={selectFilter(FilterFieldType.AUCTION)}
          />
          <SelectCustomized
            name="country"
            id="country"
            label="Country"
            value="Japan"
            items={[{ value: 'Japan' }]}
            onChange={() => {}}
            disabled
          />
        </div>
        <div className={styles.browse}>Browse our Inventory of over 40.000 cars</div>
        <Button type="primary" fullWidth onClick={handleSearch} aria-label="Search">
          Search
        </Button>
      </div>
    </div>
  )
})
