import React from 'react'
import { Button, PopupTitle } from '@/ui/atoms'
import { Message1619 } from '@/pages/procurement/ui/atoms/message-16-19'
import { paymentApi } from '@/api'
import { loadStripe } from '@stripe/stripe-js'
import StripeConfig from '@/constants/stripe'
import styles from './popup-inquiry-form-by-classified.module.scss'

const stripePromise = loadStripe(StripeConfig.API_TOKEN)

export const PopupInquiryFormByClassified = () => {
  const saveChange = async () => {
    const amount = 1000
    try {
      const stripe = await stripePromise
      const response = await paymentApi.createCheckoutSessionUsingPOST({ amount })

      if (response.data && stripe) {
        const result = await stripe.redirectToCheckout({
          sessionId: response.data,
        })

        if (result.error) {
          console.error(result.error.message)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div className={styles.popup}>
      <PopupTitle size="small" className="mb-16">
        Deposit required
      </PopupTitle>
      <Message1619>Thank you for submitting your request. We will get in touch with you shortly.</Message1619>
      <Message1619>
        Please note that we would be able to work on your purchase request only after you transfer us a deposit of
        $1000. This deposit is fully refundable in case you decide not to go with the purchase. You can submit your
        payment by following this link below.
      </Message1619>
      <Message1619>
        If you still have questions please can contact us directly by using the Contact us form on this website.
      </Message1619>
      <Button type="primary" fullWidth onClick={saveChange} className={styles.submit} aria-label="Replenish account">
        Submit refundable deposit
      </Button>
    </div>
  )
}
