import React from 'react'
import cn from 'classnames'

import styles from './stripe-list.module.scss'

export const StripeList: React.FC<{
  className?: string
  start?: 'dark' | 'light'
}> = ({ children, className, start = 'light' }) => (
  <div
    className={cn(
      { [styles.stripeListLight]: start === 'light' },
      { [styles.stripeListDark]: start === 'dark' },
      className,
    )}
  >
    {children}
  </div>
)
