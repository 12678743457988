import React from 'react'
import { GalleryItem } from '../gallery-item'

import styles from '../gallery.module.scss'

type Props = {
  slidesURLs: string[]
  activeSlide: number
  slideSelectionHandler: (slide: number) => void
}

export const GalleryList: React.FC<Props> = ({ slidesURLs, activeSlide, slideSelectionHandler }) => {
  const renderUrl = Array.isArray(slidesURLs)
    ? slidesURLs
    : slidesURLs[390]
      ? slidesURLs[390]
      : slidesURLs[320]
        ? slidesURLs[320]
        : slidesURLs[50]
          ? slidesURLs[50]
          : ['']
  return (
    <div className={styles.galleryList}>
      {renderUrl.map((slideURL, number) => (
        <div key={slideURL} className={styles.listItemWrapper}>
          <GalleryItem
            imageURL={slideURL}
            active={number === activeSlide}
            selectionHandler={() => slideSelectionHandler(number)}
          />
        </div>
      ))}
    </div>
  )
}
