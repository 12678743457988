import { ActionsObservable, combineEpics } from 'redux-observable'
import { Action } from 'typescript-fsa'

import { mergeMap, catchError } from 'rxjs/operators'
import { of, from } from 'rxjs'
import { UserResourceApiGetAllUsersUsingGETRequestAction } from '@/api/origin/action'
import { normalizeClients } from '@/redux/normalize'
import { userApi } from '@/api'
import { ofAction } from '@/lib/rxjs-operators'

const getAllClients = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(UserResourceApiGetAllUsersUsingGETRequestAction.started),
  mergeMap(({ payload: { queryParams, page, size, sort } }) => from(userApi.getAllUsersUsingGET(queryParams, page, size, sort)).pipe(
    mergeMap(({ data }: { data: any }) => of(
      UserResourceApiGetAllUsersUsingGETRequestAction.done({
        // @ts-ignore
        result: normalizeClients(data),
      }),
    ),
    ),
    catchError((error) => of(UserResourceApiGetAllUsersUsingGETRequestAction.failed(error.response))),
  ),
  ),
)
export const clientsEpic = combineEpics(getAllClients)
