import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { AuctionState } from '@/redux/types/auction-type'
import {
  AvtoJpLotsFacadeControllerApiIndexAuctionLotsUsingGETRequestAction,
  AvtoJpLotsFacadeControllerApiFindAuctionLotByIdUsingGETRequestAction,
  UserResourceApiCreateBiddingFlowUsingPOSTRequestAction,
  LotResourceApiGetLotUsingGETRequestAction,
  AvtoJpLotsFacadeControllerApiIndexPassedAuctionLotsUsingGETRequestAction,
} from '@/api/origin/action'
import { changeCurrentPage, changeTypeGrid, initVehiclesPage } from '@/redux/actions/auction-action'
import { PageOfLotDto } from '@/api/origin/api'

export const actionInitState: AuctionState = {
  byId: {},
  allIds: [],
  passedById: {},
  passedPages: 0,
  empty: false,
  first: false,
  last: false,
  number: 0,
  numberOfElements: 0,
  size: 12,
  totalElements: 0,
  totalPages: 0,
  grid: 'grid',
  isLoading: false,
}

export const auctionReducer = reducerWithInitialState<AuctionState>(actionInitState)
  .case(AvtoJpLotsFacadeControllerApiIndexAuctionLotsUsingGETRequestAction.started, (state) => ({
    ...state,
    isLoading: false,
  }))
  .case(AvtoJpLotsFacadeControllerApiFindAuctionLotByIdUsingGETRequestAction.started, (state) => ({
    ...state,
    isLoading: false,
  }))
  .case(AvtoJpLotsFacadeControllerApiIndexPassedAuctionLotsUsingGETRequestAction.started, (state) => ({
    ...state,
    isLoading: false,
  }))
  .case(AvtoJpLotsFacadeControllerApiIndexAuctionLotsUsingGETRequestAction.done, (state, { result }) => ({
    ...state,
    ...result,
    number: result.number !== undefined ? +result.number + 1 : undefined,
    isLoading: true,
  }))
  .case(AvtoJpLotsFacadeControllerApiIndexPassedAuctionLotsUsingGETRequestAction.done, (state, { result, params }) => {
    const {
      options: { id, page },
    } = params
    return {
      ...state,
      passedById: {
        ...state.passedById,
        [id]: {
          ...state.passedById[id],
          [`page-${page}`]: result,
        },
      },
      // tslint:disable-next-line
      // passedPages: params?.options?.pageCount || 0,
      passedPages: (params && params.options && params.options.pageCount) || 0,
      isLoading: true,
    }
  })
  .case(
    AvtoJpLotsFacadeControllerApiFindAuctionLotByIdUsingGETRequestAction.done,
    (state: any, { result, params: { id } }: { result: any; params: any }) => ({
      ...state,
      byId: {
        ...state.byId,
        [id]: result,
      },
      allIds: [...new Set([...state.allIds, id])],
      isLoading: true,
    }),
  )
  // @ts-ignore
  .case(
    UserResourceApiCreateBiddingFlowUsingPOSTRequestAction.done,
    // eslint-disable-next-line
    // @ts-ignore
    (state: PageOfLotDto, { result, params }: any) => ({
      ...state,
      byId: {
        // @ts-ignore
        ...state.byId,
        [params.externalId]: {
          // @ts-ignore
          ...state.byId[params.externalId],
          id: result.lotId,
        },
      },
    }),
  )
  // @ts-ignore
  .case(changeCurrentPage, (state: PageOfLotDto, number: number) => ({ ...state, number }))
  // @ts-ignore
  .case(changeTypeGrid, (state: PageOfLotDto, grid: 'grid' | 'list') => ({ ...state, grid }))
  // @ts-ignore
  .case(initVehiclesPage, (state: PageOfLotDto & { grid: 'grid' | 'list' }, { grid }: { grid: 'list' | 'grid' }) => ({
    ...state,
    grid: grid || state.grid,
  }))
  // @ts-ignore
  .case(LotResourceApiGetLotUsingGETRequestAction.done, (state: PageOfLotDto, { result, params: { id } }) => ({
    ...state,
    byId: {
      // @ts-ignore
      ...state.byId,
      [id]: result,
    },
    // @ts-ignore
    allIds: [...new Set([...state.allIds, id])],
  }))
  .build()
