import React from 'react'
import cn from 'classnames'
import { Icon } from '@/ui/atoms'
import { DataType } from '../../molecules/side-menu/data'
import styles from './item-side-menu.module.scss'

type Props = {
  className?: string
  active?: boolean
  item: DataType
  isDone: false
  isBidWon: boolean
  // shippingAdress: string
}

export const ItemSideMenu: React.FC<Props> = ({ className, item, active, isDone, isBidWon }) => {
  let title = item.title
  const desc = item.desc
  if (item.id === 3 && isBidWon) {
    title = 'Bid Won'
  }
  // if (item.id === 4 && shippingAdress !== '') {
  //   desc = shippingAdress
  // }
  return (
    <div className={cn(styles.itemSideMenu, className, { [styles.active]: active, [styles.inactive]: isDone })}>
      <div className={styles.wrapperIcon}>
        <Icon name="icon-checkbox-circle" />
      </div>
      <div className={styles.wrapperTitle}>
        <h4 dangerouslySetInnerHTML={{ __html: title }} />
        {desc && <span>{desc}</span>}
        {item.time && <span>{item.time}</span>}
      </div>
    </div>
  )
}
