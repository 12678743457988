import React from 'react'
import cn from 'classnames'
import styles from './property.module.scss'

type Props = {
  name: string
  value?: any
  postfix?: any
  classNames?: string
}

export const Property: React.FC<Props> = ({ name, value = '-', postfix, classNames }) => {
  if (/^\s*$/.test(value)) value = '-'

  return (
    <div className={cn(classNames, styles.propertyWrapper)}>
      <div className={styles.name}>{name}</div>
      <div className={styles.value}>
        {value}
        {postfix}
      </div>
    </div>
  )
}
