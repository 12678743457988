import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable'
import { Action } from 'typescript-fsa'
import { Store } from 'redux'
import * as storage from '@/lib/storage'
import { mergeMap, withLatestFrom, pluck } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofAction } from '@/lib/rxjs-operators'
import { changeProcurement } from '../actions/procurement-action'
import { NOTHING } from '../actions/auth-action'

const changeProcurementEpic = (action$: ActionsObservable<Action<any>>, store$: StateObservable<Store>) => action$.pipe(
  ofAction(changeProcurement),
  withLatestFrom(
    store$.pipe(pluck('procurement')),
    store$.pipe(pluck('lot')),
    store$.pipe(pluck('auction')),
    store$.pipe(pluck('bid')),
    store$.pipe(pluck('comparisonList')),
  ),
  mergeMap(([, procurement, lot, auction, bid, comparisonList]: any) => {
    storage.saveItem(
      'ease-auto-import',
      JSON.stringify({
        procurement,
        lot,
        auction,
        bid,
        comparisonList,
      }),
      false,
    )
    return of(NOTHING())
  }),
)
export const procurementEpic = combineEpics(changeProcurementEpic)
