import React, { useEffect, useState } from 'react'
import cn from 'classnames'

import { useClickAway } from 'react-use'

import { RangeInput, Slider } from '../../atoms'
import style from './range-dropdown.module.scss'

type Props = {
  id: string
  money?: boolean
  label: string
  step?: number
  value?: number[] | readonly number[]
  max: number
  min: number
  onChange: (value: number[] | readonly number[]) => void
  reset: () => void
  classes?: string
}

export const RangeDropdown: React.FC<Props> = ({
  id,
  label,
  value,
  onChange,
  money,
  step = 100,
  max,
  min,
  reset,
  classes,
}) => {
  const ref = React.useRef(null)
  const [isOpen, setIsOpen] = React.useState(false)
  const [state, setState] = React.useState(value || [min, min + step])

  React.useEffect(() => {
    if (value && value !== state) {
      setState(value as any)
    }
    // eslint-disable-next-line
  }, [value])

  const open = () => !isOpen && setIsOpen(true)
  const close = () => setIsOpen(false)
  const inputValue = value ? `${money ? '$ ' : ''}${state[0]} - ${state[1]}${money ? '+' : ''}` : ''
  const set = (value: number[]) => {
    setState(value)
  }

  const onHandleChange = () => {
    onChange(state)
    close()
  }

  const onClearHandle = () => {
    reset()
    close()
  }

  useClickAway(ref, () => close())

  return (
    <div ref={ref} onClick={() => open()} className={cn(style.priceInputWrapper, classes)}>
      <RangeInput disabled label={label} value={inputValue} id={`rande_dropdown_${id}`} />
      {isOpen ? (
        <Popover
          money={money}
          step={step}
          value={state as any}
          onChange={set as any}
          max={max}
          min={min}
          onHandleChange={onHandleChange}
          onClearHandle={onClearHandle}
        />
      ) : null}
    </div>
  )
}

const Popover: React.FC<{
  value: [number, number]
  max?: number
  min?: number
  onChange: (value: readonly number[]) => void
  step?: number
  money?: boolean
  onHandleChange: () => void
  onClearHandle: () => void
}> = ({ value, step, onChange, max, min, money, onHandleChange, onClearHandle }) => {
  const [first, second] = value
  const [isRangeHasError, setIsRangeHasError] = useState(false)

  const handleRangeInputChange = (values: [number, number], sourceOfChange: 'min-input-field' | 'max-input-field') => {
    if (values[1] < values[0]) {
      setIsRangeHasError(true)
    } else {
      onChange(values)
    }
  }

  useEffect(() => {
    setIsRangeHasError(false)
  }, [value])

  return (
    <div className={style.popover}>
      <Slider step={step} value={value} onChange={onChange} max={max} min={min} />
      <div className={style.containerInput}>
        <RangeInput
          id="rande_money-min"
          money={money}
          value={String(first)}
          onChange={(event) => handleRangeInputChange([Number(event), second], 'min-input-field')}
          hasError={isRangeHasError}
          min={min}
          max={max}
        />
        <RangeInput
          id="rande_money-max"
          money={money}
          value={String(second)}
          onChange={(event) => handleRangeInputChange([first, Number(event)], 'max-input-field')}
          hasError={isRangeHasError}
          min={min}
          max={max}
        />
      </div>
      <div className={style.containerApply}>
        <span className={style.clear} onClick={onClearHandle}>
          Clear
        </span>
        <span
          className={cn(style.apply, { [style.applyHasError]: isRangeHasError })}
          onClick={isRangeHasError ? undefined : onHandleChange}
        >
          Apply
        </span>
      </div>
    </div>
  )
}
