import React from 'react'
import { Icon } from '@/ui/atoms'
import styles from './snackbar.module.scss'

export const Snackbar: React.FC<{ text: string }> = ({ text }) => (
  <div className={styles.wrapperContent}>
    <Icon name="icon-snackbar-success" width="40px" height="40px" />
    <div>
      <div className={styles.messageText}>{text}</div>
    </div>
  </div>
)
