import React from 'react'
import cn from 'classnames'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { NavigationItem } from '@/ui/atoms/navigation-item'
import { FooterType } from '@/redux/types/seo-type'
import styles from './footer-copyright.module.scss'

type Props = {
  info: FooterType
  className?: string
}

export const FooterCopyright = withRouter(
  ({ info, className, location: { pathname } }: Props & RouteComponentProps) => (
    <div className={cn(className, styles.wrapperSection)}>
      <div className={cn('container', styles.wrapperContainer)}>
        <NavigationItem
          href="/terms-of-service"
          value="Terms & Conditions"
          active={pathname === '/'}
          className={styles.naviFooter}
        />
        <NavigationItem
          href="/privacy-policy"
          value="Privacy Policy"
          active={pathname === '/'}
          className={styles.naviFooter}
        />
        <span>{info.copyright}</span>
      </div>
    </div>
  ),
)
