import React from 'react'
import cn from 'classnames'

import styles from './tab.module.scss'

export const Tab: React.FC<{
  className?: string
  title: string
  equalTabWidth?: boolean
  onClick?: (tabIndex: number, handleClick: void) => void
  handleClick?: () => void
  tabIndex?: number
  isActive?: boolean
  isDisabled?: boolean
}> = ({ title, onClick, tabIndex, isActive, className, equalTabWidth, handleClick, isDisabled }) => (
  <div
    className={cn(
      styles.tab,
      { [styles.active]: isActive },
      { [styles.equalTabWidth]: equalTabWidth },
      { [styles.disabled]: isDisabled },
      className,
    )}
    onClick={() => {
      onClick!(tabIndex!)
      if (handleClick) {
        handleClick()
      }
    }}
  >
    {title}
  </div>
)
