import React, { useState } from 'react'

// @ts-ignore
import ItemsCarousel from 'react-items-carousel'
import { SectionHeaderDynamic, ExploreOurCarsCard, Button } from '@/ui/atoms'

import { IExploreOurCars, IExploreOurCar } from '@/redux/types/seo-type'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'

import { useResize } from '@/lib'
import cn from 'classnames'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { AvtoJpLotsFacadeControllerApiFindAuctionLotByIdUsingGETRequestAction } from '@/api/origin/action'

import styles from './explore-our-cars.module.scss'

type Props = {
  exploreOurCars: IExploreOurCars
  isLoading: boolean
}

export const ExploreOurCars: React.FC<Props> = ({ exploreOurCars, isLoading }) => {
  const dispatch = useDispatch()
  const windowSize = useResize()
  const itemWidth = 295
  const mobile = windowSize < 600
  const windowCountItems = mobile
    ? Math.floor((windowSize - 35) / itemWidth)
    : Math.floor((windowSize - 100) / itemWidth)
  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const chevronWidth = mobile ? 25 : 30

  const handleLoad = () => {
    if (isLoading) {
      exploreOurCars.cars.forEach((car: IExploreOurCar) => dispatch(AvtoJpLotsFacadeControllerApiFindAuctionLotByIdUsingGETRequestAction.started({ id: car.external_id })),
      )
    }
  }

  const handleRedirect = () => dispatch(push('/vehicles'))
  return (
    <section className={styles.section}>
      <LazyLoadComponent afterLoad={handleLoad}>
        <div className={styles.sectionWrapper}>
          <SectionHeaderDynamic type="light" className={styles.sectionHeaderDynamic} text={exploreOurCars.title} />

          <div className={styles.carouselWrapper} style={{ padding: `0 ${chevronWidth}px` }}>
            <ItemsCarousel
              requestToChangeActive={setActiveItemIndex}
              activeItemIndex={activeItemIndex}
              numberOfCards={windowCountItems}
              gutter={20}
              infiniteLoop
              leftChevron={(
                <button
                  className={cn(styles.controlArrow, styles.controlPrev, { [styles.disabled]: mobile })}
                  aria-label="Left Chevron"
                  name="Left Chevron"
                />
              )}
              rightChevron={(
                <button
                  className={cn(styles.controlArrow, { [styles.disabled]: mobile })}
                  aria-label="Right Chevron"
                  name="Right Chevron"
                />
              )}
              outsideChevron
              chevronWidth={chevronWidth}
            >
              {exploreOurCars.cars.map((car: IExploreOurCar, index: number) => (
                <ExploreOurCarsCard key={index} car={car} />
              ))}
            </ItemsCarousel>
          </div>

          <LazyLoadComponent>
            <div className={styles.button}>
              <Button type="primary" onClick={handleRedirect} aria-label="View all cars">
                View all cars
              </Button>
            </div>
          </LazyLoadComponent>
        </div>
      </LazyLoadComponent>
    </section>
  )
}
