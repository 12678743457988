import React from 'react'
import cn from 'classnames'

import styles from './rating-item.module.scss'

type Props = {
  className?: string
  estimate: string
  link_rating: {
    url: string
    text: string
    target: string
  }
}

export const RatingItem: React.FC<Props> = ({ className, estimate, children, link_rating }) => {
  const Tag = link_rating && link_rating.url ? 'a' : 'div'
  const attribute = link_rating.url
    ? {
      href: link_rating.url,
      target: link_rating.target,
      rel: link_rating.text,
    }
    : {}
  return (
    <div className={cn(className, styles.wrapperComponent)}>
      <Tag className={styles.card} {...attribute} rel="noreferrer">
        <div className={styles.estimate}>{estimate}</div>
        <div className={styles.content}>{children}</div>
      </Tag>
    </div>
  )
}
