import { getUSAFormatPrice } from '@/lib'

export const getDepositAmount = (value: number) => {
  switch (true) {
    case value <= 10000:
      return getUSAFormatPrice(1000, true)
    case value > 10000 && value <= 15000:
      return getUSAFormatPrice(1500, true)
    case value > 15000 && value <= 20000:
      return getUSAFormatPrice(2000, true)
    case value > 20000 && value <= 30000:
      return getUSAFormatPrice(3000, true)
    case value > 30000:
      return 'Expensive car, please call customer service : 1 (215) 392 6743'
    default:
      return getUSAFormatPrice(1000, true)
  }
}

export const getDepositAmountNumber = (value: number) => {
  switch (true) {
    case value <= 10000:
      return 1000
    case value > 10000 && value <= 15000:
      return 1500
    case value > 15000 && value <= 20000:
      return 2000
    case value > 20000 && value <= 30000:
      return 3000
    default:
      return 1000
  }
}
