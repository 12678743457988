import { ActionsObservable, combineEpics } from 'redux-observable'
import { Action } from 'typescript-fsa'

import { mergeMap, catchError } from 'rxjs/operators'
import { of, from } from 'rxjs'
import { PortsControllerApiIndexPortsUsingGETRequestAction } from '@/api/origin/action'

import { portsApi } from '@/api'
import { ofAction } from '@/lib/rxjs-operators'

import { PortDto } from '@/api/origin/api'
import { NOTHING } from '@/redux/actions/auth-action'

const getPort = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(PortsControllerApiIndexPortsUsingGETRequestAction.started),
  mergeMap((options?: any) => from(portsApi.indexPortsUsingGET(options)).pipe(
    mergeMap(({ data }: { data: PortDto[] }) => of(
      PortsControllerApiIndexPortsUsingGETRequestAction.done({
        result: data,
        params: options,
      }),
    ),
    ),
    catchError((_error) => of(NOTHING())),
  ),
  ),
)

export const portEpic = combineEpics(getPort)
