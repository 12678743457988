import React from 'react'
import { push } from 'connected-react-router'
import { Button, PopupTitle, Icon } from '@/ui/atoms'
import { Message1619 } from '@/pages/procurement/ui/atoms/message-16-19'
import { useDispatch } from 'react-redux'
import { close } from '@/redux/actions/popup-action'
import styles from './bidding-expensive-popup.module.scss'

export const BiddingExpensivePopup = () => {
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(push('/'))
    dispatch(close())
  }
  return (
    <>
      <PopupTitle size="small" className="mb-10" alignCenter>
        <div className={styles.iconTitleWrapper}>
          <Icon name="icon-bid-l" width="26.7px" height="26.7px" />
          <PopupTitle size="small">Expensive car</PopupTitle>
        </div>
      </PopupTitle>
      <Message1619 className="mb-35">Expensive car, please call customer service : 1 (215) 392 6743</Message1619>
      <div>
        <Button type="primary" className={styles.buttonHome} onClick={handleClose} aria-label="Home">
          Home
        </Button>
      </div>
    </>
  )
}
