import React from 'react'
import cn from 'classnames'
import { Icon } from '@/ui/atoms'
import { ConditionPromptNoIcon } from '../condition-prompt-no-icon'
import styles from './condition-prompt.module.scss'

export const ConditionPrompt: React.FC<{
  className?: string
  iconClassName?: string
  estimate: string
}> = ({ className, iconClassName, estimate }) => (
  <div className={cn(className, styles.wrapperComponent)}>
    <div className={styles.itemWrapper}>
      <Icon name="icon-card-info" width="16px" height="16px" className={cn(styles.shippingIcon, iconClassName)} />
    </div>
    <div className={styles.itemPrompt}>
      <ConditionPromptNoIcon estimate={estimate} />
    </div>
  </div>
)
