import React from 'react'
import cn from 'classnames'

import styles from './pagination-item.module.scss'

export const PaginationItem: React.FC<{
  label: string | number
  active?: boolean
  onClick: () => void
  disabled?: boolean
}> = ({ label, active, onClick, disabled }) => (
  <button
    className={cn(styles.item, { [styles.active]: active, [styles.disabled]: disabled })}
    onClick={onClick}
    disabled={disabled}
    name="Pagination button"
    aria-label="Pagination button"
  >
    {label}
  </button>
)
