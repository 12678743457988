import React from 'react'
import cn from 'classnames'
import { SectionHeaderDynamicH1 } from '@/ui/atoms'
import { FindYourDreamCar } from '..'
import styles from './home-search.module.scss'

export const HomeSearch: React.FC<{
  className?: string
  subtitle: string
  h1_title: string
  background_image: string
  lang: string
}> = ({ className, subtitle, h1_title, background_image, lang }) => (
  <section className={cn(className, styles.section)} style={{ backgroundImage: `url("${background_image}")` }}>
    <div className="container">
      <div className={styles.grid}>
        <FindYourDreamCar className={styles.findPosition} lang={lang} />
        <div className={styles.titleCol}>
          <SectionHeaderDynamicH1 type="dark" className={styles.title} text={h1_title} />
          <h2 className={styles.subTitle}>{subtitle}</h2>
        </div>
      </div>
    </div>
  </section>
)
