import React from 'react'
import { LotDto } from '@/api/origin/api'
import { Card } from './card'

export const List: React.FC<{
  className: string
  watchList: number[]
  lots: { [key: string]: LotDto }
  onDelete: (id: number) => void
}> = ({ className, watchList, onDelete, lots }) => (
  <div className={className}>
    {watchList.map((i) => (
      <Card key={lots[i].id} car={lots[i]} onDelete={() => onDelete(lots[i].id as number)} />
    ))}
  </div>
)
