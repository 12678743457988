import { ActionsObservable, combineEpics } from 'redux-observable'
import { Action } from 'typescript-fsa'

import { mergeMap, catchError } from 'rxjs/operators'
import { of, from } from 'rxjs'
import { userApi } from '@/api'
import { ofAction } from '@/lib/rxjs-operators'
import { WalletTransactionDto } from '@/api/origin/api'

import {
  UserResourceApiIndexWalletTransactionsUsingGETRequestAction,
  UserResourceApiCreateWalletTransactionUsingPOSTRequestAction,
  UserResourceApiUpdateWalletTransactionUsingPUTRequestAction,
} from '@/api/origin/action'
import { normalizeWallet } from '@/redux/normalize'

const getWalletUser = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(UserResourceApiIndexWalletTransactionsUsingGETRequestAction.started),
  mergeMap(({ payload: { userId, page, size, sort } }) => from(userApi.indexWalletTransactionsUsingGET(userId, page, size, sort)).pipe(
    mergeMap(({ data, headers }: { data: WalletTransactionDto[]; headers: { [key: string]: string } }) => of(
      UserResourceApiIndexWalletTransactionsUsingGETRequestAction.done({
        // TODO: fix ts-ignore
        // @ts-ignore
        result: normalizeWallet(data),
        params: {
          userId,
          page,
          size,
          sort,
        },
        options: {
          pageCount: Math.ceil(Number(headers['x-total-count']) / size),
        },
      }),
    ),
    ),
    catchError((error) => of(UserResourceApiIndexWalletTransactionsUsingGETRequestAction.failed(error.response))),
  ),
  ),
)

const createWalletTransactionByUserId = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(UserResourceApiCreateWalletTransactionUsingPOSTRequestAction.started),
  mergeMap(({ payload: { userId, requestBody, options } }: any) => from(userApi.createWalletTransactionUsingPOST(userId, requestBody, options)).pipe(
    mergeMap(({ data }: { data: WalletTransactionDto }) => of(
      UserResourceApiCreateWalletTransactionUsingPOSTRequestAction.done({
        result: data,
        // TODO: fix ts-ignore
        // @ts-ignore
        params: {
          userId,
        },
      }),
    ),
    ),
    catchError((error) => of(UserResourceApiCreateWalletTransactionUsingPOSTRequestAction.failed(error.response))),
  ),
  ),
)

const updateWalletTransactionByUserId = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(UserResourceApiUpdateWalletTransactionUsingPUTRequestAction.started),
  mergeMap(({ payload: { userId, walletTransactionId, requestBody } }: any) => from(userApi.updateWalletTransactionUsingPUT(userId, walletTransactionId, requestBody)).pipe(
    mergeMap(() => of(
      UserResourceApiUpdateWalletTransactionUsingPUTRequestAction.done({
        params: {
          ...requestBody,
          id: walletTransactionId,
        },
      }),
    ),
    ),
    catchError((error) => of(UserResourceApiUpdateWalletTransactionUsingPUTRequestAction.failed(error.response))),
  ),
  ),
)

export const walletEpic = combineEpics(getWalletUser, createWalletTransactionByUserId, updateWalletTransactionByUserId)
