import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { OrderState } from '@/redux/types/order-type'
import { UserResourceApiIndexOrdersUsingGETRequestAction } from '@/api/origin/action'
import { logout } from '@/redux/actions/auth-action'

export const orderInitState: OrderState = {
  byId: {},
  allIds: [],
  isLoading: false,
}

export const orderReducer = reducerWithInitialState<OrderState>(orderInitState)
  .case(logout, () => orderInitState)
  .case(UserResourceApiIndexOrdersUsingGETRequestAction.started, (state) => ({
    ...state,
    isLoading: false,
  }))
  .case(UserResourceApiIndexOrdersUsingGETRequestAction.done, (state, { result }: any) => ({
    ...state,
    byId: {
      ...state.byId,
      ...result.order.byId,
    },
    allIds: [...new Set([...result.order.allIds])],
    isLoading: true,
  }))
  .build()
