import React, { useState } from 'react'
import cn from 'classnames'
import { RangeDropdown } from '@/ui/molecules'
import { FilterState, PlainName, RangeName, PlaneFilterValue } from '@/redux/types/filter-type'
import { SectionCollapse, CustomScrollBar, Icon, SelectCustomized, Button, FormMultiselect } from '@/ui/atoms'
// import { getYear } from '@/lib'
import { FilterDto } from '@/api/origin/api'
import styles from './filter-for-mobile.module.scss'

export const FilterForMobile: React.FC<{
  filter: FilterState
  changePlainFilter: (name: PlainName) => (value?: string | string[]) => void
  changeRangeFilter: (name: RangeName) => (value?: PlaneFilterValue) => void
  resetFilter: () => void
}> = React.memo(({ filter, changePlainFilter, changeRangeFilter, resetFilter }) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const openFilter = () => (!isFilterOpen ? setIsFilterOpen(true) : undefined)
  const closeFilter = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsFilterOpen(false)
  }

  const handleChange = (name: PlainName) => (value: FilterDto[]) => {
    changePlainFilter(name)(value.map((item) => item.name || ''))
  }

  const listModelGroup = filter.MAKE.list
    .filter((item: FilterDto) => filter.MAKE.value.includes(item['name'] || ''))
    .map((item: FilterDto) => ({
      title: item.name || '',
      items: filter.MODEL.list.filter((modelItem: any) => modelItem.parentExternalId === item.externalId),
    }))

  return (
    <div className={styles.container}>
      <div className={styles.buttonControlWrapper}>
        <button onClick={openFilter} name="button_filter" aria-label="Button Filter">
          <Icon name="icon-filter-vehicle" width="44px" height="44px" className="mr-16" />
        </button>
        <button name="button_sort" aria-label="Button Sort">
          <Icon name="icon-sort-vehicle" width="44px" height="44px" />
        </button>
      </div>
      <form className={cn(styles.formWrapper, { [styles.active]: isFilterOpen })}>
        <div className={styles.formHeader}>
          <Icon
            name="icon-popup-close"
            width="14px"
            height="14px"
            className={styles.formHeaderCloseIcon}
            onClick={closeFilter}
          />
          <div className={styles.formHeaderTitle}>Filters</div>
          <div className={styles.formHeaderResetButton} onClick={resetFilter}>
            Reset Filters
          </div>
        </div>
        <div className={styles.formBody}>
          <CustomScrollBar height="auto">
            <SectionCollapse
              title="Price Range"
              children={(
                <RangeDropdown
                  id="Price"
                  money
                  label="Price"
                  // classes={'mb-25'}
                  step={1}
                  min={0}
                  max={99999}
                  value={filter.PRICE.value as any}
                  onChange={(value: any) => changeRangeFilter('PRICE')(value)}
                  reset={() => changeRangeFilter('PRICE')(undefined)}
                />
              )}
            />
            <SectionCollapse
              title="Make"
              children={(
                <FormMultiselect<string[], FilterDto>
                  // className={'mb-25'}
                  label="Make"
                  value={filter.MAKE.value}
                  items={filter.MAKE.list}
                  keyAsValue="name"
                  keyAsTitle="name"
                  onChange={handleChange('MAKE')}
                />
              )}
            />
            <SectionCollapse
              title="Model"
              children={(
                <FormMultiselect<string[], FilterDto>
                  // className={'mb-25'}
                  label="Model"
                  value={filter.MODEL.value}
                  items={filter.MODEL.list}
                  groupItems={listModelGroup}
                  keyAsValue="name"
                  keyAsTitle="name"
                  onChange={handleChange('MODEL')}
                />
              )}
            />
            <SectionCollapse title="Year">
              <RangeDropdown
                id="Year"
                label="Year"
                min={1921}
                max={2010}
                // classes={'mb-25'}
                step={1}
                value={filter.YEAR.value as any}
                onChange={(value: any) => changeRangeFilter('YEAR')(value)}
                reset={() => changeRangeFilter('YEAR')(undefined)}
              />
            </SectionCollapse>
            <SectionCollapse
              title="Engine Displacement"
              children={(
                <RangeDropdown
                  id="Engine"
                  label="Engine Displacement(Cс):"
                  step={500}
                  min={0}
                  max={10000}
                  // classes={'mb-25'}
                  value={filter.ENGINE_DISPLACEMENT.value as any}
                  onChange={(value: any) => changeRangeFilter('ENGINE_DISPLACEMENT')(value)}
                  reset={() => changeRangeFilter('ENGINE_DISPLACEMENT')(undefined)}
                />
              )}
            />
            <SectionCollapse
              title="Mileage"
              children={(
                <RangeDropdown
                  id="Mileage"
                  label="Mileage"
                  step={10000}
                  min={0}
                  max={100000}
                  // classes={'mb-25'}
                  value={filter.MILEAGE.value as any}
                  onChange={(value: any) => changeRangeFilter('MILEAGE')(value)}
                  reset={() => changeRangeFilter('MILEAGE')(undefined)}
                />
              )}
            />
            <SectionCollapse
              title="Transmission"
              children={(
                <SelectCustomized
                  // className={'mb-25'}
                  name="transmission"
                  id="transmission_mobile"
                  label="Transmission"
                  isNoneBlock
                  value={filter.TRANSMISSION_TYPE.value ? filter.TRANSMISSION_TYPE.value : ''}
                  items={filter.TRANSMISSION_TYPE.list.map((item: any) => ({ id: item.id, value: item.name }))}
                  onChange={changePlainFilter('TRANSMISSION_TYPE')}
                />
              )}
            />
            <SectionCollapse
              title="Color"
              children={(
                <SelectCustomized
                  // className={'mb-25'}
                  name="color"
                  id="color_mobile"
                  label="Color"
                  isNoneBlock
                  value={filter.COLOR.value ? filter.COLOR.value : ''}
                  items={filter.COLOR.list.map((item: any) => ({ id: item.id, value: item.name }))}
                  onChange={changePlainFilter('COLOR')}
                />
              )}
            />
            <SectionCollapse
              title="Auction"
              children={(
                <SelectCustomized
                  // className={'mb-25'}
                  name="auction"
                  id="auction_mobile"
                  label="Auction"
                  isNoneBlock
                  value={filter.AUCTION.value ? filter.AUCTION.value : ''}
                  items={filter.AUCTION.list.map((item: any) => ({ id: item.id, value: item.name }))}
                  onChange={changePlainFilter('AUCTION')}
                />
              )}
            />
            <SectionCollapse
              title="Country"
              children={(
                <SelectCustomized
                  // className={'mb-25'}
                  name="country"
                  id="country_mobile"
                  label="Country"
                  isNoneBlock
                  value="Japan"
                  items={[{ value: 'Japan' }]}
                  onChange={() => {}}
                  disabled
                />
              )}
            />
            <div className={styles.buttonWrapper}>
              <Button
                className={styles.button}
                type="primary"
                fullWidth
                onClick={closeFilter}
                aria-label="Apply Filters"
              >
                Apply Filters
              </Button>
            </div>
          </CustomScrollBar>
        </div>
      </form>
    </div>
  )
})
