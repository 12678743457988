import actionCreatorFactory from 'typescript-fsa'
import { GridType } from '@/redux/types/auction-type'
import { AvtoJpLotsFacadeControllerApiIndexAuctionLotsUsingGETRequest } from '@/api/origin/action'

const actionCreator = actionCreatorFactory('ACTION')

export const changeCurrentPage = actionCreator<number>('CHANGE_CURRENT_PAGE')
export const changeTypeGrid = actionCreator<GridType>('CHANGE_TYPE_GRID')
export const initVehiclesPage = actionCreator<
  AvtoJpLotsFacadeControllerApiIndexAuctionLotsUsingGETRequest & { grid?: GridType; prefilter?: any }
>('INIT_VEHICLES_PAGE')
