import React from 'react'
import cn from 'classnames'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Icon, LanguageLink } from '@/ui/atoms'
import { Post } from '@/redux/types/blog-type'
import { formatDate, getShortDescription } from '@/lib'
import styles from './blog-item.module.scss'

export const BlogItem: React.FC<{
  className?: string
  post: Post
  to: string
}> = ({ className, post, to }) => (
  <article className={cn(className)}>
    <LanguageLink to={to} className={styles.imageLink} aria-label={post.title}>
      {post.thumbnail ? (
        <LazyLoadImage alt="post-img" className={styles.image} src={post.thumbnail} />
      ) : (
        <Icon name="image-placeholder" className={styles.imageLink} />
      )}
    </LanguageLink>
    <div className={styles.wrapperDateTitle}>
      <div className={styles.date}>{formatDate(post)}</div>
      <LanguageLink to={to} aria-label={post.title}>
        <h3 className={styles.title}>{getShortDescription(post.title, 50)}</h3>
      </LanguageLink>
    </div>
    <div className={styles.desc}>{getShortDescription(post.short_description, 55)}</div>
    <LanguageLink to={to} className={styles.readMore} aria-label="Read More">
      Read More
    </LanguageLink>
  </article>
)
