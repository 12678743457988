import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable'
import { Action } from 'typescript-fsa'
import { push } from 'connected-react-router'
import { mergeMap, catchError, mapTo, pluck, withLatestFrom, switchMap } from 'rxjs/operators'
import { from, of } from 'rxjs'
import * as BlogRequest from '@/api/blog'
import { fetchByAll, fetchById, changeCurrentPage, changeSort } from '@/redux/actions/blog-action'
import { Store } from '@/redux/store/store.interface'
import { normalizeBlog } from '@/redux/normalize'
import { ofAction, ofActions } from '../../lib/rxjs-operators'

const fetchByAllEpic = (action$: ActionsObservable<Action<any>>, store$: StateObservable<Store>) => action$.pipe(
  ofAction(fetchByAll.started),
  withLatestFrom(store$.pipe(pluck('blog'))),
  switchMap(([_, stateBlog]) => from(
    BlogRequest.fetchByAll({
      search: stateBlog.search,
      page: stateBlog.page_number,
      order: stateBlog.order,
    }),
  ).pipe(
    mergeMap((result) => of(
      fetchByAll.done({
        result: { ...result, posts: normalizeBlog(result.posts, result.page_number) },
      }),
    ),
    ),
    catchError((error) => of(
      fetchByAll.failed({
        error: error.response,
        params: {},
      }),
    ),
    ),
  ),
  ),
)

const fetchByIdEpic = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(fetchById.started),
  mergeMap(({ payload }) => from(BlogRequest.fetchById(payload)).pipe(
    mergeMap((result) => {
      if (!result) {
        return of(push('/blog'))
      }
      return of(fetchById.done({ result, params: payload }))
    }),
    catchError((error) => [
      fetchById.failed({
        error: error.response,
        params: payload,
      }),
      push('/blog'),
    ]),
  ),
  ),
)

const changeCurrentPageEpic = (action$: ActionsObservable<Action<any>>) => action$.pipe(ofActions([changeCurrentPage, changeSort]), mapTo(fetchByAll.started({})))

export const blogEpic = combineEpics(fetchByAllEpic, changeCurrentPageEpic, fetchByIdEpic)
