import React from 'react'
import { Link } from 'react-router-dom'
import { Store } from '@/redux/store/store.interface'
import { useSelector } from 'react-redux'

const getSelectorStore = ({ lang }: Store) => ({
  lang: lang.lang,
})

export const LanguageLink: React.FC<any> = (props) => {
  const { to, children, ...rest } = props
  const { lang } = useSelector<Store, { lang: string }>(getSelectorStore)
  const path = lang === '' || lang === to.slice(1) ? to : `/${lang}${to}`
  return (
    <>
      <Link {...rest} to={path}>
        {children}
      </Link>
    </>
  )
}
