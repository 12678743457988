import { ActionsObservable, combineEpics } from 'redux-observable'
import { Action } from 'typescript-fsa'
import React from 'react'
import { mergeMap, catchError } from 'rxjs/operators'
import { of, from } from 'rxjs'
import {
  UserResourceApiCreateBidUsingPOSTRequestAction,
  UserResourceApiCreateBiddingFlowUsingPOSTRequestAction,
  UserResourceApiIndexBidsUsingGETRequestAction,
  UserResourceApiUpdateBidStateUsingPUTRequestAction,
  UserResourceApiUpdateBidUsingPUTRequestAction,
  UserResourceApiReadUserUsingGETRequestAction,
} from '@/api/origin/action'
import { normalizeBid } from '@/redux/normalize'
import { routerActions } from 'connected-react-router'
import { userApi } from '@/api'
import { ofAction } from '@/lib/rxjs-operators'
import { open } from '@/redux/actions/popup-action'
import { BiddingClosedPopup } from '@/pages/procurement/ui/atoms'
import { BiddingExpensivePopup } from '@/pages/procurement/ui/atoms/bidding-expensive-popup'
import { PopupTopUpBalance } from '@/pages/dashboard/page-my-wallet/ui/atoms/popup-top-up-balance'
import { changeProcurement } from '../actions/procurement-action'

const getBidsByUserId = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(UserResourceApiIndexBidsUsingGETRequestAction.started),
  mergeMap(({ payload: { userId, page, size, sort, options } }) => from(userApi.indexBidsUsingGET(userId, page, size, sort, options)).pipe(
    mergeMap(({ data }: { data: any }) => of(
      UserResourceApiIndexBidsUsingGETRequestAction.done({
        // @ts-ignore
        result: normalizeBid(data, options || {}),
      }),
    ),
    ),
    catchError((error) => {
      if (error.response.status === 403 && error.response.data.type === '/lot-is-too-expensive') {
        return [
          UserResourceApiCreateBiddingFlowUsingPOSTRequestAction.failed(error.response),
          open({ body: React.createElement(BiddingExpensivePopup), size: 'small' }),
        ]
      } else {
        return [of(UserResourceApiIndexBidsUsingGETRequestAction.failed(error.response))]
      }
    }),
  ),
  ),
)

const createBidByUserId = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(UserResourceApiCreateBidUsingPOSTRequestAction.started),
  mergeMap(({ payload: { userId, requestBody, options } }) => from(userApi.createBidUsingPOST(userId, requestBody)).pipe(
    mergeMap(({ data }: { data: any }) => {
      const { lot, ...bidData } = data
      const bid = {
        ...bidData,
        lotId: lot.id || null,
      }

      return [UserResourceApiCreateBidUsingPOSTRequestAction.done(bid), changeProcurement(options)]
    }),
    catchError((error) => of(UserResourceApiCreateBidUsingPOSTRequestAction.failed(error.response))),
  ),
  ),
)

const createBiddingFlows = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(UserResourceApiCreateBiddingFlowUsingPOSTRequestAction.started),
  mergeMap(({ payload: { userId, requestBody, options: { auction } } }) => from(userApi.createBiddingFlowUsingPOST(userId, requestBody)).pipe(
    mergeMap(({ data }: { data: any }) => of(
      UserResourceApiCreateBiddingFlowUsingPOSTRequestAction.done({
        result: data,
        params: auction,
      }),
    ),
    ),
    catchError((error) => {
      if (error.response.status === 403 && error.response.data.type === '/bidding-closed') {
        return [
          UserResourceApiCreateBiddingFlowUsingPOSTRequestAction.failed(error.response),
          open({ body: React.createElement(BiddingClosedPopup), size: 'small' }),
        ]
      }
      if (error.response.status === 403 && error.response.data.type === '/lot-is-too-expensive') {
        return [
          UserResourceApiCreateBiddingFlowUsingPOSTRequestAction.failed(error.response),
          open({ body: React.createElement(BiddingExpensivePopup), size: 'small' }),
        ]
      }
      return [UserResourceApiCreateBiddingFlowUsingPOSTRequestAction.failed(error.response), routerActions.goBack()]
    }),
  ),
  ),
)

const updateBidUsing = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(UserResourceApiUpdateBidUsingPUTRequestAction.started),
  mergeMap(({ payload: { bidId, userId, requestBody, options } }) => from(userApi.updateBidUsingPUT(bidId, userId, requestBody)).pipe(
    mergeMap(({ data }: { data: any }) => [
      UserResourceApiUpdateBidUsingPUTRequestAction.done(data),
      changeProcurement(options),
      UserResourceApiReadUserUsingGETRequestAction.started({ userId }),
    ]),
    catchError((error) => {
      if (error.response.status === 403 && error.response.data.type === '/bidding-closed') {
        return [
          UserResourceApiUpdateBidUsingPUTRequestAction.failed(error.response),
          open({ body: React.createElement(BiddingClosedPopup), size: 'small' }),
        ]
      }
      if (error.response.status === 403 && error.response.data.type === '/lot-is-too-expensive') {
        return [
          UserResourceApiCreateBiddingFlowUsingPOSTRequestAction.failed(error.response),
          open({ body: React.createElement(BiddingExpensivePopup), size: 'small' }),
        ]
      }
      if (error.response.status === 402) {
        return [
          UserResourceApiCreateBiddingFlowUsingPOSTRequestAction.failed(error.response),
          open({
            body: React.createElement(PopupTopUpBalance, { title: 'Not enough funds on balance' }),
            size: 'small',
          }),
        ]
      }
      return of(UserResourceApiUpdateBidUsingPUTRequestAction.failed(error.response))
    }),
  ),
  ),
)

const updateBidState = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(UserResourceApiUpdateBidStateUsingPUTRequestAction.started),
  mergeMap(({ payload: { bidId, userId, value } }) => from(userApi.updateBidStateUsingPUT(bidId, userId, value)).pipe(
    mergeMap(({ data }: { data: any }) => {
      data = { bidId, value }
      return [
        UserResourceApiUpdateBidStateUsingPUTRequestAction.done(data),
        UserResourceApiReadUserUsingGETRequestAction.started({ userId }),
      ]
    }),
    catchError((error) => {
      if (error.response.status === 403 && error.response.data.type === '/bidding-closed') {
        return [
          UserResourceApiCreateBiddingFlowUsingPOSTRequestAction.failed(error.response),
          open({ body: React.createElement(BiddingClosedPopup), size: 'small' }),
        ]
      } else if (error.response.status === 403 && error.response.data.type === '/lot-is-too-expensive') {
        return [
          UserResourceApiCreateBiddingFlowUsingPOSTRequestAction.failed(error.response),
          open({ body: React.createElement(BiddingExpensivePopup), size: 'small' }),
        ]
      }
      return [of(UserResourceApiCreateBiddingFlowUsingPOSTRequestAction.failed(error.response))]
    }),
  ),
  ),
)

export const bidEpic = combineEpics(
  createBidByUserId,
  createBiddingFlows,
  getBidsByUserId,
  updateBidUsing,
  updateBidState,
)
