import React from 'react'
import cn from 'classnames'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { NavArrow } from '@/ui/molecules'
import { Icon } from '@/ui/atoms'
import { getCarImg } from '@/lib/get-car-img'
import styles from './img.module.scss'

type Props = {
  img?: string[]
  context: 'vehicleVerticalCard' | 'vehicleHorizontalCard' | 'watchlistCard'
}

export const Img: React.FC<Props> = ({ img = [], context }) => {
  const [index, setState] = React.useState(0)
  let imgFormatArr: any = []
  let imgFormatObj: any = {}

  if (Array.isArray(getCarImg(img))) {
    imgFormatArr = getCarImg(img)
  } else {
    imgFormatObj = getCarImg(img)
  }

  const change = (direction: string) => (e: any) => {
    e.preventDefault()
    const imgObjLength = Object.keys(imgFormatObj).every((i) => imgFormatObj[i].length > 1)
      ? Object.keys(imgFormatObj).map((item) => imgFormatObj[item].length)
      : [1]
    const totalImage =
      imgFormatArr && imgFormatArr.length > 1
        ? imgFormatArr.length - 1
        : Object.keys(imgFormatObj).every((i) => imgFormatObj[i].length > 1)
          ? imgObjLength[0] - 1
          : 1

    if (direction === 'right' && index < totalImage) {
      setState(index + 1)
    } else if (direction === 'left' && index !== 0) {
      setState(index - 1)
    }
  }

  const imageClasses = cn(
    styles.image,
    { [styles.verticalCardContext]: context === 'vehicleVerticalCard' },
    { [styles.horizontalCardContext]: context === 'vehicleHorizontalCard' },
    { [styles.watchlistCard]: context === 'watchlistCard' },
  )

  const srcImg =
    imgFormatArr.length >= 1
      ? imgFormatArr[index]
      : !imgFormatObj
        ? ''
        : Boolean(imgFormatObj[390])
          ? imgFormatObj[390][index]
          : Boolean(imgFormatObj[320])
            ? imgFormatObj[320][index]
            : Boolean(imgFormatObj[50])
              ? imgFormatObj[50][index]
              : ''

  if (imgFormatArr.length > 0 || Object.keys(imgFormatObj).every((i) => imgFormatObj[i].length > 0)) {
    return (
      <div className={styles.wrapper}>
        {(imgFormatArr.length > 1 || Object.keys(imgFormatObj).every((i) => imgFormatObj[i].length > 1)) && (
          <NavArrow
            wrapperClassName={styles.wrapperArray}
            arrowClassName={styles.arrow}
            type="backward"
            onClick={change('left')}
          />
        )}
        <LazyLoadImage className={imageClasses} src={srcImg} alt="img" />
        {(imgFormatArr.length > 1 || Object.keys(imgFormatObj).every((i) => imgFormatObj[i].length > 1)) && (
          <NavArrow
            wrapperClassName={cn(styles.wrapperArray, styles.wrapperRightArray)}
            arrowClassName={styles.arrow}
            type="forward"
            onClick={change('right')}
          />
        )}
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      <Icon name="image-placeholder" className={imageClasses} />
    </div>
  )
}
