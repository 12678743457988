import { LotDto } from '@/api/origin/api'

export const formatAveragePrice = (car: LotDto) => (car.price
  ? parseInt(String(car.averagePrice), 10).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
    minimumFractionDigits: 0,
  })
  : '-')
