import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import copy from 'copy-to-clipboard'
import { FacebookShareButton, TwitterShareButton } from 'react-share'
import ReactToPrint from 'react-to-print'
import { Icon } from '@/ui/atoms'
import styles from './popup-share-page.module.scss'

type Props = {
  cancel: () => void
  url: any
  text?: string
  getPageRef?: () => any
}

export const PopupSharePage: React.FC<Props> = ({ cancel, url, text, getPageRef }) => {
  const [linkState, setLinkState] = useState('Copy Link')
  const [meta, setMeta] = useState('')
  const componentRef = getPageRef && getPageRef()

  useEffect(() => {
    const tagTitle = document.head.querySelector('title')
    setTimeout(() => {
      if (tagTitle) {
        setMeta(text + tagTitle.text.trim())
      } else {
        // eslint-disable-next-line no-unused-expressions
        text && setMeta(text)
      }
    }, 500)
  })

  const copyLink = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault()
    copy(`${url}`)
    setTimeout(() => {
      cancel()
    }, 2000)
    setLinkState('Link Copied')
  }
  return (
    <div className={styles.popupWrapper}>
      <h2 className={styles.titlePopup}>Share this vehicle</h2>
      <div className={styles.shareLinkWrapper}>
        <FacebookShareButton url={url} quote={meta}>
          <div className={cn(styles.shareLinkItem, styles.shareLinkItemFirstLine)} onClick={cancel}>
            <div className={styles.iconWrapper}>
              <Icon
                name="icon-share-facebook"
                width="17px"
                height="32px"
                className={cn(styles.icon, styles.iconFacebook)}
              />
            </div>
            <span className={styles.title}>Facebook</span>
          </div>
        </FacebookShareButton>
        <TwitterShareButton url={url} title={meta}>
          <div className={cn(styles.shareLinkItem, styles.shareLinkItemFirstLine)} onClick={cancel}>
            <div className={styles.iconWrapper}>
              <Icon name="icon-twitter" width="23px" height="19px" className={styles.icon} />
            </div>
            <span className={styles.title}>Twitter</span>
          </div>
        </TwitterShareButton>
        <button className={cn(styles.shareLinkItem, styles.shareCopyLink)} onClick={copyLink}>
          <div className={styles.iconWrapper}>
            <Icon name="icon-copy-link" width="20px" height="20px" className={styles.icon} />
          </div>
          <span className={styles.title}>{linkState}</span>
        </button>
        <div onClick={cancel}>
          <ReactToPrint
            trigger={() => (
              <button className={styles.shareLinkItem}>
                <div className={styles.iconWrapper}>
                  <Icon name="icon-print" width="20px" height="24px" className={styles.icon} />
                </div>
                <span className={styles.title}>Print</span>
              </button>
            )}
            // @ts-ignore
            content={() => componentRef.current}
          />
        </div>
        <a className={styles.shareLinkItem} href={`mailto:?subject=Look what car!&body=${text} ${url}`}>
          <div className={styles.iconWrapper} onClick={cancel}>
            <Icon name="icon-share-male" width="20px" height="13px" className={styles.icon} />
          </div>
          <span className={styles.title}>Mail</span>
        </a>
      </div>
    </div>
  )
}
