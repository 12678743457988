import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { BlogState } from '@/redux/types/blog-type'
import { fetchByAll, fetchById, changeCurrentPage, changeSort, changeSearch } from '@/redux/actions/blog-action'

export const blogInitialState: BlogState = {
  posts: {
    bySlug: {},
    allPostsSlugs: [],
    byPageNumber: {},
  },
  page_number: 1,
  total_pages: 0,
  order: 'DESC',
  search: '',
  posts_per_page: 6,
}

export const blogReducer = reducerWithInitialState<BlogState>(blogInitialState)
  .case(fetchByAll.done, (state, { result }) => ({
    ...state,
    ...result,
    posts: {
      bySlug: {
        ...state.posts.bySlug,
        ...result.posts.bySlug,
      },
      byPageNumber: {
        ...state.posts.byPageNumber,
        ...result.posts.byPageNumber,
      },
      allPostsSlugs: [...new Set([...state.posts.allPostsSlugs, ...result.posts.allPostsSlugs])],
    },
    page_number: Number(result.page_number),
  }))
  .case(fetchById.done, (state, { result }) => ({
    ...state,
    posts: {
      ...state.posts,
      bySlug: {
        ...state.posts.bySlug,
        [result.slug]: result,
      },
      allPostsSlugs: [...new Set([...state.posts.allPostsSlugs, result.slug])],
    },
  }))
  .case(changeCurrentPage, (state, payload) => ({ ...state, page_number: payload }))
  .case(changeSort, (state, payload) => ({ ...state, order: payload }))
  .case(changeSearch, (state, payload) => ({ ...state, search: payload }))
  .build()
