import React from 'react'
import cn from 'classnames'

import style from './button.module.scss'

type Props = {
  type: 'primary' | 'secondary' | 'tertiary' | 'tertiary-contrariwise'
  onClick?: (event: any) => void
  disabled?: boolean
  fullWidth?: boolean
  className?: string
  formSubmitButton?: boolean
}

export const Button: React.FC<Props> = ({
  type,
  className,
  fullWidth,
  disabled,
  onClick,
  children,
  formSubmitButton,
  ...params
}) => (
  <button
    className={cn(className, style.btn, style['btn-' + type], {
      [style.fullWidth]: fullWidth,
      [style['btn-type_disabled']]: disabled,
    })}
    disabled={disabled}
    onClick={onClick}
    type={formSubmitButton ? 'submit' : 'button'}
    name="Button"
    {...params}
  >
    {children}
  </button>
)
