import React from 'react'
import { Formik } from 'formik'
import { useDispatch } from 'react-redux'
import { Button, PopupTitle, Input, DataPicker, SelectCustomized, Tab, Textarea } from '@/ui/atoms'
import { Checkbox, Tabs } from '@/ui/molecules'
import { close } from '@/redux/actions/popup-action'
import { isPhoneHasProblem, isEmailHasProblem } from '@/lib/form'
import { postRequestACallAction } from '@/redux/actions/request-call-action'
import cn from 'classnames'
import { RequestACallType } from '@/redux/types/request-call-type'
import styles from './popup-request-a-call.module.scss'

interface IForm {
  name: string
  phone: string
  hour: number
  minute: number
  isAnytime: boolean
  date: string
  activeTabIndex: number
  email: string
  message: string
}

export const PopupRequestACall = () => {
  const dispatch = useDispatch()

  const initialFieldsValues: IForm = {
    name: '',
    phone: '',
    date: new Date().toString(),
    hour: 0,
    minute: 0,
    isAnytime: false,
    email: '',
    message: '',
    activeTabIndex: 0,
  }

  return (
    <div className={styles.popup}>
      <PopupTitle size="small" className="mb-16">
        Contact Form
      </PopupTitle>
      <Formik initialValues={initialFieldsValues} validate={handleValidate} onSubmit={handleRequest}>
        {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue, touched }) => (
          <form>
            <Tabs
              limitedHeight="11 lines"
              activeTab={values.activeTabIndex}
              onTabChange={handleFieldsChange(setFieldValue, 'activeTabIndex')}
              isBorderSet={false}
              className="mb-16"
            >
              <Tab title="Contact me" className={cn(styles.tab, 'mb-16')}>
                <Input
                  wrapperClassName="mb-16"
                  name="name"
                  id="name"
                  label="Name"
                  value={values.name}
                  isError={Boolean(errors.name && touched.name)}
                  errorText={errors.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Input
                  wrapperClassName="mb-16"
                  name="phone"
                  id="phone"
                  label="Phone"
                  value={values.phone}
                  isError={Boolean(errors.phone && touched.phone)}
                  errorText={errors.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {!values.isAnytime && (
                  <div className={cn(styles.dateBlock, 'mb-16')}>
                    <div className={styles.dataPicker}>
                      <DataPicker
                        name="date"
                        id="date"
                        label="Date"
                        value={values.date}
                        onChange={(date: any) => setFieldValue('date', date, false)}
                        minDate={new Date().toString()}
                        format="MM/DD/YY"
                      />
                    </div>
                    <div className={styles.blockTime}>
                      <span className="ml-16">at</span>
                      <SelectCustomized
                        className={cn(styles.hour, 'ml-16')}
                        name="hour"
                        id="hour"
                        value={values.hour}
                        items={getHour()}
                        onChange={handleFieldsChange(setFieldValue, 'hour')}
                      />
                      <SelectCustomized
                        className={cn(styles.hour, 'ml-16')}
                        name="minute"
                        id="minute"
                        value={values.minute}
                        items={getMinutes()}
                        onChange={handleFieldsChange(setFieldValue, 'minute')}
                      />
                    </div>
                  </div>
                )}
                <Checkbox
                  className="mb-16"
                  name="shipping-insurance"
                  classNameContent={styles.shippingInsuranceContent}
                  checked={values.isAnytime}
                  onChange={handleFieldsChange(setFieldValue, 'isAnytime')}
                >
                  Call me anytime
                </Checkbox>
              </Tab>
              <Tab title="Email Me" className={cn(styles.tab, 'mb-16')}>
                <Input
                  wrapperClassName="mb-16"
                  name="name"
                  id="name"
                  label="Name"
                  value={values.name}
                  isError={Boolean(errors.name && touched.name)}
                  errorText={errors.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Input
                  wrapperClassName="mb-16"
                  name="email"
                  id="email"
                  label="Email"
                  value={values.email}
                  isError={Boolean(errors.email && touched.email)}
                  errorText={errors.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Textarea
                  label="Message (Optional)"
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Tab>
            </Tabs>
            <Button type="primary" fullWidth onClick={handleSubmit} formSubmitButton aria-label="Contact me">
              Contact me
            </Button>
          </form>
        )}
      </Formik>
    </div>
  )

  function handleFieldsChange(setFieldValue: Function, fieldName: string) {
    return (value: number | boolean) => {
      setFieldValue(fieldName, value, true)
    }
  }

  function handleValidate(values: IForm | any) {
    const errors: IForm | any = {}
    if (!values.name) errors.name = 'Please fill this field'
    if (values.activeTabIndex === 0) {
      if (!values.phone) errors.phone = 'Please fill this field'
      if (values.phone && isPhoneHasProblem(values.phone)) errors.phone = 'Invalid phone'
    } else {
      if (!values.email) errors.email = 'Please fill this field'
      if (values.email && isEmailHasProblem(values.email)) errors.email = 'Invalid email'
    }
    return errors
  }

  function handleRequest(values: IForm, { setSubmitting }: { setSubmitting: Function }) {
    setSubmitting(false)
    dispatch(close())
    const date: Date = new Date(values.date)
    date.setMilliseconds(values.hour * 60 * 60 * 1000 + values.minute * 60 * 1000)
    const params: RequestACallType = {
      name: values.name,
      titleRequest: values.activeTabIndex === 0 ? 'Call Request' : 'Email Request',
    }
    if (values.activeTabIndex === 0) {
      params.phone = values.phone
      params.isAnytime = values.isAnytime
      params.date = date
    } else {
      params.email = values.email
      params.message = values.message
    }
    dispatch(postRequestACallAction(params))
  }

  function getHour() {
    return Array.from({ length: 24 }, (_, i) => ({ name: i < 10 ? `0${i}` : `${i}`, value: i }))
  }

  function getMinutes() {
    return Array.from({ length: 60 }, (_, i) => ({ name: i < 10 ? `0${i}` : `${i}`, value: i }))
  }
}
