import { reducerWithInitialState } from 'typescript-fsa-reducers'
import * as SEOActon from '@/redux/actions/seo-action'
import { SEOState } from '@/redux/types/seo-type'

export const SEOInitialState: SEOState = {
  general: {
    footer: {
      documents: [
        {
          title: 'Copyright',
          rich_text: '',
        },
        {
          title: 'Terms & Conditions',
          rich_text: '',
        },
        {
          title: 'Privacy Policy',
          rich_text: '',
        },
      ],
      copyright: '2019 Easyautoimport LLC. All rights reserved.',
      terms_conditions: '',
      privacy_policy: '',
    },
    opening_hours: '8am-8pm PST M-Th; 6am-3pm PST Fri',
    phone_number: '1 (215) 392 6743',
    email: 'info@easyautimport.com',
    address: '1947 Wakeling St Philadelphia PA 19124',
    address_map: '',
    address_map_link: '',
    header_image: '',
    social_networks: {
      twitter: {
        url: 'https://twitter.com',
        text: '',
        target: 'none',
      },
      facebook: {
        url: 'https://www.facebook.com',
        text: '',
        target: 'none',
      },
      instagram: { url: 'https://www.instagram.com/', text: '', target: 'none' },
    },
  },
  seo: {
    home: {
      title: '',
      description: '',
    },
    vehicles: { title: '', description: '' },
    about_us: { title: '', description: '' },
    blog: { title: '', description: '' },
    support: { title: '', description: '' },
    europclassifieds: { title: '', description: '' },
  },
  home_page: {
    header: {
      subtitle: '',
      h1_title: '',
      background_image: '',
    },
    steps: {
      title: '',
      video_code: '',
      steps: [],
      button: {
        url: '',
        text: '',
        target: '',
      },
    },
    benefits: {
      title: '',
      benefits: [],
    },
    ratings: [],
    testimonials: {
      title: '',
      testimonials: [],
    },
    get_started: {
      title: '',
      description: '',
      button: {
        url: '',
        text: '',
        target: '',
      },
    },
    page_variants: [],
    explore_our_cars: {
      title: '',
      cars: [],
    },
    find_car_from_classified: {
      title: '',
      blocks: [],
    },
  },
  about_us: {
    title: '',
    sub_title: '',
    rich_text: '',
    header_image: '',
    points: [],
    paragraphs: [],
  },
  faq: {
    title: '',
    rich_text: '',
  },
  vehicles: {
    page_variants: [],
  },
  europ_classifieds: {
    title: '',
    paragraphs: [],
  },
  isLoading: false,
}

export const SEOReducer = reducerWithInitialState<SEOState>(SEOInitialState)
  .case(SEOActon.fetch.done as any, (state: any, payload: any) => ({
    ...state,
    ...payload,
    isLoading: true,
  }))
  .build()
