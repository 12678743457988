import React from 'react'
import cn from 'classnames'

import styles from './login-buttons.module.scss'

export const LoginButtons: React.FC<{
  className?: string
  signIn: () => void
  signUp: () => void
}> = ({ className, signIn, signUp }) => (
  <div className={cn(className, styles.wrapperComponent)}>
    <div className={styles.signInButton} onClick={signIn}>
      Sign In
    </div>
    <div className={styles.signUpButton} onClick={signUp}>
      Sign Up
    </div>
  </div>
)
