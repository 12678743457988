import React from 'react'
import cn from 'classnames'
import PhoneIcon from '@material-ui/icons/Phone'
import { useResize } from '@/lib'
import PhoneFormat from 'phone'
import styles from './phone.module.scss'

export const Phone: React.FC<{
  phone: string
  className?: string
  responsive?: boolean
}> = ({ className, phone, responsive }) => {
  const windowSize = useResize()
  return (
    <a
      href={`tel:${PhoneFormat(phone)[0]}`}
      className={cn(className, styles.wrapperComponent)}
      aria-label={`tel. ${phone}`}
    >
      {Boolean(windowSize < 899 && responsive) ? (
        <div className={styles.iconWrapper}>
          <PhoneIcon className={styles.icon} />
        </div>
      ) : (
        `tel. ${phone}`
      )}
    </a>
  )
}
