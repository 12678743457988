import React from 'react'
import cn from 'classnames'
import { getUSAFormatPrice } from '@/lib'
import { EstShippingPrompt } from '@/ui/atoms'
import style from './est-shipping.module.scss'

interface IProps {
  className?: string
  value?: number
}

export const EstShipping: React.FC<IProps> = ({ className, value }) => (
  <div className={cn(style.shipping, className)}>
    {value && (
      <>
        Est. Shipping:
        {' '}
        {getUSAFormatPrice(value!)}
        <EstShippingPrompt value={value!} />
      </>
    )}
  </div>
)
