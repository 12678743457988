// core components and functions imports
import React, { useState } from 'react'
import cn from 'classnames'

// styles imports
import styles from './checkbox.module.scss'
import { Icon } from '../../atoms'

// template components and user functions imports

type Props = {
  className?: string
  classNameContent?: string
  name: string
  checked?: boolean
  onChange?: (isChecked: boolean) => void
}

export const Checkbox: React.FC<Props> = ({
  className,
  classNameContent,
  name,
  children,
  checked = false,
  onChange,
}) => {
  const [isChecked, setIsChecked] = useState(checked)

  return (
    <label className={cn(styles.wrapperComponent, className)}>
      <input
        type="checkbox"
        name={name}
        checked={isChecked}
        onChange={(e) => {
          setIsChecked(e.target.checked)
          // eslint-disable-next-line no-unused-expressions
          onChange && onChange(e.target.checked)
        }}
        hidden
        className={styles.input}
      />
      <div>
        <div className={styles.wrapperIcon}>
          <Icon
            name="icon-checkbox"
            width="18px"
            height="18px"
            className={cn(styles.icon, { [styles.active]: isChecked })}
          />
          <div className={cn(styles.iconOverflow, { [styles.active]: isChecked })} />
        </div>
      </div>
      <div className={cn(styles.content, classNameContent)}>{children}</div>
    </label>
  )
}

// inner components
