import React from 'react'
import cn from 'classnames'
import { getUSAFormatPrice } from '@/lib'
import { AveragePricePrompt } from '@/ui/atoms'
import style from './average-price.module.scss'

interface IProps {
  className?: string
  value?: number
}

export const AveragePrice: React.FC<IProps> = ({ className, value }) => (
  <div className={cn(className, style.averagePrice)}>
    Average Price:
    {' '}
    {value ? getUSAFormatPrice(value) : '-'}
    <AveragePricePrompt />
  </div>
)
