import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { WatchListState } from '@/redux/types/watch-list-type'
import { DashboardResourceApiIndexFavoritesUsingGETRequestAction } from '@/api/origin/action'
import { logout } from '@/redux/actions/auth-action'

export const watchListInitState: WatchListState = []
export const watchListReducer = reducerWithInitialState<WatchListState>(watchListInitState)
  .case(logout, (_) => watchListInitState)
  .case(DashboardResourceApiIndexFavoritesUsingGETRequestAction.done, (_, { result }: any) => result.allIds.reverse())
  .case(DashboardResourceApiIndexFavoritesUsingGETRequestAction.failed, () => watchListInitState)
  .build()
