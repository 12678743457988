import React from 'react'
import cn from 'classnames'

import { Flag } from '@/ui/atoms'

import styles from './source-of-supply.module.scss'

export const SourceOfSupply: React.FC<{
  className?: string
  value?: string
  flag?: 'germany' | 'italy' | 'japan'
}> = ({ className, value = '', flag }) => (
  <div className={cn(className, styles.sourceOfSupply)}>
    {flag && <Flag value={flag} className={styles.positioningFlag} />}
    {value}
  </div>
)
