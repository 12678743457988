import axios, { AxiosRequestConfig } from 'axios'
import { WP_API_URL } from '@/constants/API'

export const setupAxiosInterceptors = () => {
  const onRequestSuccess = (config: AxiosRequestConfig) => {
    const cookieName = 'EAI-XSRF-TOKEN'
    const cookie = document.cookie.match(
      new RegExp('(?:^|; )' + cookieName.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'),
    )
    if (cookie && config.url && WP_API_URL && !config.url.includes(WP_API_URL)) {
      config.headers['X-XSRF-TOKEN'] = cookie[1]
    }

    config.url = `${config.url}`
    config.withCredentials = true
    return config
  }

  axios.interceptors.request.use(onRequestSuccess)
}
