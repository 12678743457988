import { stringify } from 'query-string'
import { FilterState } from '@/redux/types/filter-type'
import { rangeFilterToString } from '@/lib'

export const makeFilterQueryParams = (filterStore: FilterState, isPrefilter: boolean, isMake?: boolean) => {
  let model: string[] | undefined = filterStore.MODEL.value ? filterStore.MODEL.value : undefined
  if (isMake) {
    const externalIdMakes = filterStore.MAKE.list
      .filter((make) => filterStore.MAKE.value.includes(make.name || ''))
      .map((make) => make.externalId || '')
    const externalIdMODELS = filterStore.MODEL.list.filter((model) => filterStore.MODEL.value.includes(model.name || ''),
    )
    model = externalIdMODELS
      .filter((model) => externalIdMakes.includes(model.parentExternalId || ''))
      .map((model) => model.name || '')
  }
  return stringify({
    make: filterStore.MAKE.value.length > 0 && isPrefilter ? filterStore.MAKE.value.join(',') : undefined,
    model: model && model.length > 0 && isPrefilter ? model.join(',') : undefined,
    transmissionType: filterStore.TRANSMISSION_TYPE.value ? filterStore.TRANSMISSION_TYPE.value : undefined,
    priceUSD: filterStore.PRICE.value ? rangeFilterToString(filterStore.PRICE.value) : undefined,
    engineDisplacementCc: filterStore.ENGINE_DISPLACEMENT.value
      ? rangeFilterToString(filterStore.ENGINE_DISPLACEMENT.value)
      : undefined,
    year: filterStore.YEAR.value ? rangeFilterToString(filterStore.YEAR.value) : undefined,
    color: filterStore.COLOR.value ? filterStore.COLOR.value : undefined,
    auction: filterStore.AUCTION.value ? filterStore.AUCTION.value : undefined,
    mileageMi: filterStore.MILEAGE.value ? rangeFilterToString(filterStore.MILEAGE.value) : undefined,
  })
}
