import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import InputBase from '@material-ui/core/InputBase'

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(1),
    },
  },
  input: {
    borderBottom: 'none',
    fontSize: 14,
    padding: '10px 20px 10px 2px',
    maxWidth: '150px',
  },
}))(InputBase)

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  selected: {
    backgroundColor: '#eca52c !important',
    color: 'white',
    fontWeight: 600,
  },
}))

type Props = {
  margin?: string
  id?: string
  name?: string
  disabled?: boolean
  type?: string
  label?: string
  isError?: boolean
  autoComplete?: string
  errorText?: React.ReactNode
  helpText?: React.ReactNode
  onChange: (value: any) => void
  wrapperClassName?: string
  currency?: boolean
  items: {
    name: string | number
    value: string | number
  }[]
} & React.HTMLProps<HTMLInputElement>

export const SelectSubMenu: React.FC<Props> = ({
  label,
  value = '',
  disabled,
  errorText,
  onBlur,
  helpText,
  isError,
  wrapperClassName = '',
  currency,
  items = [],
  onChange,
  ...inputProps
}) => {
  const classes = useStyles()
  const [value2, setValue] = React.useState(value)
  const handleChange = (event: any) => {
    setValue(event.target.value)
    onChange(event.target.value)
  }

  return (
    <div>
      <FormControl className={classes.margin}>
        <Select value={value2} onChange={handleChange} input={<BootstrapInput />}>
          {items.map((i) => (
            <MenuItem value={i.value} key={i.value} classes={{ selected: classes.selected }}>
              <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{i.value}</div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
