import React from 'react'

import { Switch, withRouter } from 'react-router'

import { Popup, Snackbar } from '@/ui/atoms'
import { useSelector, useDispatch } from 'react-redux'
import { Store } from '@/redux/store/store.interface'
import { close } from '@/redux/actions/popup-action'
import { ReactComponent as SvgSprite } from '@/static/images/sprite.svg'
import './app.scss'

export const App = withRouter((props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [props.location.pathname])

  const store = useSelector<Store, Store>((store) => store)
  const dispatch = useDispatch()

  const closePopup = () => dispatch(close())

  return (
    <>
      {store.snack.index.length > 0 && (
        <div style={{ zIndex: 21 }}>
          {store.snack.index.map((id: any) => (
            <Snackbar active key={id}>
              {store.snack.items[id]}
            </Snackbar>
          ))}
        </div>
      )}

      {store.popup.state ? (
        <Popup
          size={store.popup.size}
          height={store.popup.height}
          handler={closePopup}
          noScroll={store.popup.noScroll}
          isCloseButton={store.popup.isCloseButton}
        >
          {store.popup.body}
        </Popup>
      ) : null}

      <Switch location={props.location}>{props.children}</Switch>
      <SvgSprite />
    </>
  )
})
