import React from 'react'
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable'
import { Action } from 'typescript-fsa'

import { mergeMap, catchError, withLatestFrom, pluck } from 'rxjs/operators'
import { of } from 'rxjs'

import { ofAction } from '@/lib/rxjs-operators'
import { addComparison, removeComparison } from '@/redux/actions/comparison-list-action'
import { SnackbarAddComparison, SnackbarRemoveComparison } from '@/pages/index/page-comparison-list/ui/atoms'
import { create } from '@/redux/actions/snack-action'
import { NOTHING } from '@/redux/actions/auth-action'
import nanoid from 'nanoid'
import * as storage from '@/lib/storage'
import { Store } from 'redux'
import { push } from 'connected-react-router'

const addComparisonEpic = (action$: ActionsObservable<Action<any>>, store$: StateObservable<Store>) => action$.pipe(
  ofAction(addComparison),
  withLatestFrom(
    store$.pipe(pluck('comparisonList')),
    store$.pipe(pluck('procurement')),
    store$.pipe(pluck('lot')),
    store$.pipe(pluck('auction')),
    store$.pipe(pluck('bid')),
  ),
  mergeMap(([{ payload }, comparisonList, procurement, lot, auction, bid]: any) => {
    storage.saveItem(
      'ease-auto-import',
      JSON.stringify({
        comparisonList: [...new Set([...comparisonList, payload.externalId])],
        procurement,
        lot,
        auction,
        bid,
      }),
      false,
    )
    const id = nanoid()
    return of(create({ body: React.createElement(SnackbarAddComparison, { name: payload.name }, null), id }))
  }),
  catchError((error) => of(NOTHING())),
)

const removeComparisonEpic = (action$: ActionsObservable<Action<any>>, store$: StateObservable<Store>) => action$.pipe(
  ofAction(removeComparison),
  withLatestFrom(
    store$.pipe(pluck('comparisonList')),
    store$.pipe(pluck('procurement')),
    store$.pipe(pluck('lot')),
    store$.pipe(pluck('auction')),
    store$.pipe(pluck('bid')),
  ),
  mergeMap(([{ payload }, comparisonList, procurement, lot, auction, bid]: any) => {
    const newComparisonList = [...comparisonList.filter((id: string) => id !== payload.externalId)]
    storage.saveItem(
      'ease-auto-import',
      JSON.stringify({
        comparisonList: newComparisonList,
        procurement,
        lot,
        auction,
        bid,
      }),
      false,
    )
    const id = nanoid()
    if (newComparisonList.length === 0) {
      return [
        create({ body: React.createElement(SnackbarRemoveComparison, { name: payload.name }, null), id }),
        push('/'),
      ]
    }
    return of(create({ body: React.createElement(SnackbarRemoveComparison, { name: payload.name }, null), id }))
  }),
  catchError((error) => of(NOTHING())),
)

export const comparisonListEpic = combineEpics(addComparisonEpic, removeComparisonEpic)
