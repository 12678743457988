import { ActionsObservable, combineEpics } from 'redux-observable'
import { Action } from 'typescript-fsa'
import { mergeMap, catchError } from 'rxjs/operators'
import { of, from } from 'rxjs'
import * as SEORequest from '@/api/seo'
import * as SEOAction from '@/redux/actions/seo-action'
import { NOTHING } from '@/redux/actions/auth-action'
import * as storage from '@/lib/storage'

const fetch = (action$: ActionsObservable<Action<any>>) => from(SEORequest.fetch()).pipe(
  mergeMap((result) => {
    storage.saveItem('ease-auto-import-seo', JSON.stringify({ ...result }))
    return of(SEOAction.fetch.done(result))
  }),
  catchError((error: any) => {
    console.error(error.response)
    return of(NOTHING())
  }),
)
export const seoEpic = combineEpics(fetch)
