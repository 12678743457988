import React, { useRef } from 'react'
import NumberFormat from 'react-number-format'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles((theme) => ({
  dense: {
    marginTop: theme.spacing(0),
  },

  root: {
    '& input:valid + fieldset': {
      borderColor: '#EDEDED',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: '#ECA52C',
      opacity: 1,
      transform: 'translate(14px, -3px) scale(0.75) !important',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(8px, 15px) scale(1)',
    },
    '& label.Mui-focused': {
      color: '#ECA52C',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#ECA52C',
      borderWidth: 1,
    },
    '& input': {
      transition: 'background-color 5000s ease-in-out 0s',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        // borderColor: '#EDEDED',
        borderWidth: 1,
      },

      '&:hover fieldset': {
        borderColor: '#ECA52C',
        borderWidth: 1,
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ECA52C',
        borderWidth: 1,
      },
    },
  },
}))

type Props = {
  margin?: string
  id?: string
  name?: string
  disabled?: boolean
  type?: string
  label?: string
  isError?: boolean
  autoComplete?: string
  errorText?: React.ReactNode
  helpText?: React.ReactNode
  onChange?: any
  onKeyDown?: any
  wrapperClassName?: string
  currency?: boolean
  decimalSeparator?: boolean | string
  allowNegative?: boolean
  InputLabelProps?: object
  multiline?: boolean
  rows?: number
  autocomplete?: string
} & React.HTMLProps<HTMLInputElement>

export const Input: React.FC<Props> = ({
  label,
  value = '',
  disabled,
  errorText,
  onBlur,
  helpText,
  isError,
  wrapperClassName = '',
  currency,
  onKeyDown,
  InputLabelProps,
  multiline,
  rows,
  autocomplete,
  ...inputProps
}) => {
  const inputRef = useRef(null)
  const classes = useStyles()

  return (
    <div className={wrapperClassName}>
      <TextField
        className={clsx(classes.root, classes.dense)}
        margin="dense"
        variant="outlined"
        value={value}
        label={label}
        onKeyDown={onKeyDown}
        inputRef={inputRef}
        disabled={disabled}
        error={isError}
        helperText={isError ? errorText : helpText}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          ...(currency && { inputComponent: NumberFormatCustom }),
        }}
        inputProps={inputProps as any}
        aria-label={label}
        data-border={false}
        multiline={multiline}
        rows={rows}
      />
    </div>
  )
}

function NumberFormatCustom(props: any) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        })
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  )
}
