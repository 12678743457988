export const getCarImg = (imagesArr: any[]) => {
  const imgFormatArr: any = []
  const imgFormatObj: any = {}

  imagesArr.forEach((item: any) => {
    if (typeof item === 'string') {
      const i = item.includes('h=50') ? item.slice(0, item.length - 4) + 'w=320' : item + '&w=320'
      imgFormatArr.push(i)
    } else if (item && item.height) {
      imgFormatObj[item.height] = imgFormatObj[item.height]
        ? [...imgFormatObj[item.height], item.url]
        : (imgFormatObj[item.height] = [item.url])
    } else if (!item.height && !imagesArr.some((item: any) => item.height)) {
      const y = item.url.includes('h=50') ? item.url.slice(0, item.url.length - 4) + 'w=320' : item.url
      imgFormatArr.push(y)
    }
  })

  return imgFormatArr.length > 0 ? imgFormatArr : Object.keys(imgFormatObj).length > 0 ? imgFormatObj : undefined
}
