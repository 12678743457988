import { UserDTO } from '@/api/origin/api'

export enum AuthorizationState {
  Initial = 'Initial',
  Authorizing = 'Authorizing',
  Authorized = 'Authorized',
  FailedWithCredentials = 'FailedWithCredentials',
  // FailedWithRefreshToken = 'FailedWithRefreshToken',
  ServerError = 'ServerError',
}

export type AuthState = {
  user: UserDTO | null
  state: AuthorizationState
  previousState: AuthorizationState | null
  // refreshToken: string | null
  // accessToken: string | null
}
