import React, { useState } from 'react'
// @ts-ignore
import ItemsCarousel from 'react-items-carousel'
import cn from 'classnames'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { IFindCarFromClassified, IFindCarFromClassifiedBlock } from '@/redux/types/seo-type'
import { useResize } from '@/lib'
import { SectionHeaderDynamicH1 } from '@/ui/atoms'
import { Item } from './item'
import styles from './find_car_from_classified.module.scss'

type Props = {
  findCar: IFindCarFromClassified
}

export const FindCarFromClassified = ({ findCar }: Props) => {
  const windowSize = useResize()
  const windowSizeWindow = windowSize > 1200 ? 1200 : windowSize
  const windowCountItems = Math.floor(windowSizeWindow / 150)
  const isMobile = windowSizeWindow < 800
  const chevronWidth = windowSize > 1230 || isMobile ? 15 : 40
  const isArrow = windowSizeWindow < findCar.blocks.length * 144 && !isMobile
  const [activeItemIndex, setActiveItemIndex] = useState(0)

  return (
    <LazyLoadComponent>
      <SectionHeaderDynamicH1 type="dark" className={styles.title} text={findCar.title} />
      <section className={styles.section}>
        <div className={styles.sectionWrapper}>
          <div className={styles.carouselWrapper} style={{ padding: `0 ${chevronWidth}px` }}>
            <ItemsCarousel
              requestToChangeActive={setActiveItemIndex}
              activeItemIndex={activeItemIndex}
              numberOfCards={windowCountItems}
              gutter={20}
              infiniteLoop
              outsideChevron={isArrow}
              chevronWidth={chevronWidth}
              leftChevron={(
                <div className={cn({ [styles.disabled]: !isArrow })}>
                  <button
                    className={cn(styles.controlArrow, styles.controlPrev)}
                    aria-label="Left Button"
                    name="Left button"
                  />
                </div>
              )}
              rightChevron={(
                <div className={cn({ [styles.disabled]: !isArrow })}>
                  <button
                    className={cn(styles.controlArrow, styles.controlNext)}
                    aria-label="Right Button"
                    name="Right button"
                  />
                </div>
              )}
            >
              {findCar.blocks.map((block: IFindCarFromClassifiedBlock, index: number) => (
                <Item key={index} src={block.image} link={block.link_classified} />
              ))}
            </ItemsCarousel>
          </div>
        </div>
      </section>
    </LazyLoadComponent>
  )
}
