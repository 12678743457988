import React from 'react'
import cn from 'classnames'
import { useHistory } from 'react-router'
import { getCarTitle } from '@/lib'
import { ListItem } from './list-item'
import styles from './styles.module.scss'

interface IProps {
  isOpen?: boolean
  bids: any
  lots: any
  filterBidsId: string[]
}

export const Options: React.FC<IProps> = ({ isOpen, bids, lots, filterBidsId }) => {
  const history = useHistory()
  const handleOpenBids = () => history.push('/dashboard/my-bids')
  const handleOpenLot = (externalId: string) => () => history.push(`/vehicle/${externalId}`)

  return (
    <div className={cn(styles.wrapperComponent, { [styles.active]: isOpen })}>
      <div className={cn(styles.titleWrapper, 'mb-16')}>Current Bids</div>
      {filterBidsId.length === 0 ? (
        <div className={styles.message}>You don’t have current bids.</div>
      ) : (
        <>
          {filterBidsId.map((id: string) => {
            const bid = bids.byId[id]
            const lot = lots.byId[bid.lotId]
            const bidImg = lot.images ? lot.images[1] || '' : ''
            return (
              <ListItem key={id} imageSrc={bidImg} title={getCarTitle(lot)} onClick={handleOpenLot(lot.externalId)} />
            )
          })}

          <div className={styles.blockAction}>
            <div onClick={handleOpenBids}>View Bids</div>
          </div>
        </>
      )}
    </div>
  )
}
