import { ActionsObservable, combineEpics } from 'redux-observable'
import { Action } from 'typescript-fsa'

import { mergeMap, catchError } from 'rxjs/operators'
import { of, from } from 'rxjs'
import { userApi } from '@/api'
import { ofAction } from '@/lib/rxjs-operators'

import {
  UserResourceApiIndexOrdersUsingGETRequestAction,
  UserResourceApiUpdateOrderUsingPUTRequestAction,
} from '@/api/origin/action'
import { changeProcurement } from '@/redux/actions/procurement-action'
import { normalizeOrder } from '@/redux/normalize'

const getOrdersUser = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(UserResourceApiIndexOrdersUsingGETRequestAction.started),
  mergeMap(
    ({ payload: { userId, sort = [], sorted = false, state = [], unsorted = false, options = { size: 10 } } }) => from(userApi.indexOrdersUsingGET(userId, sort, sorted, state, unsorted, options)).pipe(
      mergeMap(({ data }: { data: any }) => of(
        UserResourceApiIndexOrdersUsingGETRequestAction.done({
          result: normalizeOrder(data),
          params: {
            userId,
            sort,
            sorted,
            state,
            unsorted,
            options,
          },
        }),
      ),
      ),
      catchError((error) => of(UserResourceApiIndexOrdersUsingGETRequestAction.failed(error.response))),
    ),
  ),
)

const updateOrderUser = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(UserResourceApiUpdateOrderUsingPUTRequestAction.started),
  mergeMap(({ payload: { orderId, userId, requestBody, options } }) => from(userApi.updateOrderUsingPUT(orderId, userId, requestBody)).pipe(
    mergeMap(({ data }: { data: any }) => [
      UserResourceApiUpdateOrderUsingPUTRequestAction.done(data),
      changeProcurement(options),
      UserResourceApiIndexOrdersUsingGETRequestAction.started({ userId }),
    ]),
    catchError((error) => of(UserResourceApiUpdateOrderUsingPUTRequestAction.failed(error.response))),
  ),
  ),
)

export const orderEpic = combineEpics(getOrdersUser, updateOrderUser)
