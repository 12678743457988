import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Icon } from '@/ui/atoms'
import { NavArrow } from '@/ui/molecules'
import { getCarImg } from '@/lib'
import { List } from './gallery/gallery-list'
import { Carousel } from './gallery/gallery-carousel'
import styles from './slider.module.scss'

type Props = {
  slidesURLs?: string[]
  className?: string
}

export const Slider: React.FC<Props> = ({ className, slidesURLs = [] }) => {
  const galleryTypeList = 'list'
  const galleryTypeCarousel = 'carousel'
  const galleryTypeNone = 'none'

  const [imgFormatArr, setImgFormatArr] = useState<any>([])
  const [imgFormatObj, setImgFormatObj] = useState<any>({})

  useEffect(() => {
    const images = getCarImg(slidesURLs)
    if (Array.isArray(images)) {
      setImgFormatArr(images)
    } else {
      setImgFormatObj(images)
    }
  }, [slidesURLs])

  const imgObjLength =
    imgFormatObj && Object.keys(imgFormatObj).every((i) => imgFormatObj[i].length > 1)
      ? Object.keys(imgFormatObj).map((item) => imgFormatObj[item].length)
      : [1]
  const numberOfSlides: number = imgFormatArr && imgFormatArr.length > 0 ? imgFormatArr.length : imgObjLength[0]
  const defaultActiveSlide: number = 0

  const [activeSlide, setActiveSlide] = useState(defaultActiveSlide)
  const [typeOfGalleryInUse, setTypeOfGalleryInUse] = useState(galleryTypeNone)

  useEffect(() => {
    setTypeOfGalleryInUse(determineTypeOfGalleryInUse(numberOfSlides))
  }, [numberOfSlides, imgFormatArr.length, imgObjLength])

  const parseRequiredGallery = (): React.ReactNode | null => {
    switch (typeOfGalleryInUse) {
      case galleryTypeList:
        return (
          <List
            slidesURLs={
              imgFormatArr.length > 0 ? imgFormatArr : Object.keys(imgFormatObj).length > 0 ? imgFormatObj : undefined
            }
            activeSlide={activeSlide}
            slideSelectionHandler={handleSlideSelection}
          />
        )
      case galleryTypeCarousel:
        return (
          <Carousel
            slidesURLs={
              imgFormatArr.length > 0 ? imgFormatArr : Object.keys(imgFormatObj).length > 0 ? imgFormatObj : undefined
            }
            activeSlide={activeSlide}
            imgObjLength={imgObjLength[0]}
            slideSelectionHandler={handleSlideSelection}
          />
        )
      case galleryTypeNone:
        return null
      default:
        return null
    }
  }

  const handleSlideSelection = (clickedSlide: number) => {
    if (clickedSlide !== activeSlide) setActiveSlide(clickedSlide)
  }

  const handleBackwardImageArrowClick = (activeSlide: number) => {
    const nextSlideToShow = activeSlide - 1 >= 0 ? activeSlide - 1 : numberOfSlides - 1
    setActiveSlide(nextSlideToShow)
  }

  const handleForwardImageArrowClick = (activeSlide: number) => {
    const nextSlideToShow = activeSlide + 1 < numberOfSlides ? activeSlide + 1 : 0
    setActiveSlide(nextSlideToShow)
  }

  return (
    <div className={cn(className)}>
      <div className={styles.activeImageContainer}>
        {isThereSlides() ? (
          <div className={styles.activeImageWrapper}>
            <LazyLoadImage className={styles.activeImage} src={getSlideURL(activeSlide)} alt="img" />
            {numberOfSlides > 1 && (
              <NavArrow
                wrapperClassName={styles.wrapperArrow}
                arrowClassName={styles.arrow}
                type="backward"
                onClick={() => handleBackwardImageArrowClick(activeSlide)}
              />
            )}
            {numberOfSlides > 1 && (
              <NavArrow
                wrapperClassName={cn(styles.wrapperArrow, styles.wrapperRightArrow)}
                arrowClassName={styles.arrow}
                type="forward"
                onClick={() => handleForwardImageArrowClick(activeSlide)}
              />
            )}
          </div>
        ) : (
          <Icon name="image-placeholder" className={styles.activeImage} />
        )}
      </div>
      <div className={styles.carouselWrapper}>{parseRequiredGallery()}</div>
    </div>
  )

  function getSlideURL(slideNum: number): string {
    return imgFormatArr.length > 0
      ? imgFormatArr[slideNum]
      : !imgFormatObj
        ? ''
        : Boolean(imgFormatObj[390])
          ? imgFormatObj[390][slideNum]
          : Boolean(imgFormatObj[320])
            ? imgFormatObj[320][slideNum]
            : Boolean(imgFormatObj[50])
              ? imgFormatObj[50][slideNum]
              : ''
  }

  function determineTypeOfGalleryInUse(numberOfSlides: number): string {
    if (numberOfSlides > 1 && numberOfSlides < 5) return galleryTypeList
    else if (numberOfSlides >= 5) return galleryTypeCarousel
    else return galleryTypeNone
  }

  function isThereSlides(): boolean {
    return !!numberOfSlides
  }
}
