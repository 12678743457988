import React, { ReactNode } from 'react'
import cn from 'classnames'
import { CustomizedTooltips, Icon } from '@/ui/atoms'

import styles from './info-prompt.module.scss'

interface Props {
  className?: string
  iconClassName?: string
  iconSize?: number
  promptAlign?: 'left'
  title: string | ReactNode
}

export const InfoPrompt: React.FC<Props> = ({ title, className, iconClassName, iconSize = 16 }) => (
  <div className={cn(styles.root, className)}>
    <CustomizedTooltips title={title}>
      <Icon
        name="icon-card-info"
        width={`${iconSize}px`}
        height={`${iconSize}px`}
        className={cn(styles.icon, iconClassName)}
      />
    </CustomizedTooltips>
  </div>
)
