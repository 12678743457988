/* eslint-disable */
// tslint:disable
/**
 * easyimport API
 * easyimport API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import qs from 'query-string'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface Address
 */
export interface Address {
  /**
   *
   * @type {string}
   * @memberof Address
   */
  address?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  address2?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  city?: string
  /**
   *
   * @type {number}
   * @memberof Address
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof Address
   */
  state?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  zipCode?: string
}
/**
 *
 * @export
 * @interface AddressDto
 */
export interface AddressDto {
  /**
   *
   * @type {string}
   * @memberof AddressDto
   */
  address?: string
  /**
   *
   * @type {string}
   * @memberof AddressDto
   */
  address2?: string
  /**
   *
   * @type {string}
   * @memberof AddressDto
   */
  city?: string
  /**
   *
   * @type {number}
   * @memberof AddressDto
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof AddressDto
   */
  state?: string
  /**
   *
   * @type {string}
   * @memberof AddressDto
   */
  zipCode?: string
}
/**
 *
 * @export
 * @interface AddressUpdateRequest
 */
export interface AddressUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof AddressUpdateRequest
   */
  address?: string
  /**
   *
   * @type {string}
   * @memberof AddressUpdateRequest
   */
  address2?: string
  /**
   *
   * @type {string}
   * @memberof AddressUpdateRequest
   */
  city?: string
  /**
   *
   * @type {string}
   * @memberof AddressUpdateRequest
   */
  state?: string
  /**
   *
   * @type {string}
   * @memberof AddressUpdateRequest
   */
  zipCode?: string
}
/**
 *
 * @export
 * @interface BidCreateRequest
 */
export interface BidCreateRequest {
  /**
   *
   * @type {boolean}
   * @memberof BidCreateRequest
   */
  auctionSheetTranslationRequested?: boolean
  /**
   *
   * @type {number}
   * @memberof BidCreateRequest
   */
  lotId?: number
}
/**
 *
 * @export
 * @interface BidDto
 */
export interface BidDto {
  /**
   *
   * @type {number}
   * @memberof BidDto
   */
  agentsFee?: number
  /**
   *
   * @type {boolean}
   * @memberof BidDto
   */
  auctionSheetTranslationRequested?: boolean
  /**
   *
   * @type {number}
   * @memberof BidDto
   */
  biddingPrice?: number
  /**
   *
   * @type {number}
   * @memberof BidDto
   */
  depositAmount?: number
  /**
   *
   * @type {number}
   * @memberof BidDto
   */
  depositAmountContingency?: number
  /**
   *
   * @type {number}
   * @memberof BidDto
   */
  estimatedCost?: number
  /**
   *
   * @type {number}
   * @memberof BidDto
   */
  id?: number
  /**
   *
   * @type {LotDto}
   * @memberof BidDto
   */
  lot?: LotDto
  /**
   *
   * @type {number}
   * @memberof BidDto
   */
  priority?: number
  /**
   *
   * @type {number}
   * @memberof BidDto
   */
  serviceFee?: number
  /**
   *
   * @type {number}
   * @memberof BidDto
   */
  shippingCost?: number
  /**
   *
   * @type {string}
   * @memberof BidDto
   */
  state?: BidDtoStateEnum
  /**
   *
   * @type {string}
   * @memberof BidDto
   */
  stateChangedDate?: string
  /**
   *
   * @type {number}
   * @memberof BidDto
   */
  userId?: number
}

/**
 * @export
 * @enum {string}
 */
export enum BidDtoStateEnum {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  OUTBID = 'OUTBID',
  CANCELED = 'CANCELED',
  WON = 'WON',
}

/**
 *
 * @export
 * @interface BidUpdateManagerRequest
 */
export interface BidUpdateManagerRequest {
  /**
   *
   * @type {string}
   * @memberof BidUpdateManagerRequest
   */
  state: BidUpdateManagerRequestStateEnum
  /**
   *
   * @type {number}
   * @memberof BidUpdateManagerRequest
   */
  biddingPrice: number
  /**
   *
   * @type {number}
   * @memberof BidUpdateManagerRequest
   */
  priority: number
}

/**
 * @export
 * @enum {string}
 */
export enum BidUpdateManagerRequestStateEnum {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  OUTBID = 'OUTBID',
  CANCELED = 'CANCELED',
  WON = 'WON',
}

/**
 *
 * @export
 * @interface BidUpdateUserRequest
 */
export interface BidUpdateUserRequest {
  /**
   *
   * @type {number}
   * @memberof BidUpdateUserRequest
   */
  biddingPrice: number
  /**
   *
   * @type {number}
   * @memberof BidUpdateUserRequest
   */
  priority: number
  /**
   *
   * @type {number}
   * @memberof BidUpdateUserRequest
   */
  depositAmount?: number
}
/**
 *
 * @export
 * @interface BiddingFlowCreateRequest
 */
export interface BiddingFlowCreateRequest {
  /**
   *
   * @type {string}
   * @memberof BiddingFlowCreateRequest
   */
  externalId: string
}
/**
 *
 * @export
 * @interface BiddingFlowDto
 */
export interface BiddingFlowDto {
  /**
   *
   * @type {number}
   * @memberof BiddingFlowDto
   */
  id?: number
  /**
   *
   * @type {number}
   * @memberof BiddingFlowDto
   */
  lotId?: number
  /**
   *
   * @type {number}
   * @memberof BiddingFlowDto
   */
  userId?: number
}
/**
 *
 * @export
 * @interface BidsReportRowDto
 */
export interface BidsReportRowDto {
  /**
   *
   * @type {string}
   * @memberof BidsReportRowDto
   */
  auction?: string
  /**
   *
   * @type {string}
   * @memberof BidsReportRowDto
   */
  auctionDate?: string
  /**
   *
   * @type {object}
   * @memberof BidsReportRowDto
   */
  bids?: object
  /**
   *
   * @type {number}
   * @memberof BidsReportRowDto
   */
  bidsQty?: number
  /**
   *
   * @type {string}
   * @memberof BidsReportRowDto
   */
  condition?: string
  /**
   *
   * @type {number}
   * @memberof BidsReportRowDto
   */
  highestBid?: number
  /**
   *
   * @type {string}
   * @memberof BidsReportRowDto
   */
  image?: string
  /**
   *
   * @type {string}
   * @memberof BidsReportRowDto
   */
  lotExternalId?: string
  /**
   *
   * @type {number}
   * @memberof BidsReportRowDto
   */
  lotId?: number
  /**
   *
   * @type {number}
   * @memberof BidsReportRowDto
   */
  mileage?: number
  /**
   *
   * @type {string}
   * @memberof BidsReportRowDto
   */
  modification?: string
  /**
   *
   * @type {number}
   * @memberof BidsReportRowDto
   */
  price?: number
  /**
   *
   * @type {string}
   * @memberof BidsReportRowDto
   */
  vehicle?: string
}
/**
 *
 * @export
 * @interface CardDTO
 */
export interface CardDTO {
  /**
   *
   * @type {string}
   * @memberof CardDTO
   */
  bankName?: string
  /**
   *
   * @type {string}
   * @memberof CardDTO
   */
  expirationDate?: string
  /**
   *
   * @type {number}
   * @memberof CardDTO
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof CardDTO
   */
  number?: string
  /**
   *
   * @type {string}
   * @memberof CardDTO
   */
  ownerName?: string
  /**
   *
   * @type {string}
   * @memberof CardDTO
   */
  stripeToken?: string
}
/**
 *
 * @export
 * @interface CheckoutSessionDTO
 */
export interface CheckoutSessionDTO {
  /**
   *
   * @type {number}
   * @memberof CheckoutSessionDTO
   */
  amount: number
}
/**
 *
 * @export
 * @interface DocumentationStateChangeRecordDto
 */
export interface DocumentationStateChangeRecordDto {
  /**
   *
   * @type {string}
   * @memberof DocumentationStateChangeRecordDto
   */
  dateStateChanged?: string
  /**
   *
   * @type {number}
   * @memberof DocumentationStateChangeRecordDto
   */
  id?: number
  /**
   *
   * @type {number}
   * @memberof DocumentationStateChangeRecordDto
   */
  stateToId?: number
  /**
   *
   * @type {string}
   * @memberof DocumentationStateChangeRecordDto
   */
  uuid?: string
}
/**
 *
 * @export
 * @interface DocumentationStateDto
 */
export interface DocumentationStateDto {
  /**
   *
   * @type {number}
   * @memberof DocumentationStateDto
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof DocumentationStateDto
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof DocumentationStateDto
   */
  orderNumber?: number
  /**
   *
   * @type {string}
   * @memberof DocumentationStateDto
   */
  uuid?: string
}
/**
 *
 * @export
 * @interface FilterDto
 */
export interface FilterDto {
  /**
   *
   * @type {string}
   * @memberof FilterDto
   */
  additionalInfo?: string
  /**
   *
   * @type {string}
   * @memberof FilterDto
   */
  externalId?: string
  /**
   *
   * @type {number}
   * @memberof FilterDto
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof FilterDto
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof FilterDto
   */
  parentExternalId?: string
  /**
   *
   * @type {string}
   * @memberof FilterDto
   */
  platform?: string
  /**
   *
   * @type {string}
   * @memberof FilterDto
   */
  type?: string
}
/**
 *
 * @export
 * @interface JWTToken
 */
export interface JWTToken {
  /**
   *
   * @type {string}
   * @memberof JWTToken
   */
  id_token?: string
}
/**
 *
 * @export
 * @interface KeyAndPasswordVM
 */
export interface KeyAndPasswordVM {
  /**
   *
   * @type {string}
   * @memberof KeyAndPasswordVM
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof KeyAndPasswordVM
   */
  newPassword?: string
}
/**
 *
 * @export
 * @interface LoginVM
 */
export interface LoginVM {
  /**
   *
   * @type {string}
   * @memberof LoginVM
   */
  password: string
  /**
   *
   * @type {boolean}
   * @memberof LoginVM
   */
  [`remember-me`]?: boolean
  /**
   *
   * @type {string}
   * @memberof LoginVM
   */
  username: string
}
/**
 *
 * @export
 * @interface LotDto
 */
export interface LotDto {
  /**
   *
   * @type {string}
   * @memberof LotDto
   */
  auctionCountry?: string
  /**
   *
   * @type {string}
   * @memberof LotDto
   */
  auctionSheetUrl?: string
  /**
   *
   * @type {string}
   * @memberof LotDto
   */
  auctionDate?: string
  /**
   *
   * @type {string}
   * @memberof LotDto
   */
  auctionName?: string
  /**
   *
   * @type {number}
   * @memberof LotDto
   */
  averagePrice?: number
  /**
   *
   * @type {number}
   * @memberof LotDto
   */
  averagePriceOriginal?: number
  /**
   *
   * @type {string}
   * @memberof LotDto
   */
  chassis?: string
  /**
   *
   * @type {string}
   * @memberof LotDto
   */
  color?: string
  /**
   *
   * @type {string}
   * @memberof LotDto
   */
  colorOriginal?: string
  /**
   *
   * @type {string}
   * @memberof LotDto
   */
  currency?: LotDtoCurrencyEnum
  /**
   *
   * @type {string}
   * @memberof LotDto
   */
  driveWheel?: string
  /**
   *
   * @type {number}
   * @memberof LotDto
   */
  engineDisplacementCc?: number
  /**
   *
   * @type {string}
   * @memberof LotDto
   */
  exteriorRate?: string
  /**
   *
   * @type {string}
   * @memberof LotDto
   */
  externalId?: string
  /**
   *
   * @type {number}
   * @memberof LotDto
   */
  finalPrice?: number
  /**
   *
   * @type {number}
   * @memberof LotDto
   */
  finalPriceOriginal?: number
  /**
   *
   * @type {number}
   * @memberof LotDto
   */
  id?: number
  /**
   *
   * @type {Array<string>}
   * @memberof LotDto
   */
  images?: Array<string>
  /**
   *
   * @type {string}
   * @memberof LotDto
   */
  inspection?: string
  /**
   *
   * @type {string}
   * @memberof LotDto
   */
  interiorRate?: string
  /**
   *
   * @type {string}
   * @memberof LotDto
   */
  make?: string
  /**
   *
   * @type {number}
   * @memberof LotDto
   */
  mileage?: number
  /**
   *
   * @type {string}
   * @memberof LotDto
   */
  mileageUnit?: LotDtoMileageUnitEnum
  /**
   *
   * @type {string}
   * @memberof LotDto
   */
  model?: string
  /**
   *
   * @type {string}
   * @memberof LotDto
   */
  modification?: string
  /**
   *
   * @type {string}
   * @memberof LotDto
   */
  number?: string
  /**
   *
   * @type {OptionsDTO}
   * @memberof LotDto
   */
  options?: OptionsDTO
  /**
   *
   * @type {string}
   * @memberof LotDto
   */
  originalCurrency?: LotDtoOriginalCurrencyEnum
  /**
   *
   * @type {number}
   * @memberof LotDto
   */
  platformId?: number
  /**
   *
   * @type {string}
   * @memberof LotDto
   */
  enginePower?: string
  /**
   *
   * @type {number}
   * @memberof LotDto
   */
  price?: number
  /**
   *
   * @type {Array<number>}
   * @memberof LotDto
   */
  priceHistory?: Array<number>
  /**
   *
   * @type {number}
   * @memberof LotDto
   */
  priceOriginal?: number
  /**
   *
   * @type {Array<number>}
   * @memberof LotDto
   */
  priceOriginalHistory?: Array<number>
  /**
   *
   * @type {string}
   * @memberof LotDto
   */
  rate?: string
  /**
   *
   * @type {string}
   * @memberof LotDto
   */
  serialNumber?: string
  /**
   *
   * @type {number}
   * @memberof LotDto
   */
  stateId?: number
  /**
   *
   * @type {string}
   * @memberof LotDto
   */
  transmission?: string
  /**
   *
   * @type {string}
   * @memberof LotDto
   */
  transmissionType?: string
  /**
   *
   * @type {number}
   * @memberof LotDto
   */
  year?: number
}

/**
 * @export
 * @enum {string}
 */
export enum LotDtoCurrencyEnum {
  USD = 'USD',
  JPY = 'JPY',
  EUR = 'EUR',
}
/**
 * @export
 * @enum {string}
 */
export enum LotDtoMileageUnitEnum {
  MI = 'MI',
  KM = 'KM',
}
/**
 * @export
 * @enum {string}
 */
export enum LotDtoOriginalCurrencyEnum {
  USD = 'USD',
  JPY = 'JPY',
  EUR = 'EUR',
}

/**
 *
 * @export
 * @interface LotStateDto
 */
export interface LotStateDto {
  /**
   *
   * @type {number}
   * @memberof LotStateDto
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof LotStateDto
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof LotStateDto
   */
  uuid?: string
}
/**
 *
 * @export
 * @interface ManagedUserVM
 */
export interface ManagedUserVM {
  /**
   *
   * @type {number}
   * @memberof ManagedUserVM
   */
  accountBalance?: number
  /**
   *
   * @type {boolean}
   * @memberof ManagedUserVM
   */
  activated?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof ManagedUserVM
   */
  authorities?: Array<string>
  /**
   *
   * @type {AddressDto}
   * @memberof ManagedUserVM
   */
  billingAddress?: AddressDto
  /**
   *
   * @type {string}
   * @memberof ManagedUserVM
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof ManagedUserVM
   */
  username?: string
  /**
   *
   * @type {boolean}
   * @memberof ManagedUserVM
   */
  [`remember-me`]?: boolean
  /**
   *
   * @type {string}
   * @memberof ManagedUserVM
   */
  firstName?: string
  /**
   *
   * @type {number}
   * @memberof ManagedUserVM
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof ManagedUserVM
   */
  langKey?: string
  /**
   *
   * @type {string}
   * @memberof ManagedUserVM
   */
  lastName?: string
  /**
   *
   * @type {AddressDto}
   * @memberof ManagedUserVM
   */
  mailingAddress?: AddressDto
  /**
   *
   * @type {boolean}
   * @memberof ManagedUserVM
   */
  newsSubscription?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ManagedUserVM
   */
  offerSubscription?: boolean
  /**
   *
   * @type {string}
   * @memberof ManagedUserVM
   */
  password?: string
  /**
   *
   * @type {string}
   * @memberof ManagedUserVM
   */
  phone?: string
  /**
   *
   * @type {AddressDto}
   * @memberof ManagedUserVM
   */
  shippingAddress?: AddressDto
}
/**
 *
 * @export
 * @interface ModelFile
 */
export interface ModelFile {
  /**
   *
   * @type {boolean}
   * @memberof ModelFile
   */
  absolute?: boolean
  /**
   *
   * @type {any}
   * @memberof ModelFile
   */
  absoluteFile?: any
  /**
   *
   * @type {string}
   * @memberof ModelFile
   */
  absolutePath?: string
  /**
   *
   * @type {any}
   * @memberof ModelFile
   */
  canonicalFile?: any
  /**
   *
   * @type {string}
   * @memberof ModelFile
   */
  canonicalPath?: string
  /**
   *
   * @type {boolean}
   * @memberof ModelFile
   */
  directory?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ModelFile
   */
  file?: boolean
  /**
   *
   * @type {number}
   * @memberof ModelFile
   */
  freeSpace?: number
  /**
   *
   * @type {boolean}
   * @memberof ModelFile
   */
  hidden?: boolean
  /**
   *
   * @type {string}
   * @memberof ModelFile
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof ModelFile
   */
  parent?: string
  /**
   *
   * @type {any}
   * @memberof ModelFile
   */
  parentFile?: any
  /**
   *
   * @type {string}
   * @memberof ModelFile
   */
  path?: string
  /**
   *
   * @type {number}
   * @memberof ModelFile
   */
  totalSpace?: number
  /**
   *
   * @type {number}
   * @memberof ModelFile
   */
  usableSpace?: number
}
/**
 *
 * @export
 * @interface NotificationDTO
 */
export interface NotificationDTO {
  /**
   *
   * @type {string}
   * @memberof NotificationDTO
   */
  date?: string
  /**
   *
   * @type {number}
   * @memberof NotificationDTO
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof NotificationDTO
   */
  message?: string
  /**
   *
   * @type {string}
   * @memberof NotificationDTO
   */
  params?: string
  /**
   *
   * @type {string}
   * @memberof NotificationDTO
   */
  type?: NotificationDTOTypeEnum
}

/**
 * @export
 * @enum {string}
 */
export enum NotificationDTOTypeEnum {
  WATCHCHANGED = 'WATCH_CHANGED',
  BIDCHANGED = 'BID_CHANGED',
  ORDERCHANGED = 'ORDER_CHANGED',
  CHATMESSAGE = 'CHAT_MESSAGE',
}

/**
 *
 * @export
 * @interface OptionsDTO
 */
export interface OptionsDTO {
  /**
   *
   * @type {boolean}
   * @memberof OptionsDTO
   */
  hasAirConditioning?: boolean
  /**
   *
   * @type {boolean}
   * @memberof OptionsDTO
   */
  hasAirbag?: boolean
  /**
   *
   * @type {boolean}
   * @memberof OptionsDTO
   */
  hasAlloyWheels?: boolean
  /**
   *
   * @type {boolean}
   * @memberof OptionsDTO
   */
  hasClimateControl?: boolean
  /**
   *
   * @type {boolean}
   * @memberof OptionsDTO
   */
  hasHatch?: boolean
  /**
   *
   * @type {boolean}
   * @memberof OptionsDTO
   */
  hasPowerSteering?: boolean
  /**
   *
   * @type {boolean}
   * @memberof OptionsDTO
   */
  hasPowerWindows?: boolean
  /**
   *
   * @type {boolean}
   * @memberof OptionsDTO
   */
  hasRearSpoiler?: boolean
}
/**
 *
 * @export
 * @interface OrderCreateRequest
 */
export interface OrderCreateRequest {
  /**
   *
   * @type {number}
   * @memberof OrderCreateRequest
   */
  bidId: number
  /**
   *
   * @type {number}
   * @memberof OrderCreateRequest
   */
  vehiclePrice: number
}
/**
 *
 * @export
 * @interface OrderDto
 */
export interface OrderDto {
  /**
   *
   * @type {number}
   * @memberof OrderDto
   */
  agentsFee?: number
  /**
   *
   * @type {string}
   * @memberof OrderDto
   */
  code?: string
  /**
   *
   * @type {string}
   * @memberof OrderDto
   */
  dateCreated?: string
  /**
   *
   * @type {string}
   * @memberof OrderDto
   */
  dateDocumentationStateChanged?: string
  /**
   *
   * @type {string}
   * @memberof OrderDto
   */
  dateVehicleStateChanged?: string
  /**
   *
   * @type {number}
   * @memberof OrderDto
   */
  depositAmount?: number
  /**
   *
   * @type {string}
   * @memberof OrderDto
   */
  desiredDeliveryDate?: string
  /**
   *
   * @type {Array<DocumentationStateChangeRecordDto>}
   * @memberof OrderDto
   */
  documentationStateChangeRecords?: Array<DocumentationStateChangeRecordDto>
  /**
   *
   * @type {number}
   * @memberof OrderDto
   */
  documentationStateId?: number
  /**
   *
   * @type {string}
   * @memberof OrderDto
   */
  estimatedDeliveryDate?: string
  /**
   *
   * @type {number}
   * @memberof OrderDto
   */
  id?: number
  /**
   *
   * @type {LotDto}
   * @memberof OrderDto
   */
  lot?: LotDto
  /**
   *
   * @type {number}
   * @memberof OrderDto
   */
  portId?: number
  /**
   *
   * @type {boolean}
   * @memberof OrderDto
   */
  selfPickup?: boolean
  /**
   *
   * @type {number}
   * @memberof OrderDto
   */
  serviceFee?: number
  /**
   *
   * @type {AddressDto}
   * @memberof OrderDto
   */
  shippingAddress?: AddressDto
  /**
   *
   * @type {number}
   * @memberof OrderDto
   */
  shippingCost?: number
  /**
   *
   * @type {number}
   * @memberof OrderDto
   */
  shippingInsuranceCost?: number
  /**
   *
   * @type {boolean}
   * @memberof OrderDto
   */
  shippingInsuranceRequested?: boolean
  /**
   *
   * @type {string}
   * @memberof OrderDto
   */
  state?: OrderDtoStateEnum
  /**
   *
   * @type {string}
   * @memberof OrderDto
   */
  stateChangedDate?: string
  /**
   *
   * @type {number}
   * @memberof OrderDto
   */
  totalCost?: number
  /**
   *
   * @type {string}
   * @memberof OrderDto
   */
  transportType?: OrderDtoTransportTypeEnum
  /**
   *
   * @type {number}
   * @memberof OrderDto
   */
  userId?: number
  /**
   *
   * @type {number}
   * @memberof OrderDto
   */
  vehiclePrice?: number
  /**
   *
   * @type {Array<VehicleStateChangeRecordDto>}
   * @memberof OrderDto
   */
  vehicleStateChangeRecords?: Array<VehicleStateChangeRecordDto>
  /**
   *
   * @type {number}
   * @memberof OrderDto
   */
  vehicleStateId?: number
}

/**
 * @export
 * @enum {string}
 */
export enum OrderDtoStateEnum {
  NEW = 'NEW',
  SHIPPINGINFORMATIONPROVIDED = 'SHIPPING_INFORMATION_PROVIDED',
  VALIDATED = 'VALIDATED',
  CONFIRMED = 'CONFIRMED',
  PAID = 'PAID',
  COMPLETED = 'COMPLETED',
}
/**
 * @export
 * @enum {string}
 */
export enum OrderDtoTransportTypeEnum {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

/**
 *
 * @export
 * @interface OrderUpdateManagerRequest
 */
export interface OrderUpdateManagerRequest {
  /**
   *
   * @type {string}
   * @memberof OrderUpdateManagerRequest
   */
  state: OrderUpdateManagerRequestStateEnum
  /**
   *
   * @type {number}
   * @memberof OrderUpdateManagerRequest
   */
  shippingCost: number
  /**
   *
   * @type {number}
   * @memberof OrderUpdateManagerRequest
   */
  vehicleStateId?: number
  /**
   *
   * @type {string}
   * @memberof OrderUpdateManagerRequest
   */
  dateVehicleStateChanged?: string
  /**
   *
   * @type {number}
   * @memberof OrderUpdateManagerRequest
   */
  documentationStateId?: number
  /**
   *
   * @type {string}
   * @memberof OrderUpdateManagerRequest
   */
  dateDocumentationStateChanged?: string
  /**
   *
   * @type {string}
   * @memberof OrderUpdateManagerRequest
   */
  estimatedDeliveryDate?: string
}

/**
 * @export
 * @enum {string}
 */
export enum OrderUpdateManagerRequestStateEnum {
  NEW = 'NEW',
  SHIPPINGINFORMATIONPROVIDED = 'SHIPPING_INFORMATION_PROVIDED',
  VALIDATED = 'VALIDATED',
  CONFIRMED = 'CONFIRMED',
  PAID = 'PAID',
  COMPLETED = 'COMPLETED',
}

/**
 *
 * @export
 * @interface OrderUpdateUserRequest
 */
export interface OrderUpdateUserRequest {
  /**
   *
   * @type {string}
   * @memberof OrderUpdateUserRequest
   */
  desiredDeliveryDate: string
  /**
   *
   * @type {boolean}
   * @memberof OrderUpdateUserRequest
   */
  selfPickup: boolean
  /**
   *
   * @type {string}
   * @memberof OrderUpdateUserRequest
   */
  transportType: OrderUpdateUserRequestTransportTypeEnum
  /**
   *
   * @type {AddressUpdateRequest}
   * @memberof OrderUpdateUserRequest
   */
  shippingAddress: AddressUpdateRequest
  /**
   *
   * @type {number}
   * @memberof OrderUpdateUserRequest
   */
  portId?: number
  /**
   *
   * @type {boolean}
   * @memberof OrderUpdateUserRequest
   */
  shippingInsuranceRequested: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum OrderUpdateUserRequestTransportTypeEnum {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

/**
 *
 * @export
 * @interface PageOfLotDto
 */
export interface PageOfLotDto {
  /**
   *
   * @type {Array<LotDto>}
   * @memberof PageOfLotDto
   */
  content?: Array<LotDto>
  /**
   *
   * @type {boolean}
   * @memberof PageOfLotDto
   */
  empty?: boolean
  /**
   *
   * @type {boolean}
   * @memberof PageOfLotDto
   */
  first?: boolean
  /**
   *
   * @type {boolean}
   * @memberof PageOfLotDto
   */
  last?: boolean
  /**
   *
   * @type {number}
   * @memberof PageOfLotDto
   */
  number?: number
  /**
   *
   * @type {number}
   * @memberof PageOfLotDto
   */
  numberOfElements?: number
  /**
   *
   * @type {number}
   * @memberof PageOfLotDto
   */
  size?: number
  /**
   *
   * @type {Sort}
   * @memberof PageOfLotDto
   */
  sort?: Sort
  /**
   *
   * @type {number}
   * @memberof PageOfLotDto
   */
  totalElements?: number
  /**
   *
   * @type {number}
   * @memberof PageOfLotDto
   */
  totalPages?: number
}
/**
 *
 * @export
 * @interface PasswordChangeDTO
 */
export interface PasswordChangeDTO {
  /**
   *
   * @type {string}
   * @memberof PasswordChangeDTO
   */
  currentPassword?: string
  /**
   *
   * @type {string}
   * @memberof PasswordChangeDTO
   */
  newPassword?: string
}
/**
 *
 * @export
 * @interface PortDto
 */
export interface PortDto {
  /**
   *
   * @type {number}
   * @memberof PortDto
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof PortDto
   */
  name?: string
}
/**
 *
 * @export
 * @interface Resource
 */
export interface Resource {
  /**
   *
   * @type {string}
   * @memberof Resource
   */
  description?: string
  /**
   *
   * @type {any}
   * @memberof Resource
   */
  file?: any
  /**
   *
   * @type {string}
   * @memberof Resource
   */
  filename?: string
  /**
   *
   * @type {object}
   * @memberof Resource
   */
  inputStream?: object
  /**
   *
   * @type {boolean}
   * @memberof Resource
   */
  open?: boolean
  /**
   *
   * @type {boolean}
   * @memberof Resource
   */
  readable?: boolean
  /**
   *
   * @type {string}
   * @memberof Resource
   */
  uri?: string
  /**
   *
   * @type {URL}
   * @memberof Resource
   */
  url?: URL
}
/**
 *
 * @export
 * @interface SearchFilterDTO
 */
export interface SearchFilterDTO {
  /**
   *
   * @type {string}
   * @memberof SearchFilterDTO
   */
  filterName?: string
  /**
   *
   * @type {number}
   * @memberof SearchFilterDTO
   */
  id?: number
  /**
   *
   * @type {number}
   * @memberof SearchFilterDTO
   */
  searchHistoryId?: number
  /**
   *
   * @type {string}
   * @memberof SearchFilterDTO
   */
  value?: string
}
/**
 *
 * @export
 * @interface SearchHistoryDTO
 */
export interface SearchHistoryDTO {
  /**
   *
   * @type {string}
   * @memberof SearchHistoryDTO
   */
  date?: string
  /**
   *
   * @type {number}
   * @memberof SearchHistoryDTO
   */
  id?: number
}
/**
 *
 * @export
 * @interface Sort
 */
export interface Sort {
  /**
   *
   * @type {boolean}
   * @memberof Sort
   */
  empty?: boolean
  /**
   *
   * @type {boolean}
   * @memberof Sort
   */
  sorted?: boolean
  /**
   *
   * @type {boolean}
   * @memberof Sort
   */
  unsorted?: boolean
}
/**
 *
 * @export
 * @interface URI
 */
export interface URI {
  /**
   *
   * @type {boolean}
   * @memberof URI
   */
  absolute?: boolean
  /**
   *
   * @type {string}
   * @memberof URI
   */
  authority?: string
  /**
   *
   * @type {string}
   * @memberof URI
   */
  fragment?: string
  /**
   *
   * @type {string}
   * @memberof URI
   */
  host?: string
  /**
   *
   * @type {boolean}
   * @memberof URI
   */
  opaque?: boolean
  /**
   *
   * @type {string}
   * @memberof URI
   */
  path?: string
  /**
   *
   * @type {number}
   * @memberof URI
   */
  port?: number
  /**
   *
   * @type {string}
   * @memberof URI
   */
  query?: string
  /**
   *
   * @type {string}
   * @memberof URI
   */
  rawAuthority?: string
  /**
   *
   * @type {string}
   * @memberof URI
   */
  rawFragment?: string
  /**
   *
   * @type {string}
   * @memberof URI
   */
  rawPath?: string
  /**
   *
   * @type {string}
   * @memberof URI
   */
  rawQuery?: string
  /**
   *
   * @type {string}
   * @memberof URI
   */
  rawSchemeSpecificPart?: string
  /**
   *
   * @type {string}
   * @memberof URI
   */
  rawUserInfo?: string
  /**
   *
   * @type {string}
   * @memberof URI
   */
  scheme?: string
  /**
   *
   * @type {string}
   * @memberof URI
   */
  schemeSpecificPart?: string
  /**
   *
   * @type {string}
   * @memberof URI
   */
  userInfo?: string
}
/**
 *
 * @export
 * @interface URL
 */
export interface URL {
  /**
   *
   * @type {string}
   * @memberof URL
   */
  authority?: string
  /**
   *
   * @type {object}
   * @memberof URL
   */
  content?: object
  /**
   *
   * @type {number}
   * @memberof URL
   */
  defaultPort?: number
  /**
   *
   * @type {string}
   * @memberof URL
   */
  file?: string
  /**
   *
   * @type {string}
   * @memberof URL
   */
  host?: string
  /**
   *
   * @type {string}
   * @memberof URL
   */
  path?: string
  /**
   *
   * @type {number}
   * @memberof URL
   */
  port?: number
  /**
   *
   * @type {string}
   * @memberof URL
   */
  protocol?: string
  /**
   *
   * @type {string}
   * @memberof URL
   */
  query?: string
  /**
   *
   * @type {string}
   * @memberof URL
   */
  number?: string
  /**
   *
   * @type {string}
   * @memberof URL
   */
  userInfo?: string
}
/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {number}
   * @memberof User
   */
  accountBalance?: number
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  activated: boolean
  /**
   *
   * @type {Address}
   * @memberof User
   */
  billingAddress?: Address
  /**
   *
   * @type {string}
   * @memberof User
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof User
   */
  firstName?: string
  /**
   *
   * @type {number}
   * @memberof User
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof User
   */
  langKey?: string
  /**
   *
   * @type {string}
   * @memberof User
   */
  lastName?: string
  /**
   *
   * @type {string}
   * @memberof User
   */
  login: string
  /**
   *
   * @type {Address}
   * @memberof User
   */
  mailingAddress?: Address
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  newsSubscription?: boolean
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  offerSubscription?: boolean
  /**
   *
   * @type {string}
   * @memberof User
   */
  phone?: string
  /**
   *
   * @type {string}
   * @memberof User
   */
  resetDate?: string
  /**
   *
   * @type {Address}
   * @memberof User
   */
  shippingAddress?: Address
}
/**
 *
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
  /**
   *
   * @type {number}
   * @memberof UserDTO
   */
  accountBalance?: number
  /**
   *
   * @type {boolean}
   * @memberof UserDTO
   */
  activated?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof UserDTO
   */
  authorities?: Array<string>
  /**
   *
   * @type {AddressDto}
   * @memberof UserDTO
   */
  billingAddress?: AddressDto
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  firstName?: string
  /**
   *
   * @type {number}
   * @memberof UserDTO
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  langKey?: string
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  lastName?: string
  /**
   *
   * @type {AddressDto}
   * @memberof UserDTO
   */
  mailingAddress?: AddressDto
  /**
   *
   * @type {boolean}
   * @memberof UserDTO
   */
  newsSubscription?: boolean
  /**
   *
   * @type {boolean}
   * @memberof UserDTO
   */
  offerSubscription?: boolean
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  phone?: string
  /**
   *
   * @type {AddressDto}
   * @memberof UserDTO
   */
  shippingAddress?: AddressDto
}
/**
 *
 * @export
 * @interface VehicleStateChangeRecordDto
 */
export interface VehicleStateChangeRecordDto {
  /**
   *
   * @type {string}
   * @memberof VehicleStateChangeRecordDto
   */
  dateStateChanged?: string
  /**
   *
   * @type {number}
   * @memberof VehicleStateChangeRecordDto
   */
  id?: number
  /**
   *
   * @type {number}
   * @memberof VehicleStateChangeRecordDto
   */
  stateToId?: number
  /**
   *
   * @type {string}
   * @memberof VehicleStateChangeRecordDto
   */
  uuid?: string
}
/**
 *
 * @export
 * @interface VehicleStateDto
 */
export interface VehicleStateDto {
  /**
   *
   * @type {number}
   * @memberof VehicleStateDto
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof VehicleStateDto
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof VehicleStateDto
   */
  orderNumber?: number
  /**
   *
   * @type {string}
   * @memberof VehicleStateDto
   */
  uuid?: string
}
/**
 *
 * @export
 * @interface WalletTransactionCreateRequest
 */
export interface WalletTransactionCreateRequest {
  /**
   *
   * @type {number}
   * @memberof WalletTransactionCreateRequest
   */
  typeId: number
  /**
   *
   * @type {string}
   * @memberof WalletTransactionCreateRequest
   */
  transactionDate: string
  /**
   *
   * @type {number}
   * @memberof WalletTransactionCreateRequest
   */
  amount: number
  /**
   *
   * @type {string}
   * @memberof WalletTransactionCreateRequest
   */
  note?: string
}
/**
 *
 * @export
 * @interface WalletTransactionDto
 */
export interface WalletTransactionDto {
  /**
   *
   * @type {number}
   * @memberof WalletTransactionDto
   */
  amount?: number
  /**
   *
   * @type {string}
   * @memberof WalletTransactionDto
   */
  dateCreated?: string
  /**
   *
   * @type {number}
   * @memberof WalletTransactionDto
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof WalletTransactionDto
   */
  note?: string
  /**
   *
   * @type {string}
   * @memberof WalletTransactionDto
   */
  transactionDate?: string
  /**
   *
   * @type {number}
   * @memberof WalletTransactionDto
   */
  typeId?: number
  /**
   *
   * @type {number}
   * @memberof WalletTransactionDto
   */
  userId?: number
}
/**
 *
 * @export
 * @interface WalletTransactionTypeDto
 */
export interface WalletTransactionTypeDto {
  /**
   *
   * @type {number}
   * @memberof WalletTransactionTypeDto
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof WalletTransactionTypeDto
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof WalletTransactionTypeDto
   */
  uuid?: string
}
/**
 *
 * @export
 * @interface WalletTransactionUpdateRequest
 */
export interface WalletTransactionUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof WalletTransactionUpdateRequest
   */
  typeId: number
  /**
   *
   * @type {string}
   * @memberof WalletTransactionUpdateRequest
   */
  transactionDate: string
  /**
   *
   * @type {string}
   * @memberof WalletTransactionUpdateRequest
   */
  note?: string
}

/**
 * AccountResourceApi - axios parameter creator
 * @export
 */
export const AccountResourceApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary activateAccount
     * @param {string} key key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateAccountUsingGET(key: string, options: any = {}): RequestArgs {
      // verify required parameter 'key' is not null or undefined
      if (key === null || key === undefined) {
        throw new RequiredError(
          'key',
          'Required parameter key was null or undefined when calling activateAccountUsingGET.',
        )
      }
      const localVarPath = `/api/activate`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (key !== undefined) {
        localVarQueryParameter['key'] = key
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary changePassword
     * @param {PasswordChangeDTO} passwordChangeDto passwordChangeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePasswordUsingPOST(passwordChangeDto: PasswordChangeDTO, options: any = {}): RequestArgs {
      // verify required parameter 'passwordChangeDto' is not null or undefined
      if (passwordChangeDto === null || passwordChangeDto === undefined) {
        throw new RequiredError(
          'passwordChangeDto',
          'Required parameter passwordChangeDto was null or undefined when calling changePasswordUsingPOST.',
        )
      }
      const localVarPath = `/api/account/change-password`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof passwordChangeDto !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(passwordChangeDto !== undefined ? passwordChangeDto : {})
        : passwordChangeDto || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary finishPasswordReset
     * @param {KeyAndPasswordVM} keyAndPassword keyAndPassword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    finishPasswordResetUsingPOST(keyAndPassword: KeyAndPasswordVM, options: any = {}): RequestArgs {
      // verify required parameter 'keyAndPassword' is not null or undefined
      if (keyAndPassword === null || keyAndPassword === undefined) {
        throw new RequiredError(
          'keyAndPassword',
          'Required parameter keyAndPassword was null or undefined when calling finishPasswordResetUsingPOST.',
        )
      }
      const localVarPath = `/api/account/reset-password/finish`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof keyAndPassword !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(keyAndPassword !== undefined ? keyAndPassword : {})
        : keyAndPassword || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary getAccount
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountUsingGET(options: any = {}): RequestArgs {
      const cookieName = 'EAI-XSRF-TOKEN'
      const cookie = document.cookie.match(
        new RegExp('(?:^|; )' + cookieName.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'),
      )

      const localVarPath = `/api/account`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['X-XSRF-TOKEN'] = cookie ? cookie[1] : ''
      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary isAuthenticated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isAuthenticatedUsingGET(options: any = {}): RequestArgs {
      const localVarPath = `/api/authenticate`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary registerAccount
     * @param {ManagedUserVM} managedUserVM managedUserVM
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerAccountUsingPOST(managedUserVM: ManagedUserVM, options: any = {}): RequestArgs {
      // verify required parameter 'managedUserVM' is not null or undefined
      if (managedUserVM === null || managedUserVM === undefined) {
        throw new RequiredError(
          'managedUserVM',
          'Required parameter managedUserVM was null or undefined when calling registerAccountUsingPOST.',
        )
      }
      const localVarPath = `/api/users`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof managedUserVM !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(managedUserVM !== undefined ? managedUserVM : {})
        : managedUserVM || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary signin user
     * @param {ManagedUserVM} managedUserVM managedUserVM
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    signInUserUsingPOST(managedUserVM: ManagedUserVM, options: any = {}): RequestArgs {
      // verify required parameter 'managedUserVM' is not null or undefined
      if (managedUserVM === null || managedUserVM === undefined) {
        throw new RequiredError(
          'managedUserVM',
          'Required parameter managedUserVM was null or undefined when calling registerAccountUsingPOST.',
        )
      }
      const cookieName = 'EAI-XSRF-TOKEN'
      const cookie = document.cookie.match(
        new RegExp('(?:^|; )' + cookieName.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'),
      )
      const localVarPath = `/signin`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded'
      localVarHeaderParameter['X-XSRF-TOKEN'] = cookie ? cookie[1] : ''

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      localVarRequestOptions.data = qs.stringify(managedUserVM)

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary signin user
     * @param email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPasswordPOST(email: string, options: any = {}): RequestArgs {
      // verify required parameter 'managedUserVM' is not null or undefined
      if (email === undefined) {
        throw new RequiredError(
          'email',
          'Required parameter managedUserVM was null or undefined when calling resetPasswordPOST.',
        )
      }
      const cookieName = 'EAI-XSRF-TOKEN'
      const cookie = document.cookie.match(
        new RegExp('(?:^|; )' + cookieName.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'),
      )
      const localVarPath = `/api/accesstokens`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded'
      localVarHeaderParameter['X-XSRF-TOKEN'] = cookie ? cookie[1] : ''
      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      localVarRequestOptions.data = qs.stringify({ email: email })

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary signin user
     * @param userId
     * @param token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountActivatedUserUsingPUT(userId: number, token: string, options: any = {}): RequestArgs {
      if (userId === null || token === undefined) {
        throw new RequiredError(
          'parameter',
          'Required parameter was null or undefined when calling accountActivatedUserUsingPUT.',
        )
      }
      const cookieName = 'EAI-XSRF-TOKEN'
      const cookie = document.cookie.match(
        new RegExp('(?:^|; )' + cookieName.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'),
      )
      const localVarPath = `/api/users/${userId}/activated`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded'
      localVarHeaderParameter['X-XSRF-TOKEN'] = cookie ? cookie[1] : ''
      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      localVarRequestOptions.data = qs.stringify({ token: `${token}`, value: true })
      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary confirm password
     * @param userId
     * @param token
     * @param newPassword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmNewPasswordPUT(userId: number, token: string, newPassword: string, options: any = {}): RequestArgs {
      if (userId === null || token === undefined || newPassword === undefined) {
        throw new RequiredError(
          'parameter',
          'Required parameter was null or undefined when calling confirmNewPasswordPUT.',
        )
      }
      const cookieName = 'EAI-XSRF-TOKEN'
      const cookie = document.cookie.match(
        new RegExp('(?:^|; )' + cookieName.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'),
      )
      const localVarPath = `/api/users/${userId}/password`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded'
      localVarHeaderParameter['X-XSRF-TOKEN'] = cookie ? cookie[1] : ''
      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      localVarRequestOptions.data = qs.stringify({ token: `${token}`, value: newPassword })
      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary requestPasswordReset
     * @param {string} mail mail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestPasswordResetUsingPOST(mail: string, options: any = {}): RequestArgs {
      // verify required parameter 'mail' is not null or undefined
      if (mail === null || mail === undefined) {
        throw new RequiredError(
          'mail',
          'Required parameter mail was null or undefined when calling requestPasswordResetUsingPOST.',
        )
      }
      const localVarPath = `/api/account/reset-password/init`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof mail !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(mail !== undefined ? mail : {}) : mail || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary saveAccount
     * @param {UserDTO} userDTO userDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveAccountUsingPOST(userDTO: UserDTO, options: any = {}): RequestArgs {
      // verify required parameter 'userDTO' is not null or undefined
      if (userDTO === null || userDTO === undefined) {
        throw new RequiredError(
          'userDTO',
          'Required parameter userDTO was null or undefined when calling saveAccountUsingPOST.',
        )
      }
      const cookieName = 'EAI-XSRF-TOKEN'
      const cookie = document.cookie.match(
        new RegExp('(?:^|; )' + cookieName.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'),
      )
      const localVarPath = `/api/account`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarHeaderParameter['X-XSRF-TOKEN'] = cookie ? cookie[1] : ''
      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof userDTO !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(userDTO !== undefined ? userDTO : {})
        : userDTO || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * AccountResourceApi - functional programming interface
 * @export
 */
export const AccountResourceApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary activateAccount
     * @param {string} key key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateAccountUsingGET(
      key: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountResourceApiAxiosParamCreator(configuration).activateAccountUsingGET(key, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary changePassword
     * @param {PasswordChangeDTO} passwordChangeDto passwordChangeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePasswordUsingPOST(
      passwordChangeDto: PasswordChangeDTO,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountResourceApiAxiosParamCreator(configuration).changePasswordUsingPOST(
        passwordChangeDto,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary finishPasswordReset
     * @param {KeyAndPasswordVM} keyAndPassword keyAndPassword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    finishPasswordResetUsingPOST(
      keyAndPassword: KeyAndPasswordVM,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountResourceApiAxiosParamCreator(configuration).finishPasswordResetUsingPOST(
        keyAndPassword,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary getAccount
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountUsingGET(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDTO> {
      const localVarAxiosArgs = AccountResourceApiAxiosParamCreator(configuration).getAccountUsingGET(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary isAuthenticated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isAuthenticatedUsingGET(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = AccountResourceApiAxiosParamCreator(configuration).isAuthenticatedUsingGET(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary registerAccount
     * @param {ManagedUserVM} managedUserVM managedUserVM
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerAccountUsingPOST(
      managedUserVM: ManagedUserVM,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountResourceApiAxiosParamCreator(configuration).registerAccountUsingPOST(
        managedUserVM,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary signInUser
     * @param {ManagedUserVM} managedUserVM managedUserVM
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    signInUserUsingPOST(
      managedUserVM: ManagedUserVM,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountResourceApiAxiosParamCreator(configuration).signInUserUsingPOST(
        managedUserVM,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary signInUser
     * @param email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPasswordPOST(email: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountResourceApiAxiosParamCreator(configuration).resetPasswordPOST(email, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary account Activated
     * @param userId
     * @param token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountActivatedUserUsingPUT(
      userId: number,
      token: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountResourceApiAxiosParamCreator(configuration).accountActivatedUserUsingPUT(
        userId,
        token,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary confirmPassword
     * @param userId
     * @param token
     * @param newPassword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmNewPasswordPUT(
      userId: number,
      token: string,
      newPassword: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountResourceApiAxiosParamCreator(configuration).confirmNewPasswordPUT(
        userId,
        token,
        newPassword,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary requestPasswordReset
     * @param {string} mail mail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestPasswordResetUsingPOST(
      mail: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountResourceApiAxiosParamCreator(configuration).requestPasswordResetUsingPOST(
        mail,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary saveAccount
     * @param {UserDTO} userDTO userDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveAccountUsingPOST(
      userDTO: UserDTO,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = AccountResourceApiAxiosParamCreator(configuration).saveAccountUsingPOST(
        userDTO,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * AccountResourceApi - factory interface
 * @export
 */
export const AccountResourceApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary activateAccount
     * @param {string} key key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateAccountUsingGET(key: string, options?: any): AxiosPromise<void> {
      return AccountResourceApiFp(configuration).activateAccountUsingGET(key, options)(axios, basePath)
    },
    /**
     *
     * @summary changePassword
     * @param {PasswordChangeDTO} passwordChangeDto passwordChangeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePasswordUsingPOST(passwordChangeDto: PasswordChangeDTO, options?: any): AxiosPromise<void> {
      return AccountResourceApiFp(configuration).changePasswordUsingPOST(passwordChangeDto, options)(axios, basePath)
    },
    /**
     *
     * @summary finishPasswordReset
     * @param {KeyAndPasswordVM} keyAndPassword keyAndPassword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    finishPasswordResetUsingPOST(keyAndPassword: KeyAndPasswordVM, options?: any): AxiosPromise<void> {
      return AccountResourceApiFp(configuration).finishPasswordResetUsingPOST(keyAndPassword, options)(axios, basePath)
    },
    /**
     *
     * @summary getAccount
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountUsingGET(options?: any): AxiosPromise<UserDTO> {
      return AccountResourceApiFp(configuration).getAccountUsingGET(options)(axios, basePath)
    },
    /**
     *
     * @summary isAuthenticated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isAuthenticatedUsingGET(options?: any): AxiosPromise<string> {
      return AccountResourceApiFp(configuration).isAuthenticatedUsingGET(options)(axios, basePath)
    },
    /**
     *
     * @summary registerAccount
     * @param {ManagedUserVM} managedUserVM managedUserVM
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerAccountUsingPOST(managedUserVM: ManagedUserVM, options?: any): AxiosPromise<void> {
      return AccountResourceApiFp(configuration).registerAccountUsingPOST(managedUserVM, options)(axios, basePath)
    },
    /**
     *
     * @summary requestPasswordReset
     * @param {string} mail mail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestPasswordResetUsingPOST(mail: string, options?: any): AxiosPromise<void> {
      return AccountResourceApiFp(configuration).requestPasswordResetUsingPOST(mail, options)(axios, basePath)
    },
    /**
     *
     * @summary saveAccount
     * @param {UserDTO} userDTO userDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveAccountUsingPOST(userDTO: UserDTO, options?: any): AxiosPromise<void> {
      return AccountResourceApiFp(configuration).saveAccountUsingPOST(userDTO, options)(axios, basePath)
    },
  }
}

/**
 * AccountResourceApi - object-oriented interface
 * @export
 * @class AccountResourceApi
 * @extends {BaseAPI}
 */
export class AccountResourceApi extends BaseAPI {
  /**
   *
   * @summary activateAccount
   * @param {string} key key
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountResourceApi
   */
  public activateAccountUsingGET(key: string, options?: any) {
    return AccountResourceApiFp(this.configuration).activateAccountUsingGET(key, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary changePassword
   * @param {PasswordChangeDTO} passwordChangeDto passwordChangeDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountResourceApi
   */
  public changePasswordUsingPOST(passwordChangeDto: PasswordChangeDTO, options?: any) {
    return AccountResourceApiFp(this.configuration).changePasswordUsingPOST(passwordChangeDto, options)(
      this.axios,
      this.basePath,
    )
  }

  /**
   *
   * @summary finishPasswordReset
   * @param {KeyAndPasswordVM} keyAndPassword keyAndPassword
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountResourceApi
   */
  public finishPasswordResetUsingPOST(keyAndPassword: KeyAndPasswordVM, options?: any) {
    return AccountResourceApiFp(this.configuration).finishPasswordResetUsingPOST(keyAndPassword, options)(
      this.axios,
      this.basePath,
    )
  }

  /**
   *
   * @summary getAccount
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountResourceApi
   */
  public getAccountUsingGET(options?: any) {
    return AccountResourceApiFp(this.configuration).getAccountUsingGET(options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary isAuthenticated
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountResourceApi
   */
  public isAuthenticatedUsingGET(options?: any) {
    return AccountResourceApiFp(this.configuration).isAuthenticatedUsingGET(options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary registerAccount
   * @param {ManagedUserVM} managedUserVM managedUserVM
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountResourceApi
   */
  public registerAccountUsingPOST(managedUserVM: ManagedUserVM, options?: any) {
    return AccountResourceApiFp(this.configuration).registerAccountUsingPOST(managedUserVM, options)(
      this.axios,
      this.basePath,
    )
  }

  /**
   *
   * @summary signIn user
   * @param {ManagedUserVM} managedUserVM managedUserVM
   * @param {*} [options?] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountResourceApi
   */
  public signInUserUsingPOST(managedUserVM: ManagedUserVM, options?: any) {
    return AccountResourceApiFp(this.configuration).signInUserUsingPOST(managedUserVM, options)(
      this.axios,
      this.basePath,
    )
  }

  /**
   *
   * @summary reset Password
   * @param email
   * @param {*} [options?] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountResourceApi
   */
  public resetPasswordPOST(email: string, options?: any) {
    return AccountResourceApiFp(this.configuration).resetPasswordPOST(email, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary account Activated
   * @param userId
   * @param token
   * @param {*} [options?] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountResourceApi
   */
  public accountActivatedUserUsingPUT(userId: number, token: string, options?: any) {
    return AccountResourceApiFp(this.configuration).accountActivatedUserUsingPUT(
      userId,
      token,
      options,
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary confirm Password
   * @param userId
   * @param token
   * @param newPassword
   * @param {*} [options?] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountResourceApi
   */
  public confirmNewPasswordPUT(userId: number, token: string, newPassword: string, options?: any) {
    return AccountResourceApiFp(this.configuration).confirmNewPasswordPUT(
      userId,
      token,
      newPassword,
      options,
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary requestPasswordReset
   * @param {string} mail mail
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountResourceApi
   */
  public requestPasswordResetUsingPOST(mail: string, options?: any) {
    return AccountResourceApiFp(this.configuration).requestPasswordResetUsingPOST(mail, options)(
      this.axios,
      this.basePath,
    )
  }

  /**
   *
   * @summary saveAccount
   * @param {UserDTO} userDTO userDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountResourceApi
   */
  public saveAccountUsingPOST(userDTO: UserDTO, options?: any) {
    return AccountResourceApiFp(this.configuration).saveAccountUsingPOST(userDTO, options)(this.axios, this.basePath)
  }
}

/**
 * AvtoJpLotsFacadeControllerApi - axios parameter creator
 * @export
 */
export const AvtoJpLotsFacadeControllerApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary findAuctionLotById
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAuctionLotByIdUsingGET(id: string, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling findAuctionLotByIdUsingGET.',
        )
      }
      const localVarPath = `/api/auction-lots/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary indexAuctionLots
     * @param {string} [auction]
     * @param {string} [color]
     * @param {string} [engineDisplacementCc]
     * @param {Array<string>} [make]
     * @param {string} [mileageMi]
     * @param {Array<string>} [model]
     * @param {number} [page]
     * @param {string} [price]
     * @param {string} [priceUSD]
     * @param {number} [size]
     * @param {Array<string>} [sort]
     * @param {string} [transmissionType]
     * @param {string} [year]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexAuctionLotsUsingGET(
      auction?: string,
      color?: string,
      engineDisplacementCc?: string,
      make?: Array<string>,
      mileageMi?: string,
      model?: Array<string>,
      page?: number,
      price?: string,
      priceUSD?: string,
      size?: number,
      sort?: Array<string>,
      transmissionType?: string,
      year?: string,
      options: any = {},
    ): RequestArgs {
      const localVarPath = `/api/auction-lots`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (auction !== undefined) {
        localVarQueryParameter['auction'] = auction
      }

      if (color !== undefined) {
        localVarQueryParameter['color'] = color
      }

      if (engineDisplacementCc !== undefined) {
        localVarQueryParameter['engineDisplacementCc'] = engineDisplacementCc
      }

      if (make) {
        localVarQueryParameter['make'] = make
      }

      if (mileageMi !== undefined) {
        localVarQueryParameter['mileageMi'] = mileageMi
      }

      if (model) {
        localVarQueryParameter['model'] = model
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (price !== undefined) {
        localVarQueryParameter['price'] = price
      }

      if (priceUSD !== undefined) {
        localVarQueryParameter['priceUSD'] = priceUSD
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size
      }

      if (sort) {
        localVarQueryParameter['sort'] = sort
      }

      if (transmissionType !== undefined) {
        localVarQueryParameter['transmissionType'] = transmissionType
      }

      if (year !== undefined) {
        localVarQueryParameter['year'] = year
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary indexPassedAuctionLots
     * @param {string} markaName markaName
     * @param {string} modelName modelName
     * @param {number} year year
     * @param {string} [kuzov] kuzov
     * @param {number} [page]
     * @param {number} [size]
     * @param {Array<string>} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexPassedAuctionLotsUsingGET(
      markaName: string,
      modelName: string,
      year: number,
      kuzov?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'markaName' is not null or undefined
      if (markaName === null || markaName === undefined) {
        throw new RequiredError(
          'markaName',
          'Required parameter markaName was null or undefined when calling indexPassedAuctionLotsUsingGET.',
        )
      }
      // verify required parameter 'modelName' is not null or undefined
      if (modelName === null || modelName === undefined) {
        throw new RequiredError(
          'modelName',
          'Required parameter modelName was null or undefined when calling indexPassedAuctionLotsUsingGET.',
        )
      }
      // verify required parameter 'year' is not null or undefined
      if (year === null || year === undefined) {
        throw new RequiredError(
          'year',
          'Required parameter year was null or undefined when calling indexPassedAuctionLotsUsingGET.',
        )
      }
      const localVarPath = `/api/passed-auction-lots`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (kuzov !== undefined) {
        localVarQueryParameter['kuzov'] = kuzov
      }

      if (markaName !== undefined) {
        localVarQueryParameter['markaName'] = markaName
      }

      if (modelName !== undefined) {
        localVarQueryParameter['modelName'] = modelName
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size
      }

      if (sort) {
        localVarQueryParameter['sort'] = sort
      }

      if (year !== undefined) {
        localVarQueryParameter['year'] = year
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * AvtoJpLotsFacadeControllerApi - functional programming interface
 * @export
 */
export const AvtoJpLotsFacadeControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary findAuctionLotById
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAuctionLotByIdUsingGET(
      id: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LotDto> {
      const localVarAxiosArgs = AvtoJpLotsFacadeControllerApiAxiosParamCreator(
        configuration,
      ).findAuctionLotByIdUsingGET(id, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary indexAuctionLots
     * @param {string} [auction]
     * @param {string} [color]
     * @param {string} [engineDisplacementCc]
     * @param {Array<string>} [make]
     * @param {string} [mileageMi]
     * @param {Array<string>} [model]
     * @param {number} [page]
     * @param {string} [price]
     * @param {string} [priceUSD]
     * @param {number} [size]
     * @param {Array<string>} [sort]
     * @param {string} [transmissionType]
     * @param {string} [year]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexAuctionLotsUsingGET(
      auction?: string,
      color?: string,
      engineDisplacementCc?: string,
      make?: Array<string>,
      mileageMi?: string,
      model?: Array<string>,
      page?: number,
      price?: string,
      priceUSD?: string,
      size?: number,
      sort?: Array<string>,
      transmissionType?: string,
      year?: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LotDto> {
      const localVarAxiosArgs = AvtoJpLotsFacadeControllerApiAxiosParamCreator(configuration).indexAuctionLotsUsingGET(
        auction,
        color,
        engineDisplacementCc,
        make,
        mileageMi,
        model,
        page,
        price,
        priceUSD,
        size,
        sort,
        transmissionType,
        year,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary indexPassedAuctionLots
     * @param {string} markaName markaName
     * @param {string} modelName modelName
     * @param {number} year year
     * @param {string} [kuzov] kuzov
     * @param {number} [page]
     * @param {number} [size]
     * @param {Array<string>} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexPassedAuctionLotsUsingGET(
      markaName: string,
      modelName: string,
      year: number,
      kuzov?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LotDto>> {
      const localVarAxiosArgs = AvtoJpLotsFacadeControllerApiAxiosParamCreator(
        configuration,
      ).indexPassedAuctionLotsUsingGET(markaName, modelName, year, kuzov, page, size, sort, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * AvtoJpLotsFacadeControllerApi - factory interface
 * @export
 */
export const AvtoJpLotsFacadeControllerApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary findAuctionLotById
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAuctionLotByIdUsingGET(id: string, options?: any): AxiosPromise<LotDto> {
      return AvtoJpLotsFacadeControllerApiFp(configuration).findAuctionLotByIdUsingGET(id, options)(axios, basePath)
    },
    /**
     *
     * @summary indexAuctionLots
     * @param {string} [auction]
     * @param {string} [color]
     * @param {string} [engineDisplacementCc]
     * @param {Array<string>} [make]
     * @param {string} [mileageMi]
     * @param {Array<string>} [model]
     * @param {number} [page]
     * @param {string} [price]
     * @param {string} [priceUSD]
     * @param {number} [size]
     * @param {Array<string>} [sort]
     * @param {string} [transmissionType]
     * @param {string} [year]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexAuctionLotsUsingGET(
      auction?: string,
      color?: string,
      engineDisplacementCc?: string,
      make?: Array<string>,
      mileageMi?: string,
      model?: Array<string>,
      page?: number,
      price?: string,
      priceUSD?: string,
      size?: number,
      sort?: Array<string>,
      transmissionType?: string,
      year?: string,
      options?: any,
    ): AxiosPromise<LotDto> {
      return AvtoJpLotsFacadeControllerApiFp(configuration).indexAuctionLotsUsingGET(
        auction,
        color,
        engineDisplacementCc,
        make,
        mileageMi,
        model,
        page,
        price,
        priceUSD,
        size,
        sort,
        transmissionType,
        year,
        options,
      )(axios, basePath)
    },
    /**
     *
     * @summary indexPassedAuctionLots
     * @param {string} markaName markaName
     * @param {string} modelName modelName
     * @param {number} year year
     * @param {string} [kuzov] kuzov
     * @param {number} [page]
     * @param {number} [size]
     * @param {Array<string>} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexPassedAuctionLotsUsingGET(
      markaName: string,
      modelName: string,
      year: number,
      kuzov?: string,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<Array<LotDto>> {
      return AvtoJpLotsFacadeControllerApiFp(configuration).indexPassedAuctionLotsUsingGET(
        markaName,
        modelName,
        year,
        kuzov,
        page,
        size,
        sort,
        options,
      )(axios, basePath)
    },
  }
}

/**
 * AvtoJpLotsFacadeControllerApi - object-oriented interface
 * @export
 * @class AvtoJpLotsFacadeControllerApi
 * @extends {BaseAPI}
 */
export class AvtoJpLotsFacadeControllerApi extends BaseAPI {
  /**
   *
   * @summary findAuctionLotById
   * @param {string} id id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AvtoJpLotsFacadeControllerApi
   */
  public findAuctionLotByIdUsingGET(id: string, options?: any) {
    return AvtoJpLotsFacadeControllerApiFp(this.configuration).findAuctionLotByIdUsingGET(id, options)(
      this.axios,
      this.basePath,
    )
  }

  /**
   *
   * @summary indexAuctionLots
   * @param {string} [auction]
   * @param {string} [color]
   * @param {string} [engineDisplacementCc]
   * @param {Array<string>} [make]
   * @param {string} [mileageMi]
   * @param {Array<string>} [model]
   * @param {number} [page]
   * @param {string} [price]
   * @param {string} [priceUSD]
   * @param {number} [size]
   * @param {Array<string>} [sort]
   * @param {string} [transmissionType]
   * @param {string} [year]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AvtoJpLotsFacadeControllerApi
   */
  public indexAuctionLotsUsingGET(
    auction?: string,
    color?: string,
    engineDisplacementCc?: string,
    make?: Array<string>,
    mileageMi?: string,
    model?: Array<string>,
    page?: number,
    price?: string,
    priceUSD?: string,
    size?: number,
    sort?: Array<string>,
    transmissionType?: string,
    year?: string,
    options?: any,
  ) {
    return AvtoJpLotsFacadeControllerApiFp(this.configuration).indexAuctionLotsUsingGET(
      auction,
      color,
      engineDisplacementCc,
      make,
      mileageMi,
      model,
      page,
      price,
      priceUSD,
      size,
      sort,
      transmissionType,
      year,
      options,
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary indexPassedAuctionLots
   * @param {string} markaName markaName
   * @param {string} modelName modelName
   * @param {number} year year
   * @param {string} [kuzov] kuzov
   * @param {number} [page]
   * @param {number} [size]
   * @param {Array<string>} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AvtoJpLotsFacadeControllerApi
   */
  public indexPassedAuctionLotsUsingGET(
    markaName: string,
    modelName: string,
    year: number,
    kuzov?: string,
    page?: number,
    size?: number,
    sort?: Array<string>,
    options?: any,
  ) {
    return AvtoJpLotsFacadeControllerApiFp(this.configuration).indexPassedAuctionLotsUsingGET(
      markaName,
      modelName,
      year,
      kuzov,
      page,
      size,
      sort,
      options,
    )(this.axios, this.basePath)
  }
}

/**
 * BidsControllerApi - axios parameter creator
 * @export
 */
export const BidsControllerApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary updateBidByManager
     * @param {number} id id
     * @param {BidUpdateManagerRequest} requestBody requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBidByManagerUsingPUT(id: number, requestBody: BidUpdateManagerRequest, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling updateBidByManagerUsingPUT.',
        )
      }
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling updateBidByManagerUsingPUT.',
        )
      }
      const localVarPath = `/api/bids/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof requestBody !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * BidsControllerApi - functional programming interface
 * @export
 */
export const BidsControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary updateBidByManager
     * @param {number} id id
     * @param {BidUpdateManagerRequest} requestBody requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBidByManagerUsingPUT(
      id: number,
      requestBody: BidUpdateManagerRequest,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = BidsControllerApiAxiosParamCreator(configuration).updateBidByManagerUsingPUT(
        id,
        requestBody,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * BidsControllerApi - factory interface
 * @export
 */
export const BidsControllerApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary updateBidByManager
     * @param {number} id id
     * @param {BidUpdateManagerRequest} requestBody requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBidByManagerUsingPUT(id: number, requestBody: BidUpdateManagerRequest, options?: any): AxiosPromise<void> {
      return BidsControllerApiFp(configuration).updateBidByManagerUsingPUT(id, requestBody, options)(axios, basePath)
    },
  }
}

/**
 * BidsControllerApi - object-oriented interface
 * @export
 * @class BidsControllerApi
 * @extends {BaseAPI}
 */
export class BidsControllerApi extends BaseAPI {
  /**
   *
   * @summary updateBidByManager
   * @param {number} id id
   * @param {BidUpdateManagerRequest} requestBody requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BidsControllerApi
   */
  public updateBidByManagerUsingPUT(id: number, requestBody: BidUpdateManagerRequest, options?: any) {
    return BidsControllerApiFp(this.configuration).updateBidByManagerUsingPUT(
      id,
      requestBody,
      options,
    )(this.axios, this.basePath)
  }
}

/**
 * BidsReportRowsControllerApi - axios parameter creator
 * @export
 */
export const BidsReportRowsControllerApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary indexBidsReportRows
     * @param {'NEW' | 'ACTIVE' | 'OUTBID' | 'CANCELED' | 'WON'} bidState bidState
     * @param {number} [page]
     * @param {number} [size]
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexBidsReportRowsUsingGET(
      bidState: 'NEW' | 'ACTIVE' | 'OUTBID' | 'CANCELED' | 'WON',
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'bidState' is not null or undefined
      if (bidState === null || bidState === undefined) {
        throw new RequiredError(
          'bidState',
          'Required parameter bidState was null or undefined when calling indexBidsReportRowsUsingGET.',
        )
      }
      const localVarPath = `/api/bidsreportrows`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (bidState !== undefined) {
        localVarQueryParameter['bidState'] = bidState
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size
      }

      if (sort) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * BidsReportRowsControllerApi - functional programming interface
 * @export
 */
export const BidsReportRowsControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary indexBidsReportRows
     * @param {'NEW' | 'ACTIVE' | 'OUTBID' | 'CANCELED' | 'WON'} bidState bidState
     * @param {number} [page]
     * @param {number} [size]
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexBidsReportRowsUsingGET(
      bidState: 'NEW' | 'ACTIVE' | 'OUTBID' | 'CANCELED' | 'WON',
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BidsReportRowDto>> {
      const localVarAxiosArgs = BidsReportRowsControllerApiAxiosParamCreator(configuration).indexBidsReportRowsUsingGET(
        bidState,
        page,
        size,
        sort,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * BidsReportRowsControllerApi - factory interface
 * @export
 */
export const BidsReportRowsControllerApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary indexBidsReportRows
     * @param {'NEW' | 'ACTIVE' | 'OUTBID' | 'CANCELED' | 'WON'} bidState bidState
     * @param {number} [page]
     * @param {number} [size]
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexBidsReportRowsUsingGET(
      bidState: 'NEW' | 'ACTIVE' | 'OUTBID' | 'CANCELED' | 'WON',
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<Array<BidsReportRowDto>> {
      return BidsReportRowsControllerApiFp(configuration).indexBidsReportRowsUsingGET(
        bidState,
        page,
        size,
        sort,
        options,
      )(axios, basePath)
    },
  }
}

/**
 * BidsReportRowsControllerApi - object-oriented interface
 * @export
 * @class BidsReportRowsControllerApi
 * @extends {BaseAPI}
 */
export class BidsReportRowsControllerApi extends BaseAPI {
  /**
   *
   * @summary indexBidsReportRows
   * @param {'NEW' | 'ACTIVE' | 'OUTBID' | 'CANCELED' | 'WON'} bidState bidState
   * @param {number} [page]
   * @param {number} [size]
   * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BidsReportRowsControllerApi
   */
  public indexBidsReportRowsUsingGET(
    bidState: 'NEW' | 'ACTIVE' | 'OUTBID' | 'CANCELED' | 'WON',
    page?: number,
    size?: number,
    sort?: Array<string>,
    options?: any,
  ) {
    return BidsReportRowsControllerApiFp(this.configuration).indexBidsReportRowsUsingGET(
      bidState,
      page,
      size,
      sort,
      options,
    )(this.axios, this.basePath)
  }
}

/**
 * CardResourceApi - axios parameter creator
 * @export
 */
export const CardResourceApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary createCard
     * @param {CardDTO} cardDTO cardDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCardUsingPOST(cardDTO: CardDTO, options: any = {}): RequestArgs {
      // verify required parameter 'cardDTO' is not null or undefined
      if (cardDTO === null || cardDTO === undefined) {
        throw new RequiredError(
          'cardDTO',
          'Required parameter cardDTO was null or undefined when calling createCardUsingPOST.',
        )
      }
      const localVarPath = `/api/cards`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof cardDTO !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(cardDTO !== undefined ? cardDTO : {})
        : cardDTO || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary deleteCard
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCardUsingDELETE(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling deleteCardUsingDELETE.')
      }
      const localVarPath = `/api/cards/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary getAllCards
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCardsUsingGET(options: any = {}): RequestArgs {
      const localVarPath = `/api/cards`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary getCard
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCardUsingGET(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling getCardUsingGET.')
      }
      const localVarPath = `/api/cards/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary searchCards
     * @param {string} query query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchCardsUsingGET(query: string, options: any = {}): RequestArgs {
      // verify required parameter 'query' is not null or undefined
      if (query === null || query === undefined) {
        throw new RequiredError(
          'query',
          'Required parameter query was null or undefined when calling searchCardsUsingGET.',
        )
      }
      const localVarPath = `/api/_search/cards`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (query !== undefined) {
        localVarQueryParameter['query'] = query
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary updateCard
     * @param {CardDTO} cardDTO cardDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCardUsingPUT(cardDTO: CardDTO, options: any = {}): RequestArgs {
      // verify required parameter 'cardDTO' is not null or undefined
      if (cardDTO === null || cardDTO === undefined) {
        throw new RequiredError(
          'cardDTO',
          'Required parameter cardDTO was null or undefined when calling updateCardUsingPUT.',
        )
      }
      const localVarPath = `/api/cards`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof cardDTO !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(cardDTO !== undefined ? cardDTO : {})
        : cardDTO || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CardResourceApi - functional programming interface
 * @export
 */
export const CardResourceApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary createCard
     * @param {CardDTO} cardDTO cardDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCardUsingPOST(
      cardDTO: CardDTO,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardDTO> {
      const localVarAxiosArgs = CardResourceApiAxiosParamCreator(configuration).createCardUsingPOST(cardDTO, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary deleteCard
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCardUsingDELETE(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = CardResourceApiAxiosParamCreator(configuration).deleteCardUsingDELETE(id, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary getAllCards
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCardsUsingGET(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CardDTO>> {
      const localVarAxiosArgs = CardResourceApiAxiosParamCreator(configuration).getAllCardsUsingGET(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary getCard
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCardUsingGET(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardDTO> {
      const localVarAxiosArgs = CardResourceApiAxiosParamCreator(configuration).getCardUsingGET(id, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary searchCards
     * @param {string} query query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchCardsUsingGET(
      query: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CardDTO>> {
      const localVarAxiosArgs = CardResourceApiAxiosParamCreator(configuration).searchCardsUsingGET(query, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary updateCard
     * @param {CardDTO} cardDTO cardDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCardUsingPUT(
      cardDTO: CardDTO,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardDTO> {
      const localVarAxiosArgs = CardResourceApiAxiosParamCreator(configuration).updateCardUsingPUT(cardDTO, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * CardResourceApi - factory interface
 * @export
 */
export const CardResourceApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary createCard
     * @param {CardDTO} cardDTO cardDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCardUsingPOST(cardDTO: CardDTO, options?: any): AxiosPromise<CardDTO> {
      return CardResourceApiFp(configuration).createCardUsingPOST(cardDTO, options)(axios, basePath)
    },
    /**
     *
     * @summary deleteCard
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCardUsingDELETE(id: number, options?: any): AxiosPromise<void> {
      return CardResourceApiFp(configuration).deleteCardUsingDELETE(id, options)(axios, basePath)
    },
    /**
     *
     * @summary getAllCards
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCardsUsingGET(options?: any): AxiosPromise<Array<CardDTO>> {
      return CardResourceApiFp(configuration).getAllCardsUsingGET(options)(axios, basePath)
    },
    /**
     *
     * @summary getCard
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCardUsingGET(id: number, options?: any): AxiosPromise<CardDTO> {
      return CardResourceApiFp(configuration).getCardUsingGET(id, options)(axios, basePath)
    },
    /**
     *
     * @summary searchCards
     * @param {string} query query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchCardsUsingGET(query: string, options?: any): AxiosPromise<Array<CardDTO>> {
      return CardResourceApiFp(configuration).searchCardsUsingGET(query, options)(axios, basePath)
    },
    /**
     *
     * @summary updateCard
     * @param {CardDTO} cardDTO cardDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCardUsingPUT(cardDTO: CardDTO, options?: any): AxiosPromise<CardDTO> {
      return CardResourceApiFp(configuration).updateCardUsingPUT(cardDTO, options)(axios, basePath)
    },
  }
}

/**
 * CardResourceApi - object-oriented interface
 * @export
 * @class CardResourceApi
 * @extends {BaseAPI}
 */
export class CardResourceApi extends BaseAPI {
  /**
   *
   * @summary createCard
   * @param {CardDTO} cardDTO cardDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CardResourceApi
   */
  public createCardUsingPOST(cardDTO: CardDTO, options?: any) {
    return CardResourceApiFp(this.configuration).createCardUsingPOST(cardDTO, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary deleteCard
   * @param {number} id id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CardResourceApi
   */
  public deleteCardUsingDELETE(id: number, options?: any) {
    return CardResourceApiFp(this.configuration).deleteCardUsingDELETE(id, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary getAllCards
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CardResourceApi
   */
  public getAllCardsUsingGET(options?: any) {
    return CardResourceApiFp(this.configuration).getAllCardsUsingGET(options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary getCard
   * @param {number} id id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CardResourceApi
   */
  public getCardUsingGET(id: number, options?: any) {
    return CardResourceApiFp(this.configuration).getCardUsingGET(id, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary searchCards
   * @param {string} query query
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CardResourceApi
   */
  public searchCardsUsingGET(query: string, options?: any) {
    return CardResourceApiFp(this.configuration).searchCardsUsingGET(query, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary updateCard
   * @param {CardDTO} cardDTO cardDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CardResourceApi
   */
  public updateCardUsingPUT(cardDTO: CardDTO, options?: any) {
    return CardResourceApiFp(this.configuration).updateCardUsingPUT(cardDTO, options)(this.axios, this.basePath)
  }
}

/**
 * DashboardResourceApi - axios parameter creator
 * @export
 */
export const DashboardResourceApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary createFavorite
     * @param {string} externalId external_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFavoriteUsingPOST(externalId: string, options: any = {}): RequestArgs {
      // verify required parameter 'externalId' is not null or undefined
      if (externalId === null || externalId === undefined) {
        throw new RequiredError(
          'externalId',
          'Required parameter externalId was null or undefined when calling createFavoriteUsingPOST.',
        )
      }
      const localVarPath = `/api/dashboard/watch`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (externalId !== undefined) {
        localVarQueryParameter['external_id'] = externalId
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary deleteFavorite
     * @param {number} lotId lotId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFavoriteUsingDELETE(lotId: number, options: any = {}): RequestArgs {
      // verify required parameter 'lotId' is not null or undefined
      if (lotId === null || lotId === undefined) {
        throw new RequiredError(
          'lotId',
          'Required parameter lotId was null or undefined when calling deleteFavoriteUsingDELETE.',
        )
      }
      const localVarPath = `/api/dashboard/watch/{lotId}`.replace(`{${'lotId'}}`, encodeURIComponent(String(lotId)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary indexFavorites
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexFavoritesUsingGET(options: any = {}): RequestArgs {
      const localVarPath = `/api/dashboard/watch`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * DashboardResourceApi - functional programming interface
 * @export
 */
export const DashboardResourceApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary createFavorite
     * @param {string} externalId external_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFavoriteUsingPOST(
      externalId: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LotDto> {
      const localVarAxiosArgs = DashboardResourceApiAxiosParamCreator(configuration).createFavoriteUsingPOST(
        externalId,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary deleteFavorite
     * @param {number} lotId lotId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFavoriteUsingDELETE(
      lotId: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = DashboardResourceApiAxiosParamCreator(configuration).deleteFavoriteUsingDELETE(
        lotId,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary indexFavorites
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexFavoritesUsingGET(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
      const localVarAxiosArgs = DashboardResourceApiAxiosParamCreator(configuration).indexFavoritesUsingGET(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * DashboardResourceApi - factory interface
 * @export
 */
export const DashboardResourceApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary createFavorite
     * @param {string} externalId external_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFavoriteUsingPOST(externalId: string, options?: any): AxiosPromise<LotDto> {
      return DashboardResourceApiFp(configuration).createFavoriteUsingPOST(externalId, options)(axios, basePath)
    },
    /**
     *
     * @summary deleteFavorite
     * @param {number} lotId lotId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFavoriteUsingDELETE(lotId: number, options?: any): AxiosPromise<void> {
      return DashboardResourceApiFp(configuration).deleteFavoriteUsingDELETE(lotId, options)(axios, basePath)
    },
    /**
     *
     * @summary indexFavorites
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexFavoritesUsingGET(options?: any): AxiosPromise<object> {
      return DashboardResourceApiFp(configuration).indexFavoritesUsingGET(options)(axios, basePath)
    },
  }
}

/**
 * DashboardResourceApi - object-oriented interface
 * @export
 * @class DashboardResourceApi
 * @extends {BaseAPI}
 */
export class DashboardResourceApi extends BaseAPI {
  /**
   *
   * @summary createFavorite
   * @param {string} externalId external_id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DashboardResourceApi
   */
  public createFavoriteUsingPOST(externalId: string, options?: any) {
    return DashboardResourceApiFp(this.configuration).createFavoriteUsingPOST(externalId, options)(
      this.axios,
      this.basePath,
    )
  }

  /**
   *
   * @summary deleteFavorite
   * @param {number} lotId lotId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DashboardResourceApi
   */
  public deleteFavoriteUsingDELETE(lotId: number, options?: any) {
    return DashboardResourceApiFp(this.configuration).deleteFavoriteUsingDELETE(lotId, options)(
      this.axios,
      this.basePath,
    )
  }

  /**
   *
   * @summary indexFavorites
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DashboardResourceApi
   */
  public indexFavoritesUsingGET(options?: any) {
    return DashboardResourceApiFp(this.configuration).indexFavoritesUsingGET(options)(this.axios, this.basePath)
  }
}

/**
 * DocumentationStatesControllerApi - axios parameter creator
 * @export
 */
export const DocumentationStatesControllerApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary indexDocumentationStates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexDocumentationStatesUsingGET(options: any = {}): RequestArgs {
      const localVarPath = `/api/documentationstates`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary readDocumentationState
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readDocumentationStateUsingGET(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling readDocumentationStateUsingGET.',
        )
      }
      const localVarPath = `/api/documentationstates/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * DocumentationStatesControllerApi - functional programming interface
 * @export
 */
export const DocumentationStatesControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary indexDocumentationStates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexDocumentationStatesUsingGET(
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentationStateDto>> {
      const localVarAxiosArgs = DocumentationStatesControllerApiAxiosParamCreator(
        configuration,
      ).indexDocumentationStatesUsingGET(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary readDocumentationState
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readDocumentationStateUsingGET(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentationStateDto> {
      const localVarAxiosArgs = DocumentationStatesControllerApiAxiosParamCreator(
        configuration,
      ).readDocumentationStateUsingGET(id, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * DocumentationStatesControllerApi - factory interface
 * @export
 */
export const DocumentationStatesControllerApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary indexDocumentationStates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexDocumentationStatesUsingGET(options?: any): AxiosPromise<Array<DocumentationStateDto>> {
      return DocumentationStatesControllerApiFp(configuration).indexDocumentationStatesUsingGET(options)(
        axios,
        basePath,
      )
    },
    /**
     *
     * @summary readDocumentationState
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readDocumentationStateUsingGET(id: number, options?: any): AxiosPromise<DocumentationStateDto> {
      return DocumentationStatesControllerApiFp(configuration).readDocumentationStateUsingGET(id, options)(
        axios,
        basePath,
      )
    },
  }
}

/**
 * DocumentationStatesControllerApi - object-oriented interface
 * @export
 * @class DocumentationStatesControllerApi
 * @extends {BaseAPI}
 */
export class DocumentationStatesControllerApi extends BaseAPI {
  /**
   *
   * @summary indexDocumentationStates
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentationStatesControllerApi
   */
  public indexDocumentationStatesUsingGET(options?: any) {
    return DocumentationStatesControllerApiFp(this.configuration).indexDocumentationStatesUsingGET(options)(
      this.axios,
      this.basePath,
    )
  }

  /**
   *
   * @summary readDocumentationState
   * @param {number} id id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentationStatesControllerApi
   */
  public readDocumentationStateUsingGET(id: number, options?: any) {
    return DocumentationStatesControllerApiFp(this.configuration).readDocumentationStateUsingGET(id, options)(
      this.axios,
      this.basePath,
    )
  }
}

/**
 * FilterResourceApi - axios parameter creator
 * @export
 */
export const FilterResourceApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary findFilterByPlatformAndType
     * @param {'AUCTION' | 'MAKE' | 'MODEL' | 'COLOR' | 'TRANSMISSION_TYPE'} filterType filterType
     * @param {'AVTO_JP'} platform platform
     * @param {Array<string>} [parent] parent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findFilterByPlatformAndTypeUsingGET(
      filterType: 'AUCTION' | 'MAKE' | 'MODEL' | 'COLOR' | 'TRANSMISSION_TYPE',
      platform: 'AVTO_JP',
      parent?: Array<string>,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'filterType' is not null or undefined
      if (filterType === null || filterType === undefined) {
        throw new RequiredError(
          'filterType',
          'Required parameter filterType was null or undefined when calling findFilterByPlatformAndTypeUsingGET.',
        )
      }
      // verify required parameter 'platform' is not null or undefined
      if (platform === null || platform === undefined) {
        throw new RequiredError(
          'platform',
          'Required parameter platform was null or undefined when calling findFilterByPlatformAndTypeUsingGET.',
        )
      }
      const localVarPath = `/api/filter/{platform}/{filterType}`
        .replace(`{${'filterType'}}`, encodeURIComponent(String(filterType)))
        .replace(`{${'platform'}}`, encodeURIComponent(String(platform)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (parent) {
        localVarQueryParameter['parent'] = parent
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary getPlatforms
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlatformsUsingGET(options: any = {}): RequestArgs {
      const localVarPath = `/api/platform`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * FilterResourceApi - functional programming interface
 * @export
 */
export const FilterResourceApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary findFilterByPlatformAndType
     * @param {'AUCTION' | 'MAKE' | 'MODEL' | 'COLOR' | 'TRANSMISSION_TYPE'} filterType filterType
     * @param {'AVTO_JP'} platform platform
     * @param {Array<string>} [parent] parent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findFilterByPlatformAndTypeUsingGET(
      filterType: 'AUCTION' | 'MAKE' | 'MODEL' | 'COLOR' | 'TRANSMISSION_TYPE',
      platform: 'AVTO_JP',
      parent?: Array<string>,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FilterDto>> {
      const localVarAxiosArgs = FilterResourceApiAxiosParamCreator(configuration).findFilterByPlatformAndTypeUsingGET(
        filterType,
        platform,
        parent,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary getPlatforms
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlatformsUsingGET(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
      const localVarAxiosArgs = FilterResourceApiAxiosParamCreator(configuration).getPlatformsUsingGET(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * FilterResourceApi - factory interface
 * @export
 */
export const FilterResourceApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary findFilterByPlatformAndType
     * @param {'AUCTION' | 'MAKE' | 'MODEL' | 'COLOR' | 'TRANSMISSION_TYPE'} filterType filterType
     * @param {'AVTO_JP'} platform platform
     * @param {Array<string>} [parent] parent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findFilterByPlatformAndTypeUsingGET(
      filterType: 'AUCTION' | 'MAKE' | 'MODEL' | 'COLOR' | 'TRANSMISSION_TYPE',
      platform: 'AVTO_JP',
      parent?: Array<string>,
      options?: any,
    ): AxiosPromise<Array<FilterDto>> {
      return FilterResourceApiFp(configuration).findFilterByPlatformAndTypeUsingGET(
        filterType,
        platform,
        parent,
        options,
      )(axios, basePath)
    },
    /**
     *
     * @summary getPlatforms
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlatformsUsingGET(options?: any): AxiosPromise<Array<string>> {
      return FilterResourceApiFp(configuration).getPlatformsUsingGET(options)(axios, basePath)
    },
  }
}

/**
 * FilterResourceApi - object-oriented interface
 * @export
 * @class FilterResourceApi
 * @extends {BaseAPI}
 */
export class FilterResourceApi extends BaseAPI {
  /**
   *
   * @summary findFilterByPlatformAndType
   * @param {'AUCTION' | 'MAKE' | 'MODEL' | 'COLOR' | 'TRANSMISSION_TYPE'} filterType filterType
   * @param {'AVTO_JP'} platform platform
   * @param {Array<string>} [parent] parent
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilterResourceApi
   */
  public findFilterByPlatformAndTypeUsingGET(
    filterType: 'AUCTION' | 'MAKE' | 'MODEL' | 'COLOR' | 'TRANSMISSION_TYPE',
    platform: 'AVTO_JP',
    parent?: Array<string>,
    options?: any,
  ) {
    return FilterResourceApiFp(this.configuration).findFilterByPlatformAndTypeUsingGET(
      filterType,
      platform,
      parent,
      options,
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary getPlatforms
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilterResourceApi
   */
  public getPlatformsUsingGET(options?: any) {
    return FilterResourceApiFp(this.configuration).getPlatformsUsingGET(options)(this.axios, this.basePath)
  }
}

/**
 * LotResourceApi - axios parameter creator
 * @export
 */
export const LotResourceApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary getAllLots
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllLotsUsingGET(options: any = {}): RequestArgs {
      const localVarPath = `/api/lots`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary getLot
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLotUsingGET(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling getLotUsingGET.')
      }
      const localVarPath = `/api/lots/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * LotResourceApi - functional programming interface
 * @export
 */
export const LotResourceApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary getAllLots
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllLotsUsingGET(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LotDto>> {
      const localVarAxiosArgs = LotResourceApiAxiosParamCreator(configuration).getAllLotsUsingGET(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary getLot
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLotUsingGET(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LotDto> {
      const localVarAxiosArgs = LotResourceApiAxiosParamCreator(configuration).getLotUsingGET(id, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * LotResourceApi - factory interface
 * @export
 */
export const LotResourceApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary getAllLots
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllLotsUsingGET(options?: any): AxiosPromise<Array<LotDto>> {
      return LotResourceApiFp(configuration).getAllLotsUsingGET(options)(axios, basePath)
    },
    /**
     *
     * @summary getLot
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLotUsingGET(id: number, options?: any): AxiosPromise<LotDto> {
      return LotResourceApiFp(configuration).getLotUsingGET(id, options)(axios, basePath)
    },
  }
}

/**
 * LotResourceApi - object-oriented interface
 * @export
 * @class LotResourceApi
 * @extends {BaseAPI}
 */
export class LotResourceApi extends BaseAPI {
  /**
   *
   * @summary getAllLots
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LotResourceApi
   */
  public getAllLotsUsingGET(options?: any) {
    return LotResourceApiFp(this.configuration).getAllLotsUsingGET(options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary getLot
   * @param {number} id id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LotResourceApi
   */
  public getLotUsingGET(id: number, options?: any) {
    return LotResourceApiFp(this.configuration).getLotUsingGET(id, options)(this.axios, this.basePath)
  }
}

/**
 * LotStatesControllerApi - axios parameter creator
 * @export
 */
export const LotStatesControllerApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary indexLotStates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexLotStatesUsingGET(options: any = {}): RequestArgs {
      const localVarPath = `/api/lotstates`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary readLotState
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readLotStateUsingGET(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling readLotStateUsingGET.')
      }
      const localVarPath = `/api/lotstates/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * LotStatesControllerApi - functional programming interface
 * @export
 */
export const LotStatesControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary indexLotStates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexLotStatesUsingGET(
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LotStateDto>> {
      const localVarAxiosArgs = LotStatesControllerApiAxiosParamCreator(configuration).indexLotStatesUsingGET(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary readLotState
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readLotStateUsingGET(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LotStateDto> {
      const localVarAxiosArgs = LotStatesControllerApiAxiosParamCreator(configuration).readLotStateUsingGET(id, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * LotStatesControllerApi - factory interface
 * @export
 */
export const LotStatesControllerApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary indexLotStates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexLotStatesUsingGET(options?: any): AxiosPromise<Array<LotStateDto>> {
      return LotStatesControllerApiFp(configuration).indexLotStatesUsingGET(options)(axios, basePath)
    },
    /**
     *
     * @summary readLotState
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readLotStateUsingGET(id: number, options?: any): AxiosPromise<LotStateDto> {
      return LotStatesControllerApiFp(configuration).readLotStateUsingGET(id, options)(axios, basePath)
    },
  }
}

/**
 * LotStatesControllerApi - object-oriented interface
 * @export
 * @class LotStatesControllerApi
 * @extends {BaseAPI}
 */
export class LotStatesControllerApi extends BaseAPI {
  /**
   *
   * @summary indexLotStates
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LotStatesControllerApi
   */
  public indexLotStatesUsingGET(options?: any) {
    return LotStatesControllerApiFp(this.configuration).indexLotStatesUsingGET(options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary readLotState
   * @param {number} id id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LotStatesControllerApi
   */
  public readLotStateUsingGET(id: number, options?: any) {
    return LotStatesControllerApiFp(this.configuration).readLotStateUsingGET(id, options)(this.axios, this.basePath)
  }
}

/**
 * NotificationResourceApi - axios parameter creator
 * @export
 */
export const NotificationResourceApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary createNotification
     * @param {NotificationDTO} notificationDTO notificationDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNotificationUsingPOST(notificationDTO: NotificationDTO, options: any = {}): RequestArgs {
      // verify required parameter 'notificationDTO' is not null or undefined
      if (notificationDTO === null || notificationDTO === undefined) {
        throw new RequiredError(
          'notificationDTO',
          'Required parameter notificationDTO was null or undefined when calling createNotificationUsingPOST.',
        )
      }
      const localVarPath = `/api/notifications`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof notificationDTO !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(notificationDTO !== undefined ? notificationDTO : {})
        : notificationDTO || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary deleteNotification
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteNotificationUsingDELETE(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling deleteNotificationUsingDELETE.',
        )
      }
      const localVarPath = `/api/notifications/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary getAllNotifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllNotificationsUsingGET(options: any = {}): RequestArgs {
      const localVarPath = `/api/notifications`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary getNotification
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotificationUsingGET(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getNotificationUsingGET.',
        )
      }
      const localVarPath = `/api/notifications/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary searchNotifications
     * @param {string} query query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchNotificationsUsingGET(query: string, options: any = {}): RequestArgs {
      // verify required parameter 'query' is not null or undefined
      if (query === null || query === undefined) {
        throw new RequiredError(
          'query',
          'Required parameter query was null or undefined when calling searchNotificationsUsingGET.',
        )
      }
      const localVarPath = `/api/_search/notifications`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (query !== undefined) {
        localVarQueryParameter['query'] = query
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary updateNotification
     * @param {NotificationDTO} notificationDTO notificationDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNotificationUsingPUT(notificationDTO: NotificationDTO, options: any = {}): RequestArgs {
      // verify required parameter 'notificationDTO' is not null or undefined
      if (notificationDTO === null || notificationDTO === undefined) {
        throw new RequiredError(
          'notificationDTO',
          'Required parameter notificationDTO was null or undefined when calling updateNotificationUsingPUT.',
        )
      }
      const localVarPath = `/api/notifications`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof notificationDTO !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(notificationDTO !== undefined ? notificationDTO : {})
        : notificationDTO || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * NotificationResourceApi - functional programming interface
 * @export
 */
export const NotificationResourceApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary createNotification
     * @param {NotificationDTO} notificationDTO notificationDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNotificationUsingPOST(
      notificationDTO: NotificationDTO,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationDTO> {
      const localVarAxiosArgs = NotificationResourceApiAxiosParamCreator(configuration).createNotificationUsingPOST(
        notificationDTO,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary deleteNotification
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteNotificationUsingDELETE(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = NotificationResourceApiAxiosParamCreator(configuration).deleteNotificationUsingDELETE(
        id,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary getAllNotifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllNotificationsUsingGET(
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationDTO>> {
      const localVarAxiosArgs = NotificationResourceApiAxiosParamCreator(configuration).getAllNotificationsUsingGET(
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary getNotification
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotificationUsingGET(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationDTO> {
      const localVarAxiosArgs = NotificationResourceApiAxiosParamCreator(configuration).getNotificationUsingGET(
        id,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary searchNotifications
     * @param {string} query query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchNotificationsUsingGET(
      query: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationDTO>> {
      const localVarAxiosArgs = NotificationResourceApiAxiosParamCreator(configuration).searchNotificationsUsingGET(
        query,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary updateNotification
     * @param {NotificationDTO} notificationDTO notificationDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNotificationUsingPUT(
      notificationDTO: NotificationDTO,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationDTO> {
      const localVarAxiosArgs = NotificationResourceApiAxiosParamCreator(configuration).updateNotificationUsingPUT(
        notificationDTO,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * NotificationResourceApi - factory interface
 * @export
 */
export const NotificationResourceApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary createNotification
     * @param {NotificationDTO} notificationDTO notificationDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNotificationUsingPOST(notificationDTO: NotificationDTO, options?: any): AxiosPromise<NotificationDTO> {
      return NotificationResourceApiFp(configuration).createNotificationUsingPOST(notificationDTO, options)(
        axios,
        basePath,
      )
    },
    /**
     *
     * @summary deleteNotification
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteNotificationUsingDELETE(id: number, options?: any): AxiosPromise<void> {
      return NotificationResourceApiFp(configuration).deleteNotificationUsingDELETE(id, options)(axios, basePath)
    },
    /**
     *
     * @summary getAllNotifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllNotificationsUsingGET(options?: any): AxiosPromise<Array<NotificationDTO>> {
      return NotificationResourceApiFp(configuration).getAllNotificationsUsingGET(options)(axios, basePath)
    },
    /**
     *
     * @summary getNotification
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotificationUsingGET(id: number, options?: any): AxiosPromise<NotificationDTO> {
      return NotificationResourceApiFp(configuration).getNotificationUsingGET(id, options)(axios, basePath)
    },
    /**
     *
     * @summary searchNotifications
     * @param {string} query query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchNotificationsUsingGET(query: string, options?: any): AxiosPromise<Array<NotificationDTO>> {
      return NotificationResourceApiFp(configuration).searchNotificationsUsingGET(query, options)(axios, basePath)
    },
    /**
     *
     * @summary updateNotification
     * @param {NotificationDTO} notificationDTO notificationDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNotificationUsingPUT(notificationDTO: NotificationDTO, options?: any): AxiosPromise<NotificationDTO> {
      return NotificationResourceApiFp(configuration).updateNotificationUsingPUT(notificationDTO, options)(
        axios,
        basePath,
      )
    },
  }
}

/**
 * NotificationResourceApi - object-oriented interface
 * @export
 * @class NotificationResourceApi
 * @extends {BaseAPI}
 */
export class NotificationResourceApi extends BaseAPI {
  /**
   *
   * @summary createNotification
   * @param {NotificationDTO} notificationDTO notificationDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationResourceApi
   */
  public createNotificationUsingPOST(notificationDTO: NotificationDTO, options?: any) {
    return NotificationResourceApiFp(this.configuration).createNotificationUsingPOST(notificationDTO, options)(
      this.axios,
      this.basePath,
    )
  }

  /**
   *
   * @summary deleteNotification
   * @param {number} id id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationResourceApi
   */
  public deleteNotificationUsingDELETE(id: number, options?: any) {
    return NotificationResourceApiFp(this.configuration).deleteNotificationUsingDELETE(id, options)(
      this.axios,
      this.basePath,
    )
  }

  /**
   *
   * @summary getAllNotifications
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationResourceApi
   */
  public getAllNotificationsUsingGET(options?: any) {
    return NotificationResourceApiFp(this.configuration).getAllNotificationsUsingGET(options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary getNotification
   * @param {number} id id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationResourceApi
   */
  public getNotificationUsingGET(id: number, options?: any) {
    return NotificationResourceApiFp(this.configuration).getNotificationUsingGET(id, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary searchNotifications
   * @param {string} query query
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationResourceApi
   */
  public searchNotificationsUsingGET(query: string, options?: any) {
    return NotificationResourceApiFp(this.configuration).searchNotificationsUsingGET(query, options)(
      this.axios,
      this.basePath,
    )
  }

  /**
   *
   * @summary updateNotification
   * @param {NotificationDTO} notificationDTO notificationDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationResourceApi
   */
  public updateNotificationUsingPUT(notificationDTO: NotificationDTO, options?: any) {
    return NotificationResourceApiFp(this.configuration).updateNotificationUsingPUT(notificationDTO, options)(
      this.axios,
      this.basePath,
    )
  }
}

/**
 * OrdersControllerApi - axios parameter creator
 * @export
 */
export const OrdersControllerApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary createOrder
     * @param {OrderCreateRequest} requestBody requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrderUsingPOST(requestBody: OrderCreateRequest, options: any = {}): RequestArgs {
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling createOrderUsingPOST.',
        )
      }
      const localVarPath = `/api/orders`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof requestBody !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary indexOrdersByManager
     * @param {number} [page]
     * @param {number} [size]
     * @param {Array<string>} [sort]
     * @param {'NEW' | 'SHIPPING_INFORMATION_PROVIDED' | 'VALIDATED' | 'CONFIRMED' | 'PAID' | 'COMPLETED'} [state] state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexOrdersByManagerUsingGET(
      page?: number,
      size?: number,
      sort?: Array<string>,
      state?: 'NEW' | 'SHIPPING_INFORMATION_PROVIDED' | 'VALIDATED' | 'CONFIRMED' | 'PAID' | 'COMPLETED',
      options: any = {},
    ): RequestArgs {
      const localVarPath = `/api/orders`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size
      }

      if (sort) {
        localVarQueryParameter['sort'] = sort
      }

      if (state !== undefined) {
        localVarQueryParameter['state'] = state
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary updateOrderByManager
     * @param {number} id id
     * @param {OrderUpdateManagerRequest} requestBody requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrderByManagerUsingPUT(id: number, requestBody: OrderUpdateManagerRequest, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling updateOrderByManagerUsingPUT.',
        )
      }
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling updateOrderByManagerUsingPUT.',
        )
      }
      const localVarPath = `/api/orders/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof requestBody !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * OrdersControllerApi - functional programming interface
 * @export
 */
export const OrdersControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary createOrder
     * @param {OrderCreateRequest} requestBody requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrderUsingPOST(
      requestBody: OrderCreateRequest,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = OrdersControllerApiAxiosParamCreator(configuration).createOrderUsingPOST(
        requestBody,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary indexOrdersByManager
     * @param {number} [page]
     * @param {number} [size]
     * @param {Array<string>} [sort]
     * @param {'NEW' | 'SHIPPING_INFORMATION_PROVIDED' | 'VALIDATED' | 'CONFIRMED' | 'PAID' | 'COMPLETED'} [state] state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexOrdersByManagerUsingGET(
      page?: number,
      size?: number,
      sort?: Array<string>,
      state?: 'NEW' | 'SHIPPING_INFORMATION_PROVIDED' | 'VALIDATED' | 'CONFIRMED' | 'PAID' | 'COMPLETED',
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderDto>> {
      const localVarAxiosArgs = OrdersControllerApiAxiosParamCreator(configuration).indexOrdersByManagerUsingGET(
        page,
        size,
        sort,
        state,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary updateOrderByManager
     * @param {number} id id
     * @param {OrderUpdateManagerRequest} requestBody requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrderByManagerUsingPUT(
      id: number,
      requestBody: OrderUpdateManagerRequest,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDto> {
      const localVarAxiosArgs = OrdersControllerApiAxiosParamCreator(configuration).updateOrderByManagerUsingPUT(
        id,
        requestBody,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * OrdersControllerApi - factory interface
 * @export
 */
export const OrdersControllerApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary createOrder
     * @param {OrderCreateRequest} requestBody requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrderUsingPOST(requestBody: OrderCreateRequest, options?: any): AxiosPromise<void> {
      return OrdersControllerApiFp(configuration).createOrderUsingPOST(requestBody, options)(axios, basePath)
    },
    /**
     *
     * @summary indexOrdersByManager
     * @param {number} [page]
     * @param {number} [size]
     * @param {Array<string>} [sort]
     * @param {'NEW' | 'SHIPPING_INFORMATION_PROVIDED' | 'VALIDATED' | 'CONFIRMED' | 'PAID' | 'COMPLETED'} [state] state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexOrdersByManagerUsingGET(
      page?: number,
      size?: number,
      sort?: Array<string>,
      state?: 'NEW' | 'SHIPPING_INFORMATION_PROVIDED' | 'VALIDATED' | 'CONFIRMED' | 'PAID' | 'COMPLETED',
      options?: any,
    ): AxiosPromise<Array<OrderDto>> {
      return OrdersControllerApiFp(configuration).indexOrdersByManagerUsingGET(
        page,
        size,
        sort,
        state,
        options,
      )(axios, basePath)
    },
    /**
     *
     * @summary updateOrderByManager
     * @param {number} id id
     * @param {OrderUpdateManagerRequest} requestBody requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrderByManagerUsingPUT(
      id: number,
      requestBody: OrderUpdateManagerRequest,
      options?: any,
    ): AxiosPromise<OrderDto> {
      return OrdersControllerApiFp(configuration).updateOrderByManagerUsingPUT(
        id,
        requestBody,
        options,
      )(axios, basePath)
    },
  }
}

/**
 * OrdersControllerApi - object-oriented interface
 * @export
 * @class OrdersControllerApi
 * @extends {BaseAPI}
 */
export class OrdersControllerApi extends BaseAPI {
  /**
   *
   * @summary createOrder
   * @param {OrderCreateRequest} requestBody requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersControllerApi
   */
  public createOrderUsingPOST(requestBody: OrderCreateRequest, options?: any) {
    return OrdersControllerApiFp(this.configuration).createOrderUsingPOST(requestBody, options)(
      this.axios,
      this.basePath,
    )
  }

  /**
   *
   * @summary indexOrdersByManager
   * @param {number} [page]
   * @param {number} [size]
   * @param {Array<string>} [sort]
   * @param {'NEW' | 'SHIPPING_INFORMATION_PROVIDED' | 'VALIDATED' | 'CONFIRMED' | 'PAID' | 'COMPLETED'} [state] state
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersControllerApi
   */
  public indexOrdersByManagerUsingGET(
    page?: number,
    size?: number,
    sort?: Array<string>,
    state?: 'NEW' | 'SHIPPING_INFORMATION_PROVIDED' | 'VALIDATED' | 'CONFIRMED' | 'PAID' | 'COMPLETED',
    options?: any,
  ) {
    return OrdersControllerApiFp(this.configuration).indexOrdersByManagerUsingGET(
      page,
      size,
      sort,
      state,
      options,
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary updateOrderByManager
   * @param {number} id id
   * @param {OrderUpdateManagerRequest} requestBody requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrdersControllerApi
   */
  public updateOrderByManagerUsingPUT(id: number, requestBody: OrderUpdateManagerRequest, options?: any) {
    return OrdersControllerApiFp(this.configuration).updateOrderByManagerUsingPUT(
      id,
      requestBody,
      options,
    )(this.axios, this.basePath)
  }
}

/**
 * PaymentResourceApi - axios parameter creator
 * @export
 */
export const PaymentResourceApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary createCheckoutSession
     * @param {CheckoutSessionDTO} checkoutSessionDTO checkoutSessionDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCheckoutSessionUsingPOST(checkoutSessionDTO: CheckoutSessionDTO, options: any = {}): RequestArgs {
      // verify required parameter 'checkoutSessionDTO' is not null or undefined
      if (checkoutSessionDTO === null || checkoutSessionDTO === undefined) {
        throw new RequiredError(
          'checkoutSessionDTO',
          'Required parameter checkoutSessionDTO was null or undefined when calling createCheckoutSessionUsingPOST.',
        )
      }
      const localVarPath = `/api/payments/checkout-session`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof checkoutSessionDTO !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(checkoutSessionDTO !== undefined ? checkoutSessionDTO : {})
        : checkoutSessionDTO || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary webhook
     * @param {string} stripeSignature Stripe-Signature
     * @param {string} payload payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webhookUsingPOST(stripeSignature: string, payload: string, options: any = {}): RequestArgs {
      // verify required parameter 'stripeSignature' is not null or undefined
      if (stripeSignature === null || stripeSignature === undefined) {
        throw new RequiredError(
          'stripeSignature',
          'Required parameter stripeSignature was null or undefined when calling webhookUsingPOST.',
        )
      }
      // verify required parameter 'payload' is not null or undefined
      if (payload === null || payload === undefined) {
        throw new RequiredError(
          'payload',
          'Required parameter payload was null or undefined when calling webhookUsingPOST.',
        )
      }
      const localVarPath = `/api/payments/webhook`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (stripeSignature !== undefined && stripeSignature !== null) {
        localVarHeaderParameter['Stripe-Signature'] = String(stripeSignature)
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof payload !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(payload !== undefined ? payload : {})
        : payload || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * PaymentResourceApi - functional programming interface
 * @export
 */
export const PaymentResourceApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary createCheckoutSession
     * @param {CheckoutSessionDTO} checkoutSessionDTO checkoutSessionDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCheckoutSessionUsingPOST(
      checkoutSessionDTO: CheckoutSessionDTO,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = PaymentResourceApiAxiosParamCreator(configuration).createCheckoutSessionUsingPOST(
        checkoutSessionDTO,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary webhook
     * @param {string} stripeSignature Stripe-Signature
     * @param {string} payload payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webhookUsingPOST(
      stripeSignature: string,
      payload: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = PaymentResourceApiAxiosParamCreator(configuration).webhookUsingPOST(
        stripeSignature,
        payload,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * PaymentResourceApi - factory interface
 * @export
 */
export const PaymentResourceApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary createCheckoutSession
     * @param {CheckoutSessionDTO} checkoutSessionDTO checkoutSessionDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCheckoutSessionUsingPOST(checkoutSessionDTO: CheckoutSessionDTO, options?: any): AxiosPromise<string> {
      return PaymentResourceApiFp(configuration).createCheckoutSessionUsingPOST(checkoutSessionDTO, options)(
        axios,
        basePath,
      )
    },
    /**
     *
     * @summary webhook
     * @param {string} stripeSignature Stripe-Signature
     * @param {string} payload payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webhookUsingPOST(stripeSignature: string, payload: string, options?: any): AxiosPromise<void> {
      return PaymentResourceApiFp(configuration).webhookUsingPOST(stripeSignature, payload, options)(axios, basePath)
    },
  }
}

/**
 * PaymentResourceApi - object-oriented interface
 * @export
 * @class PaymentResourceApi
 * @extends {BaseAPI}
 */
export class PaymentResourceApi extends BaseAPI {
  /**
   *
   * @summary createCheckoutSession
   * @param {CheckoutSessionDTO} checkoutSessionDTO checkoutSessionDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentResourceApi
   */
  public createCheckoutSessionUsingPOST(checkoutSessionDTO: CheckoutSessionDTO, options?: any) {
    return PaymentResourceApiFp(this.configuration).createCheckoutSessionUsingPOST(checkoutSessionDTO, options)(
      this.axios,
      this.basePath,
    )
  }

  /**
   *
   * @summary webhook
   * @param {string} stripeSignature Stripe-Signature
   * @param {string} payload payload
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentResourceApi
   */
  public webhookUsingPOST(stripeSignature: string, payload: string, options?: any) {
    return PaymentResourceApiFp(this.configuration).webhookUsingPOST(
      stripeSignature,
      payload,
      options,
    )(this.axios, this.basePath)
  }
}

/**
 * PortsControllerApi - axios parameter creator
 * @export
 */
export const PortsControllerApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary indexPorts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexPortsUsingGET(options: any = {}): RequestArgs {
      const localVarPath = `/api/ports`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary readPort
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readPortUsingGET(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling readPortUsingGET.')
      }
      const localVarPath = `/api/ports/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * PortsControllerApi - functional programming interface
 * @export
 */
export const PortsControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary indexPorts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexPortsUsingGET(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PortDto>> {
      const localVarAxiosArgs = PortsControllerApiAxiosParamCreator(configuration).indexPortsUsingGET(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary readPort
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readPortUsingGET(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortDto> {
      const localVarAxiosArgs = PortsControllerApiAxiosParamCreator(configuration).readPortUsingGET(id, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * PortsControllerApi - factory interface
 * @export
 */
export const PortsControllerApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary indexPorts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexPortsUsingGET(options?: any): AxiosPromise<Array<PortDto>> {
      return PortsControllerApiFp(configuration).indexPortsUsingGET(options)(axios, basePath)
    },
    /**
     *
     * @summary readPort
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readPortUsingGET(id: number, options?: any): AxiosPromise<PortDto> {
      return PortsControllerApiFp(configuration).readPortUsingGET(id, options)(axios, basePath)
    },
  }
}

/**
 * PortsControllerApi - object-oriented interface
 * @export
 * @class PortsControllerApi
 * @extends {BaseAPI}
 */
export class PortsControllerApi extends BaseAPI {
  /**
   *
   * @summary indexPorts
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortsControllerApi
   */
  public indexPortsUsingGET(options?: any) {
    return PortsControllerApiFp(this.configuration).indexPortsUsingGET(options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary readPort
   * @param {number} id id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PortsControllerApi
   */
  public readPortUsingGET(id: number, options?: any) {
    return PortsControllerApiFp(this.configuration).readPortUsingGET(id, options)(this.axios, this.basePath)
  }
}

/**
 * SearchFilterResourceApi - axios parameter creator
 * @export
 */
export const SearchFilterResourceApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary createSearchFilter
     * @param {SearchFilterDTO} searchFilterDTO searchFilterDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSearchFilterUsingPOST(searchFilterDTO: SearchFilterDTO, options: any = {}): RequestArgs {
      // verify required parameter 'searchFilterDTO' is not null or undefined
      if (searchFilterDTO === null || searchFilterDTO === undefined) {
        throw new RequiredError(
          'searchFilterDTO',
          'Required parameter searchFilterDTO was null or undefined when calling createSearchFilterUsingPOST.',
        )
      }
      const localVarPath = `/api/search-filters`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof searchFilterDTO !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(searchFilterDTO !== undefined ? searchFilterDTO : {})
        : searchFilterDTO || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary deleteSearchFilter
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSearchFilterUsingDELETE(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling deleteSearchFilterUsingDELETE.',
        )
      }
      const localVarPath = `/api/search-filters/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary getAllSearchFilters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllSearchFiltersUsingGET(options: any = {}): RequestArgs {
      const localVarPath = `/api/search-filters`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary getSearchFilter
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSearchFilterUsingGET(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getSearchFilterUsingGET.',
        )
      }
      const localVarPath = `/api/search-filters/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary searchSearchFilters
     * @param {string} query query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchSearchFiltersUsingGET(query: string, options: any = {}): RequestArgs {
      // verify required parameter 'query' is not null or undefined
      if (query === null || query === undefined) {
        throw new RequiredError(
          'query',
          'Required parameter query was null or undefined when calling searchSearchFiltersUsingGET.',
        )
      }
      const localVarPath = `/api/_search/search-filters`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (query !== undefined) {
        localVarQueryParameter['query'] = query
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary updateSearchFilter
     * @param {SearchFilterDTO} searchFilterDTO searchFilterDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSearchFilterUsingPUT(searchFilterDTO: SearchFilterDTO, options: any = {}): RequestArgs {
      // verify required parameter 'searchFilterDTO' is not null or undefined
      if (searchFilterDTO === null || searchFilterDTO === undefined) {
        throw new RequiredError(
          'searchFilterDTO',
          'Required parameter searchFilterDTO was null or undefined when calling updateSearchFilterUsingPUT.',
        )
      }
      const localVarPath = `/api/search-filters`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof searchFilterDTO !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(searchFilterDTO !== undefined ? searchFilterDTO : {})
        : searchFilterDTO || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * SearchFilterResourceApi - functional programming interface
 * @export
 */
export const SearchFilterResourceApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary createSearchFilter
     * @param {SearchFilterDTO} searchFilterDTO searchFilterDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSearchFilterUsingPOST(
      searchFilterDTO: SearchFilterDTO,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchFilterDTO> {
      const localVarAxiosArgs = SearchFilterResourceApiAxiosParamCreator(configuration).createSearchFilterUsingPOST(
        searchFilterDTO,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary deleteSearchFilter
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSearchFilterUsingDELETE(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = SearchFilterResourceApiAxiosParamCreator(configuration).deleteSearchFilterUsingDELETE(
        id,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary getAllSearchFilters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllSearchFiltersUsingGET(
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchFilterDTO>> {
      const localVarAxiosArgs = SearchFilterResourceApiAxiosParamCreator(configuration).getAllSearchFiltersUsingGET(
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary getSearchFilter
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSearchFilterUsingGET(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchFilterDTO> {
      const localVarAxiosArgs = SearchFilterResourceApiAxiosParamCreator(configuration).getSearchFilterUsingGET(
        id,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary searchSearchFilters
     * @param {string} query query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchSearchFiltersUsingGET(
      query: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchFilterDTO>> {
      const localVarAxiosArgs = SearchFilterResourceApiAxiosParamCreator(configuration).searchSearchFiltersUsingGET(
        query,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary updateSearchFilter
     * @param {SearchFilterDTO} searchFilterDTO searchFilterDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSearchFilterUsingPUT(
      searchFilterDTO: SearchFilterDTO,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchFilterDTO> {
      const localVarAxiosArgs = SearchFilterResourceApiAxiosParamCreator(configuration).updateSearchFilterUsingPUT(
        searchFilterDTO,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * SearchFilterResourceApi - factory interface
 * @export
 */
export const SearchFilterResourceApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary createSearchFilter
     * @param {SearchFilterDTO} searchFilterDTO searchFilterDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSearchFilterUsingPOST(searchFilterDTO: SearchFilterDTO, options?: any): AxiosPromise<SearchFilterDTO> {
      return SearchFilterResourceApiFp(configuration).createSearchFilterUsingPOST(searchFilterDTO, options)(
        axios,
        basePath,
      )
    },
    /**
     *
     * @summary deleteSearchFilter
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSearchFilterUsingDELETE(id: number, options?: any): AxiosPromise<void> {
      return SearchFilterResourceApiFp(configuration).deleteSearchFilterUsingDELETE(id, options)(axios, basePath)
    },
    /**
     *
     * @summary getAllSearchFilters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllSearchFiltersUsingGET(options?: any): AxiosPromise<Array<SearchFilterDTO>> {
      return SearchFilterResourceApiFp(configuration).getAllSearchFiltersUsingGET(options)(axios, basePath)
    },
    /**
     *
     * @summary getSearchFilter
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSearchFilterUsingGET(id: number, options?: any): AxiosPromise<SearchFilterDTO> {
      return SearchFilterResourceApiFp(configuration).getSearchFilterUsingGET(id, options)(axios, basePath)
    },
    /**
     *
     * @summary searchSearchFilters
     * @param {string} query query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchSearchFiltersUsingGET(query: string, options?: any): AxiosPromise<Array<SearchFilterDTO>> {
      return SearchFilterResourceApiFp(configuration).searchSearchFiltersUsingGET(query, options)(axios, basePath)
    },
    /**
     *
     * @summary updateSearchFilter
     * @param {SearchFilterDTO} searchFilterDTO searchFilterDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSearchFilterUsingPUT(searchFilterDTO: SearchFilterDTO, options?: any): AxiosPromise<SearchFilterDTO> {
      return SearchFilterResourceApiFp(configuration).updateSearchFilterUsingPUT(searchFilterDTO, options)(
        axios,
        basePath,
      )
    },
  }
}

/**
 * SearchFilterResourceApi - object-oriented interface
 * @export
 * @class SearchFilterResourceApi
 * @extends {BaseAPI}
 */
export class SearchFilterResourceApi extends BaseAPI {
  /**
   *
   * @summary createSearchFilter
   * @param {SearchFilterDTO} searchFilterDTO searchFilterDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchFilterResourceApi
   */
  public createSearchFilterUsingPOST(searchFilterDTO: SearchFilterDTO, options?: any) {
    return SearchFilterResourceApiFp(this.configuration).createSearchFilterUsingPOST(searchFilterDTO, options)(
      this.axios,
      this.basePath,
    )
  }

  /**
   *
   * @summary deleteSearchFilter
   * @param {number} id id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchFilterResourceApi
   */
  public deleteSearchFilterUsingDELETE(id: number, options?: any) {
    return SearchFilterResourceApiFp(this.configuration).deleteSearchFilterUsingDELETE(id, options)(
      this.axios,
      this.basePath,
    )
  }

  /**
   *
   * @summary getAllSearchFilters
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchFilterResourceApi
   */
  public getAllSearchFiltersUsingGET(options?: any) {
    return SearchFilterResourceApiFp(this.configuration).getAllSearchFiltersUsingGET(options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary getSearchFilter
   * @param {number} id id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchFilterResourceApi
   */
  public getSearchFilterUsingGET(id: number, options?: any) {
    return SearchFilterResourceApiFp(this.configuration).getSearchFilterUsingGET(id, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary searchSearchFilters
   * @param {string} query query
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchFilterResourceApi
   */
  public searchSearchFiltersUsingGET(query: string, options?: any) {
    return SearchFilterResourceApiFp(this.configuration).searchSearchFiltersUsingGET(query, options)(
      this.axios,
      this.basePath,
    )
  }

  /**
   *
   * @summary updateSearchFilter
   * @param {SearchFilterDTO} searchFilterDTO searchFilterDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchFilterResourceApi
   */
  public updateSearchFilterUsingPUT(searchFilterDTO: SearchFilterDTO, options?: any) {
    return SearchFilterResourceApiFp(this.configuration).updateSearchFilterUsingPUT(searchFilterDTO, options)(
      this.axios,
      this.basePath,
    )
  }
}

/**
 * SearchHistoryResourceApi - axios parameter creator
 * @export
 */
export const SearchHistoryResourceApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary createSearchHistory
     * @param {SearchHistoryDTO} searchHistoryDTO searchHistoryDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSearchHistoryUsingPOST(searchHistoryDTO: SearchHistoryDTO, options: any = {}): RequestArgs {
      // verify required parameter 'searchHistoryDTO' is not null or undefined
      if (searchHistoryDTO === null || searchHistoryDTO === undefined) {
        throw new RequiredError(
          'searchHistoryDTO',
          'Required parameter searchHistoryDTO was null or undefined when calling createSearchHistoryUsingPOST.',
        )
      }
      const localVarPath = `/api/search-histories`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof searchHistoryDTO !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(searchHistoryDTO !== undefined ? searchHistoryDTO : {})
        : searchHistoryDTO || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary deleteSearchHistory
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSearchHistoryUsingDELETE(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling deleteSearchHistoryUsingDELETE.',
        )
      }
      const localVarPath = `/api/search-histories/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary getAllSearchHistories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllSearchHistoriesUsingGET(options: any = {}): RequestArgs {
      const localVarPath = `/api/search-histories`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary getSearchHistory
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSearchHistoryUsingGET(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getSearchHistoryUsingGET.',
        )
      }
      const localVarPath = `/api/search-histories/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary searchSearchHistories
     * @param {string} query query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchSearchHistoriesUsingGET(query: string, options: any = {}): RequestArgs {
      // verify required parameter 'query' is not null or undefined
      if (query === null || query === undefined) {
        throw new RequiredError(
          'query',
          'Required parameter query was null or undefined when calling searchSearchHistoriesUsingGET.',
        )
      }
      const localVarPath = `/api/_search/search-histories`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (query !== undefined) {
        localVarQueryParameter['query'] = query
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary updateSearchHistory
     * @param {SearchHistoryDTO} searchHistoryDTO searchHistoryDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSearchHistoryUsingPUT(searchHistoryDTO: SearchHistoryDTO, options: any = {}): RequestArgs {
      // verify required parameter 'searchHistoryDTO' is not null or undefined
      if (searchHistoryDTO === null || searchHistoryDTO === undefined) {
        throw new RequiredError(
          'searchHistoryDTO',
          'Required parameter searchHistoryDTO was null or undefined when calling updateSearchHistoryUsingPUT.',
        )
      }
      const localVarPath = `/api/search-histories`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof searchHistoryDTO !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(searchHistoryDTO !== undefined ? searchHistoryDTO : {})
        : searchHistoryDTO || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * SearchHistoryResourceApi - functional programming interface
 * @export
 */
export const SearchHistoryResourceApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary createSearchHistory
     * @param {SearchHistoryDTO} searchHistoryDTO searchHistoryDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSearchHistoryUsingPOST(
      searchHistoryDTO: SearchHistoryDTO,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchHistoryDTO> {
      const localVarAxiosArgs = SearchHistoryResourceApiAxiosParamCreator(configuration).createSearchHistoryUsingPOST(
        searchHistoryDTO,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary deleteSearchHistory
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSearchHistoryUsingDELETE(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = SearchHistoryResourceApiAxiosParamCreator(configuration).deleteSearchHistoryUsingDELETE(
        id,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary getAllSearchHistories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllSearchHistoriesUsingGET(
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchHistoryDTO>> {
      const localVarAxiosArgs = SearchHistoryResourceApiAxiosParamCreator(configuration).getAllSearchHistoriesUsingGET(
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary getSearchHistory
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSearchHistoryUsingGET(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchHistoryDTO> {
      const localVarAxiosArgs = SearchHistoryResourceApiAxiosParamCreator(configuration).getSearchHistoryUsingGET(
        id,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary searchSearchHistories
     * @param {string} query query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchSearchHistoriesUsingGET(
      query: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchHistoryDTO>> {
      const localVarAxiosArgs = SearchHistoryResourceApiAxiosParamCreator(configuration).searchSearchHistoriesUsingGET(
        query,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary updateSearchHistory
     * @param {SearchHistoryDTO} searchHistoryDTO searchHistoryDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSearchHistoryUsingPUT(
      searchHistoryDTO: SearchHistoryDTO,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchHistoryDTO> {
      const localVarAxiosArgs = SearchHistoryResourceApiAxiosParamCreator(configuration).updateSearchHistoryUsingPUT(
        searchHistoryDTO,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * SearchHistoryResourceApi - factory interface
 * @export
 */
export const SearchHistoryResourceApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary createSearchHistory
     * @param {SearchHistoryDTO} searchHistoryDTO searchHistoryDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSearchHistoryUsingPOST(searchHistoryDTO: SearchHistoryDTO, options?: any): AxiosPromise<SearchHistoryDTO> {
      return SearchHistoryResourceApiFp(configuration).createSearchHistoryUsingPOST(searchHistoryDTO, options)(
        axios,
        basePath,
      )
    },
    /**
     *
     * @summary deleteSearchHistory
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSearchHistoryUsingDELETE(id: number, options?: any): AxiosPromise<void> {
      return SearchHistoryResourceApiFp(configuration).deleteSearchHistoryUsingDELETE(id, options)(axios, basePath)
    },
    /**
     *
     * @summary getAllSearchHistories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllSearchHistoriesUsingGET(options?: any): AxiosPromise<Array<SearchHistoryDTO>> {
      return SearchHistoryResourceApiFp(configuration).getAllSearchHistoriesUsingGET(options)(axios, basePath)
    },
    /**
     *
     * @summary getSearchHistory
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSearchHistoryUsingGET(id: number, options?: any): AxiosPromise<SearchHistoryDTO> {
      return SearchHistoryResourceApiFp(configuration).getSearchHistoryUsingGET(id, options)(axios, basePath)
    },
    /**
     *
     * @summary searchSearchHistories
     * @param {string} query query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchSearchHistoriesUsingGET(query: string, options?: any): AxiosPromise<Array<SearchHistoryDTO>> {
      return SearchHistoryResourceApiFp(configuration).searchSearchHistoriesUsingGET(query, options)(axios, basePath)
    },
    /**
     *
     * @summary updateSearchHistory
     * @param {SearchHistoryDTO} searchHistoryDTO searchHistoryDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSearchHistoryUsingPUT(searchHistoryDTO: SearchHistoryDTO, options?: any): AxiosPromise<SearchHistoryDTO> {
      return SearchHistoryResourceApiFp(configuration).updateSearchHistoryUsingPUT(searchHistoryDTO, options)(
        axios,
        basePath,
      )
    },
  }
}

/**
 * SearchHistoryResourceApi - object-oriented interface
 * @export
 * @class SearchHistoryResourceApi
 * @extends {BaseAPI}
 */
export class SearchHistoryResourceApi extends BaseAPI {
  /**
   *
   * @summary createSearchHistory
   * @param {SearchHistoryDTO} searchHistoryDTO searchHistoryDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchHistoryResourceApi
   */
  public createSearchHistoryUsingPOST(searchHistoryDTO: SearchHistoryDTO, options?: any) {
    return SearchHistoryResourceApiFp(this.configuration).createSearchHistoryUsingPOST(searchHistoryDTO, options)(
      this.axios,
      this.basePath,
    )
  }

  /**
   *
   * @summary deleteSearchHistory
   * @param {number} id id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchHistoryResourceApi
   */
  public deleteSearchHistoryUsingDELETE(id: number, options?: any) {
    return SearchHistoryResourceApiFp(this.configuration).deleteSearchHistoryUsingDELETE(id, options)(
      this.axios,
      this.basePath,
    )
  }

  /**
   *
   * @summary getAllSearchHistories
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchHistoryResourceApi
   */
  public getAllSearchHistoriesUsingGET(options?: any) {
    return SearchHistoryResourceApiFp(this.configuration).getAllSearchHistoriesUsingGET(options)(
      this.axios,
      this.basePath,
    )
  }

  /**
   *
   * @summary getSearchHistory
   * @param {number} id id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchHistoryResourceApi
   */
  public getSearchHistoryUsingGET(id: number, options?: any) {
    return SearchHistoryResourceApiFp(this.configuration).getSearchHistoryUsingGET(id, options)(
      this.axios,
      this.basePath,
    )
  }

  /**
   *
   * @summary searchSearchHistories
   * @param {string} query query
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchHistoryResourceApi
   */
  public searchSearchHistoriesUsingGET(query: string, options?: any) {
    return SearchHistoryResourceApiFp(this.configuration).searchSearchHistoriesUsingGET(query, options)(
      this.axios,
      this.basePath,
    )
  }

  /**
   *
   * @summary updateSearchHistory
   * @param {SearchHistoryDTO} searchHistoryDTO searchHistoryDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchHistoryResourceApi
   */
  public updateSearchHistoryUsingPUT(searchHistoryDTO: SearchHistoryDTO, options?: any) {
    return SearchHistoryResourceApiFp(this.configuration).updateSearchHistoryUsingPUT(searchHistoryDTO, options)(
      this.axios,
      this.basePath,
    )
  }
}

/**
 * UserJwtControllerApi - axios parameter creator
 * @export
 */
export const UserJwtControllerApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary authorize
     * @param {LoginVM} loginVM loginVM
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authorizeUsingPOST(loginVM: LoginVM, options: any = {}): RequestArgs {
      // verify required parameter 'loginVM' is not null or undefined
      if (loginVM === null || loginVM === undefined) {
        throw new RequiredError(
          'loginVM',
          'Required parameter loginVM was null or undefined when calling authorizeUsingPOST.',
        )
      }
      const localVarPath = `/api/authenticate`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof loginVM !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(loginVM !== undefined ? loginVM : {})
        : loginVM || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * UserJwtControllerApi - functional programming interface
 * @export
 */
export const UserJwtControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary authorize
     * @param {LoginVM} loginVM loginVM
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authorizeUsingPOST(
      loginVM: LoginVM,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<JWTToken> {
      const localVarAxiosArgs = UserJwtControllerApiAxiosParamCreator(configuration).authorizeUsingPOST(
        loginVM,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * UserJwtControllerApi - factory interface
 * @export
 */
export const UserJwtControllerApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary authorize
     * @param {LoginVM} loginVM loginVM
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authorizeUsingPOST(loginVM: LoginVM, options?: any): AxiosPromise<JWTToken> {
      return UserJwtControllerApiFp(configuration).authorizeUsingPOST(loginVM, options)(axios, basePath)
    },
  }
}

/**
 * UserJwtControllerApi - object-oriented interface
 * @export
 * @class UserJwtControllerApi
 * @extends {BaseAPI}
 */
export class UserJwtControllerApi extends BaseAPI {
  /**
   *
   * @summary authorize
   * @param {LoginVM} loginVM loginVM
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserJwtControllerApi
   */
  public authorizeUsingPOST(loginVM: LoginVM, options?: any) {
    return UserJwtControllerApiFp(this.configuration).authorizeUsingPOST(loginVM, options)(this.axios, this.basePath)
  }
}

/**
 * UserResourceApi - axios parameter creator
 * @export
 */
export const UserResourceApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary createBid
     * @param {number} userId userId
     * @param {BidCreateRequest} requestBody requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBidUsingPOST(userId: number, requestBody: BidCreateRequest, options: any = {}): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling createBidUsingPOST.',
        )
      }
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling createBidUsingPOST.',
        )
      }
      const localVarPath = `/api/users/{userId}/bids`.replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof requestBody !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary createBiddingFlow
     * @param {number} userId userId
     * @param {BiddingFlowCreateRequest} requestBody requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBiddingFlowUsingPOST(userId: number, requestBody: BiddingFlowCreateRequest, options: any = {}): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling createBiddingFlowUsingPOST.',
        )
      }
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling createBiddingFlowUsingPOST.',
        )
      }
      const localVarPath = `/api/users/{userId}/biddingflows`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId)),
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof requestBody !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary createUser
     * @param {UserDTO} userDTO userDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserUsingPOST(userDTO: UserDTO, options: any = {}): RequestArgs {
      // verify required parameter 'userDTO' is not null or undefined
      if (userDTO === null || userDTO === undefined) {
        throw new RequiredError(
          'userDTO',
          'Required parameter userDTO was null or undefined when calling createUserUsingPOST.',
        )
      }
      const localVarPath = `/api/users`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof userDTO !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(userDTO !== undefined ? userDTO : {})
        : userDTO || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary createWalletTransaction
     * @param {number} userId userId
     * @param {WalletTransactionCreateRequest} requestBody requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createWalletTransactionUsingPOST(
      userId: number,
      requestBody: WalletTransactionCreateRequest,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling createWalletTransactionUsingPOST.',
        )
      }
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling createWalletTransactionUsingPOST.',
        )
      }
      const localVarPath = `/api/users/{userId}/wallettransactions`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId)),
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof requestBody !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary deleteUser
     * @param {string} login login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUserUsingDELETE(login: string, options: any = {}): RequestArgs {
      // verify required parameter 'login' is not null or undefined
      if (login === null || login === undefined) {
        throw new RequiredError(
          'login',
          'Required parameter login was null or undefined when calling deleteUserUsingDELETE.',
        )
      }
      const localVarPath = `/api/users/{login}`.replace(`{${'login'}}`, encodeURIComponent(String(login)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary getAllUsers
     * @param {object} queryParams queryParams
     * @param {number} [page] Page number of the requested page
     * @param {number} [size] Size of a page
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllUsersUsingGET(
      queryParams: object,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'queryParams' is not null or undefined
      if (queryParams === null || queryParams === undefined) {
        throw new RequiredError(
          'queryParams',
          'Required parameter queryParams was null or undefined when calling getAllUsersUsingGET.',
        )
      }
      const localVarPath = `/api/users`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (queryParams !== undefined) {
        localVarQueryParameter['queryParams'] = queryParams
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size
      }

      if (sort) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary getAuthorities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAuthoritiesUsingGET(options: any = {}): RequestArgs {
      const localVarPath = `/api/users/authorities`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary indexBids
     * @param {number} userId userId
     * @param {number} [page]
     * @param {number} [size]
     * @param {Array<string>} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexBidsUsingGET(
      userId: number,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling indexBidsUsingGET.',
        )
      }
      const localVarPath = `/api/users/{userId}/bids`.replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size
      }

      if (sort) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary indexOrders
     * @param {number} userId userId
     * @param {Array<string>} [sort]
     * @param {boolean} [sorted]
     * @param {Array<'NEW' | 'SHIPPING_INFORMATION_PROVIDED' | 'VALIDATED' | 'CONFIRMED' | 'PAID' | 'COMPLETED'>} [state] state
     * @param {boolean} [unsorted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexOrdersUsingGET(
      userId: number,
      sort?: Array<string>,
      sorted?: boolean,
      state?: Array<'NEW' | 'SHIPPING_INFORMATION_PROVIDED' | 'VALIDATED' | 'CONFIRMED' | 'PAID' | 'COMPLETED'>,
      unsorted?: boolean,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling indexOrdersUsingGET.',
        )
      }
      const localVarPath = `/api/users/{userId}/orders`.replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (sort) {
        localVarQueryParameter['sort'] = sort
      }

      if (sorted !== undefined) {
        localVarQueryParameter['sorted'] = sorted
      }

      if (state) {
        localVarQueryParameter['state'] = state
      }

      if (unsorted !== undefined) {
        localVarQueryParameter['unsorted'] = unsorted
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary indexWalletTransactions
     * @param {number} userId userId
     * @param {number} [page]
     * @param {number} [size]
     * @param {Array<string>} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexWalletTransactionsUsingGET(
      userId: number,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling indexWalletTransactionsUsingGET.',
        )
      }
      const localVarPath = `/api/users/{userId}/wallettransactions`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId)),
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size
      }

      if (sort) {
        localVarQueryParameter['sort'] = sort
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary readBid
     * @param {number} bidId bidId
     * @param {number} userId userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readBidUsingGET(bidId: number, userId: number, options: any = {}): RequestArgs {
      // verify required parameter 'bidId' is not null or undefined
      if (bidId === null || bidId === undefined) {
        throw new RequiredError('bidId', 'Required parameter bidId was null or undefined when calling readBidUsingGET.')
      }
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling readBidUsingGET.',
        )
      }
      const localVarPath = `/api/users/{userId}/bids/{bidId}`
        .replace(`{${'bidId'}}`, encodeURIComponent(String(bidId)))
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary readOrderDriverLicense
     * @param {number} orderId orderId
     * @param {number} userId userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readOrderDriverLicenseUsingGET(orderId: number, userId: number, options: any = {}): RequestArgs {
      // verify required parameter 'orderId' is not null or undefined
      if (orderId === null || orderId === undefined) {
        throw new RequiredError(
          'orderId',
          'Required parameter orderId was null or undefined when calling readOrderDriverLicenseUsingGET.',
        )
      }
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling readOrderDriverLicenseUsingGET.',
        )
      }
      const localVarPath = `/api/users/{userId}/orders/{orderId}/driverlicense`
        .replace(`{${'orderId'}}`, encodeURIComponent(String(orderId)))
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary readOrder
     * @param {number} orderId orderId
     * @param {number} userId userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readOrderUsingGET(orderId: number, userId: number, options: any = {}): RequestArgs {
      // verify required parameter 'orderId' is not null or undefined
      if (orderId === null || orderId === undefined) {
        throw new RequiredError(
          'orderId',
          'Required parameter orderId was null or undefined when calling readOrderUsingGET.',
        )
      }
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling readOrderUsingGET.',
        )
      }
      const localVarPath = `/api/users/{userId}/orders/{orderId}`
        .replace(`{${'orderId'}}`, encodeURIComponent(String(orderId)))
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary readUser
     * @param {number} userId userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readUserUsingGET(userId: number, options: any = {}): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling readUserUsingGET.',
        )
      }
      const localVarPath = `/api/users/{userId}`.replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary readWalletTransaction
     * @param {number} userId userId
     * @param {number} walletTransactionId walletTransactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readWalletTransactionUsingGET(userId: number, walletTransactionId: number, options: any = {}): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling readWalletTransactionUsingGET.',
        )
      }
      // verify required parameter 'walletTransactionId' is not null or undefined
      if (walletTransactionId === null || walletTransactionId === undefined) {
        throw new RequiredError(
          'walletTransactionId',
          'Required parameter walletTransactionId was null or undefined when calling readWalletTransactionUsingGET.',
        )
      }
      const localVarPath = `/api/users/{userId}/wallettransactions/{walletTransactionId}`
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
        .replace(`{${'walletTransactionId'}}`, encodeURIComponent(String(walletTransactionId)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary searchUsers
     * @param {string} query query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchUsersUsingGET(query: string, options: any = {}): RequestArgs {
      // verify required parameter 'query' is not null or undefined
      if (query === null || query === undefined) {
        throw new RequiredError(
          'query',
          'Required parameter query was null or undefined when calling searchUsersUsingGET.',
        )
      }
      const localVarPath = `/api/_search/users/{query}`.replace(`{${'query'}}`, encodeURIComponent(String(query)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary updateBidState
     * @param {number} bidId bidId
     * @param {number} userId userId
     * @param {'NEW' | 'ACTIVE' | 'OUTBID' | 'CANCELED' | 'WON'} value value
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBidStateUsingPUT(
      bidId: number,
      userId: number,
      value: 'NEW' | 'ACTIVE' | 'OUTBID' | 'CANCELED' | 'WON',
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'bidId' is not null or undefined
      if (bidId === null || bidId === undefined) {
        throw new RequiredError(
          'bidId',
          'Required parameter bidId was null or undefined when calling updateBidStateUsingPUT.',
        )
      }
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling updateBidStateUsingPUT.',
        )
      }
      // verify required parameter 'value' is not null or undefined
      if (value === null || value === undefined) {
        throw new RequiredError(
          'value',
          'Required parameter value was null or undefined when calling updateBidStateUsingPUT.',
        )
      }
      const localVarPath = `/api/users/{userId}/bids/{bidId}/state`
        .replace(`{${'bidId'}}`, encodeURIComponent(String(bidId)))
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (value !== undefined) {
        localVarQueryParameter['value'] = value
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary updateBid
     * @param {number} bidId bidId
     * @param {number} userId userId
     * @param {BidUpdateUserRequest} requestBody requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBidUsingPUT(
      bidId: number,
      userId: number,
      requestBody: BidUpdateUserRequest,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'bidId' is not null or undefined
      if (bidId === null || bidId === undefined) {
        throw new RequiredError(
          'bidId',
          'Required parameter bidId was null or undefined when calling updateBidUsingPUT.',
        )
      }
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling updateBidUsingPUT.',
        )
      }
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling updateBidUsingPUT.',
        )
      }
      const localVarPath = `/api/users/{userId}/bids/{bidId}`
        .replace(`{${'bidId'}}`, encodeURIComponent(String(bidId)))
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof requestBody !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary updateOrderDriverLicense
     * @param {number} orderId orderId
     * @param {number} userId userId
     * @param {any} file file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrderDriverLicenseUsingPOST(orderId: number, userId: number, file: any, options: any = {}): RequestArgs {
      // verify required parameter 'orderId' is not null or undefined
      if (orderId === null || orderId === undefined) {
        throw new RequiredError(
          'orderId',
          'Required parameter orderId was null or undefined when calling updateOrderDriverLicenseUsingPOST.',
        )
      }
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling updateOrderDriverLicenseUsingPOST.',
        )
      }
      // verify required parameter 'file' is not null or undefined
      if (file === null || file === undefined) {
        throw new RequiredError(
          'file',
          'Required parameter file was null or undefined when calling updateOrderDriverLicenseUsingPOST.',
        )
      }
      const localVarPath = `/api/users/{userId}/orders/{orderId}/driverlicense`
        .replace(`{${'orderId'}}`, encodeURIComponent(String(orderId)))
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new FormData()

      if (file !== undefined) {
        localVarFormParams.append('file', file as any)
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary updateOrder
     * @param {number} orderId orderId
     * @param {number} userId userId
     * @param {OrderUpdateUserRequest} requestBody requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrderUsingPUT(
      orderId: number,
      userId: number,
      requestBody: OrderUpdateUserRequest,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'orderId' is not null or undefined
      if (orderId === null || orderId === undefined) {
        throw new RequiredError(
          'orderId',
          'Required parameter orderId was null or undefined when calling updateOrderUsingPUT.',
        )
      }
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling updateOrderUsingPUT.',
        )
      }
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling updateOrderUsingPUT.',
        )
      }
      const localVarPath = `/api/users/{userId}/orders/{orderId}`
        .replace(`{${'orderId'}}`, encodeURIComponent(String(orderId)))
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof requestBody !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary updateUser
     * @param {UserDTO} userDTO userDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserUsingPUT(userDTO: UserDTO, options: any = {}): RequestArgs {
      // verify required parameter 'userDTO' is not null or undefined
      if (userDTO === null || userDTO === undefined) {
        throw new RequiredError(
          'userDTO',
          'Required parameter userDTO was null or undefined when calling updateUserUsingPUT.',
        )
      }
      const localVarPath = `/api/users`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof userDTO !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(userDTO !== undefined ? userDTO : {})
        : userDTO || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary updateWalletTransaction
     * @param {number} userId userId
     * @param {number} walletTransactionId walletTransactionId
     * @param {WalletTransactionUpdateRequest} requestBody requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateWalletTransactionUsingPUT(
      userId: number,
      walletTransactionId: number,
      requestBody: WalletTransactionUpdateRequest,
      options: any = {},
    ): RequestArgs {
      // verify required parameter 'userId' is not null or undefined
      if (userId === null || userId === undefined) {
        throw new RequiredError(
          'userId',
          'Required parameter userId was null or undefined when calling updateWalletTransactionUsingPUT.',
        )
      }
      // verify required parameter 'walletTransactionId' is not null or undefined
      if (walletTransactionId === null || walletTransactionId === undefined) {
        throw new RequiredError(
          'walletTransactionId',
          'Required parameter walletTransactionId was null or undefined when calling updateWalletTransactionUsingPUT.',
        )
      }
      // verify required parameter 'requestBody' is not null or undefined
      if (requestBody === null || requestBody === undefined) {
        throw new RequiredError(
          'requestBody',
          'Required parameter requestBody was null or undefined when calling updateWalletTransactionUsingPUT.',
        )
      }
      const localVarPath = `/api/users/{userId}/wallettransactions/{walletTransactionId}`
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
        .replace(`{${'walletTransactionId'}}`, encodeURIComponent(String(walletTransactionId)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof requestBody !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requestBody !== undefined ? requestBody : {})
        : requestBody || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * UserResourceApi - functional programming interface
 * @export
 */
export const UserResourceApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary createBid
     * @param {number} userId userId
     * @param {BidCreateRequest} requestBody requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBidUsingPOST(
      userId: number,
      requestBody: BidCreateRequest,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BidDto> {
      const localVarAxiosArgs = UserResourceApiAxiosParamCreator(configuration).createBidUsingPOST(
        userId,
        requestBody,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary createBiddingFlow
     * @param {number} userId userId
     * @param {BiddingFlowCreateRequest} requestBody requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBiddingFlowUsingPOST(
      userId: number,
      requestBody: BiddingFlowCreateRequest,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BiddingFlowDto> {
      const localVarAxiosArgs = UserResourceApiAxiosParamCreator(configuration).createBiddingFlowUsingPOST(
        userId,
        requestBody,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary createUser
     * @param {UserDTO} userDTO userDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserUsingPOST(
      userDTO: UserDTO,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User> {
      const localVarAxiosArgs = UserResourceApiAxiosParamCreator(configuration).createUserUsingPOST(userDTO, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary createWalletTransaction
     * @param {number} userId userId
     * @param {WalletTransactionCreateRequest} requestBody requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createWalletTransactionUsingPOST(
      userId: number,
      requestBody: WalletTransactionCreateRequest,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletTransactionDto> {
      const localVarAxiosArgs = UserResourceApiAxiosParamCreator(configuration).createWalletTransactionUsingPOST(
        userId,
        requestBody,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary deleteUser
     * @param {string} login login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUserUsingDELETE(
      login: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = UserResourceApiAxiosParamCreator(configuration).deleteUserUsingDELETE(login, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary getAllUsers
     * @param {object} queryParams queryParams
     * @param {number} [page] Page number of the requested page
     * @param {number} [size] Size of a page
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllUsersUsingGET(
      queryParams: object,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDTO>> {
      const localVarAxiosArgs = UserResourceApiAxiosParamCreator(configuration).getAllUsersUsingGET(
        queryParams,
        page,
        size,
        sort,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary getAuthorities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAuthoritiesUsingGET(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
      const localVarAxiosArgs = UserResourceApiAxiosParamCreator(configuration).getAuthoritiesUsingGET(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary indexBids
     * @param {number} userId userId
     * @param {number} [page]
     * @param {number} [size]
     * @param {Array<string>} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexBidsUsingGET(
      userId: number,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BidDto>> {
      const localVarAxiosArgs = UserResourceApiAxiosParamCreator(configuration).indexBidsUsingGET(
        userId,
        page,
        size,
        sort,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary indexOrders
     * @param {number} userId userId
     * @param {Array<string>} [sort]
     * @param {boolean} [sorted]
     * @param {Array<'NEW' | 'SHIPPING_INFORMATION_PROVIDED' | 'VALIDATED' | 'CONFIRMED' | 'PAID' | 'COMPLETED'>} [state] state
     * @param {boolean} [unsorted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexOrdersUsingGET(
      userId: number,
      sort?: Array<string>,
      sorted?: boolean,
      state?: Array<'NEW' | 'SHIPPING_INFORMATION_PROVIDED' | 'VALIDATED' | 'CONFIRMED' | 'PAID' | 'COMPLETED'>,
      unsorted?: boolean,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderDto>> {
      const localVarAxiosArgs = UserResourceApiAxiosParamCreator(configuration).indexOrdersUsingGET(
        userId,
        sort,
        sorted,
        state,
        unsorted,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary indexWalletTransactions
     * @param {number} userId userId
     * @param {number} [page]
     * @param {number} [size]
     * @param {Array<string>} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexWalletTransactionsUsingGET(
      userId: number,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WalletTransactionDto>> {
      const localVarAxiosArgs = UserResourceApiAxiosParamCreator(configuration).indexWalletTransactionsUsingGET(
        userId,
        page,
        size,
        sort,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary readBid
     * @param {number} bidId bidId
     * @param {number} userId userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readBidUsingGET(
      bidId: number,
      userId: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BidDto> {
      const localVarAxiosArgs = UserResourceApiAxiosParamCreator(configuration).readBidUsingGET(bidId, userId, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary readOrderDriverLicense
     * @param {number} orderId orderId
     * @param {number} userId userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readOrderDriverLicenseUsingGET(
      orderId: number,
      userId: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Resource> {
      const localVarAxiosArgs = UserResourceApiAxiosParamCreator(configuration).readOrderDriverLicenseUsingGET(
        orderId,
        userId,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary readOrder
     * @param {number} orderId orderId
     * @param {number} userId userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readOrderUsingGET(
      orderId: number,
      userId: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDto> {
      const localVarAxiosArgs = UserResourceApiAxiosParamCreator(configuration).readOrderUsingGET(
        orderId,
        userId,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary readUser
     * @param {number} userId userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readUserUsingGET(
      userId: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDTO> {
      const localVarAxiosArgs = UserResourceApiAxiosParamCreator(configuration).readUserUsingGET(userId, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary readWalletTransaction
     * @param {number} userId userId
     * @param {number} walletTransactionId walletTransactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readWalletTransactionUsingGET(
      userId: number,
      walletTransactionId: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletTransactionDto> {
      const localVarAxiosArgs = UserResourceApiAxiosParamCreator(configuration).readWalletTransactionUsingGET(
        userId,
        walletTransactionId,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary searchUsers
     * @param {string} query query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchUsersUsingGET(
      query: string,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>> {
      const localVarAxiosArgs = UserResourceApiAxiosParamCreator(configuration).searchUsersUsingGET(query, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary updateBidState
     * @param {number} bidId bidId
     * @param {number} userId userId
     * @param {'NEW' | 'ACTIVE' | 'OUTBID' | 'CANCELED' | 'WON'} value value
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBidStateUsingPUT(
      bidId: number,
      userId: number,
      value: 'NEW' | 'ACTIVE' | 'OUTBID' | 'CANCELED' | 'WON',
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = UserResourceApiAxiosParamCreator(configuration).updateBidStateUsingPUT(
        bidId,
        userId,
        value,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary updateBid
     * @param {number} bidId bidId
     * @param {number} userId userId
     * @param {BidUpdateUserRequest} requestBody requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBidUsingPUT(
      bidId: number,
      userId: number,
      requestBody: BidUpdateUserRequest,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BidDto> {
      const localVarAxiosArgs = UserResourceApiAxiosParamCreator(configuration).updateBidUsingPUT(
        bidId,
        userId,
        requestBody,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary updateOrderDriverLicense
     * @param {number} orderId orderId
     * @param {number} userId userId
     * @param {any} file file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrderDriverLicenseUsingPOST(
      orderId: number,
      userId: number,
      file: any,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = UserResourceApiAxiosParamCreator(configuration).updateOrderDriverLicenseUsingPOST(
        orderId,
        userId,
        file,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary updateOrder
     * @param {number} orderId orderId
     * @param {number} userId userId
     * @param {OrderUpdateUserRequest} requestBody requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrderUsingPUT(
      orderId: number,
      userId: number,
      requestBody: OrderUpdateUserRequest,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = UserResourceApiAxiosParamCreator(configuration).updateOrderUsingPUT(
        orderId,
        userId,
        requestBody,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary updateUser
     * @param {UserDTO} userDTO userDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserUsingPUT(
      userDTO: UserDTO,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDTO> {
      const localVarAxiosArgs = UserResourceApiAxiosParamCreator(configuration).updateUserUsingPUT(userDTO, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary updateWalletTransaction
     * @param {number} userId userId
     * @param {number} walletTransactionId walletTransactionId
     * @param {WalletTransactionUpdateRequest} requestBody requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateWalletTransactionUsingPUT(
      userId: number,
      walletTransactionId: number,
      requestBody: WalletTransactionUpdateRequest,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = UserResourceApiAxiosParamCreator(configuration).updateWalletTransactionUsingPUT(
        userId,
        walletTransactionId,
        requestBody,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * UserResourceApi - factory interface
 * @export
 */
export const UserResourceApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary createBid
     * @param {number} userId userId
     * @param {BidCreateRequest} requestBody requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBidUsingPOST(userId: number, requestBody: BidCreateRequest, options?: any): AxiosPromise<BidDto> {
      return UserResourceApiFp(configuration).createBidUsingPOST(userId, requestBody, options)(axios, basePath)
    },
    /**
     *
     * @summary createBiddingFlow
     * @param {number} userId userId
     * @param {BiddingFlowCreateRequest} requestBody requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBiddingFlowUsingPOST(
      userId: number,
      requestBody: BiddingFlowCreateRequest,
      options?: any,
    ): AxiosPromise<BiddingFlowDto> {
      return UserResourceApiFp(configuration).createBiddingFlowUsingPOST(userId, requestBody, options)(axios, basePath)
    },
    /**
     *
     * @summary createUser
     * @param {UserDTO} userDTO userDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserUsingPOST(userDTO: UserDTO, options?: any): AxiosPromise<User> {
      return UserResourceApiFp(configuration).createUserUsingPOST(userDTO, options)(axios, basePath)
    },
    /**
     *
     * @summary createWalletTransaction
     * @param {number} userId userId
     * @param {WalletTransactionCreateRequest} requestBody requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createWalletTransactionUsingPOST(
      userId: number,
      requestBody: WalletTransactionCreateRequest,
      options?: any,
    ): AxiosPromise<WalletTransactionDto> {
      return UserResourceApiFp(configuration).createWalletTransactionUsingPOST(
        userId,
        requestBody,
        options,
      )(axios, basePath)
    },
    /**
     *
     * @summary deleteUser
     * @param {string} login login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUserUsingDELETE(login: string, options?: any): AxiosPromise<void> {
      return UserResourceApiFp(configuration).deleteUserUsingDELETE(login, options)(axios, basePath)
    },
    /**
     *
     * @summary getAllUsers
     * @param {object} queryParams queryParams
     * @param {number} [page] Page number of the requested page
     * @param {number} [size] Size of a page
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllUsersUsingGET(
      queryParams: object,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<Array<UserDTO>> {
      return UserResourceApiFp(configuration).getAllUsersUsingGET(
        queryParams,
        page,
        size,
        sort,
        options,
      )(axios, basePath)
    },
    /**
     *
     * @summary getAuthorities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAuthoritiesUsingGET(options?: any): AxiosPromise<Array<string>> {
      return UserResourceApiFp(configuration).getAuthoritiesUsingGET(options)(axios, basePath)
    },
    /**
     *
     * @summary indexBids
     * @param {number} userId userId
     * @param {number} [page]
     * @param {number} [size]
     * @param {Array<string>} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexBidsUsingGET(
      userId: number,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<Array<BidDto>> {
      return UserResourceApiFp(configuration).indexBidsUsingGET(userId, page, size, sort, options)(axios, basePath)
    },
    /**
     *
     * @summary indexOrders
     * @param {number} userId userId
     * @param {Array<string>} [sort]
     * @param {boolean} [sorted]
     * @param {Array<'NEW' | 'SHIPPING_INFORMATION_PROVIDED' | 'VALIDATED' | 'CONFIRMED' | 'PAID' | 'COMPLETED'>} [state] state
     * @param {boolean} [unsorted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexOrdersUsingGET(
      userId: number,
      sort?: Array<string>,
      sorted?: boolean,
      state?: Array<'NEW' | 'SHIPPING_INFORMATION_PROVIDED' | 'VALIDATED' | 'CONFIRMED' | 'PAID' | 'COMPLETED'>,
      unsorted?: boolean,
      options?: any,
    ): AxiosPromise<Array<OrderDto>> {
      return UserResourceApiFp(configuration).indexOrdersUsingGET(
        userId,
        sort,
        sorted,
        state,
        unsorted,
        options,
      )(axios, basePath)
    },
    /**
     *
     * @summary indexWalletTransactions
     * @param {number} userId userId
     * @param {number} [page]
     * @param {number} [size]
     * @param {Array<string>} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexWalletTransactionsUsingGET(
      userId: number,
      page?: number,
      size?: number,
      sort?: Array<string>,
      options?: any,
    ): AxiosPromise<Array<WalletTransactionDto>> {
      return UserResourceApiFp(configuration).indexWalletTransactionsUsingGET(
        userId,
        page,
        size,
        sort,
        options,
      )(axios, basePath)
    },
    /**
     *
     * @summary readBid
     * @param {number} bidId bidId
     * @param {number} userId userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readBidUsingGET(bidId: number, userId: number, options?: any): AxiosPromise<BidDto> {
      return UserResourceApiFp(configuration).readBidUsingGET(bidId, userId, options)(axios, basePath)
    },
    /**
     *
     * @summary readOrderDriverLicense
     * @param {number} orderId orderId
     * @param {number} userId userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readOrderDriverLicenseUsingGET(orderId: number, userId: number, options?: any): AxiosPromise<Resource> {
      return UserResourceApiFp(configuration).readOrderDriverLicenseUsingGET(orderId, userId, options)(axios, basePath)
    },
    /**
     *
     * @summary readOrder
     * @param {number} orderId orderId
     * @param {number} userId userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readOrderUsingGET(orderId: number, userId: number, options?: any): AxiosPromise<OrderDto> {
      return UserResourceApiFp(configuration).readOrderUsingGET(orderId, userId, options)(axios, basePath)
    },
    /**
     *
     * @summary readUser
     * @param {number} userId userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readUserUsingGET(userId: number, options?: any): AxiosPromise<UserDTO> {
      return UserResourceApiFp(configuration).readUserUsingGET(userId, options)(axios, basePath)
    },
    /**
     *
     * @summary readWalletTransaction
     * @param {number} userId userId
     * @param {number} walletTransactionId walletTransactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readWalletTransactionUsingGET(
      userId: number,
      walletTransactionId: number,
      options?: any,
    ): AxiosPromise<WalletTransactionDto> {
      return UserResourceApiFp(configuration).readWalletTransactionUsingGET(
        userId,
        walletTransactionId,
        options,
      )(axios, basePath)
    },
    /**
     *
     * @summary searchUsers
     * @param {string} query query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchUsersUsingGET(query: string, options?: any): AxiosPromise<Array<User>> {
      return UserResourceApiFp(configuration).searchUsersUsingGET(query, options)(axios, basePath)
    },
    /**
     *
     * @summary updateBidState
     * @param {number} bidId bidId
     * @param {number} userId userId
     * @param {'NEW' | 'ACTIVE' | 'OUTBID' | 'CANCELED' | 'WON'} value value
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBidStateUsingPUT(
      bidId: number,
      userId: number,
      value: 'NEW' | 'ACTIVE' | 'OUTBID' | 'CANCELED' | 'WON',
      options?: any,
    ): AxiosPromise<void> {
      return UserResourceApiFp(configuration).updateBidStateUsingPUT(bidId, userId, value, options)(axios, basePath)
    },
    /**
     *
     * @summary updateBid
     * @param {number} bidId bidId
     * @param {number} userId userId
     * @param {BidUpdateUserRequest} requestBody requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBidUsingPUT(
      bidId: number,
      userId: number,
      requestBody: BidUpdateUserRequest,
      options?: any,
    ): AxiosPromise<BidDto> {
      return UserResourceApiFp(configuration).updateBidUsingPUT(bidId, userId, requestBody, options)(axios, basePath)
    },
    /**
     *
     * @summary updateOrderDriverLicense
     * @param {number} orderId orderId
     * @param {number} userId userId
     * @param {any} file file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrderDriverLicenseUsingPOST(orderId: number, userId: number, file: any, options?: any): AxiosPromise<void> {
      return UserResourceApiFp(configuration).updateOrderDriverLicenseUsingPOST(
        orderId,
        userId,
        file,
        options,
      )(axios, basePath)
    },
    /**
     *
     * @summary updateOrder
     * @param {number} orderId orderId
     * @param {number} userId userId
     * @param {OrderUpdateUserRequest} requestBody requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrderUsingPUT(
      orderId: number,
      userId: number,
      requestBody: OrderUpdateUserRequest,
      options?: any,
    ): AxiosPromise<void> {
      return UserResourceApiFp(configuration).updateOrderUsingPUT(
        orderId,
        userId,
        requestBody,
        options,
      )(axios, basePath)
    },
    /**
     *
     * @summary updateUser
     * @param {UserDTO} userDTO userDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserUsingPUT(userDTO: UserDTO, options?: any): AxiosPromise<UserDTO> {
      return UserResourceApiFp(configuration).updateUserUsingPUT(userDTO, options)(axios, basePath)
    },
    /**
     *
     * @summary updateWalletTransaction
     * @param {number} userId userId
     * @param {number} walletTransactionId walletTransactionId
     * @param {WalletTransactionUpdateRequest} requestBody requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateWalletTransactionUsingPUT(
      userId: number,
      walletTransactionId: number,
      requestBody: WalletTransactionUpdateRequest,
      options?: any,
    ): AxiosPromise<void> {
      return UserResourceApiFp(configuration).updateWalletTransactionUsingPUT(
        userId,
        walletTransactionId,
        requestBody,
        options,
      )(axios, basePath)
    },
  }
}

/**
 * UserResourceApi - object-oriented interface
 * @export
 * @class UserResourceApi
 * @extends {BaseAPI}
 */
export class UserResourceApi extends BaseAPI {
  /**
   *
   * @summary createBid
   * @param {number} userId userId
   * @param {BidCreateRequest} requestBody requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserResourceApi
   */
  public createBidUsingPOST(userId: number, requestBody: BidCreateRequest, options?: any) {
    return UserResourceApiFp(this.configuration).createBidUsingPOST(
      userId,
      requestBody,
      options,
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary createBiddingFlow
   * @param {number} userId userId
   * @param {BiddingFlowCreateRequest} requestBody requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserResourceApi
   */
  public createBiddingFlowUsingPOST(userId: number, requestBody: BiddingFlowCreateRequest, options?: any) {
    return UserResourceApiFp(this.configuration).createBiddingFlowUsingPOST(
      userId,
      requestBody,
      options,
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary createUser
   * @param {UserDTO} userDTO userDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserResourceApi
   */
  public createUserUsingPOST(userDTO: UserDTO, options?: any) {
    return UserResourceApiFp(this.configuration).createUserUsingPOST(userDTO, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary createWalletTransaction
   * @param {number} userId userId
   * @param {WalletTransactionCreateRequest} requestBody requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserResourceApi
   */
  public createWalletTransactionUsingPOST(userId: number, requestBody: WalletTransactionCreateRequest, options?: any) {
    return UserResourceApiFp(this.configuration).createWalletTransactionUsingPOST(
      userId,
      requestBody,
      options,
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary deleteUser
   * @param {string} login login
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserResourceApi
   */
  public deleteUserUsingDELETE(login: string, options?: any) {
    return UserResourceApiFp(this.configuration).deleteUserUsingDELETE(login, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary getAllUsers
   * @param {object} queryParams queryParams
   * @param {number} [page] Page number of the requested page
   * @param {number} [size] Size of a page
   * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserResourceApi
   */
  public getAllUsersUsingGET(queryParams: object, page?: number, size?: number, sort?: Array<string>, options?: any) {
    return UserResourceApiFp(this.configuration).getAllUsersUsingGET(
      queryParams,
      page,
      size,
      sort,
      options,
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary getAuthorities
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserResourceApi
   */
  public getAuthoritiesUsingGET(options?: any) {
    return UserResourceApiFp(this.configuration).getAuthoritiesUsingGET(options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary indexBids
   * @param {number} userId userId
   * @param {number} [page]
   * @param {number} [size]
   * @param {Array<string>} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserResourceApi
   */
  public indexBidsUsingGET(userId: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
    return UserResourceApiFp(this.configuration).indexBidsUsingGET(
      userId,
      page,
      size,
      sort,
      options,
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary indexOrders
   * @param {number} userId userId
   * @param {Array<string>} [sort]
   * @param {boolean} [sorted]
   * @param {Array<'NEW' | 'SHIPPING_INFORMATION_PROVIDED' | 'VALIDATED' | 'CONFIRMED' | 'PAID' | 'COMPLETED'>} [state] state
   * @param {boolean} [unsorted]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserResourceApi
   */
  public indexOrdersUsingGET(
    userId: number,
    sort?: Array<string>,
    sorted?: boolean,
    state?: Array<'NEW' | 'SHIPPING_INFORMATION_PROVIDED' | 'VALIDATED' | 'CONFIRMED' | 'PAID' | 'COMPLETED'>,
    unsorted?: boolean,
    options?: any,
  ) {
    return UserResourceApiFp(this.configuration).indexOrdersUsingGET(
      userId,
      sort,
      sorted,
      state,
      unsorted,
      options,
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary indexWalletTransactions
   * @param {number} userId userId
   * @param {number} [page]
   * @param {number} [size]
   * @param {Array<string>} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserResourceApi
   */
  public indexWalletTransactionsUsingGET(
    userId: number,
    page?: number,
    size?: number,
    sort?: Array<string>,
    options?: any,
  ) {
    return UserResourceApiFp(this.configuration).indexWalletTransactionsUsingGET(
      userId,
      page,
      size,
      sort,
      options,
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary readBid
   * @param {number} bidId bidId
   * @param {number} userId userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserResourceApi
   */
  public readBidUsingGET(bidId: number, userId: number, options?: any) {
    return UserResourceApiFp(this.configuration).readBidUsingGET(bidId, userId, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary readOrderDriverLicense
   * @param {number} orderId orderId
   * @param {number} userId userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserResourceApi
   */
  public readOrderDriverLicenseUsingGET(orderId: number, userId: number, options?: any) {
    return UserResourceApiFp(this.configuration).readOrderDriverLicenseUsingGET(
      orderId,
      userId,
      options,
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary readOrder
   * @param {number} orderId orderId
   * @param {number} userId userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserResourceApi
   */
  public readOrderUsingGET(orderId: number, userId: number, options?: any) {
    return UserResourceApiFp(this.configuration).readOrderUsingGET(orderId, userId, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary readUser
   * @param {number} userId userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserResourceApi
   */
  public readUserUsingGET(userId: number, options?: any) {
    return UserResourceApiFp(this.configuration).readUserUsingGET(userId, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary readWalletTransaction
   * @param {number} userId userId
   * @param {number} walletTransactionId walletTransactionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserResourceApi
   */
  public readWalletTransactionUsingGET(userId: number, walletTransactionId: number, options?: any) {
    return UserResourceApiFp(this.configuration).readWalletTransactionUsingGET(
      userId,
      walletTransactionId,
      options,
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary searchUsers
   * @param {string} query query
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserResourceApi
   */
  public searchUsersUsingGET(query: string, options?: any) {
    return UserResourceApiFp(this.configuration).searchUsersUsingGET(query, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary updateBidState
   * @param {number} bidId bidId
   * @param {number} userId userId
   * @param {'NEW' | 'ACTIVE' | 'OUTBID' | 'CANCELED' | 'WON'} value value
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserResourceApi
   */
  public updateBidStateUsingPUT(
    bidId: number,
    userId: number,
    value: 'NEW' | 'ACTIVE' | 'OUTBID' | 'CANCELED' | 'WON',
    options?: any,
  ) {
    return UserResourceApiFp(this.configuration).updateBidStateUsingPUT(
      bidId,
      userId,
      value,
      options,
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary updateBid
   * @param {number} bidId bidId
   * @param {number} userId userId
   * @param {BidUpdateUserRequest} requestBody requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserResourceApi
   */
  public updateBidUsingPUT(bidId: number, userId: number, requestBody: BidUpdateUserRequest, options?: any) {
    return UserResourceApiFp(this.configuration).updateBidUsingPUT(
      bidId,
      userId,
      requestBody,
      options,
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary updateOrderDriverLicense
   * @param {number} orderId orderId
   * @param {number} userId userId
   * @param {any} file file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserResourceApi
   */
  public updateOrderDriverLicenseUsingPOST(orderId: number, userId: number, file: any, options?: any) {
    return UserResourceApiFp(this.configuration).updateOrderDriverLicenseUsingPOST(
      orderId,
      userId,
      file,
      options,
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary updateOrder
   * @param {number} orderId orderId
   * @param {number} userId userId
   * @param {OrderUpdateUserRequest} requestBody requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserResourceApi
   */
  public updateOrderUsingPUT(orderId: number, userId: number, requestBody: OrderUpdateUserRequest, options?: any) {
    return UserResourceApiFp(this.configuration).updateOrderUsingPUT(
      orderId,
      userId,
      requestBody,
      options,
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary updateUser
   * @param {UserDTO} userDTO userDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserResourceApi
   */
  public updateUserUsingPUT(userDTO: UserDTO, options?: any) {
    return UserResourceApiFp(this.configuration).updateUserUsingPUT(userDTO, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary updateWalletTransaction
   * @param {number} userId userId
   * @param {number} walletTransactionId walletTransactionId
   * @param {WalletTransactionUpdateRequest} requestBody requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserResourceApi
   */
  public updateWalletTransactionUsingPUT(
    userId: number,
    walletTransactionId: number,
    requestBody: WalletTransactionUpdateRequest,
    options?: any,
  ) {
    return UserResourceApiFp(this.configuration).updateWalletTransactionUsingPUT(
      userId,
      walletTransactionId,
      requestBody,
      options,
    )(this.axios, this.basePath)
  }
}

/**
 * VehicleStatesControllerApi - axios parameter creator
 * @export
 */
export const VehicleStatesControllerApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary indexVehicleStates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexVehicleStatesUsingGET(options: any = {}): RequestArgs {
      const localVarPath = `/api/vehiclestates`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary readVehicleState
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readVehicleStateUsingGET(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling readVehicleStateUsingGET.',
        )
      }
      const localVarPath = `/api/vehiclestates/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * VehicleStatesControllerApi - functional programming interface
 * @export
 */
export const VehicleStatesControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary indexVehicleStates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexVehicleStatesUsingGET(
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VehicleStateDto>> {
      const localVarAxiosArgs = VehicleStatesControllerApiAxiosParamCreator(configuration).indexVehicleStatesUsingGET(
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary readVehicleState
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readVehicleStateUsingGET(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleStateDto> {
      const localVarAxiosArgs = VehicleStatesControllerApiAxiosParamCreator(configuration).readVehicleStateUsingGET(
        id,
        options,
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * VehicleStatesControllerApi - factory interface
 * @export
 */
export const VehicleStatesControllerApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary indexVehicleStates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexVehicleStatesUsingGET(options?: any): AxiosPromise<Array<VehicleStateDto>> {
      return VehicleStatesControllerApiFp(configuration).indexVehicleStatesUsingGET(options)(axios, basePath)
    },
    /**
     *
     * @summary readVehicleState
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readVehicleStateUsingGET(id: number, options?: any): AxiosPromise<VehicleStateDto> {
      return VehicleStatesControllerApiFp(configuration).readVehicleStateUsingGET(id, options)(axios, basePath)
    },
  }
}

/**
 * VehicleStatesControllerApi - object-oriented interface
 * @export
 * @class VehicleStatesControllerApi
 * @extends {BaseAPI}
 */
export class VehicleStatesControllerApi extends BaseAPI {
  /**
   *
   * @summary indexVehicleStates
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleStatesControllerApi
   */
  public indexVehicleStatesUsingGET(options?: any) {
    return VehicleStatesControllerApiFp(this.configuration).indexVehicleStatesUsingGET(options)(
      this.axios,
      this.basePath,
    )
  }

  /**
   *
   * @summary readVehicleState
   * @param {number} id id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleStatesControllerApi
   */
  public readVehicleStateUsingGET(id: number, options?: any) {
    return VehicleStatesControllerApiFp(this.configuration).readVehicleStateUsingGET(id, options)(
      this.axios,
      this.basePath,
    )
  }
}

/**
 * WalletTransactionTypesControllerApi - axios parameter creator
 * @export
 */
export const WalletTransactionTypesControllerApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary indexWalletTransactionTypes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexWalletTransactionTypesUsingGET(options: any = {}): RequestArgs {
      const localVarPath = `/api/wallettransactiontypes`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary readWalletTransactionType
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readWalletTransactionTypeUsingGET(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling readWalletTransactionTypeUsingGET.',
        )
      }
      const localVarPath = `/api/wallettransactiontypes/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * WalletTransactionTypesControllerApi - functional programming interface
 * @export
 */
export const WalletTransactionTypesControllerApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary indexWalletTransactionTypes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexWalletTransactionTypesUsingGET(
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WalletTransactionTypeDto>> {
      const localVarAxiosArgs = WalletTransactionTypesControllerApiAxiosParamCreator(
        configuration,
      ).indexWalletTransactionTypesUsingGET(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary readWalletTransactionType
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readWalletTransactionTypeUsingGET(
      id: number,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletTransactionTypeDto> {
      const localVarAxiosArgs = WalletTransactionTypesControllerApiAxiosParamCreator(
        configuration,
      ).readWalletTransactionTypeUsingGET(id, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * WalletTransactionTypesControllerApi - factory interface
 * @export
 */
export const WalletTransactionTypesControllerApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary indexWalletTransactionTypes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexWalletTransactionTypesUsingGET(options?: any): AxiosPromise<Array<WalletTransactionTypeDto>> {
      return WalletTransactionTypesControllerApiFp(configuration).indexWalletTransactionTypesUsingGET(options)(
        axios,
        basePath,
      )
    },
    /**
     *
     * @summary readWalletTransactionType
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readWalletTransactionTypeUsingGET(id: number, options?: any): AxiosPromise<WalletTransactionTypeDto> {
      return WalletTransactionTypesControllerApiFp(configuration).readWalletTransactionTypeUsingGET(id, options)(
        axios,
        basePath,
      )
    },
  }
}

/**
 * WalletTransactionTypesControllerApi - object-oriented interface
 * @export
 * @class WalletTransactionTypesControllerApi
 * @extends {BaseAPI}
 */
export class WalletTransactionTypesControllerApi extends BaseAPI {
  /**
   *
   * @summary indexWalletTransactionTypes
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletTransactionTypesControllerApi
   */
  public indexWalletTransactionTypesUsingGET(options?: any) {
    return WalletTransactionTypesControllerApiFp(this.configuration).indexWalletTransactionTypesUsingGET(options)(
      this.axios,
      this.basePath,
    )
  }

  /**
   *
   * @summary readWalletTransactionType
   * @param {number} id id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WalletTransactionTypesControllerApi
   */
  public readWalletTransactionTypeUsingGET(id: number, options?: any) {
    return WalletTransactionTypesControllerApiFp(this.configuration).readWalletTransactionTypeUsingGET(id, options)(
      this.axios,
      this.basePath,
    )
  }
}
