import React from 'react'
import cn from 'classnames'
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component'
import { SectionHeaderDynamic } from '@/ui/atoms'
import { Reason, ReasonDescription, ReasonTitle } from './reason'
import styles from './why-customers-choose-us.module.scss'

export const WhyCustomersChooseUs: React.FC<{
  className?: string
  benefits: {
    title: string
    benefits: {
      title: string
      description: string
      icon: string
    }[]
  }
}> = ({ className, benefits }) => (
  <section className={cn(className, styles.section)}>
    <LazyLoadComponent>
      <SectionHeaderDynamic type="light" className={styles.sectionHeaderDynamic} text={benefits.title} />
      <div className={cn(styles.grid, 'container')}>
        {benefits.benefits.map((i, index) => (
          <Reason key={index}>
            <LazyLoadImage src={i.icon} className={styles.lazyLoadImage} alt="img" />
            <ReasonTitle>{i.title}</ReasonTitle>
            <ReasonDescription>{i.description}</ReasonDescription>
          </Reason>
        ))}
      </div>
    </LazyLoadComponent>
  </section>
)
