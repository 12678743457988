import React from 'react'

import cn from 'classnames'

import { Icon, CustomScrollBar } from '@/ui/atoms'

import styles from './popup.module.scss'

type Props = {
  noScroll?: boolean
  size?: 'small' | 'large'
  color?: 'dark' | 'grey'
  handler: (activePopup: number) => void
  height?: number | string
  isCloseButton?: boolean
}

export const Popup: React.FC<Props> = ({
  noScroll,
  size = 'large',
  color = 'dark',
  children,
  handler,
  height,
  isCloseButton = true,
}) => {
  const popupWindowClasses = cn(
    styles.popupWindow,
    { [styles.popupWindowSizeSmall]: size === 'small' },
    { [styles.popupWindowSizeLarge]: size === 'large' },
  )

  const popupBackgroundColorClasses = cn(
    styles.popupBackgroundColor,
    { [styles.popupBackgroundColorDark]: color === 'dark' },
    { [styles.popupBackgroundColorGrey]: color === 'grey' },
  )

  return (
    <div className={styles.popup}>
      <div className={cn(styles.popupOuterSpace, popupBackgroundColorClasses)} onClick={() => handler(0)} />
      <div className={cn(popupWindowClasses, styles.wrapperPopup)} style={{ height }}>
        {noScroll ? (
          <div className={styles.contentNoScroll}>
            {isCloseButton && (
              <button className={styles.popupCloseIcon} onClick={() => handler(0)}>
                <Icon className={styles.iconPopup} name="icon-popup-close" width="16px" height="16px" />
              </button>
            )}
            <div>{children}</div>
          </div>
        ) : (
          <CustomScrollBar height="auto">
            <div className={styles.content}>
              {isCloseButton && (
                <button className={styles.popupCloseIcon} onClick={() => handler(0)}>
                  <Icon className={styles.iconPopup} name="icon-popup-close" width="16px" height="16px" />
                </button>
              )}
              <div>{children}</div>
            </div>
          </CustomScrollBar>
        )}
      </div>
    </div>
  )
}
