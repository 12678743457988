import React from 'react'
import cn from 'classnames'
import { SelectLook } from '@/pages/index/page-vehicles/ui/atoms'
import { GridType } from '@/redux/types/auction-type'
import styles from './search-outcome.module.scss'

type Props = {
  total: number
  japan: number
  typeGrid: GridType
  changeTypeGrid: (type: GridType) => void
}

export const SearchOutcome: React.FC<Props> = ({ total, japan, changeTypeGrid, typeGrid }) => (
  <div className={styles.searchOutcomeWrapper}>
    <div className={cn(styles.searchOutcome)}>
      <div className={styles.outcomeTotal}>
        {total}
        {' '}
        Cars Total
      </div>
      <div className={styles.outcomeRegion}>
        Japan:
        {' '}
        <span className={styles.outcomeRegionCount}>{japan}</span>
      </div>
    </div>
    <SelectLook currentGridType={typeGrid} setTypeGrid={changeTypeGrid} />
  </div>
)
