import React from 'react'
import cn from 'classnames'

import styles from '../gallery.module.scss'

type Props = {
  imageURL: string
  active: boolean
  selectionHandler: () => void
}

export const GalleryItem: React.FC<Props> = ({ imageURL, active, selectionHandler }) => (
  <div
    className={cn(styles.galleryItem, { [styles.active]: active })}
    style={{ backgroundImage: `url("${imageURL}")` }}
    onClick={selectionHandler}
  />
)
