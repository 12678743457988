/* eslint-disable */

import actionCreatorFactory from 'typescript-fsa'
import {
  Address,
  AddressDto,
  AddressUpdateRequest,
  BidCreateRequest,
  BidDto,
  BidUpdateManagerRequest,
  BidUpdateUserRequest,
  BiddingFlowCreateRequest,
  BiddingFlowDto,
  BidsReportRowDto,
  CardDTO,
  CheckoutSessionDTO,
  DocumentationStateChangeRecordDto,
  DocumentationStateDto,
  FilterDto,
  JWTToken,
  KeyAndPasswordVM,
  LoginVM,
  LotDto,
  LotStateDto,
  ManagedUserVM,
  ModelFile,
  NotificationDTO,
  OptionsDTO,
  OrderCreateRequest,
  OrderDto,
  OrderUpdateManagerRequest,
  OrderUpdateUserRequest,
  PageOfLotDto,
  PasswordChangeDTO,
  PortDto,
  Resource,
  SearchFilterDTO,
  SearchHistoryDTO,
  Sort,
  URI,
  URL,
  User,
  UserDTO,
  VehicleStateChangeRecordDto,
  VehicleStateDto,
  WalletTransactionCreateRequest,
  WalletTransactionDto,
  WalletTransactionTypeDto,
  WalletTransactionUpdateRequest,
} from './api'
import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'
import { LotDtoE } from '@/redux/types/lot-type'
export const accountResourceApiActionCreator = actionCreatorFactory('ACCOUNT_RESOURCE_API')
export const avtoJpLotsFacadeControllerApiActionCreator = actionCreatorFactory('AVTO_JP_LOTS_FACADE_CONTROLLER_API')
export const bidsControllerApiActionCreator = actionCreatorFactory('BIDS_CONTROLLER_API')
export const bidsReportRowsControllerApiActionCreator = actionCreatorFactory('BIDS_REPORT_ROWS_CONTROLLER_API')
export const cardResourceApiActionCreator = actionCreatorFactory('CARD_RESOURCE_API')
export const dashboardResourceApiActionCreator = actionCreatorFactory('DASHBOARD_RESOURCE_API')
export const documentationStatesControllerApiActionCreator = actionCreatorFactory('DOCUMENTATION_STATES_CONTROLLER_API')
export const filterResourceApiActionCreator = actionCreatorFactory('FILTER_RESOURCE_API')
export const lotResourceApiActionCreator = actionCreatorFactory('LOT_RESOURCE_API')
export const lotStatesControllerApiActionCreator = actionCreatorFactory('LOT_STATES_CONTROLLER_API')
export const notificationResourceApiActionCreator = actionCreatorFactory('NOTIFICATION_RESOURCE_API')
export const ordersControllerApiActionCreator = actionCreatorFactory('ORDERS_CONTROLLER_API')
export const paymentResourceApiActionCreator = actionCreatorFactory('PAYMENT_RESOURCE_API')
export const portsControllerApiActionCreator = actionCreatorFactory('PORTS_CONTROLLER_API')
export const searchFilterResourceApiActionCreator = actionCreatorFactory('SEARCH_FILTER_RESOURCE_API')
export const searchHistoryResourceApiActionCreator = actionCreatorFactory('SEARCH_HISTORY_RESOURCE_API')
export const userJwtControllerApiActionCreator = actionCreatorFactory('USER_JWT_CONTROLLER_API')
export const userResourceApiActionCreator = actionCreatorFactory('USER_RESOURCE_API')
export const vehicleStatesControllerApiActionCreator = actionCreatorFactory('VEHICLE_STATES_CONTROLLER_API')
export const walletTransactionTypesControllerApiActionCreator = actionCreatorFactory(
  'WALLET_TRANSACTION_TYPES_CONTROLLER_API',
)

export type AccountResourceApiActivateAccountUsingGETRequest = { key: string; options?: any }
export type AccountResourceApiActivateAccountUsingGETSuccess = void
export type AccountResourceApiActivateAccountUsingGETError = any

export const AccountResourceApiActivateAccountUsingGETRequestAction = accountResourceApiActionCreator.async<
  AccountResourceApiActivateAccountUsingGETRequest,
  AccountResourceApiActivateAccountUsingGETSuccess,
  AccountResourceApiActivateAccountUsingGETError
>('ACTIVATE_ACCOUNT_USING_GET')

export type AccountResourceApiChangePasswordUsingPOSTRequest = { passwordChangeDto: PasswordChangeDTO; options?: any }
export type AccountResourceApiChangePasswordUsingPOSTSuccess = void
export type AccountResourceApiChangePasswordUsingPOSTError = any

export const AccountResourceApiChangePasswordUsingPOSTRequestAction = accountResourceApiActionCreator.async<
  AccountResourceApiChangePasswordUsingPOSTRequest,
  AccountResourceApiChangePasswordUsingPOSTSuccess,
  AccountResourceApiChangePasswordUsingPOSTError
>('CHANGE_PASSWORD_USING_POST')

export type AccountResourceApiFinishPasswordResetUsingPOSTRequest = { keyAndPassword: KeyAndPasswordVM; options?: any }
export type AccountResourceApiFinishPasswordResetUsingPOSTSuccess = void
export type AccountResourceApiFinishPasswordResetUsingPOSTError = any

export const AccountResourceApiFinishPasswordResetUsingPOSTRequestAction = accountResourceApiActionCreator.async<
  AccountResourceApiFinishPasswordResetUsingPOSTRequest,
  AccountResourceApiFinishPasswordResetUsingPOSTSuccess,
  AccountResourceApiFinishPasswordResetUsingPOSTError
>('FINISH_PASSWORD_RESET_USING_POST')

export type AccountResourceApiGetAccountUsingGETRequest = { options?: any }
export type AccountResourceApiGetAccountUsingGETSuccess = UserDTO | null
export type AccountResourceApiGetAccountUsingGETError = any

export const AccountResourceApiGetAccountUsingGETRequestAction = accountResourceApiActionCreator.async<
  AccountResourceApiGetAccountUsingGETRequest,
  AccountResourceApiGetAccountUsingGETSuccess,
  AccountResourceApiGetAccountUsingGETError
>('GET_ACCOUNT_USING_GET')

export type AccountResourceApiIsAuthenticatedUsingGETRequest = { options?: any }
export type AccountResourceApiIsAuthenticatedUsingGETSuccess = string
export type AccountResourceApiIsAuthenticatedUsingGETError = any

export const AccountResourceApiIsAuthenticatedUsingGETRequestAction = accountResourceApiActionCreator.async<
  AccountResourceApiIsAuthenticatedUsingGETRequest,
  AccountResourceApiIsAuthenticatedUsingGETSuccess,
  AccountResourceApiIsAuthenticatedUsingGETError
>('IS_AUTHENTICATED_USING_GET')

export type AccountResourceApiRegisterAccountUsingPOSTRequest = { managedUserVM: ManagedUserVM; options?: any }
export type AccountResourceApiRegisterAccountUsingPOSTSuccess = void
export type AccountResourceApiRegisterAccountUsingPOSTError = any

export const AccountResourceApiRegisterAccountUsingPOSTRequestAction = accountResourceApiActionCreator.async<
  AccountResourceApiRegisterAccountUsingPOSTRequest,
  AccountResourceApiRegisterAccountUsingPOSTSuccess,
  AccountResourceApiRegisterAccountUsingPOSTError
>('REGISTER_ACCOUNT_USING_POST')

export type AccountResourceApiRequestPasswordResetUsingPOSTRequest = { mail: string; options?: any }
export type AccountResourceApiRequestPasswordResetUsingPOSTSuccess = void
export type AccountResourceApiRequestPasswordResetUsingPOSTError = any

export const AccountResourceApiRequestPasswordResetUsingPOSTRequestAction = accountResourceApiActionCreator.async<
  AccountResourceApiRequestPasswordResetUsingPOSTRequest,
  AccountResourceApiRequestPasswordResetUsingPOSTSuccess,
  AccountResourceApiRequestPasswordResetUsingPOSTError
>('REQUEST_PASSWORD_RESET_USING_POST')

export type AccountResourceApiSaveAccountUsingPOSTRequest = { userDTO: UserDTO; options?: any }
export type AccountResourceApiSaveAccountUsingPOSTSuccess = void
export type AccountResourceApiSaveAccountUsingPOSTError = any

export const AccountResourceApiSaveAccountUsingPOSTRequestAction = accountResourceApiActionCreator.async<
  AccountResourceApiSaveAccountUsingPOSTRequest,
  AccountResourceApiSaveAccountUsingPOSTSuccess,
  AccountResourceApiSaveAccountUsingPOSTError
>('SAVE_ACCOUNT_USING_POST')

export type AvtoJpLotsFacadeControllerApiFindAuctionLotByIdUsingGETRequest = { id: string; options?: any }
export type AvtoJpLotsFacadeControllerApiFindAuctionLotByIdUsingGETSuccess = LotDto
export type AvtoJpLotsFacadeControllerApiFindAuctionLotByIdUsingGETError = any

export const AvtoJpLotsFacadeControllerApiFindAuctionLotByIdUsingGETRequestAction = avtoJpLotsFacadeControllerApiActionCreator.async<
  AvtoJpLotsFacadeControllerApiFindAuctionLotByIdUsingGETRequest,
  AvtoJpLotsFacadeControllerApiFindAuctionLotByIdUsingGETSuccess,
  AvtoJpLotsFacadeControllerApiFindAuctionLotByIdUsingGETError
>('FIND_AUCTION_LOT_BY_ID_USING_GET')

export type AvtoJpLotsFacadeControllerApiIndexAuctionLotsUsingGETRequest = {
  auction?: string
  color?: string
  engineDisplacementCc?: string
  make?: string[]
  mileageMi?: string
  model?: string[]
  page: any
  price?: string
  priceUSD?: string
  size: any
  sort?: string[]
  transmissionType?: string
  year?: string
  options?: any
}
export type AvtoJpLotsFacadeControllerApiIndexAuctionLotsUsingGETSuccess = LotDto
export type AvtoJpLotsFacadeControllerApiIndexAuctionLotsUsingGETError = any

export const AvtoJpLotsFacadeControllerApiIndexAuctionLotsUsingGETRequestAction = avtoJpLotsFacadeControllerApiActionCreator.async<
  AvtoJpLotsFacadeControllerApiIndexAuctionLotsUsingGETRequest,
  AvtoJpLotsFacadeControllerApiIndexAuctionLotsUsingGETSuccess,
  AvtoJpLotsFacadeControllerApiIndexAuctionLotsUsingGETError
>('INDEX_AUCTION_LOTS_USING_GET')

export type AvtoJpLotsFacadeControllerApiIndexPassedAuctionLotsUsingGETRequest = {
  markaName: string
  modelName: string
  year: number
  kuzov?: string
  page: any
  size: any
  sort?: string[]
  options?: any
}
export type AvtoJpLotsFacadeControllerApiIndexPassedAuctionLotsUsingGETSuccess = LotDto[]
export type AvtoJpLotsFacadeControllerApiIndexPassedAuctionLotsUsingGETError = any

export const AvtoJpLotsFacadeControllerApiIndexPassedAuctionLotsUsingGETRequestAction = avtoJpLotsFacadeControllerApiActionCreator.async<
  AvtoJpLotsFacadeControllerApiIndexPassedAuctionLotsUsingGETRequest,
  AvtoJpLotsFacadeControllerApiIndexPassedAuctionLotsUsingGETSuccess,
  AvtoJpLotsFacadeControllerApiIndexPassedAuctionLotsUsingGETError
>('INDEX_PASSED_AUCTION_LOTS_USING_GET')

export type BidsControllerApiUpdateBidByManagerUsingPUTRequest = {
  id: number
  requestBody: BidUpdateManagerRequest
  options?: any
}
export type BidsControllerApiUpdateBidByManagerUsingPUTSuccess = void
export type BidsControllerApiUpdateBidByManagerUsingPUTError = any

export const BidsControllerApiUpdateBidByManagerUsingPUTRequestAction = bidsControllerApiActionCreator.async<
  BidsControllerApiUpdateBidByManagerUsingPUTRequest,
  BidsControllerApiUpdateBidByManagerUsingPUTSuccess,
  BidsControllerApiUpdateBidByManagerUsingPUTError
>('UPDATE_BID_BY_MANAGER_USING_PUT')

export type BidsReportRowsControllerApiIndexBidsReportRowsUsingGETRequest = {
  bidState: 'NEW' | 'ACTIVE' | 'OUTBID' | 'CANCELED' | 'WON'
  page: any
  size: any
  sort?: string[]
  options?: any
}
export type BidsReportRowsControllerApiIndexBidsReportRowsUsingGETSuccess = BidsReportRowDto[]
export type BidsReportRowsControllerApiIndexBidsReportRowsUsingGETError = any

export const BidsReportRowsControllerApiIndexBidsReportRowsUsingGETRequestAction = bidsReportRowsControllerApiActionCreator.async<
  BidsReportRowsControllerApiIndexBidsReportRowsUsingGETRequest,
  BidsReportRowsControllerApiIndexBidsReportRowsUsingGETSuccess,
  BidsReportRowsControllerApiIndexBidsReportRowsUsingGETError
>('INDEX_BIDS_REPORT_ROWS_USING_GET')

export type CardResourceApiCreateCardUsingPOSTRequest = { cardDTO: CardDTO; options?: any }
export type CardResourceApiCreateCardUsingPOSTSuccess = CardDTO
export type CardResourceApiCreateCardUsingPOSTError = any

export const CardResourceApiCreateCardUsingPOSTRequestAction = cardResourceApiActionCreator.async<
  CardResourceApiCreateCardUsingPOSTRequest,
  CardResourceApiCreateCardUsingPOSTSuccess,
  CardResourceApiCreateCardUsingPOSTError
>('CREATE_CARD_USING_POST')

export type CardResourceApiDeleteCardUsingDELETERequest = { id: number; options?: any }
export type CardResourceApiDeleteCardUsingDELETESuccess = void
export type CardResourceApiDeleteCardUsingDELETEError = any

export const CardResourceApiDeleteCardUsingDELETERequestAction = cardResourceApiActionCreator.async<
  CardResourceApiDeleteCardUsingDELETERequest,
  CardResourceApiDeleteCardUsingDELETESuccess,
  CardResourceApiDeleteCardUsingDELETEError
>('DELETE_CARD_USING_DELETE')

export type CardResourceApiGetAllCardsUsingGETRequest = { options?: any }
export type CardResourceApiGetAllCardsUsingGETSuccess = CardDTO[]
export type CardResourceApiGetAllCardsUsingGETError = any

export const CardResourceApiGetAllCardsUsingGETRequestAction = cardResourceApiActionCreator.async<
  CardResourceApiGetAllCardsUsingGETRequest,
  CardResourceApiGetAllCardsUsingGETSuccess,
  CardResourceApiGetAllCardsUsingGETError
>('GET_ALL_CARDS_USING_GET')

export type CardResourceApiGetCardUsingGETRequest = { id: number; options?: any }
export type CardResourceApiGetCardUsingGETSuccess = CardDTO
export type CardResourceApiGetCardUsingGETError = any

export const CardResourceApiGetCardUsingGETRequestAction = cardResourceApiActionCreator.async<
  CardResourceApiGetCardUsingGETRequest,
  CardResourceApiGetCardUsingGETSuccess,
  CardResourceApiGetCardUsingGETError
>('GET_CARD_USING_GET')

export type CardResourceApiSearchCardsUsingGETRequest = { query: string; options?: any }
export type CardResourceApiSearchCardsUsingGETSuccess = CardDTO[]
export type CardResourceApiSearchCardsUsingGETError = any

export const CardResourceApiSearchCardsUsingGETRequestAction = cardResourceApiActionCreator.async<
  CardResourceApiSearchCardsUsingGETRequest,
  CardResourceApiSearchCardsUsingGETSuccess,
  CardResourceApiSearchCardsUsingGETError
>('SEARCH_CARDS_USING_GET')

export type CardResourceApiUpdateCardUsingPUTRequest = { cardDTO: CardDTO; options?: any }
export type CardResourceApiUpdateCardUsingPUTSuccess = CardDTO
export type CardResourceApiUpdateCardUsingPUTError = any

export const CardResourceApiUpdateCardUsingPUTRequestAction = cardResourceApiActionCreator.async<
  CardResourceApiUpdateCardUsingPUTRequest,
  CardResourceApiUpdateCardUsingPUTSuccess,
  CardResourceApiUpdateCardUsingPUTError
>('UPDATE_CARD_USING_PUT')

export type DashboardResourceApiCreateFavoriteUsingPOSTRequest = { externalId: string; options?: any }
export type DashboardResourceApiCreateFavoriteUsingPOSTSuccess = LotDto
export type DashboardResourceApiCreateFavoriteUsingPOSTError = any

export const DashboardResourceApiCreateFavoriteUsingPOSTRequestAction = dashboardResourceApiActionCreator.async<
  DashboardResourceApiCreateFavoriteUsingPOSTRequest,
  DashboardResourceApiCreateFavoriteUsingPOSTSuccess,
  DashboardResourceApiCreateFavoriteUsingPOSTError
>('CREATE_FAVORITE_USING_POST')

export type DashboardResourceApiDeleteFavoriteUsingDELETERequest = { lotId: number; options?: any }
export type DashboardResourceApiDeleteFavoriteUsingDELETESuccess = void
export type DashboardResourceApiDeleteFavoriteUsingDELETEError = any

export const DashboardResourceApiDeleteFavoriteUsingDELETERequestAction = dashboardResourceApiActionCreator.async<
  DashboardResourceApiDeleteFavoriteUsingDELETERequest,
  DashboardResourceApiDeleteFavoriteUsingDELETESuccess,
  DashboardResourceApiDeleteFavoriteUsingDELETEError
>('DELETE_FAVORITE_USING_DELETE')

export type DashboardResourceApiIndexFavoritesUsingGETRequest = { options?: any }
export type DashboardResourceApiIndexFavoritesUsingGETSuccess = object
export type DashboardResourceApiIndexFavoritesUsingGETError = any

export const DashboardResourceApiIndexFavoritesUsingGETRequestAction = dashboardResourceApiActionCreator.async<
  DashboardResourceApiIndexFavoritesUsingGETRequest,
  DashboardResourceApiIndexFavoritesUsingGETSuccess,
  DashboardResourceApiIndexFavoritesUsingGETError
>('INDEX_FAVORITES_USING_GET')

export type DocumentationStatesControllerApiIndexDocumentationStatesUsingGETRequest = { options?: any }
export type DocumentationStatesControllerApiIndexDocumentationStatesUsingGETSuccess = DocumentationStateDto[]
export type DocumentationStatesControllerApiIndexDocumentationStatesUsingGETError = any

export const DocumentationStatesControllerApiIndexDocumentationStatesUsingGETRequestAction = documentationStatesControllerApiActionCreator.async<
  DocumentationStatesControllerApiIndexDocumentationStatesUsingGETRequest,
  DocumentationStatesControllerApiIndexDocumentationStatesUsingGETSuccess,
  DocumentationStatesControllerApiIndexDocumentationStatesUsingGETError
>('INDEX_DOCUMENTATION_STATES_USING_GET')

export type DocumentationStatesControllerApiReadDocumentationStateUsingGETRequest = { id: number; options?: any }
export type DocumentationStatesControllerApiReadDocumentationStateUsingGETSuccess = DocumentationStateDto
export type DocumentationStatesControllerApiReadDocumentationStateUsingGETError = any

export const DocumentationStatesControllerApiReadDocumentationStateUsingGETRequestAction = documentationStatesControllerApiActionCreator.async<
  DocumentationStatesControllerApiReadDocumentationStateUsingGETRequest,
  DocumentationStatesControllerApiReadDocumentationStateUsingGETSuccess,
  DocumentationStatesControllerApiReadDocumentationStateUsingGETError
>('READ_DOCUMENTATION_STATE_USING_GET')

export type FilterResourceApiFindFilterByPlatformAndTypeUsingGETRequest = {
  filterType: 'AUCTION' | 'MAKE' | 'MODEL' | 'COLOR' | 'TRANSMISSION_TYPE'
  platform: 'AVTO_JP'
  parent?: string[]
  options?: any
}
export type FilterResourceApiFindFilterByPlatformAndTypeUsingGETSuccess = FilterDto[]
export type FilterResourceApiFindFilterByPlatformAndTypeUsingGETError = any

export const FilterResourceApiFindFilterByPlatformAndTypeUsingGETRequestAction = filterResourceApiActionCreator.async<
  FilterResourceApiFindFilterByPlatformAndTypeUsingGETRequest,
  FilterResourceApiFindFilterByPlatformAndTypeUsingGETSuccess,
  FilterResourceApiFindFilterByPlatformAndTypeUsingGETError
>('FIND_FILTER_BY_PLATFORM_AND_TYPE_USING_GET')

export type FilterResourceApiGetPlatformsUsingGETRequest = { options?: any }
export type FilterResourceApiGetPlatformsUsingGETSuccess = string[]
export type FilterResourceApiGetPlatformsUsingGETError = any

export const FilterResourceApiGetPlatformsUsingGETRequestAction = filterResourceApiActionCreator.async<
  FilterResourceApiGetPlatformsUsingGETRequest,
  FilterResourceApiGetPlatformsUsingGETSuccess,
  FilterResourceApiGetPlatformsUsingGETError
>('GET_PLATFORMS_USING_GET')

export type LotResourceApiGetAllLotsUsingGETRequest = { options?: any }
export type LotResourceApiGetAllLotsUsingGETSuccess = LotDto[]
export type LotResourceApiGetAllLotsUsingGETError = any

export const LotResourceApiGetAllLotsUsingGETRequestAction = lotResourceApiActionCreator.async<
  LotResourceApiGetAllLotsUsingGETRequest,
  LotResourceApiGetAllLotsUsingGETSuccess,
  LotResourceApiGetAllLotsUsingGETError
>('GET_ALL_LOTS_USING_GET')

export type LotResourceApiGetLotUsingGETRequest = { id: number; options?: any }
export type LotResourceApiGetLotUsingGETSuccess = LotDto
export type LotResourceApiGetLotUsingGETError = any

export const LotResourceApiGetLotUsingGETRequestAction = lotResourceApiActionCreator.async<
  LotResourceApiGetLotUsingGETRequest,
  LotResourceApiGetLotUsingGETSuccess,
  LotResourceApiGetLotUsingGETError
>('GET_LOT_USING_GET')

export type LotStatesControllerApiIndexLotStatesUsingGETRequest = { options?: any }
export type LotStatesControllerApiIndexLotStatesUsingGETSuccess = LotStateDto[]
export type LotStatesControllerApiIndexLotStatesUsingGETError = any

export const LotStatesControllerApiIndexLotStatesUsingGETRequestAction = lotStatesControllerApiActionCreator.async<
  LotStatesControllerApiIndexLotStatesUsingGETRequest,
  LotStatesControllerApiIndexLotStatesUsingGETSuccess,
  LotStatesControllerApiIndexLotStatesUsingGETError
>('INDEX_LOT_STATES_USING_GET')

export type LotStatesControllerApiReadLotStateUsingGETRequest = { id: number; options?: any }
export type LotStatesControllerApiReadLotStateUsingGETSuccess = LotStateDto
export type LotStatesControllerApiReadLotStateUsingGETError = any

export const LotStatesControllerApiReadLotStateUsingGETRequestAction = lotStatesControllerApiActionCreator.async<
  LotStatesControllerApiReadLotStateUsingGETRequest,
  LotStatesControllerApiReadLotStateUsingGETSuccess,
  LotStatesControllerApiReadLotStateUsingGETError
>('READ_LOT_STATE_USING_GET')

export type NotificationResourceApiCreateNotificationUsingPOSTRequest = {
  notificationDTO: NotificationDTO
  options?: any
}
export type NotificationResourceApiCreateNotificationUsingPOSTSuccess = NotificationDTO
export type NotificationResourceApiCreateNotificationUsingPOSTError = any

export const NotificationResourceApiCreateNotificationUsingPOSTRequestAction = notificationResourceApiActionCreator.async<
  NotificationResourceApiCreateNotificationUsingPOSTRequest,
  NotificationResourceApiCreateNotificationUsingPOSTSuccess,
  NotificationResourceApiCreateNotificationUsingPOSTError
>('CREATE_NOTIFICATION_USING_POST')

export type NotificationResourceApiDeleteNotificationUsingDELETERequest = { id: number; options?: any }
export type NotificationResourceApiDeleteNotificationUsingDELETESuccess = void
export type NotificationResourceApiDeleteNotificationUsingDELETEError = any

export const NotificationResourceApiDeleteNotificationUsingDELETERequestAction = notificationResourceApiActionCreator.async<
  NotificationResourceApiDeleteNotificationUsingDELETERequest,
  NotificationResourceApiDeleteNotificationUsingDELETESuccess,
  NotificationResourceApiDeleteNotificationUsingDELETEError
>('DELETE_NOTIFICATION_USING_DELETE')

export type NotificationResourceApiGetAllNotificationsUsingGETRequest = { options?: any }
export type NotificationResourceApiGetAllNotificationsUsingGETSuccess = NotificationDTO[]
export type NotificationResourceApiGetAllNotificationsUsingGETError = any

export const NotificationResourceApiGetAllNotificationsUsingGETRequestAction = notificationResourceApiActionCreator.async<
  NotificationResourceApiGetAllNotificationsUsingGETRequest,
  NotificationResourceApiGetAllNotificationsUsingGETSuccess,
  NotificationResourceApiGetAllNotificationsUsingGETError
>('GET_ALL_NOTIFICATIONS_USING_GET')

export type NotificationResourceApiGetNotificationUsingGETRequest = { id: number; options?: any }
export type NotificationResourceApiGetNotificationUsingGETSuccess = NotificationDTO
export type NotificationResourceApiGetNotificationUsingGETError = any

export const NotificationResourceApiGetNotificationUsingGETRequestAction = notificationResourceApiActionCreator.async<
  NotificationResourceApiGetNotificationUsingGETRequest,
  NotificationResourceApiGetNotificationUsingGETSuccess,
  NotificationResourceApiGetNotificationUsingGETError
>('GET_NOTIFICATION_USING_GET')

export type NotificationResourceApiSearchNotificationsUsingGETRequest = { query: string; options?: any }
export type NotificationResourceApiSearchNotificationsUsingGETSuccess = NotificationDTO[]
export type NotificationResourceApiSearchNotificationsUsingGETError = any

export const NotificationResourceApiSearchNotificationsUsingGETRequestAction = notificationResourceApiActionCreator.async<
  NotificationResourceApiSearchNotificationsUsingGETRequest,
  NotificationResourceApiSearchNotificationsUsingGETSuccess,
  NotificationResourceApiSearchNotificationsUsingGETError
>('SEARCH_NOTIFICATIONS_USING_GET')

export type NotificationResourceApiUpdateNotificationUsingPUTRequest = {
  notificationDTO: NotificationDTO
  options?: any
}
export type NotificationResourceApiUpdateNotificationUsingPUTSuccess = NotificationDTO
export type NotificationResourceApiUpdateNotificationUsingPUTError = any

export const NotificationResourceApiUpdateNotificationUsingPUTRequestAction = notificationResourceApiActionCreator.async<
  NotificationResourceApiUpdateNotificationUsingPUTRequest,
  NotificationResourceApiUpdateNotificationUsingPUTSuccess,
  NotificationResourceApiUpdateNotificationUsingPUTError
>('UPDATE_NOTIFICATION_USING_PUT')

export type OrdersControllerApiCreateOrderUsingPOSTRequest = { requestBody: OrderCreateRequest; options?: any }
export type OrdersControllerApiCreateOrderUsingPOSTSuccess = void
export type OrdersControllerApiCreateOrderUsingPOSTError = any

export const OrdersControllerApiCreateOrderUsingPOSTRequestAction = ordersControllerApiActionCreator.async<
  OrdersControllerApiCreateOrderUsingPOSTRequest,
  OrdersControllerApiCreateOrderUsingPOSTSuccess,
  OrdersControllerApiCreateOrderUsingPOSTError
>('CREATE_ORDER_USING_POST')

export type OrdersControllerApiIndexOrdersByManagerUsingGETRequest = {
  page: any
  size: any
  sort?: string[]
  state?: 'NEW' | 'SHIPPING_INFORMATION_PROVIDED' | 'VALIDATED' | 'CONFIRMED' | 'PAID' | 'COMPLETED'
  options?: any
}
export type OrdersControllerApiIndexOrdersByManagerUsingGETSuccess = OrderDto[]
export type OrdersControllerApiIndexOrdersByManagerUsingGETError = any

export const OrdersControllerApiIndexOrdersByManagerUsingGETRequestAction = ordersControllerApiActionCreator.async<
  OrdersControllerApiIndexOrdersByManagerUsingGETRequest,
  OrdersControllerApiIndexOrdersByManagerUsingGETSuccess,
  OrdersControllerApiIndexOrdersByManagerUsingGETError
>('INDEX_ORDERS_BY_MANAGER_USING_GET')

export type OrdersControllerApiUpdateOrderByManagerUsingPUTRequest = {
  id: number
  requestBody: OrderUpdateManagerRequest
  options?: any
}
export type OrdersControllerApiUpdateOrderByManagerUsingPUTSuccess = OrderDto
export type OrdersControllerApiUpdateOrderByManagerUsingPUTError = any

export const OrdersControllerApiUpdateOrderByManagerUsingPUTRequestAction = ordersControllerApiActionCreator.async<
  OrdersControllerApiUpdateOrderByManagerUsingPUTRequest,
  OrdersControllerApiUpdateOrderByManagerUsingPUTSuccess,
  OrdersControllerApiUpdateOrderByManagerUsingPUTError
>('UPDATE_ORDER_BY_MANAGER_USING_PUT')

export type PaymentResourceApiCreateCheckoutSessionUsingPOSTRequest = {
  checkoutSessionDTO: CheckoutSessionDTO
  options?: any
}

export type PaymentResourceApiCreateCheckoutSessionUsingPOSTSuccess = string
export type PaymentResourceApiCreateCheckoutSessionUsingPOSTError = any

export const PaymentResourceApiCreateCheckoutSessionUsingPOSTRequestAction = paymentResourceApiActionCreator.async<
  PaymentResourceApiCreateCheckoutSessionUsingPOSTRequest,
  PaymentResourceApiCreateCheckoutSessionUsingPOSTSuccess,
  PaymentResourceApiCreateCheckoutSessionUsingPOSTError
>('CREATE_CHECKOUT_SESSION_USING_POST')

export type PaymentResourceApiWebhookUsingPOSTRequest = { stripeSignature: string; payload: string; options?: any }
export type PaymentResourceApiWebhookUsingPOSTSuccess = void
export type PaymentResourceApiWebhookUsingPOSTError = any

export const PaymentResourceApiWebhookUsingPOSTRequestAction = paymentResourceApiActionCreator.async<
  PaymentResourceApiWebhookUsingPOSTRequest,
  PaymentResourceApiWebhookUsingPOSTSuccess,
  PaymentResourceApiWebhookUsingPOSTError
>('WEBHOOK_USING_POST')

export type PortsControllerApiIndexPortsUsingGETRequest = { options?: any }
export type PortsControllerApiIndexPortsUsingGETSuccess = PortDto[]
export type PortsControllerApiIndexPortsUsingGETError = any

export const PortsControllerApiIndexPortsUsingGETRequestAction = portsControllerApiActionCreator.async<
  PortsControllerApiIndexPortsUsingGETRequest,
  PortsControllerApiIndexPortsUsingGETSuccess,
  PortsControllerApiIndexPortsUsingGETError
>('INDEX_PORTS_USING_GET')

export type PortsControllerApiReadPortUsingGETRequest = { id: number; options?: any }
export type PortsControllerApiReadPortUsingGETSuccess = PortDto
export type PortsControllerApiReadPortUsingGETError = any

export const PortsControllerApiReadPortUsingGETRequestAction = portsControllerApiActionCreator.async<
  PortsControllerApiReadPortUsingGETRequest,
  PortsControllerApiReadPortUsingGETSuccess,
  PortsControllerApiReadPortUsingGETError
>('READ_PORT_USING_GET')

export type SearchFilterResourceApiCreateSearchFilterUsingPOSTRequest = {
  searchFilterDTO: SearchFilterDTO
  options?: any
}
export type SearchFilterResourceApiCreateSearchFilterUsingPOSTSuccess = SearchFilterDTO
export type SearchFilterResourceApiCreateSearchFilterUsingPOSTError = any

export const SearchFilterResourceApiCreateSearchFilterUsingPOSTRequestAction = searchFilterResourceApiActionCreator.async<
  SearchFilterResourceApiCreateSearchFilterUsingPOSTRequest,
  SearchFilterResourceApiCreateSearchFilterUsingPOSTSuccess,
  SearchFilterResourceApiCreateSearchFilterUsingPOSTError
>('CREATE_SEARCH_FILTER_USING_POST')

export type SearchFilterResourceApiDeleteSearchFilterUsingDELETERequest = { id: number; options?: any }
export type SearchFilterResourceApiDeleteSearchFilterUsingDELETESuccess = void
export type SearchFilterResourceApiDeleteSearchFilterUsingDELETEError = any

export const SearchFilterResourceApiDeleteSearchFilterUsingDELETERequestAction = searchFilterResourceApiActionCreator.async<
  SearchFilterResourceApiDeleteSearchFilterUsingDELETERequest,
  SearchFilterResourceApiDeleteSearchFilterUsingDELETESuccess,
  SearchFilterResourceApiDeleteSearchFilterUsingDELETEError
>('DELETE_SEARCH_FILTER_USING_DELETE')

export type SearchFilterResourceApiGetAllSearchFiltersUsingGETRequest = { options?: any }
export type SearchFilterResourceApiGetAllSearchFiltersUsingGETSuccess = SearchFilterDTO[]
export type SearchFilterResourceApiGetAllSearchFiltersUsingGETError = any

export const SearchFilterResourceApiGetAllSearchFiltersUsingGETRequestAction = searchFilterResourceApiActionCreator.async<
  SearchFilterResourceApiGetAllSearchFiltersUsingGETRequest,
  SearchFilterResourceApiGetAllSearchFiltersUsingGETSuccess,
  SearchFilterResourceApiGetAllSearchFiltersUsingGETError
>('GET_ALL_SEARCH_FILTERS_USING_GET')

export type SearchFilterResourceApiGetSearchFilterUsingGETRequest = { id: number; options?: any }
export type SearchFilterResourceApiGetSearchFilterUsingGETSuccess = SearchFilterDTO
export type SearchFilterResourceApiGetSearchFilterUsingGETError = any

export const SearchFilterResourceApiGetSearchFilterUsingGETRequestAction = searchFilterResourceApiActionCreator.async<
  SearchFilterResourceApiGetSearchFilterUsingGETRequest,
  SearchFilterResourceApiGetSearchFilterUsingGETSuccess,
  SearchFilterResourceApiGetSearchFilterUsingGETError
>('GET_SEARCH_FILTER_USING_GET')

export type SearchFilterResourceApiSearchSearchFiltersUsingGETRequest = { query: string; options?: any }
export type SearchFilterResourceApiSearchSearchFiltersUsingGETSuccess = SearchFilterDTO[]
export type SearchFilterResourceApiSearchSearchFiltersUsingGETError = any

export const SearchFilterResourceApiSearchSearchFiltersUsingGETRequestAction = searchFilterResourceApiActionCreator.async<
  SearchFilterResourceApiSearchSearchFiltersUsingGETRequest,
  SearchFilterResourceApiSearchSearchFiltersUsingGETSuccess,
  SearchFilterResourceApiSearchSearchFiltersUsingGETError
>('SEARCH_SEARCH_FILTERS_USING_GET')

export type SearchFilterResourceApiUpdateSearchFilterUsingPUTRequest = {
  searchFilterDTO: SearchFilterDTO
  options?: any
}
export type SearchFilterResourceApiUpdateSearchFilterUsingPUTSuccess = SearchFilterDTO
export type SearchFilterResourceApiUpdateSearchFilterUsingPUTError = any

export const SearchFilterResourceApiUpdateSearchFilterUsingPUTRequestAction = searchFilterResourceApiActionCreator.async<
  SearchFilterResourceApiUpdateSearchFilterUsingPUTRequest,
  SearchFilterResourceApiUpdateSearchFilterUsingPUTSuccess,
  SearchFilterResourceApiUpdateSearchFilterUsingPUTError
>('UPDATE_SEARCH_FILTER_USING_PUT')

export type SearchHistoryResourceApiCreateSearchHistoryUsingPOSTRequest = {
  searchHistoryDTO: SearchHistoryDTO
  options?: any
}
export type SearchHistoryResourceApiCreateSearchHistoryUsingPOSTSuccess = SearchHistoryDTO
export type SearchHistoryResourceApiCreateSearchHistoryUsingPOSTError = any

export const SearchHistoryResourceApiCreateSearchHistoryUsingPOSTRequestAction = searchHistoryResourceApiActionCreator.async<
  SearchHistoryResourceApiCreateSearchHistoryUsingPOSTRequest,
  SearchHistoryResourceApiCreateSearchHistoryUsingPOSTSuccess,
  SearchHistoryResourceApiCreateSearchHistoryUsingPOSTError
>('CREATE_SEARCH_HISTORY_USING_POST')

export type SearchHistoryResourceApiDeleteSearchHistoryUsingDELETERequest = { id: number; options?: any }
export type SearchHistoryResourceApiDeleteSearchHistoryUsingDELETESuccess = void
export type SearchHistoryResourceApiDeleteSearchHistoryUsingDELETEError = any

export const SearchHistoryResourceApiDeleteSearchHistoryUsingDELETERequestAction = searchHistoryResourceApiActionCreator.async<
  SearchHistoryResourceApiDeleteSearchHistoryUsingDELETERequest,
  SearchHistoryResourceApiDeleteSearchHistoryUsingDELETESuccess,
  SearchHistoryResourceApiDeleteSearchHistoryUsingDELETEError
>('DELETE_SEARCH_HISTORY_USING_DELETE')

export type SearchHistoryResourceApiGetAllSearchHistoriesUsingGETRequest = { options?: any }
export type SearchHistoryResourceApiGetAllSearchHistoriesUsingGETSuccess = SearchHistoryDTO[]
export type SearchHistoryResourceApiGetAllSearchHistoriesUsingGETError = any

export const SearchHistoryResourceApiGetAllSearchHistoriesUsingGETRequestAction = searchHistoryResourceApiActionCreator.async<
  SearchHistoryResourceApiGetAllSearchHistoriesUsingGETRequest,
  SearchHistoryResourceApiGetAllSearchHistoriesUsingGETSuccess,
  SearchHistoryResourceApiGetAllSearchHistoriesUsingGETError
>('GET_ALL_SEARCH_HISTORIES_USING_GET')

export type SearchHistoryResourceApiGetSearchHistoryUsingGETRequest = { id: number; options?: any }
export type SearchHistoryResourceApiGetSearchHistoryUsingGETSuccess = SearchHistoryDTO
export type SearchHistoryResourceApiGetSearchHistoryUsingGETError = any

export const SearchHistoryResourceApiGetSearchHistoryUsingGETRequestAction = searchHistoryResourceApiActionCreator.async<
  SearchHistoryResourceApiGetSearchHistoryUsingGETRequest,
  SearchHistoryResourceApiGetSearchHistoryUsingGETSuccess,
  SearchHistoryResourceApiGetSearchHistoryUsingGETError
>('GET_SEARCH_HISTORY_USING_GET')

export type SearchHistoryResourceApiSearchSearchHistoriesUsingGETRequest = { query: string; options?: any }
export type SearchHistoryResourceApiSearchSearchHistoriesUsingGETSuccess = SearchHistoryDTO[]
export type SearchHistoryResourceApiSearchSearchHistoriesUsingGETError = any

export const SearchHistoryResourceApiSearchSearchHistoriesUsingGETRequestAction = searchHistoryResourceApiActionCreator.async<
  SearchHistoryResourceApiSearchSearchHistoriesUsingGETRequest,
  SearchHistoryResourceApiSearchSearchHistoriesUsingGETSuccess,
  SearchHistoryResourceApiSearchSearchHistoriesUsingGETError
>('SEARCH_SEARCH_HISTORIES_USING_GET')

export type SearchHistoryResourceApiUpdateSearchHistoryUsingPUTRequest = {
  searchHistoryDTO: SearchHistoryDTO
  options?: any
}
export type SearchHistoryResourceApiUpdateSearchHistoryUsingPUTSuccess = SearchHistoryDTO
export type SearchHistoryResourceApiUpdateSearchHistoryUsingPUTError = any

export const SearchHistoryResourceApiUpdateSearchHistoryUsingPUTRequestAction = searchHistoryResourceApiActionCreator.async<
  SearchHistoryResourceApiUpdateSearchHistoryUsingPUTRequest,
  SearchHistoryResourceApiUpdateSearchHistoryUsingPUTSuccess,
  SearchHistoryResourceApiUpdateSearchHistoryUsingPUTError
>('UPDATE_SEARCH_HISTORY_USING_PUT')

export type UserJwtControllerApiAuthorizeUsingPOSTRequest = { loginVM: LoginVM; options?: any }
export type UserJwtControllerApiAuthorizeUsingPOSTSuccess = JWTToken
export type UserJwtControllerApiAuthorizeUsingPOSTError = any

export const UserJwtControllerApiAuthorizeUsingPOSTRequestAction = userJwtControllerApiActionCreator.async<
  UserJwtControllerApiAuthorizeUsingPOSTRequest,
  UserJwtControllerApiAuthorizeUsingPOSTSuccess,
  UserJwtControllerApiAuthorizeUsingPOSTError
>('AUTHORIZE_USING_POST')

export type UserResourceApiCreateBidUsingPOSTRequest = { userId: number; requestBody: BidCreateRequest; options?: any }
export type UserResourceApiCreateBidUsingPOSTSuccess = BidDto
export type UserResourceApiCreateBidUsingPOSTError = any

export const UserResourceApiCreateBidUsingPOSTRequestAction = userResourceApiActionCreator.async<
  UserResourceApiCreateBidUsingPOSTRequest,
  UserResourceApiCreateBidUsingPOSTSuccess,
  UserResourceApiCreateBidUsingPOSTError
>('CREATE_BID_USING_POST')

export type UserResourceApiCreateBiddingFlowUsingPOSTRequest = {
  userId: number
  requestBody: BiddingFlowCreateRequest
  options?: any
}
export type UserResourceApiCreateBiddingFlowUsingPOSTSuccess = BiddingFlowDto
export type UserResourceApiCreateBiddingFlowUsingPOSTError = any

export const UserResourceApiCreateBiddingFlowUsingPOSTRequestAction = userResourceApiActionCreator.async<
  UserResourceApiCreateBiddingFlowUsingPOSTRequest,
  UserResourceApiCreateBiddingFlowUsingPOSTSuccess,
  UserResourceApiCreateBiddingFlowUsingPOSTError
>('CREATE_BIDDING_FLOW_USING_POST')

export type UserResourceApiCreateUserUsingPOSTRequest = { userDTO: UserDTO; options?: any }
export type UserResourceApiCreateUserUsingPOSTSuccess = User
export type UserResourceApiCreateUserUsingPOSTError = any

export const UserResourceApiCreateUserUsingPOSTRequestAction = userResourceApiActionCreator.async<
  UserResourceApiCreateUserUsingPOSTRequest,
  UserResourceApiCreateUserUsingPOSTSuccess,
  UserResourceApiCreateUserUsingPOSTError
>('CREATE_USER_USING_POST')

export type UserResourceApiCreateWalletTransactionUsingPOSTRequest = {
  userId: number
  requestBody: WalletTransactionCreateRequest
  options?: any
}
export type UserResourceApiCreateWalletTransactionUsingPOSTSuccess = WalletTransactionDto
export type UserResourceApiCreateWalletTransactionUsingPOSTError = any

export const UserResourceApiCreateWalletTransactionUsingPOSTRequestAction = userResourceApiActionCreator.async<
  UserResourceApiCreateWalletTransactionUsingPOSTRequest,
  UserResourceApiCreateWalletTransactionUsingPOSTSuccess,
  UserResourceApiCreateWalletTransactionUsingPOSTError
>('CREATE_WALLET_TRANSACTION_USING_POST')

export type UserResourceApiDeleteUserUsingDELETERequest = { login: string; options?: any }
export type UserResourceApiDeleteUserUsingDELETESuccess = void
export type UserResourceApiDeleteUserUsingDELETEError = any

export const UserResourceApiDeleteUserUsingDELETERequestAction = userResourceApiActionCreator.async<
  UserResourceApiDeleteUserUsingDELETERequest,
  UserResourceApiDeleteUserUsingDELETESuccess,
  UserResourceApiDeleteUserUsingDELETEError
>('DELETE_USER_USING_DELETE')

export type UserResourceApiGetAllUsersUsingGETRequest = {
  queryParams: object
  page?: number
  size?: number
  sort?: string[]
  options?: any
}
export type UserResourceApiGetAllUsersUsingGETSuccess = UserDTO[]
export type UserResourceApiGetAllUsersUsingGETError = any

export const UserResourceApiGetAllUsersUsingGETRequestAction = userResourceApiActionCreator.async<
  UserResourceApiGetAllUsersUsingGETRequest,
  UserResourceApiGetAllUsersUsingGETSuccess,
  UserResourceApiGetAllUsersUsingGETError
>('GET_ALL_USERS_USING_GET')

export type UserResourceApiGetAuthoritiesUsingGETRequest = { options?: any }
export type UserResourceApiGetAuthoritiesUsingGETSuccess = string[]
export type UserResourceApiGetAuthoritiesUsingGETError = any

export const UserResourceApiGetAuthoritiesUsingGETRequestAction = userResourceApiActionCreator.async<
  UserResourceApiGetAuthoritiesUsingGETRequest,
  UserResourceApiGetAuthoritiesUsingGETSuccess,
  UserResourceApiGetAuthoritiesUsingGETError
>('GET_AUTHORITIES_USING_GET')

export type UserResourceApiIndexBidsUsingGETRequest = {
  userId: number
  page: any
  size: any
  sort?: string[]
  options?: any
}
export type UserResourceApiIndexBidsUsingGETSuccess = BidDto[]
export type UserResourceApiIndexBidsUsingGETError = any

export const UserResourceApiIndexBidsUsingGETRequestAction = userResourceApiActionCreator.async<
  UserResourceApiIndexBidsUsingGETRequest,
  UserResourceApiIndexBidsUsingGETSuccess,
  UserResourceApiIndexBidsUsingGETError
>('INDEX_BIDS_USING_GET')

export type UserResourceApiIndexOrdersUsingGETRequest = {
  userId: number
  sort?: string[]
  sorted?: boolean
  state?: ('NEW' | 'SHIPPING_INFORMATION_PROVIDED' | 'VALIDATED' | 'CONFIRMED' | 'PAID' | 'COMPLETED')[]
  unsorted?: boolean
  options?: any
}

export type OrdersDtoNormalized = {
  lot: {
    byId: { [key: string]: LotDtoE }
    allIds: number[]
  }
  order: {
    byId: { [key: string]: any }
    allIds: number[]
  }
}

export type UserResourceApiIndexOrdersUsingGETSuccess = OrdersDtoNormalized
export type UserResourceApiIndexOrdersUsingGETError = any

export const UserResourceApiIndexOrdersUsingGETRequestAction = userResourceApiActionCreator.async<
  UserResourceApiIndexOrdersUsingGETRequest,
  UserResourceApiIndexOrdersUsingGETSuccess,
  UserResourceApiIndexOrdersUsingGETError
>('INDEX_ORDERS_USING_GET')

export type UserResourceApiIndexWalletTransactionsUsingGETRequest = {
  userId: number
  page: any
  size: any
  sort?: string[]
  options?: any
}
export type UserResourceApiIndexWalletTransactionsUsingGETSuccess = WalletTransactionDto[]
export type UserResourceApiIndexWalletTransactionsUsingGETError = any

export const UserResourceApiIndexWalletTransactionsUsingGETRequestAction = userResourceApiActionCreator.async<
  UserResourceApiIndexWalletTransactionsUsingGETRequest,
  UserResourceApiIndexWalletTransactionsUsingGETSuccess,
  UserResourceApiIndexWalletTransactionsUsingGETError
>('INDEX_WALLET_TRANSACTIONS_USING_GET')

export type UserResourceApiReadBidUsingGETRequest = { bidId: number; userId: number; options?: any }
export type UserResourceApiReadBidUsingGETSuccess = BidDto
export type UserResourceApiReadBidUsingGETError = any

export const UserResourceApiReadBidUsingGETRequestAction = userResourceApiActionCreator.async<
  UserResourceApiReadBidUsingGETRequest,
  UserResourceApiReadBidUsingGETSuccess,
  UserResourceApiReadBidUsingGETError
>('READ_BID_USING_GET')

export type UserResourceApiReadOrderDriverLicenseUsingGETRequest = { orderId: number; userId: number; options?: any }
export type UserResourceApiReadOrderDriverLicenseUsingGETSuccess = Resource
export type UserResourceApiReadOrderDriverLicenseUsingGETError = any

export const UserResourceApiReadOrderDriverLicenseUsingGETRequestAction = userResourceApiActionCreator.async<
  UserResourceApiReadOrderDriverLicenseUsingGETRequest,
  UserResourceApiReadOrderDriverLicenseUsingGETSuccess,
  UserResourceApiReadOrderDriverLicenseUsingGETError
>('READ_ORDER_DRIVER_LICENSE_USING_GET')

export type UserResourceApiReadOrderUsingGETRequest = { orderId: number; userId: number; options?: any }
export type UserResourceApiReadOrderUsingGETSuccess = OrderDto
export type UserResourceApiReadOrderUsingGETError = any

export const UserResourceApiReadOrderUsingGETRequestAction = userResourceApiActionCreator.async<
  UserResourceApiReadOrderUsingGETRequest,
  UserResourceApiReadOrderUsingGETSuccess,
  UserResourceApiReadOrderUsingGETError
>('READ_ORDER_USING_GET')

export type UserResourceApiReadUserUsingGETRequest = { userId: number; options?: any }
export type UserResourceApiReadUserUsingGETSuccess = UserDTO
export type UserResourceApiReadUserUsingGETError = any

export const UserResourceApiReadUserUsingGETRequestAction = userResourceApiActionCreator.async<
  UserResourceApiReadUserUsingGETRequest,
  UserResourceApiReadUserUsingGETSuccess,
  UserResourceApiReadUserUsingGETError
>('READ_USER_USING_GET')

export type UserResourceApiReadWalletTransactionUsingGETRequest = {
  userId: number
  walletTransactionId: number
  options?: any
}
export type UserResourceApiReadWalletTransactionUsingGETSuccess = WalletTransactionDto
export type UserResourceApiReadWalletTransactionUsingGETError = any

export const UserResourceApiReadWalletTransactionUsingGETRequestAction = userResourceApiActionCreator.async<
  UserResourceApiReadWalletTransactionUsingGETRequest,
  UserResourceApiReadWalletTransactionUsingGETSuccess,
  UserResourceApiReadWalletTransactionUsingGETError
>('READ_WALLET_TRANSACTION_USING_GET')

export type UserResourceApiSearchUsersUsingGETRequest = { query: string; options?: any }
export type UserResourceApiSearchUsersUsingGETSuccess = User[]
export type UserResourceApiSearchUsersUsingGETError = any

export const UserResourceApiSearchUsersUsingGETRequestAction = userResourceApiActionCreator.async<
  UserResourceApiSearchUsersUsingGETRequest,
  UserResourceApiSearchUsersUsingGETSuccess,
  UserResourceApiSearchUsersUsingGETError
>('SEARCH_USERS_USING_GET')

export type UserResourceApiUpdateBidStateUsingPUTRequest = {
  bidId: number
  userId: number
  value: 'NEW' | 'ACTIVE' | 'OUTBID' | 'CANCELED' | 'WON'
  options?: any
}
export type UserResourceApiUpdateBidStateUsingPUTSuccess = void
export type UserResourceApiUpdateBidStateUsingPUTError = any

export const UserResourceApiUpdateBidStateUsingPUTRequestAction = userResourceApiActionCreator.async<
  UserResourceApiUpdateBidStateUsingPUTRequest,
  UserResourceApiUpdateBidStateUsingPUTSuccess,
  UserResourceApiUpdateBidStateUsingPUTError
>('UPDATE_BID_STATE_USING_PUT')

export type UserResourceApiUpdateBidUsingPUTRequest = {
  bidId: number
  userId: number
  requestBody: BidUpdateUserRequest
  options?: any
}
export type UserResourceApiUpdateBidUsingPUTSuccess = BidDto
export type UserResourceApiUpdateBidUsingPUTError = any

export const UserResourceApiUpdateBidUsingPUTRequestAction = userResourceApiActionCreator.async<
  UserResourceApiUpdateBidUsingPUTRequest,
  UserResourceApiUpdateBidUsingPUTSuccess,
  UserResourceApiUpdateBidUsingPUTError
>('UPDATE_BID_USING_PUT')

export type UserResourceApiUpdateOrderDriverLicenseUsingPOSTRequest = {
  orderId: number
  userId: number
  file: any
  options?: any
}
export type UserResourceApiUpdateOrderDriverLicenseUsingPOSTSuccess = void
export type UserResourceApiUpdateOrderDriverLicenseUsingPOSTError = any

export const UserResourceApiUpdateOrderDriverLicenseUsingPOSTRequestAction = userResourceApiActionCreator.async<
  UserResourceApiUpdateOrderDriverLicenseUsingPOSTRequest,
  UserResourceApiUpdateOrderDriverLicenseUsingPOSTSuccess,
  UserResourceApiUpdateOrderDriverLicenseUsingPOSTError
>('UPDATE_ORDER_DRIVER_LICENSE_USING_POST')

export type UserResourceApiUpdateOrderUsingPUTRequest = {
  orderId: number
  userId: number
  requestBody: OrderUpdateUserRequest
  options?: any
}
export type UserResourceApiUpdateOrderUsingPUTSuccess = void
export type UserResourceApiUpdateOrderUsingPUTError = any

export const UserResourceApiUpdateOrderUsingPUTRequestAction = userResourceApiActionCreator.async<
  UserResourceApiUpdateOrderUsingPUTRequest,
  UserResourceApiUpdateOrderUsingPUTSuccess,
  UserResourceApiUpdateOrderUsingPUTError
>('UPDATE_ORDER_USING_PUT')

export type UserResourceApiUpdateUserUsingPUTRequest = { userDTO: UserDTO; options?: any }
export type UserResourceApiUpdateUserUsingPUTSuccess = UserDTO
export type UserResourceApiUpdateUserUsingPUTError = any

export const UserResourceApiUpdateUserUsingPUTRequestAction = userResourceApiActionCreator.async<
  UserResourceApiUpdateUserUsingPUTRequest,
  UserResourceApiUpdateUserUsingPUTSuccess,
  UserResourceApiUpdateUserUsingPUTError
>('UPDATE_USER_USING_PUT')

export type UserResourceApiUpdateWalletTransactionUsingPUTRequest = {
  userId: number
  walletTransactionId: number
  requestBody: WalletTransactionUpdateRequest
  options?: any
}
export type UserResourceApiUpdateWalletTransactionUsingPUTSuccess = void
export type UserResourceApiUpdateWalletTransactionUsingPUTError = any

export const UserResourceApiUpdateWalletTransactionUsingPUTRequestAction = userResourceApiActionCreator.async<
  UserResourceApiUpdateWalletTransactionUsingPUTRequest,
  UserResourceApiUpdateWalletTransactionUsingPUTSuccess,
  UserResourceApiUpdateWalletTransactionUsingPUTError
>('UPDATE_WALLET_TRANSACTION_USING_PUT')

export type VehicleStatesControllerApiIndexVehicleStatesUsingGETRequest = { options?: any }
export type VehicleStatesControllerApiIndexVehicleStatesUsingGETSuccess = VehicleStateDto[]
export type VehicleStatesControllerApiIndexVehicleStatesUsingGETError = any

export const VehicleStatesControllerApiIndexVehicleStatesUsingGETRequestAction = vehicleStatesControllerApiActionCreator.async<
  VehicleStatesControllerApiIndexVehicleStatesUsingGETRequest,
  VehicleStatesControllerApiIndexVehicleStatesUsingGETSuccess,
  VehicleStatesControllerApiIndexVehicleStatesUsingGETError
>('INDEX_VEHICLE_STATES_USING_GET')

export type VehicleStatesControllerApiReadVehicleStateUsingGETRequest = { id: number; options?: any }
export type VehicleStatesControllerApiReadVehicleStateUsingGETSuccess = VehicleStateDto
export type VehicleStatesControllerApiReadVehicleStateUsingGETError = any

export const VehicleStatesControllerApiReadVehicleStateUsingGETRequestAction = vehicleStatesControllerApiActionCreator.async<
  VehicleStatesControllerApiReadVehicleStateUsingGETRequest,
  VehicleStatesControllerApiReadVehicleStateUsingGETSuccess,
  VehicleStatesControllerApiReadVehicleStateUsingGETError
>('READ_VEHICLE_STATE_USING_GET')

export type WalletTransactionTypesControllerApiIndexWalletTransactionTypesUsingGETRequest = { options?: any }
export type WalletTransactionTypesControllerApiIndexWalletTransactionTypesUsingGETSuccess = WalletTransactionTypeDto[]
export type WalletTransactionTypesControllerApiIndexWalletTransactionTypesUsingGETError = any

export const WalletTransactionTypesControllerApiIndexWalletTransactionTypesUsingGETRequestAction = walletTransactionTypesControllerApiActionCreator.async<
  WalletTransactionTypesControllerApiIndexWalletTransactionTypesUsingGETRequest,
  WalletTransactionTypesControllerApiIndexWalletTransactionTypesUsingGETSuccess,
  WalletTransactionTypesControllerApiIndexWalletTransactionTypesUsingGETError
>('INDEX_WALLET_TRANSACTION_TYPES_USING_GET')

export type WalletTransactionTypesControllerApiReadWalletTransactionTypeUsingGETRequest = { id: number; options?: any }
export type WalletTransactionTypesControllerApiReadWalletTransactionTypeUsingGETSuccess = WalletTransactionTypeDto
export type WalletTransactionTypesControllerApiReadWalletTransactionTypeUsingGETError = any

export const WalletTransactionTypesControllerApiReadWalletTransactionTypeUsingGETRequestAction = walletTransactionTypesControllerApiActionCreator.async<
  WalletTransactionTypesControllerApiReadWalletTransactionTypeUsingGETRequest,
  WalletTransactionTypesControllerApiReadWalletTransactionTypeUsingGETSuccess,
  WalletTransactionTypesControllerApiReadWalletTransactionTypeUsingGETError
>('READ_WALLET_TRANSACTION_TYPE_USING_GET')
