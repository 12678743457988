import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch } from 'react-router-dom'
import { App } from '@/app/App'
import { PageLoadingPlaceholder } from '@/ui/atoms'
import { routes } from './routes'
import { configureStore, history } from './redux/store/configureStore'
import { setupAxiosInterceptors } from './lib'
import { PrivateRoute } from './ui/molecules'
import * as serviceWorker from './serviceWorker'

export const defaultTableRowsPerPage = 10

export const getLocationHistory = () => history.location

setupAxiosInterceptors()

const store = configureStore()

const getInnerComponent = (route: any) => (props: any) => (route.private ? (
  <PrivateRoute role={route.role}>
    <route.component path={route.path} {...props} route={route} routes={route.routes} />
    {' '}
  </PrivateRoute>
) : (
  <route.component {...props} route={route} routes={route.routes} />
))

const Main = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App>
        <Suspense fallback={<PageLoadingPlaceholder />}>
          <Switch>
            {routes.map((route, i) => (
              <Route key={i} path={route.path} exact={route.exact} render={getInnerComponent(route)} />
            ))}
          </Switch>
        </Suspense>
      </App>
    </ConnectedRouter>
  </Provider>
)

ReactDOM.render(<Main />, document.getElementById('root'))

serviceWorker.register()
