import React, { forwardRef } from 'react'
import { CustomizedTooltips } from '..'

import styles from './icon.module.scss'

type Props = {
  name: string
  className?: string
  width?: string
  height?: string
  title?: string
  onClick?: React.MouseEventHandler
}

export const Icon = forwardRef(
  ({ name, title, className, width = '100%', height = '100%', onClick = () => {} }: Props, ref) => (
    // @ts-ignore
    <div className={styles.wrapper} ref={ref}>
      {title ? (
        <CustomizedTooltips title={title}>
          <svg width={width} height={height} onClick={onClick} className={className}>
            <use xlinkHref={`#${name}`} />
          </svg>
        </CustomizedTooltips>
      ) : (
        <svg width={width} height={height} onClick={onClick} className={className}>
          <use xlinkHref={`#${name}`} />
        </svg>
      )}
    </div>
  ),
)
