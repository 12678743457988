import React, { useEffect } from 'react'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { BlogItemShort } from '@/ui/atoms'
import { Store } from '@/redux/store/store.interface'
import { BlogState } from '@/redux/types/blog-type'
import * as BlogActions from '@/redux/actions/blog-action'
import styles from './footer-latest-news.module.scss'

const getSelectorBlogById = () => ({ blog }: Store): BlogState => blog

export const FooterLatestNews: React.FC<{
  className?: string
}> = ({ className }) => {
  const blogState = useSelector<Store, BlogState>((store) => store.blog)
  const dispatch = useDispatch()
  const { posts } = useSelector<Store, any>(getSelectorBlogById())

  useEffect(() => {
    if (!posts.allPostsSlugs.length) {
      dispatch(BlogActions.fetchByAll.started({}))
    }
  }, [posts, dispatch])

  return (
    <div className={cn(className, styles.wrappedContainer)}>
      <span className={styles.title}>Latest News</span>
      <LazyLoadComponent>
        <div className={styles.newsList}>
          {blogState.posts.allPostsSlugs.slice(0, 4).map((postSlug: string) => (
            <BlogItemShort key={postSlug} post={blogState.posts.bySlug[postSlug]} to={`/blog/${postSlug}`} />
          ))}
        </div>
      </LazyLoadComponent>
    </div>
  )
}
