import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { UserResourceApiGetAllUsersUsingGETRequestAction } from '@/api/origin/action'
import { ClientsState } from '@/redux/types/clients-type'
import { logout } from '@/redux/actions/auth-action'

export const clientsInitState: ClientsState = {
  byId: {},
  allIds: [],
  isLoading: false,
}
export const clientsReducer = reducerWithInitialState<any>(clientsInitState)
  .case(logout, () => clientsInitState)
  .case(UserResourceApiGetAllUsersUsingGETRequestAction.started, (state) => ({
    ...state,
    isLoading: false,
  }))
  .case(UserResourceApiGetAllUsersUsingGETRequestAction.done, (state, { result }: any) => ({
    ...state,
    byId: {
      ...state.byId,
      ...result.byId,
    },
    allIds: [...new Set([...state.allIds, ...result.allIds])],
    isLoading: true,
  }))
  .build()
