import React, { useState } from 'react'
// @ts-ignore
import ItemsCarousel from 'react-items-carousel'
import cn from 'classnames'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import { SectionHeaderDynamic, Button } from '@/ui/atoms'
import { IFindCarFromClassified, IFindCarFromClassifiedBlock } from '@/redux/types/seo-type'
import { useResize } from '@/lib'
import { Item } from './item'
import styles from './find_car_from_classified_home_page.module.scss'

type Props = {
  findCar: IFindCarFromClassified
  isActiveButton?: boolean
}

export const FindCarFromClassifiedHomePage = ({ findCar, isActiveButton = true }: Props) => {
  const dispatch = useDispatch()
  let windowSize = useResize()
  const itemWidth = windowSize > 1199 ? 190 : 215
  windowSize = windowSize > 1200 ? 1200 : windowSize
  const windowCountItems = Math.floor(windowSize / itemWidth)
  const isMobile = windowSize < 800
  const chevronWidth = isMobile ? 15 : 40
  const isArrow = windowSize < findCar.blocks.length * itemWidth && !isMobile
  const [activeItemIndex, setActiveItemIndex] = useState(0)

  return (
    <section className={styles.section}>
      <LazyLoadComponent>
        <div className={styles.sectionWrapper}>
          <SectionHeaderDynamic type="dark" className={windowSize < 1400 ? 'mb-20' : 'mb-35'} text={findCar.title} />
          <div className={styles.carouselWrapper} style={{ padding: `0 ${chevronWidth}px` }}>
            <ItemsCarousel
              requestToChangeActive={setActiveItemIndex}
              activeItemIndex={activeItemIndex}
              numberOfCards={windowCountItems}
              gutter={20}
              infiniteLoop
              outsideChevron={isArrow}
              chevronWidth={chevronWidth}
              leftChevron={(
                <div className={cn({ [styles.disabled]: !isArrow })}>
                  <button
                    className={cn(styles.controlArrow, styles.controlPrev)}
                    aria-label="Left Button"
                    name="Left button"
                  />
                </div>
              )}
              rightChevron={(
                <div className={cn({ [styles.disabled]: !isArrow })}>
                  <button
                    className={cn(styles.controlArrow, styles.controlNext)}
                    aria-label="Right Button"
                    name="Right button"
                  />
                </div>
              )}
            >
              {findCar.blocks.map((block: IFindCarFromClassifiedBlock, index: number) => (
                <Item key={index} src={block.image} link={block.link_classified} />
              ))}
            </ItemsCarousel>
          </div>
          {isActiveButton && (
            <div className={styles.button}>
              <Button type="primary" onClick={() => dispatch(push('/european-classifieds'))} aria-label="Learn more">
                LEARN MORE
              </Button>
            </div>
          )}
        </div>
      </LazyLoadComponent>
    </section>
  )
}
