import React, { useState } from 'react'
import cn from 'classnames'
import { Icon } from '@/ui/atoms'
import styles from './section-collapse.module.scss'

export const SectionCollapse: React.FC<{
  className?: string
  title: string
}> = ({ children, title }) => {
  const [isOpenDetails, setIsOpenDetails] = useState(false)
  const openSection = () => {
    setIsOpenDetails(!isOpenDetails)
  }

  return (
    <div className={styles.sectionCollapse}>
      <div className={styles.sectionCollapseHeader} onClick={openSection}>
        <div className={styles.sectionCollapseTitle}>{title}</div>
        <div className={styles.sectionCollapseActive}>
          {!isOpenDetails && <Icon name="icon-arrow-down" className={styles.iconArrow} />}
          {isOpenDetails && <Icon name="icon-arrow-up" className={styles.iconArrow} />}
        </div>
      </div>

      <div className={cn(styles.section, { [styles.active]: isOpenDetails })}>{children}</div>
    </div>
  )
}
