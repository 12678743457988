import React from 'react'
import cn from 'classnames'
import { Post } from '@/redux/types/blog-type'
import { getShortDescription, formatDate } from '@/lib'
import { LanguageLink, Icon } from '@/ui/atoms'
import styles from './blog-item-short.module.scss'

export const BlogItemShort: React.FC<{
  className?: string
  post: Post
  to: string
}> = ({ className, post, to }) => (
  <div className={cn(className, styles.wrappedContainer)}>
    <LanguageLink to={to} aria-label={post.title}>
      {post.thumbnail ? (
        <div className={styles.image} style={{ backgroundImage: `url("${post.thumbnail}")` }} />
      ) : (
        <Icon name="image-placeholder" className={styles.image} />
      )}
    </LanguageLink>
    <div className={styles.wrapperDateTitle}>
      <LanguageLink className={styles.linkTitle} to={to} aria-label={post.title}>
        <span className={styles.title}>{getShortDescription(post.title, 43)}</span>
      </LanguageLink>
      <div className={styles.date}>{formatDate(post)}</div>
    </div>
  </div>
)
