import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { PortState } from '@/redux/types/port-type'
import { PortsControllerApiIndexPortsUsingGETRequestAction } from '@/api/origin/action'

export const portsInitState: PortState = {
  ports: [],
}

export const portReducer = reducerWithInitialState<PortState>(portsInitState)
  .case(PortsControllerApiIndexPortsUsingGETRequestAction.done, (state, data: any) => ({
    ports: data.result,
  }))
  .build()
