import actionCreatorFactory from 'typescript-fsa'
import { Size } from '@/redux/types/popup-type'

const actionCreator = actionCreatorFactory('POPUP')

export const open = actionCreator<{
  noScroll?: boolean
  height?: number | string
  size?: Size
  body?: React.ReactNode
  isCloseButton?: boolean
}>('OPEN')

export const close = actionCreator('CLOSE')
