import React from 'react'
import cn from 'classnames'

import styles from './section-header-dynamic.module.scss'

type TProps = {
  type: 'light' | 'dark'
  className?: string
  text: string
}

export const SectionHeaderDynamic = ({ className, text, type }: TProps) => (
  <h2 className={cn(className, styles.header, { [styles.light]: type === 'light' })}>
    <i dangerouslySetInnerHTML={{ __html: text }} />
  </h2>
)
