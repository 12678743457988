import React from 'react'
import { LotDto } from '@/api/origin/api'
import { HorizontalCardProperty } from '@/pages/index/page-vehicles/ui/atoms'
import { shortenByWord, HTMLDecoder, formatAveragePrice, getCarMil } from '@/lib'
import { ConditionPrompt, ModificationPrompt } from '@/pages/index/page-vehicles/ui/molecules'
import { EstShippingPrompt, AveragePricePrompt } from '@/ui/atoms'
import style from './horizontal-card-properties.module.scss'

export const HorizontalCardProperties: React.FC<{ car: LotDto }> = ({ car }) => {
  const modification = HTMLDecoder(car.modification)
  return (
    <div className={style.propertiesContainer}>
      <HorizontalCardProperty
        name="Modification"
        value={shortenByWord(HTMLDecoder(modification), 20)}
        postfix={
          modification && modification.length > 20 ? (
            <ModificationPrompt className={style.modificationPrompt} car={car} />
          ) : null
        }
        hoverPostfix
      />

      <HorizontalCardProperty name="Mileage" value={getCarMil(car.mileage)} />
      <HorizontalCardProperty name="Auction" value={car.auctionName} />
      <HorizontalCardProperty
        name="Condition"
        value={
          car.rate && (
            <>
              {car.rate}
              <ConditionPrompt estimate={car.rate} />
            </>
          )
        }
      />
      <HorizontalCardProperty name="Est. Shipping" value="$1,200" postfix={<EstShippingPrompt value={1200} />} />
      <HorizontalCardProperty name="Average Price" value={formatAveragePrice(car)} postfix={<AveragePricePrompt />} />
    </div>
  )
}
