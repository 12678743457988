import React from 'react'
import { NavigationItem } from '@/ui/atoms'
import { withRouter, RouteComponentProps } from 'react-router'
/* import { NavHashLink } from 'react-router-hash-link'
// import styles from './navigation.module.scss' */

type Props = {
  className?: string
}

export const Navigation = withRouter(({ location: { pathname }, className }: Props & RouteComponentProps) => (
  <nav className={className}>
    <NavigationItem href="/" value="Home" active={pathname === '/'} />
    <NavigationItem href="/vehicles" value="Vehicles" active={pathname === '/vehicles'} />
    <NavigationItem
      href="/european-classifieds"
      value="Cars from Europe"
      active={pathname === '/european-classifieds'}
    />
    <NavigationItem href="/about-us" value="About Us" active={pathname === '/about-us'} />
    <NavigationItem href="/blog" value="Blog" active={pathname === '/blog'} />
    <NavigationItem href="/faq" value="faq" active={pathname === '/faq'} />
    {/* <NavHashLink className={styles.hashLink} to="/#HowItWorks">
        How it works
      </NavHashLink>
      <NavigationItem href="/contact-us" value="Contact Us" active={'/contact-us' === pathname} /> */}
  </nav>
))
