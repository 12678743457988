import { reducerWithInitialState } from 'typescript-fsa-reducers'
import {
  ProcurementState,
  ProcurementItem,
  ProcurementPersonalDetails,
  ProcurementShippingDetails,
  ProcurementAdditionalRequests,
  ProcurementBidding,
  ProcurementFinalPayment,
  ProcurementDriverLicense,
  ProcurementOrderReceived,
} from '@/redux/types/procurement-type'
import { addProcurement, changeProcurement } from '@/redux/actions/procurement-action'
import { OrderUpdateUserRequestTransportTypeEnum } from '@/api/origin/api'
import { logout } from '@/redux/actions/auth-action'

export const procurementInitState: ProcurementState = {
  byId: {},
  allIds: [],
}

export const procurementPersonalDetailsInitState: ProcurementPersonalDetails = {
  isDone: false,
}

export const procurementAdditionalRequestsInitState: ProcurementAdditionalRequests = {
  isDone: false,
  ppviRequested: false,
  additionalPhotosRequested: false,
}

export const procurementBiddingInitState: ProcurementBidding = {
  isDone: false,
  biddingPrice: 0,
  priority: 0,
}

export const procurementShippingDetailsInitState: ProcurementShippingDetails = {
  isDone: false,
  shippingAddress: {
    address: '',
    city: '',
    state: '',
    zipCode: '',
  },
  transportType: OrderUpdateUserRequestTransportTypeEnum.OPEN,
  desiredDeliveryDate: '',
  selfPickup: false,
  portId: 0,
  shippingInsuranceRequested: false,
}

export const procurementFinalPaymentInitState: ProcurementFinalPayment = {
  isDone: false,
}

export const procurementDriverLicenseInitState: ProcurementDriverLicense = {
  isDone: false,
}

export const procurementOrderReceivedInitState: ProcurementOrderReceived = {
  isDone: false,
}

export const procurementReducer = reducerWithInitialState<ProcurementState>(procurementInitState)
  .case(logout, () => procurementInitState)
  .case(addProcurement, (state, data: ProcurementItem) => ({
    byId: {
      ...state.byId,
      [data.id]: {
        progress: 0,
        'personal-details': procurementPersonalDetailsInitState,
        'additional-requests': procurementAdditionalRequestsInitState,
        bidding: procurementBiddingInitState,
        'shipping-details': procurementShippingDetailsInitState,
        'final-payment': procurementFinalPaymentInitState,
        'driver-license': procurementDriverLicenseInitState,
        'order-received': procurementOrderReceivedInitState,
        ...data,
      },
    },
    allIds: [...new Set([...state.allIds, data.id])],
  }))
  .case(changeProcurement, (state, data: ProcurementItem) => {
    let progress = 0
    if (!data || !data.step) {
      return {
        ...state,
      }
    }

    // eslint-disable-next-line default-case
    switch (data.step) {
      case 'personal-details':
        progress = 0
        break
      case 'additional-requests':
        progress = Math.ceil(100 / 6)
        break
      case 'bidding':
        progress = Math.ceil((100 / 6) * 2)
        break
      case 'shipping-details':
        progress = Math.ceil((100 / 6) * 3)
        break
      case 'final-payment':
        progress = Math.ceil((100 / 6) * 4)
        break
      case 'driver-license':
        progress = Math.ceil((100 / 6) * 5)
        break
      case 'order-received':
        progress = Math.ceil((100 / 6) * 6)
        break
    }
    return {
      ...state,
      byId: {
        ...state.byId,
        [data.id]: {
          ...state.byId[data.id],
          ...data,
          progress,
        },
      },
    }
  })
  .build()
