import React, { useState } from 'react'
import cn from 'classnames'
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component'
// @ts-ignore
import ItemsCarousel from 'react-items-carousel'
import { useResize } from '@/lib'
import { RatingItem } from './rating-item'
import styles from './home-ratings.module.scss'

export const HomeRatings: React.FC<{
  className?: string
  ratings: {
    value: string
    logo: string
    link_rating: {
      url: string
      text: string
      target: string
    }
  }[]
}> = ({ className, ratings }) => {
  const windowSize = useResize()
  const mobile = windowSize < 800
  const itemWidth = 399
  const count = windowSize > 1200 ? 3 : Math.floor(windowSize / itemWidth)
  const [activeItemIndex, setActiveItemIndex] = useState(0)

  return (
    <section className={cn(className, styles.wrapperComponent)}>
      <LazyLoadComponent>
        <div className={cn('container', styles.itemComponent)}>
          <ItemsCarousel
            requestToChangeActive={setActiveItemIndex}
            activeItemIndex={activeItemIndex}
            numberOfCards={count}
            gutter={50}
            infiniteLoop
          >
            {ratings.map((i, index) => (
              <RatingItem key={index} estimate={i.value} className={styles.ratingWrapper} link_rating={i.link_rating}>
                <LazyLoadImage className={styles.ratingImg} src={i.logo} alt="" />
              </RatingItem>
            ))}
          </ItemsCarousel>
          {mobile && (
            <div className={styles.controlDots}>
              {ratings.map((i: any, key: number) => (
                <button
                  key={key}
                  onClick={() => setActiveItemIndex(key)}
                  className={cn(styles.dot, { [styles.active]: key === activeItemIndex })}
                  name={`Ratings button - ${key}`}
                  aria-label={`Ratings button - ${key}`}
                />
              ))}
            </div>
          )}
        </div>
      </LazyLoadComponent>
    </section>
  )
}
