import React from 'react'

const DocIcon: React.FC = () => (
  <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.55 0.5H1.5C0.675 0.5 0 1.175 0 2V14C0 14.825 0.675 15.5 1.5 15.5H10.5C11.325 15.5 12 14.825 12 14V3.95L8.55 0.5ZM10.5 14H1.5V2H7.5V5H10.5V14Z"
      fill="#2A323C"
    />
    <path d="M7.5 7.25H3V8.75H7.5V7.25Z" fill="#2A323C" />
    <path d="M9 10.25H3V11.75H9V10.25Z" fill="#2A323C" />
  </svg>
)

export default DocIcon
