import React from 'react'
import cn from 'classnames'
import { useDispatch } from 'react-redux'
import { Pagination } from '@/ui/molecules'
import { AuctionState, GridType } from '@/redux/types/auction-type'
import { VehicleVerticalCard, VehicleHorizontalCard } from '@/pages/index/page-vehicles/ui/molecules'
import { PageLoadingPlaceholder } from '@/ui/atoms'
import { useResize } from '@/lib'
import * as VehiclesActions from '@/redux/actions/auction-action'
import { SearchOutcome } from '../../atoms/search-outcome'
import styles from './vehicle-list.module.scss'

export const VehicleList: React.FC<{
  auction: AuctionState
  changePageNumber: (num: number) => void
}> = React.memo(({ auction, changePageNumber }) => {
  const windowSize = useResize()
  const dispatch = useDispatch()
  const changeTypeGrid = (type: GridType) => dispatch(VehiclesActions.changeTypeGrid(type))

  return (
    <div>
      <div className={styles.searchOutcome}>
        <SearchOutcome
          japan={auction.totalElements || 0}
          total={auction.totalElements || 0}
          changeTypeGrid={changeTypeGrid}
          typeGrid={auction.grid}
        />
      </div>
      {auction.isLoading ? (
        windowSize > 1200 ? (
          <div
            className={cn([styles.wrapper], {
              [styles.grid]: auction.grid === 'grid',
              [styles.list]: auction.grid === 'list',
            })}
          >
            {auction.isLoading &&
              auction.allIds.map((carId) => (auction.grid === 'grid' ? (
                <VehicleVerticalCard key={auction.byId[carId].externalId} car={auction.byId[carId]} />
              ) : (
                <VehicleHorizontalCard key={auction.byId[carId].externalId} car={auction.byId[carId]} />
              )),
              )}
          </div>
        ) : (
          <div className={cn(styles.wrapper, styles.grid)}>
            {auction.isLoading &&
              auction.allIds.map((carId) => (
                <VehicleVerticalCard key={auction.byId[carId].externalId} car={auction.byId[carId]} />
              ))}
          </div>
        )
      ) : (
        <PageLoadingPlaceholder />
      )}
      {Boolean(auction.totalPages && auction.totalPages > 1) && (
        <div className={styles.pagination}>
          <Pagination
            currentPage={auction.number || 0}
            totalPage={auction.totalPages || 0}
            changePageNumber={changePageNumber}
          />
        </div>
      )}
    </div>
  )
})
