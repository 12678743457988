import styled from 'styled-components'
import { FormControl, Select, MenuItem, Grid, Chip, InputLabel, OutlinedInput, ListSubheader } from '@material-ui/core'

export const STChipWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const STChip = styled(Chip)`
  margin: 2px;
`

export const STFormControl = styled(FormControl).attrs({
  root: 'root',
})`
  && {
    min-height: 40px;
    min-width: 120px;
    width: 100%;
    //margin: 10px 0 0 0;
  }
`

export const STSelect = styled(Select as any).attrs({
  classes: { selectMenu: 'selectMenu', disabled: 'disabled', select: 'select' },
})`
  && {
    .selectMenu {
      white-space: ${(props: { ['data-onindex']?: boolean }) => (props['data-onindex'] ? 'nowrap' : 'inherit')};
      padding: 10px 26px 10px 8px;
      background-color: transparent;

      &:focus {
        background-color: transparent;
      }
    }
    .MuiSelect-select.Mui-disabled {
      cursor: not-allowed;
      color: red;
    }
  }
`

export const STIconGrid = styled(Grid)`
  && {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5px;
  }
`

export const STMenuItem = styled(MenuItem).attrs({
  classes: { selected: 'selected' },
})`
  && {
    height: 40px;

    &:hover {
      background-color: #eca52c;
    }

    &.selected {
      background-color: transparent;
      &:hover {
        background-color: #eca52c;
      }
    }

    &.Mui-disabled {
      position: sticky !important;
      top: 0;
      background: white;
      z-index: 99 !important;
      opacity: 1;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
`

export const STInputLabel = styled(InputLabel).attrs({
  classes: { focused: 'focused', outlined: 'outlined', shrink: 'shrink' },
})`
  && {
    color: #555b63;
    opacity: 0.6;
    &.focused {
      color: #eca52c;
    }

    &.outlined {
      transform: translate(10px, 13px) scale(1);
    }

    &.shrink {
      transform: translate(14px, -6px) scale(0.75);
      color: #eca52c;
      opacity: 1;
      background: #fff;
      padding: 0 4px;
    }
  }
`

export const STListSubheader = styled(ListSubheader as any).attrs({
  classes: { root: 'root', sticky: 'sticky', gutters: 'gutters' },
})`
  &.sticky {
    background: white !important;
  }
`

export const STOutlinedInput = styled(OutlinedInput).attrs({
  classes: {
    root: 'root',
    notchedOutline: 'outline',
    disabled: 'disabled',
    focused: 'focused',
  },
})`
  min-height: 40px;
  &.root .outline {
    border-width: 1px;
    border-color: #ededed;
    background-color: transparent;
  }

  &.root:hover .outline {
    border-color: #eca52c;
  }

  &.root.disabled .outline {
    border-color: #ededed;
  }

  &.root.focused .outline {
    border-color: #eca52c;
    border-width: 1px;
  }
`
