import React from 'react'

import { DefaultPopup } from '@/ui/molecules'

export const PopupEmptyComparison: React.FC<{ d: () => void; cancel: () => void }> = ({ d, cancel }) => (
  <DefaultPopup
    title="Empty Comparison List"
    body="Your comparison list is empty! Explore cars and add to comparison list some items."
    cancel={cancel}
    d={d}
    buttonText="VIEW CARS"
  />
)
