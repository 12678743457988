import React from 'react'

import { NavigationItem } from '@/ui/atoms'

import dataList, { DataListType } from './data'
import styles from './footer-navigation.module.scss'

export const FooterNavigation = () => (
  <nav className={styles.wrapperContainer}>
    {dataList.map((item: DataListType) => (
      <NavigationItem className={styles.navigationItemFooter} key={item.id} href={item.href} value={item.title} />
    ))}
  </nav>
)
