import { post } from '@/lib/fetch-data'
import { InquireFormType } from '@/redux/types/inquiries-form-type'
import { WP_API_URL } from '@/constants/API'

const url = `${WP_API_URL}/wp-json/api/email/feedback-form`
export const postRequest = (params: InquireFormType) => post(url, params).then((response) => {
  if (response) {
    if (response.http_code === 200) {
      return response.data
    }
  }
})
