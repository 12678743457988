import actionCreatorFactory from 'typescript-fsa'
import { UserDTO } from '@/api/origin/api'

const actionCreator = actionCreatorFactory()

export const logout = actionCreator('LOGOUT')

export const activated = actionCreator<any>('ACTIVATED')

export const NOTHING = actionCreator('NOTHING')

export const signInWithTokenXSRF = actionCreator.async<any, UserDTO, any>('SIGN_IN')

export const authorize = actionCreator<any>('AUTHORIZE')

export const registration = actionCreator<any>('registration')

export const resetPassword = actionCreator<any>('RESET_PASSWORD')

export const confirmNewPassword = actionCreator<any>('CONFIRM_NEW_PASSWORD')
