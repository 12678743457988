import actionCreatorFactory from 'typescript-fsa'
import { BlogState, Post } from '@/redux/types/blog-type'

const actionCreator = actionCreatorFactory('BLOG')

export const fetchByAll = actionCreator.async<any, BlogState, any>('FETCH_BY_ALL')
export const fetchById = actionCreator.async<string, Post, any>('FETCH_BY_ID')

export const changeCurrentPage = actionCreator<number>('CHANGE_CURRENT_PAGE')
export const changeSort = actionCreator<'DESC' | 'ASC'>('CHANGE_SORT')
export const changeSearch = actionCreator<string>('CHANGE_SEARCH')
