import { LotDtoE } from '@/redux/types/lot-type'
import { BidDTOE } from '@/redux/types/bid-type'
import {
  OrderDto,
  UserDTO,
  LotDto,
  BidDto,
  WalletTransactionDto,
  WalletTransactionTypeDto,
  BidsReportRowDto,
  PageOfLotDto,
  LotStateDto,
} from '@/api/origin/api'
import { Post } from '@/redux/types/blog-type'

type TOptions = {
  procurementId: number
  lotId: number
}

export const normalizeBid = (data: any, { procurementId, lotId }: TOptions) => {
  const byIdBid: { [key: string]: BidDTOE } = {}
  const allIdsBid: number[] = []

  const byIdLot: { [key: string]: LotDtoE } = {}
  const allIdsLot: number[] = []

  data.forEach((item: BidDTOE) => {
    if (item.id) {
      allIdsBid.push(item.id)
      byIdBid[item.id] = { ...item }

      const lot: LotDtoE | any = item.lot
      allIdsLot.push(lot.id)

      if (lot.id) {
        byIdLot[lot.id] = {
          ...lot,
          bidId: item.id,
          procurementId: lotId === lot.id ? procurementId || null : null,
        }
      }

      delete byIdBid[item.id].lot
      byIdBid[item.id].lotId = lot.id
    }
  })

  return {
    lot: {
      byId: byIdLot,
      allIds: allIdsLot,
    },
    bid: {
      byId: byIdBid,
      allIds: allIdsBid,
    },
  }
}

export const normalizeOrder = (data: OrderDto[]) => {
  const byIdOrder: { [key: string]: any } = {}
  const allIdsOrder: number[] = []

  const byIdLot: { [key: string]: LotDtoE } = {}
  const allIdsLot: number[] = []

  data.forEach((item: any) => {
    if (item.id) {
      allIdsOrder.push(item.id)
      byIdOrder[item.id] = { ...item }

      const lot: LotDtoE | any = item.lot
      allIdsLot.push(lot.id)

      if (lot.id) {
        byIdLot[lot.id] = {
          ...lot,
          bidId: item.id,
          procurementId: null,
        }
      }

      delete byIdOrder[item.id].lot
      byIdOrder[item.id].lotId = lot.id
    }
  })

  return {
    lot: {
      byId: byIdLot,
      allIds: allIdsLot,
    },
    order: {
      byId: byIdOrder,
      allIds: allIdsOrder,
    },
  }
}

export const normalizeClients = (data: UserDTO[]) => {
  const byId: { [key: string]: UserDTO } = {}
  const allIds: number[] = []

  data.forEach((item: UserDTO) => {
    if (item.id) {
      allIds.push(item.id)
      byId[item.id] = { ...item }
    }
  })

  return { byId, allIds }
}

export const normalizeWallet = (data: WalletTransactionDto[]) => {
  const byId: { [key: string]: WalletTransactionDto } = {}
  const allIds: number[] = []

  data.forEach((item: WalletTransactionDto) => {
    if (item.id) {
      allIds.push(item.id)
      byId[item.id] = { ...item }
    }
  })

  return { byId, allIds }
}

export const normalizePaymentTypes = (data: WalletTransactionTypeDto[]) => {
  const byId: { [key: string]: WalletTransactionTypeDto } = {}
  const allIds: number[] = []

  data.forEach((item: WalletTransactionTypeDto) => {
    if (item.id) {
      allIds.push(item.id)
      byId[item.id] = { ...item }
    }
  })

  return { byId, allIds }
}

export const normalizeWatch = (data: LotDto[]) => {
  const byId: { [key: string]: LotDto } = {}
  const allIds: number[] = []

  data.forEach((item) => {
    if (item.id) {
      allIds.push(item.id)
      byId[item.id] = item
    }
  })

  return {
    byId,
    allIds,
  }
}

export const normalizeManagerBids = (data: BidsReportRowDto[]) => {
  const byId: { [key: string]: any } = {}
  const allIds: number[] = []

  data.forEach((item: BidsReportRowDto, index: number) => {
    // @ts-ignore
    const byIdBid: { [key: string]: BidDto } = item.bids.reduce(
      (acc: { [key: string]: BidDto }, el: BidDto) => ({
        ...acc,
        // @ts-ignore
        [el.id]: el,
      }),
      {},
    )
    // @ts-ignore
    const allIdsBid: number[] = item.bids.map((el: BidDto) => el.id)

    allIds.push(index)
    byId[index] = {
      ...item,
      allIdsBids: allIdsBid,
      byIdBids: byIdBid,
    }
  })

  return {
    report: {
      byId,
      allIds,
    },
    bids: {
      byId: {},
      allIds: [],
    },
  }
}

export const normalizeManagerOrders = (data: OrderDto[]) => {
  const byId: { [key: number]: OrderDto } = {}
  const allIds: number[] = []

  data.forEach((item: OrderDto) => {
    let orderId: number = 0

    if (item.id) {
      allIds.push(item.id)
      orderId = item.id
    }

    byId[orderId] = {
      ...item,
      [orderId]: item,
    }
  })

  return {
    report: {
      byId,
      allIds,
    },
    orders: {
      byId: {},
      allIds: [],
    },
  }
}

export const normalizeAuction = (data: PageOfLotDto) => {
  const byId: { [key: string]: LotDto } = {}
  const allIds: string[] = []
  const newData = { ...data, byId, allIds }

  if (newData.content) {
    newData.content.forEach((auction: LotDto) => {
      if (auction.externalId) {
        allIds.push(auction.externalId)
        newData.byId[auction.externalId] = auction
      }
    })
  }

  delete newData.content

  return newData
}

export const normalizeLots = (data: LotDto[]) => {
  const byId: { [key: string]: LotDto } = {}
  const allIds: number[] = []
  const newData = { byId, allIds }

  if (data.length) {
    data.forEach((lot: LotDto) => {
      if (lot.id) {
        allIds.push(lot.id)
        newData.byId[lot.id] = lot
      }
    })
  }

  return newData
}

export const normalizeLotsStates = (data: LotStateDto[]) => {
  const byId: { [key: string]: LotStateDto } = {}
  const allIds: number[] = []
  const newData = { byId, allIds }

  if (data.length) {
    data.forEach((lotState: LotStateDto) => {
      if (lotState.id) {
        allIds.push(lotState.id)
        newData.byId[lotState.id] = lotState
      }
    })
  }
  return newData
}

export const normalizeBlog = (data: Post[], page_number: number) => {
  const bySlugPost: { [key: string]: Post } = {}
  const byPageNumber: { [key: number]: string[] } = {}
  const allSlugsPost: string[] = []

  data.forEach((item: Post) => {
    if (item) {
      allSlugsPost.push(item.slug)
      bySlugPost[item.slug] = item
    }
  })

  byPageNumber[page_number] = allSlugsPost

  return {
    bySlug: bySlugPost,
    allPostsSlugs: allSlugsPost,
    byPageNumber,
  }
}
