import { useEffect, PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import * as H from 'history'
import { Store } from '@/redux/store/store.interface'
import { AuthState } from '@/redux/types/auth-type'

type TProps = {
  role?: string
} & PropsWithChildren<{}>

export const PrivateRoute = ({ role, children }: TProps): any => {
  const history = useHistory()
  const { pathname }: H.Location = history.location
  const { user } = useSelector<Store, AuthState>((store) => store.auth)
  const authorities = user && user.authorities ? user.authorities : []
  useEffect(() => {
    const roleCheck = role ? authorities.includes(role) : true
    if (user && !authorities.length) {
      history.replace('/', { from: pathname })
    }

    if (user && authorities.length && !roleCheck) {
      history.replace('/', { from: pathname })
    }
  }, [authorities, role, history, pathname, user])

  return children
}
