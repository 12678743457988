import React from 'react'
import cn from 'classnames'

import styles from './message-16-19.module.scss'

interface Props {
  className?: string
}

export const Message1619: React.FC<Props> = ({ children, className }) => (
  <div className={cn(styles.root, className)}>{children}</div>
)
