import React from 'react'
import cn from 'classnames'
import { LanguageLink } from '@/ui/atoms'
import LogoIcon from '@/static/images/logo-icon.png'

import styles from './logo.module.scss'

export const Logo: React.FC<{
  className?: string
}> = ({ className }) => (
  <LanguageLink to="/" className={cn(className, styles.wrapperComponent)} aria-label="Home">
    <div className={styles.iconLogo} style={{ backgroundImage: `url("${LogoIcon}")` }} />
  </LanguageLink>
)
