import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, PopupTitle } from '@/ui/atoms'
import { Message1619 } from '@/pages/procurement/ui/atoms/message-16-19'
import { close } from '@/redux/actions/popup-action'
import { logout } from '@/redux/actions/auth-action'
import { push } from 'connected-react-router'
import styles from './popup-logout.module.scss'

export const PopupLogout = () => {
  const dispatch = useDispatch()
  const handleClose = () => dispatch(close())
  const handleLogout = () => {
    handleClose()
    dispatch(push('/'))
    dispatch(logout())
  }

  return (
    <>
      <PopupTitle size="small" className="mb-10">
        Logout
      </PopupTitle>
      <Message1619 className="mb-35">Are you sure you want to logout from your account?</Message1619>
      <div className={styles.buttonsWrapper}>
        <div onClick={handleClose} className={styles.cancelButton}>
          Cancel
        </div>
        <Button type="primary" onClick={handleLogout} aria-label="Logout">
          Logout
        </Button>
      </div>
    </>
  )
}
