import React from 'react'
import cn from 'classnames'

import { Icon } from '@/ui/atoms'

import styles from './select-look.module.scss'

export const SelectLook: React.FC<{
  setTypeGrid: any // TODO
  currentGridType?: 'grid' | 'list'
}> = ({ setTypeGrid, currentGridType }) => (
  <div className={cn('mb-40', styles.itemWrapper)}>
    <Icon
      name="icon-grid-view"
      width="24px"
      height="24px"
      className={cn(styles.item, { [styles.active]: currentGridType === 'grid' })}
      onClick={() => setTypeGrid('grid')}
    />
    <Icon
      name="icon-list-view"
      width="24px"
      height="24px"
      className={cn(styles.item, { [styles.active]: currentGridType === 'list' })}
      onClick={() => setTypeGrid('list')}
    />
  </div>
)
