import React, { ReactNode } from 'react'

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

type Props = {
  className?: string
  title: string | ReactNode
}

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
        color: '#555b63;',
        backgroundColor: 'white',
        fontFamily: 'Open Sans',
        fontWeight: 400,
      },
    },
  },
})

const ComponentRef = React.forwardRef((props: any, ref: any) => {
  const { children, ...otherPtops } = props
  return (
    <div {...otherPtops} ref={ref}>
      {children}
    </div>
  )
})

export const CustomizedTooltips: React.FC<Props> = ({ children, title }) => (
  <MuiThemeProvider theme={theme}>
    <Tooltip title={title} interactive>
      <ComponentRef>{children}</ComponentRef>
    </Tooltip>
  </MuiThemeProvider>
)
