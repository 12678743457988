import React from 'react'

import { Button, CustomizedTooltips, Icon, EstShippingPrompt, AveragePricePrompt } from '@/ui/atoms'

import { LotDto } from '@/api/origin/api'

import {
  getCarTitle,
  getCarMil,
  isAvailableCar,
  formatAuctionDate,
  getShortDescription,
  getUSAFormatPrice,
  HTMLDecoder,
  useResize,
} from '@/lib'
import { ConditionPrompt } from '@/pages/index/page-vehicles/ui/molecules'

import { Link } from 'react-router-dom'
import { Img } from '@/pages/index/page-vehicles/ui/molecules/vehicle-vertical-card/img'
import { useHistory } from 'react-router'
import { open } from '@/redux/actions/popup-action'
import { BiddingExpensivePopup } from '@/pages/procurement/ui/atoms/bidding-expensive-popup'
import { useDispatch } from 'react-redux'
import { Property } from './property'
import { Actions } from './actions'
import styles from './car.module.scss'

export const Card: React.FC<{ car: LotDto; onDelete: () => void }> = ({ car, onDelete }) => {
  const windowSize = useResize()
  const history = useHistory()
  const dispatch = useDispatch()
  const placeBid = () => {
    if (car.price && car.price > 30000) {
      dispatch(
        open({
          body: <BiddingExpensivePopup />,
          size: 'small',
        }),
      )
    } else {
      history.push(`/procurement/${car.externalId}`)
    }
  }
  return (
    <div className={styles.card}>
      <div className={styles.leftBlock}>
        <div className={styles.img}>
          <Link className={styles.linkImg} to={`/vehicle/${car.externalId}`} target="_blank">
            <Img img={car.images} context="watchlistCard" />
          </Link>
          {windowSize <= 1200 && (
            <div className={styles.actions}>
              <Actions className={styles.actionsIcon} onDelete={onDelete} />
            </div>
          )}
        </div>

        <div className={styles.info}>
          <Link to={`/vehicle/${car.externalId}`} target="_blank" className={styles.link}>
            <h2 className={styles.title}>{getCarTitle(car)}</h2>
          </Link>
          <div className={styles.propertyWrapper}>
            <Property
              classNames={styles.property}
              name="Modification"
              value={getShortDescription(HTMLDecoder(car.modification), 50)}
            />
            <Property classNames={styles.property} name="Mileage" value={getCarMil(car.mileage)} />
            <Property classNames={styles.property} name="Auction" value={car.auctionName} />
            <Property
              classNames={styles.property}
              name="Condition"
              value={
                car.rate && (
                  <>
                    {car.rate}
                    <ConditionPrompt estimate={car.rate} />
                  </>
                )
              }
            />
            <Property
              classNames={styles.property}
              name="Est. Shipping"
              value="$1,200"
              postfix={<EstShippingPrompt value={1200} />}
            />
            <Property
              name="Average Price"
              value={car.averagePrice && getUSAFormatPrice(car.averagePrice)}
              postfix={<AveragePricePrompt />}
            />
          </div>
        </div>
      </div>
      <div className={styles.shoppingManagement}>
        <div className={styles.buttonsPosition}>
          {windowSize > 1200 && (
            <div className={styles.actionsForDesktop}>
              <Actions className={styles.icon} onDelete={onDelete} />
            </div>
          )}
          <Button type="primary" onClick={placeBid} disabled={isAvailableCar(car)} fullWidth aria-label="Place a bid">
            Place a bid
          </Button>
          <div className={styles.price} title="Start Price">
            {car.price ? getUSAFormatPrice(car.price) : '-'}
          </div>
          <div className={styles.positioningStyleAuctionDate}>
            <CustomizedTooltips title="Auction Date">
              <div className={styles.auctionDateWrapper}>
                <Icon name="icon-card-data-small" width="20px" height="20px" className={styles.CalIcon} />
                <div className={styles.auctionDateValue}>{formatAuctionDate(car.auctionDate)}</div>
              </div>
            </CustomizedTooltips>
          </div>
        </div>
      </div>
    </div>
  )
}
