import React from 'react'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { push } from 'connected-react-router'
import { addComparison, removeComparison } from '@/redux/actions/comparison-list-action'
import { open, close } from '@/redux/actions/popup-action'
import { LotDto } from '@/api/origin/api'
import { Store } from '@/redux/store/store.interface'
import { ComparisonListState } from '@/redux/types/comparison-list-type'
import { PopupRemoveComparison, PopupExceededComparison } from '@/pages/index/page-comparison-list/ui/atoms'
import { HTMLDecoder, useResizeHeight, getCarTitle } from '@/lib'
import { PopupAuctionSheet } from '@/pages/index/page-vehicles/ui/atoms'
import { Button, Icon } from '@/ui/atoms'
import { PopupSalesStatistics } from '@/pages/index/page-lot/ui/molecules/popup-sales-statistics'
import { IRouteParams } from '@/lib/types'
import styles from './listing-models-of-action.module.scss'

const getSelectorComparisonList = () => ({ comparisonList }: Store) => comparisonList || []

export const ListingModelsOfAction: React.FC<{
  className?: string
  auction: LotDto
  addWatch: () => void
  removeWatch: () => void
  isWatchCar: boolean
}> = ({ className, auction, addWatch, removeWatch, isWatchCar }) => {
  const comparisonList = useSelector<Store, ComparisonListState>(getSelectorComparisonList())
  const dispatch = useDispatch()
  const params = useParams<IRouteParams>()
  const externalId = auction.externalId || ''
  const isComparison = comparisonList.includes(externalId)
  const windowHeightSize = useResizeHeight()
  const handleComparisonList = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault()
    if (isComparison) {
      openPopupRemoveComparison(externalId)
    } else {
      if (comparisonList.length === 3) {
        return openPopupExceededComparison()
      }
      dispatch(addComparison({ externalId, name: getCarTitle(auction) }))
    }
  }

  const handleCardSheet = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault()
    openPopupCardSheet()
  }

  return (
    <div className={cn(className, styles.buttonsHolder)}>
      <Button
        onClick={handleCardSheet}
        type="tertiary"
        className={styles.buttonItem}
        disabled={!auction.auctionSheetUrl}
        aria-label="Auction Sheet"
      >
        <Icon name="icon-listing-condition-report" width="16px" height="20px" className="mr-10" />
        Auction Sheet
      </Button>
      <Button
        type={isComparison ? 'tertiary-contrariwise' : 'tertiary'}
        onClick={handleComparisonList}
        className={styles.buttonItem}
        aria-label="In Comparison"
      >
        <Icon name="icon-listing-compare" width="24px" height="20px" className="mr-10" />
        {titleComparison(isComparison)}
        {isComparison && (
          <Icon name="remove-from-watchlist" width="20px" height="20px" className={cn(styles.buttonIcon, 'ml-10')} />
        )}
      </Button>
      {isWatchCar ? (
        <Button
          type="tertiary-contrariwise"
          onClick={removeWatch}
          className={styles.buttonItem}
          aria-label="In Watchlist"
        >
          <Icon
            name="icon-listing-add-watchlist"
            width="24px"
            height="22px"
            className={cn(styles.buttonIcon, 'mr-10')}
          />
          In Watchlist
          <Icon name="remove-from-watchlist" width="20px" height="20px" className={cn(styles.buttonIcon, 'ml-10')} />
        </Button>
      ) : (
        <Button type="tertiary" onClick={addWatch} className={styles.buttonItem} aria-label="Add to Watchlist">
          <Icon
            name="icon-listing-add-watchlist"
            width="24px"
            height="22px"
            className={cn(styles.buttonIcon, 'mr-10')}
          />
          Add to Watchlist
        </Button>
      )}

      <Button type="tertiary" disabled className={styles.buttonItem} aria-label="Financing Option">
        <Icon name="icon-listing-fin-options" width="24px" height="24px" className="mr-10" />
        Financing Option
      </Button>
      <Button
        type="tertiary"
        className={styles.buttonItem}
        aria-label="Financing Option"
        onClick={handleSalesStatisticSheet}
      >
        <Icon name="icon-listing-fin-options" width="24px" height="24px" className="mr-10" />
        Sales Statistics
      </Button>
    </div>
  )

  function titleComparison(isActive: boolean) {
    return isActive ? 'In Compare' : 'Compare'
  }

  function openPopupRemoveComparison(externalId: string) {
    dispatch(
      open({
        body: (
          <PopupRemoveComparison
            cancel={() => dispatch(close())}
            d={() => dispatch(removeComparison({ externalId, name: getCarTitle(auction) }))}
          />
        ),
      }),
    )
  }

  function openPopupExceededComparison() {
    dispatch(
      open({
        body: <PopupExceededComparison cancel={() => dispatch(close())} d={() => dispatch(push('/comparison-list'))} />,
      }),
    )
  }

  function openPopupCardSheet() {
    dispatch(
      open({
        body: (
          <PopupAuctionSheet
            d={() => dispatch(push('/how-to-read-auction-sheet'))}
            cancel={() => dispatch(close())}
            img={auction.auctionSheetUrl ? auction.auctionSheetUrl : ''}
            bodyWrapper={HTMLDecoder(auction.chassis)}
          />
        ),
        height: 'auto',
        noScroll: windowHeightSize > 700,
        isCloseButton: false,
      }),
    )
  }

  function handleSalesStatisticSheet() {
    dispatch(
      open({
        body: <PopupSalesStatistics auction={auction} id={params.id} cancel={() => dispatch(close())} />,
        height: 'auto',
        noScroll: windowHeightSize > 700,
        isCloseButton: false,
      }),
    )
  }
}
