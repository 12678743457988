import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component'
import { LanguageLink, AveragePrice, EstShipping, CustomizedTooltips } from '@/ui/atoms'
import { IExploreOurCar } from '@/redux/types/seo-type'
import { VerticalCardProperties, SourceOfSupply } from '@/pages/index/page-vehicles/ui/atoms'
import { getUSAFormatPrice, getCarTitle } from '@/lib'
import { getSelectorAuctionById } from '@/redux/selectors/procurement-selector'
import { Store } from '@/redux/store/store.interface'
import { LotDto } from '@/api/origin/api'
import styles from './explore-our-cars-card.module.scss'

type Props = {
  car: IExploreOurCar
}

export const ExploreOurCarsCard: React.FC<Props> = ({ car }) => {
  const auction = useSelector<Store, LotDto>(getSelectorAuctionById(car.external_id)) || null

  const field = {
    image: car.image,
    title: auction ? getCarTitle(auction) : car.title,
    price: auction && auction.price ? auction.price : Number(car.price),
    est_shipping: auction ? 1200 : Number(car.est_shipping),
    mileage: auction && auction.mileage ? auction.mileage : Number(car.mileage),
    date: auction && auction.auctionDate ? auction.auctionDate : new Date(car.date),
    grade: auction && auction.rate ? auction.rate : Object.keys(car.grade)[0],
    auction: auction && auction.auctionName ? auction.auctionName : car.auction,
  }

  const [linkCar, setLinkCar] = useState('/vehicles')
  useEffect(() => {
    if (auction) {
      setLinkCar(`/vehicle/${car.external_id}`)
    }
  }, [car.external_id, auction])

  return (
    <>
      <LanguageLink to={linkCar} target="_blank" className={styles.wrapperComponent}>
        <div className={styles.wrapper}>
          <LazyLoadImage className={styles.image} src={field.image} alt="img" />
        </div>
        <LazyLoadComponent>
          <div className={styles.wrapperInfo}>
            <div className={styles.titlePromptWrapper}>
              <CustomizedTooltips title={field.title}>
                <h3 className={styles.title}>{field.title}</h3>
              </CustomizedTooltips>
            </div>
            <div className={styles.wrapperPriceDate}>
              <div className={styles.wrapperPriceDateText}>Starting Bid:&nbsp;</div>
              <div className={styles.price}>{getUSAFormatPrice(field.price)}</div>
            </div>
            <AveragePrice value={field.price} className={styles.averagePrice} />
            <EstShipping value={field.est_shipping} className={styles.estShipping} />
            <VerticalCardProperties mileage={field.mileage} auctionDate={field.date} rate={field.grade} />
          </div>
          <div className={styles.wrapperSourceOfSupply}>
            <SourceOfSupply value={field.auction} flag="japan" />
          </div>
        </LazyLoadComponent>
      </LanguageLink>
    </>
  )
}
