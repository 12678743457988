import { ActionsObservable, combineEpics } from 'redux-observable'

import { Action } from 'typescript-fsa'
import {
  signInWithTokenXSRF,
  logout,
  NOTHING,
  authorize,
  registration,
  activated,
  resetPassword,
  confirmNewPassword,
} from '@/redux/actions/auth-action'
import { switchMap, mergeMap, catchError, first } from 'rxjs/operators'
import { of, from } from 'rxjs'
import { accountApi, userApi } from '@/api'
import { SignOut } from '@/api/account'
import {
  AccountResourceApiSaveAccountUsingPOSTRequestAction,
  UserResourceApiReadUserUsingGETRequestAction,
  DashboardResourceApiIndexFavoritesUsingGETRequestAction,
  AccountResourceApiGetAccountUsingGETRequestAction,
} from '@/api/origin/action'
import { setupAxiosInterceptors } from '@/lib/axios-interceptor'
import { ofAction } from '@/lib/rxjs-operators'
import { close } from '@/redux/actions/popup-action'
import nanoid from 'nanoid'
import { create } from '@/redux/actions/snack-action'
import React from 'react'
import { Snackbar } from '@/pages/index/page-comparison-list/ui/atoms'

const authorizeEpic = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(authorize),
  mergeMap(({ payload: { managedUserVM } }) => from(accountApi.signInUserUsingPOST(managedUserVM)).pipe(
    mergeMap(() => [signInWithTokenXSRF.started({}), close()]),
    catchError((_error) => {
      const id = nanoid()
      return of(create({ body: 'Sorry, the email and password you entered do not match. Please try again', id }))
    }),
  ),
  ),
)

const registrationEpic = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(registration),
  mergeMap(({ payload: { managedUserVM } }) => from(accountApi.registerAccountUsingPOST(managedUserVM)).pipe(
    mergeMap((_result) => {
      const id = nanoid()
      return of(
        create({
          body: React.createElement(
            Snackbar,
            { text: `We've sent an email to ${managedUserVM.email} Open it up to activate your account.` },
            null,
          ),
          id,
        }),
      )
    }),
    catchError((error) => {
      const title = error.toString().includes('409')
        ? `Email ${managedUserVM.email} already used!`
        : 'Something went wrong, try again later'
      const id = nanoid()
      return of(create({ body: React.createElement(Snackbar, { text: title }, null), id }))
    }),
  ),
  ),
)

const loginWithTokenXSRFEpic = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(signInWithTokenXSRF.started),
  switchMap(() => accountApi.getAccountUsingGET()),
  mergeMap((result) => {
    setupAxiosInterceptors()
    if (result.status === 200) {
      return of(AccountResourceApiGetAccountUsingGETRequestAction.done({ result: result.data, params: {} }))
    } else {
      const id = nanoid()
      return of(
        create({
          body: React.createElement(Snackbar, { text: 'Something went wrong. Please try again later' }, null),
          id,
        }),
      )
    }
  }),
  catchError((_error: any) => of(NOTHING())),
)

const getAccountEpic = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  first(),
  switchMap(() => accountApi.getAccountUsingGET()),
  mergeMap((result) => {
    if (result.status === 200) {
      return of(AccountResourceApiGetAccountUsingGETRequestAction.done({ result: result.data, params: {} }))
    }
    return of(NOTHING())
  }),
  catchError((_error: any) => of(AccountResourceApiGetAccountUsingGETRequestAction.done({ result: {}, params: {} }))),
)

const finishLoginWithJWTTokenEpic = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(signInWithTokenXSRF.done as any),
  mergeMap(() => [DashboardResourceApiIndexFavoritesUsingGETRequestAction.started({ options: {} })]),
)

const logoutEpic = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(logout),
  mergeMap(async () => SignOut()),
  mergeMap(() => accountApi.getAccountUsingGET()),
  mergeMap(() => {
    // if (!window.location.hash) {
    //   window.location.replace(window.location.href)
    // } else {
    //   window.location.reload(true)
    // }
    window.location.replace(window.location.href)
    return of(NOTHING())
  }),
)

const accountActivatedEpic = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(activated),
  mergeMap(({ payload: { userId, token } }) => from(accountApi.accountActivatedUserUsingPUT(userId, token)).pipe(
    mergeMap(() => of(NOTHING())),
    catchError((_error) => {
      const id = nanoid()
      return of(create({ body: 'Sorry, the email and password you entered do not match. Please try again', id }))
    }),
  ),
  ),
)

const getUserEpic = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(UserResourceApiReadUserUsingGETRequestAction.started),
  mergeMap(({ payload: { userId } }) => from(userApi.readUserUsingGET(userId)).pipe(
    mergeMap(({ data }) => of(UserResourceApiReadUserUsingGETRequestAction.done({ result: data, params: { userId: 0 } })),
    ),
    catchError((_error) => of(UserResourceApiReadUserUsingGETRequestAction.failed(_error))),
  ),
  ),
)

const saveUserEpic = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(AccountResourceApiSaveAccountUsingPOSTRequestAction.started),
  mergeMap(({ payload: { userDTO } }) => from(accountApi.saveAccountUsingPOST(userDTO)).pipe(
    mergeMap(() => {
      const id = nanoid()
      return of(
        create({
          body: React.createElement(Snackbar, { text: 'Changes saved successfully' }, null),
          id,
        }),
      )
    }),
    catchError((_error) => {
      const id = nanoid()
      return of(
        create({
          body: React.createElement(Snackbar, { text: 'Something went wrong. Please try again later' }, null),
          id,
        }),
      )
    }),
  ),
  ),
)

const resetPasswordEpic = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(resetPassword),
  mergeMap(({ payload: { email } }) => from(accountApi.resetPasswordPOST(email)).pipe(
    mergeMap(() => {
      const id = nanoid()
      return of(
        create({
          body: React.createElement(
            Snackbar,
            { text: "We've sent you an email. Open it up to change your password." },
            null,
          ),
          id,
        }),
      )
    }),
    catchError((_error: any) => {
      const id = nanoid()
      return of(
        create({
          body: React.createElement(Snackbar, { text: 'Something went wrong. Please try again later' }, null),
          id,
        }),
      )
    }),
  ),
  ),
)

const confirmPasswordEpic = (action$: ActionsObservable<Action<any>>) => action$.pipe(
  ofAction(confirmNewPassword),
  mergeMap(({ payload: { userId, token, newPassword } }) => from(accountApi.confirmNewPasswordPUT(userId, token, newPassword)).pipe(
    mergeMap(() => of(signInWithTokenXSRF.started({}))),
    catchError((_error: any) => {
      const id = nanoid()
      return of(
        create({
          body: React.createElement(Snackbar, { text: 'Something went wrong. Please try again later' }, null),
          id,
        }),
      )
    }),
  ),
  ),
)

export const authEpic = combineEpics(
  loginWithTokenXSRFEpic,
  getAccountEpic,
  finishLoginWithJWTTokenEpic,
  logoutEpic,
  authorizeEpic,
  saveUserEpic,
  getUserEpic,
  registrationEpic,
  accountActivatedEpic,
  resetPasswordEpic,
  confirmPasswordEpic,
)
