import React from 'react'
import cn from 'classnames'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Store } from '@/redux/store/store.interface'
import { AuthState } from '@/redux/types/auth-type'
import styles from './options.module.scss'

type Props = {
  active?: boolean
  logout: () => void
}

export const Options = withRouter(({ active = false, logout, history }: Props & RouteComponentProps) => {
  const { user } = useSelector<Store, AuthState>((store) => store.auth)
  const authorities = user && user.authorities ? user.authorities : []
  return (
    <div className={cn(styles.wrapperComponent, { [styles.active]: active })}>
      {authorities && authorities.includes('ROLE_MANAGER') && (
        <div className={styles.listSection}>
          <div className={styles.listItem} onClick={handleClickManagement}>
            Managers Dashboard
          </div>
        </div>
      )}
      <div className={styles.listSection}>
        <div className={styles.listItem} onClick={handleClickPersonal}>
          Personal Information
        </div>
      </div>
      <div className={styles.listSection}>
        <div className={styles.listItem} onClick={handleClickBids}>
          My Bids
        </div>
        <div className={styles.listItem} onClick={handleClickOrders}>
          My Orders
        </div>
        <div className={styles.listItem} onClick={handleClickWallet}>
          My Wallet
        </div>
      </div>
      <div className={styles.listSection}>
        <div className={styles.listItem} onClick={handleClickWatchlist}>
          Watchlist
        </div>
      </div>
      <div className={styles.listSection}>
        <div className={cn(styles.listItem, styles.depositLink)} onClick={handleClickMakeDeposit}>
          Payment Methods
        </div>
      </div>
      <div className={cn(styles.listSection, styles.last)} onClick={logout}>
        <div className={styles.listItem}>Logout</div>
      </div>
    </div>
  )

  function handleClickManagement() {
    history.push('/management/clients')
  }

  function handleClickMakeDeposit() {
    history.push('/dashboard/payment-information-user')
  }

  function handleClickWallet() {
    history.push('/dashboard/my-wallet')
  }

  function handleClickOrders() {
    history.push('/dashboard/my-orders')
  }

  function handleClickBids() {
    history.push('/dashboard/my-bids')
  }

  function handleClickPersonal() {
    history.push('/dashboard/personal-information')
  }

  function handleClickWatchlist() {
    history.push('/dashboard/watchlist')
  }
})
