import React, { useState } from 'react'
import cn from 'classnames'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
// @ts-ignore
import ItemsCarousel from 'react-items-carousel'
import { useResize } from '@/lib'
import { WhatOurClientsSayingCard, SectionHeaderDynamic } from '@/ui/atoms'
import styles from './what-our-clients-saying.module.scss'

export const WhatOurClientsSaying: React.FC<{
  className?: string
  testimonials: {
    title: string
    testimonials: {
      logo: string
      customer: string
      description: string
      rate: number
      link: {
        url: string
        text: string
        target: string
      }
    }[]
  }
}> = ({ className, testimonials }) => {
  const windowSize = useResize()
  const itemWidth = windowSize < 1400 ? 325 : 420
  const mobile = windowSize < 800
  const count = Math.floor(windowSize / itemWidth)
  const chevronWidth = mobile ? 15 : 40
  const [activeItemIndex, setActiveItemIndex] = useState(0)

  return (
    <section className={cn(className, styles.section)}>
      <LazyLoadComponent>
        <div className={styles.sectionWrapper}>
          <SectionHeaderDynamic type="light" className={styles.sectionHeaderDynamic} text={testimonials.title} />
          <div className={styles.carouselWrapper} style={{ padding: `0 ${chevronWidth}px` }}>
            <ItemsCarousel
              requestToChangeActive={setActiveItemIndex}
              activeItemIndex={activeItemIndex}
              numberOfCards={count}
              gutter={20}
              infiniteLoop
              leftChevron={(
                <div className={cn(styles.buttonWrapper, styles.buttonPrevWrapper, { [styles.disabled]: mobile })}>
                  <button
                    className={cn(styles.controlArrow, styles.controlPrev)}
                    aria-label="Left Button"
                    name="Left button"
                  />
                </div>
              )}
              rightChevron={(
                <div className={cn(styles.buttonWrapper, { [styles.disabled]: mobile })}>
                  <button
                    className={cn(styles.controlArrow, styles.controlNext)}
                    aria-label="Right Button"
                    name="Right button"
                  />
                </div>
              )}
              outsideChevron
              chevronWidth={chevronWidth}
            >
              {testimonials.testimonials.map((i, index) => (
                <WhatOurClientsSayingCard
                  description={i.description}
                  customer={i.customer}
                  logo={i.logo}
                  link={i.link}
                  rate={i.rate}
                  key={index}
                />
              ))}
            </ItemsCarousel>
            <div className={styles.controlDots}>
              {testimonials.testimonials.map((i: any, index: number) => (
                <button
                  key={index}
                  onClick={() => setActiveItemIndex(index)}
                  className={cn(styles.dot, {
                    [styles.active]:
                      index === activeItemIndex || activeItemIndex % testimonials.testimonials.length === index,
                  })}
                  aria-label={`Dot - ${index}`}
                  name="Dot button"
                />
              ))}
            </div>
          </div>
        </div>
      </LazyLoadComponent>
    </section>
  )
}
