import React from 'react'
import { Icon } from '@/ui/atoms'
import { Link } from 'react-router-dom'

import styles from './snackbar-add-watch.module.scss'

export const SnackbarAddWatch: React.FC<{ name: string }> = ({ name }) => (
  <div className={styles.wrapperContent}>
    <Icon name="icon-snackbar-success" width="40px" height="40px" />
    <div>
      <div className={styles.messageText}>
        {name}
        {' '}
        added to the watchlist.
      </div>
      <Link className={styles.link} to="/dashboard/watchlist">
        View
      </Link>
    </div>
  </div>
)
