import { useEffect, useState } from 'react'
import _throttle from 'lodash/throttle'

export const useResize = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = _throttle(() => {
      setWindowSize(window.innerWidth)
    }, 600)

    window.addEventListener('resize', handleResize, { passive: true })

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return windowSize
}
